import React from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import {
  validateAadhar,
  validateAge,
  validateEmail,
  validatePhone,
} from "../../../../constants/validationOnBlur";

const PersonalDetails = ({ data, handleChange, showEmptyError }) => {
  return (
    <Container fluid>
      <Row className="form-container">
        <Col>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicID">
                <Form.Label>
                  Full Name<span className="star"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Employee Name"
                  name="fullname"
                  value={data.fullname}
                  onChange={handleChange}
                  isInvalid={data.fullname === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Employee Name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Row md={2} sm={1} xs={1}>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      Phone Number<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="10"
                      max="10"
                      placeholder="Phone Number"
                      name="phoneno"
                      value={data.phoneno}
                      onChange={handleChange}
                      onBlur={() => validatePhone(data.phoneno)}
                      isInvalid={data.phoneno === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Contact Number
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Aadhar Number</Form.Label>
                    <Form.Control
                      type="number"
                      min="12"
                      max="12"
                      placeholder="Adhar Number"
                      name="aadharno"
                      value={data.aadharno}
                      onChange={handleChange}
                      onBlur={() => validateAadhar(data.aadharno)}
                      isInvalid={data.aadharno === "" && showEmptyError}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Row md={2} sm={1} xs={1}>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      DOB<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Select Date Of Birth"
                      name="dob"
                      max={`${new Date().toISOString().split("T")[0]}`}
                      value={data.dob}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                      onBlur={() => validateAge(data.age)}
                      isInvalid={data.dob === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Select Date Of Birth
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Age</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="age"
                      value={`${data.age ? data.age + " yrs" : "0"}`}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Gender<span className="star"> *</span>
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    className="form-label"
                    name="gender"
                    type="radio"
                    label="Male"
                    value="Male"
                    checked={data.gender === "Male"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    className="form-label"
                    name="gender"
                    type="radio"
                    label="Female"
                    value="Female"
                    checked={data.gender === "Female"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    className="form-label"
                    name="gender"
                    type="radio"
                    label="Other"
                    value="Other"
                    checked={data.gender === "Other"}
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  Select Gender
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Image
                    style={{ width: "30%" }}
                    src={data.photo}
                    alt={data.fullname}
                    rounded
                  />
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Update Photo</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Select File"
                      name="picture"
                      value={data.picture}
                      onChange={handleChange}
                      required
                      accept="image/*"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicID">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  name="emailid"
                  value={data.emailid}
                  onChange={handleChange}
                  onBlur={() => validateEmail(data.emailid)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PersonalDetails;
