
export const getStatusColorClass = (status) => {
    let colorClass;
  
    switch (status) {
      case 'Unpaid':
        colorClass = 'text-danger';
        break;
      case 'Draft':
        colorClass = 'text-warning';
        break;
      case 'Paid':
        colorClass = 'text-success';
        break;
      case 'Partially Paid':
        colorClass = 'text-primary';
        break;
      default:
        colorClass = ''; // Default color
    }
  
    return colorClass;
  };


  export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided, state) => ({
      ...provided,
      color: 'black',
      width: '150px',  // Set the desired width for the menu
    }),
    menuList: (provided, state) => ({
      ...provided,
      color: 'black',
      width: '150px',  // Ensure the menu list matches the menu width
    }),
    control: (provided, state) => ({
      ...provided,
      color: 'black',
      minHeight: '25px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'grey',
    }),
    input: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  export const customStylesItem = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
    menu: (provided, state) => ({
      ...provided,
      color: 'black',
      width: '400px',  // Set the desired width for the menu
    }),
    menuList: (provided, state) => ({
      ...provided,
      color: 'black',
      width: '400px',  // Ensure the menu list matches the menu width
    }),
    control: (provided, state) => ({
      ...provided,
      color: 'black',
      minHeight: '25px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'grey',
    }),
    input: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };