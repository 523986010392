import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { CgAttachment } from "react-icons/cg";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ViewJournal = ({ data }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    //dispatch(reloadPage(true));
  };
  const containerRef = useRef(null);

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=1000,height=1000");
    const modalContent = containerRef.current;
    const tablepartToPrint = modalContent.querySelector(".print-table").innerHTML;
    printWindow.document.open();
    printWindow.document.write(`
           <html>
            <head>
            <link rel="stylesheet" type="text/css" href="ReceiveGoods.css" media="print">
            <style>
              body {
              font-family: Arial, sans-serif;
              font-size:1.2rem
              }

              .modalHeader{
                display:flex;
                justify-content:space-between;
                padding-bottom:0.5rem;
                border-bottom:2px solid grey;
                width:100%;
              }
 
              .printTittle{
                margin-bottom: -0.2rem;
              }
 
              .gayab{
                display:none;
              }

              .listview{
                width: 50%;
                display : flex;
                justify-content:space-between;
                gap:1rem;
              }
              .listview-total{
                text-align:right;
                width: 100%;
                display : flex;
                justify-content:space-between;
                flex-direction:row-reverse;
              }
 
              hr{
                display: none;
              }
             
              table{
                width:100%;
                text-align: center;
              }
              thead {
                background-color: #2E4374;
                color: #F5F7F8;
              }
 
              th{
                padding: 0.5rem 2.50rem;
                border-inline: 0.5px solid #d0d5dd;
              }
 
              tbody{
                padding: 1rem;
                background:#F5F7F8;
                font-size:1rem;
                font-weight:normal
              }
 
              tbody tr{
                border-bottom:3px solid grey;
              }
 
              tbody tr td{
                padding:0.5rem 0rem;
              }
            </style>
          <title>Print</title>
          </head>
          <body>
          <h1>Journal</h1>
          <div class="listview">
           <div>
            <p>Status</p>
            <p>Journal No.</p>
            <p>Date</p>
            <p>Amount</p>
            <p>Note</p>
           </div>
            <div>
              <p>${data.status}</p>
              <p>${data.journalno}</p>
              <p>${data.date ? formatDateToDDMMYYYY(data.date) : []}</p>
              <p>${data.totaldebits}</p>
              <p>${data.note}</p>
            </div>
          </div>
    `);
    printWindow.document.write(tablepartToPrint);
    printWindow.document.write(`
    <div class="listview-total">
         <div style="width:60%; display:flex; justify-content:space-between; margin-right:80px;margin-top:40px;">
         <span>Total</span>
         <span>${data.totaldebits}</span>
         <span>${data.totalcredits}</span>
         </div>
    </div>
    `);
    printWindow.document.write(`</body></html>`);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleDownloadAndOpen = () => {
    // Download files (You need to implement this part based on your data structure)
    // data.documents.forEach((item,index) => {
    //   window.open(item, '_blank');
    //   // Create a temporary link element
    //   // const tempLink = document.createElement('a');
    //   // tempLink.href = item;
    //   // tempLink.target = '_blank'; // Open in a new tab/window
    //   // document.body.appendChild(tempLink);
    //   // // Trigger a click on the temporary link to open the document
    //   // setTimeout(() => {
    //   //   // Trigger a click on the temporary link to open the document
    //   //   tempLink.click();

    //   //   // Remove the temporary link
    //   //   document.body.removeChild(tempLink);
    //   // }, 200 * index);
    // });
  };

  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <div ref={containerRef}>
          <Modal.Header closeButton>
            <Modal.Title className="d-flex justify-content-between align-item-center my-auto w-100">
              <div className="form-title my-auto">Summary</div>
              <div className="my-auto">
                <span className="viewpage-title ">Status:</span>
                <span className={data.status === "Publish" ? "publish-status" : "draft-status"}>
                  {data.status}
                </span>
              </div>
              <div>
                <Button
                  className="commonBtn smallBtn cancelBtn"
                  onClick={handlePrint}
                >
                  Print
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row md={6}>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="date"
                    value={data.date}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Journal No.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Journal No."
                    name="journalno"
                    value={data.journalno}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row md={6}>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicDescription"
                >
                  <Form.Label>Note</Form.Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Type here..."
                    name="note"
                    value={data.note}
                    readOnly
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <div className="print-table">
            <Table responsive className="table-main-container text-center ">
              <thead className="tableContainer">
                <tr>
                  <th>Account</th>
                  <th>Ledger</th>
                  <th>Description</th>
                  <th>Debits</th>
                  <th>Credits</th>
                </tr>
              </thead>
              <tbody>
                {data.journalarray.map((item, index) => (
                  <tr key={index}>
                    <td>{item.account}</td>
                    <td>{item.ledger}</td>
                    <td>{item.description}</td>
                    <td>{item.debits}</td>
                    <td>{item.credits}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </div>
            <Container className="gayab" fluid>
              <Row>
                <Col></Col>
                <Col
                  style={{
                    background: "var(--text, #F5F5F5)",
                    borderRadius: "16px",
                    padding: "30px",
                    fontSize: "20px",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <Form.Label>Total</Form.Label>
                    </Col>
                    <Col>
                      <p>{data.totaldebits}</p>
                    </Col>
                    <Col>
                      <p>{data.totalcredits}</p>
                    </Col>
                  </Row>
                  <Row className="star">
                    <Col>
                      <Form.Label>Difference</Form.Label>
                    </Col>
                    <Col>
                      <p>
                        {data.totaldebits < data.totalcredits
                          ? data.totalcredits - data.totaldebits
                          : "0.00"}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        {data.totaldebits > data.totalcredits
                          ? data.totaldebits - data.totalcredits
                          : "0.00"}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Row>
              <Col>
                <Button
                  variant="link"
                  style={{ textDecoration: "none" }}
                  onClick={handleDownloadAndOpen}
                >
                  <span>
                    <CgAttachment />
                  </span>
                  {data.documents.length} Attachment
                  {data.documents.length > 1 ? "(s)" : ""}
                </Button>
              </Col>
              <Col></Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ViewJournal;
