import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const BankInformationForm = ({data, handleChange,showEmptyError}) => {
  return (
    <Container fluid>
      <Row className="form-container">
        <Col sm={12}>
          <h3>
            <span className="form-title"> Bank Details</span>
          </h3>
        </Col>
        <hr />
        <Form className="form-lable-name">
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Bank Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Bank Name"
                  name="bankname"
                  value={data.bankname}
                  onChange={handleChange}
                  // isInvalid={!data.bankname && showEmptyError}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Branch 
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Branch Name"
                  name="bankbranch"
                  value={data.bankbranch}
                  onChange={handleChange}
                  // isInvalid={!data.bankbranch && showEmptyError}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Account Number
                </Form.Label>
                <Form.Control
                  type="number"
                  name="accountnumber"
                    value={data.accountnumber}
                    onChange={handleChange}
                  placeholder="Enter Account Number"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  IFSC Code 
                </Form.Label>
                <Form.Control
                  type="text"
                  name="ifsccode"
                    value={data.ifsccode}
                    onChange={handleChange}
                  placeholder="Enter IFSC Code "
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Remarks
                </Form.Label>
                <Form.Control
                  type="text"
                  name="remarks"
                  value={data.remarks}
                  onChange={handleChange}
                  placeholder="Enter Remarks.. "
                />
                
              </Form.Group>
            
            </Col>
            <Col md={6}>

            </Col>

          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default BankInformationForm;
