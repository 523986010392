import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListJournal from "./ListJournal";

const ManualJournal = () => {
  //state
  const [currentTab, setCurrentTab] = useState("");
  const URL = extractAndFormatURLPart("accounting");
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
          <span>{URL}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListJournal/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ManualJournal;
