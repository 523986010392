import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { vendorData } from "../../../redux/actions/vendor.actions";
import SearchField from "../../../constants/SearchField";
import axios from "axios";
import { purchaseTargetDashboardData } from "../../../redux/actions/purchaseTargetDashboard.actions";
import GlobalPagination from "../../../constants/GlobalPagination";

const ListTablePurchaseTarget = () => {
  const financialYear = localStorage.getItem("financialyear") || "";
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];
  const purchaseTargetData = useSelector(
    (state) => state.purchaseTargetDashboardData.purchaseTargetDashboardData
  );
  const filteredData = !purchaseTargetData.data ? [] : purchaseTargetData.data;
  console.log("purchaseTargetData", purchaseTargetData)
  //pagination data
  const totalPagesFromAPI = {
    totalRecords: purchaseTargetData.totalItems ? purchaseTargetData.totalItems : 0,
    totalPages: purchaseTargetData.totalPages ? purchaseTargetData.totalPages : 0,
  };

  const [data, setData] = useState({
    vendor: "",
    targettype: "",
    from: "",
    to: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      purchaseTargetDashboardData(
        {
          vendor: data.vendor,
          targettype: data.targettype,
          from: data.from,
          to: data.to,
          userid,
          branchid,
          keyword: searchKeyword || "₹ΘθϖΠ",
          companyid,
          page: currentPage,
          pageSize: pageSize
        },
        setLoading
      )
    );
    dispatch(vendorData({ companyid, userid, keyword: "" }, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, data.vendor, data.from, data.to, data.targettype]);



  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/crud/getsubmodule`, {

        userid,
        submodule: "Purchase Target",
        companyid,
      })
      .then((res) => {

        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row className="p-3">
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Vendor</Form.Label>
              <Form.Select
                name="vendor"
                value={data.vendor}
                onChange={handleChange}
              >
                <option value="">Select</option>
                {vendorMaster?.map((vendor) => {
                  return (
                    <option value={vendor.fullname} key={vendor.id}>
                      {vendor.fullname}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Filter By</Form.Label>
              <Form.Select
                name="targettype"
                value={data.targettype}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="Item">Item</option>
                <option value="Category">Category</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>From</Form.Label>
              <Form.Control
                type="date"
                name="from"
                value={data.from}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>To</Form.Label>
              <Form.Control
                type="date"
                name="to"
                value={data.to}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Purchase Target</span>
            </p>
          </Col>
          <Col></Col>
          <Col lg={3}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Scheme No</th>
              <th>Scheme Name</th>
              <th>Vendor Name</th>
              <th>Item Name</th>
              <th>Category</th>
              <th>Brand</th>
              <th>Set By</th>
              <th>Tier I</th>
              <th>Total Achievement</th>
              <th>Achievement %</th>
            </tr>
          </thead>
          <tbody>
            {loading || loadData ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((purchase, ind) => (
                <tr key={ind}>
                  <td>{purchase.schemeno ? purchase.schemeno : "--"}</td>
                  <td>{purchase.schemename ? purchase.schemename : "--"}</td>
                  <td>{purchase.vendor ? purchase.vendor : "--"}</td>
                  <td>{purchase.itemname ? purchase.itemname : "--"}</td>
                  <td>{purchase.category ? purchase.category : "--"}</td>
                  <td>{purchase.brand ? purchase.brand : "--"}</td>
                  <td>{purchase.setby ? purchase.setby : "--"}</td>
                  <td>{purchase.tier1 ? purchase.tier1 : "--"}</td>
                  {purchase.setby == "Qty" ? (
                    <td>{purchase.total_qty ? purchase.total_qty : 0}</td>
                  ) : (
                    <td>{purchase.total_amount ? purchase.total_amount : 0}</td>
                  )}
                  <td>
                    {purchase.achievementpercentage
                      ? purchase.achievementpercentage
                      : 0}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTablePurchaseTarget;
