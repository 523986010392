import {
  manualJournalAddPost,
  manualJournalCountPost,
  manualJournalDocumentUploadPost,
  manualJournalPagiDataPost,
  manualJournalUpdatePost,
} from "../../constants/api";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_MANUALJOURNAL_DOCUMENT,
  FETCH_MANUAL_JOURNAL_COUNT,
  FETCH_MANUAL_JOURNAL_DATA,
} from "../actionTypes/manualjournal.actiontypes";

//Get all manualJournal data using pagination wise
export const manualJournalPagiData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(manualJournalPagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_MANUAL_JOURNAL_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching manualJournal data ${error}`);
      notifyError(error.message);
    }
  };

//Get manualJournal auto increment count
export const manualJournalAutoCount =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(manualJournalCountPost, backendData);
      const initialCount = Number(res.data.count) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: FETCH_MANUAL_JOURNAL_COUNT, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching manualJournal auto count data ${error}`);
      notifyError(error.message);
    }
  };

export const manualJournalDocmnentUpload =
  (backendData) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const formData = new FormData();
      for (let i = 0; i < backendData.length; i++) {
        formData.append("file", backendData[i]);
      }
      const res = await axios.post(
        manualJournalDocumentUploadPost,
        backendData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res.data.message === "Branch Activated") {
        // notifySuccess(res.data.message);
        dispatch({ type: FETCH_MANUALJOURNAL_DOCUMENT, payload: res });
      } else {
        notifyWarning(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
      notifyError(error.message);
    }
  };

//Acion dispatcher for adding new coa data
export const addmanualJournal = (backendData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(manualJournalAddPost, backendData);
    if (res.data.message === "ManualJournal added successfully") {
      notifySuccess(res.data.message);
      navigate("/accounting/manualJournal");
    } else {
      notifyError(res.data.message);
    }
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new manualJournal data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating Manual Journal data
export const updateManualJournal =(backendData, navigate) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(manualJournalUpdatePost, backendData);
      if (res.data.message === "ManualJournal Updated successfully") {
        notifySuccess(res.data.message);
        navigate("/accounting/manualJournal");
      } else {
        notifyError(res.data.message);
      }
      navigate("/accounting/manualJournal");
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating manualJournal data ${error}`);
      notifyError(error.response.data.message);
    }
  };
