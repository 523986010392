import { FETCH_PURCHASEORDER_DATA } from "../actionTypes/purchaseOrder.actiontypes";


  const initialState = {

    purchaseOrderData: {},

  };
  
  //
  export const  purchaseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_PURCHASEORDER_DATA: {
        return { ...state, purchaseOrderData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };