import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListItem from "./ListItem";

const Item = () => {
  const URL = extractAndFormatURLPart("inventory");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListItem />
        </Col>
      </Row>
    </Container>
  );
};

export default Item;
