import {
  FETCH_BANK_COUNT,
  FETCH_BANK_DATA,
} from "../actionTypes/bank.actiontypes";

const initialState = {
  bankPagiData: {},
  bankCount: 0,
};

export const bankReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BANK_DATA: {
      return { ...state, bankPagiData: { ...payload } };
    }

    case FETCH_BANK_COUNT: {
      return { ...state, bankCount: payload };
    }

    default: {
      return state;
    }
  }
};
