
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ListTableFirm from './ListTableFirm';

function FirmManagement() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <ListTableFirm />
        </Col>
      </Row>
    </Container>
  )
}

export default FirmManagement