import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { notifyError } from "../../../../constants/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import { bankPagiData } from "../../../../redux/actions/bank.actions";
import { ledgerPagiData } from "../../../../redux/actions/ledger.actions";
import { updateReceipt } from "../../../../redux/actions/receipt.actions";

const EditReceipt = ({
    showEditModal,
    handleEditModal,
    editItem,
    handleCloseEditModal,
}) => {
    const finalcialYear = localStorage.getItem("financialyear");
    const finalYear = finalcialYear.trim().split("-");

    //state
    const [show, setShow] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [receiptid, setReceiptid] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        id: editItem ? editItem.id : "",
        receiptid: editItem ? editItem.receiptid : "",
        date: editItem ? editItem.date : "",
        month: editItem ? editItem.month : "",
        prefno: editItem ? editItem.prefno : "",
        receivefrom: editItem ? editItem.receivefrom : "",
        receivemode: editItem ? editItem.receivemode : "",
        receiveto: editItem ? editItem.receiveto : "",
        amount: editItem ? editItem.amount : "",
        companyid: editItem ? editItem.companyid : "",
        userid: editItem ? editItem.userid : "",
        org: editItem ? editItem.org : "",
        financialyear: editItem ? editItem.financialyear : ""
    });

    //Redux part
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.loadReducer);
    const { companyid, userid, companyname, branchid, org } = useSelector((state) => state.authManager.userData.data[0]) || {};
    const { receiptCount } = useSelector((state) => state.receiptData) || 0;
    const { data: bankAllData } = useSelector((state) => state.bankData.bankPagiData) || [];
    const { data: ledgerAllData } = useSelector((state) => state.ledgerData.ledgerPagiData) || [];
    const ledgerData = !ledgerAllData ? [] : ledgerAllData.filter((item) => item.ledgertype == "Other");
  

    //Default Data backend needed
    const backendData = {
        keyword: "₹ΘθϖΠ",
        userid: userid,
        companyid: companyid,
        branchid: branchid,
    }

    useEffect(() => {
        setLoading(!loading);
        dispatch(bankPagiData(backendData, setLoading));
        dispatch(ledgerPagiData(backendData, setLoading));

        setData({
            id: editItem ? editItem.id : "",
            receiptid: editItem ? editItem.receiptid : "",
            date: editItem ? editItem.date : "",
            month: editItem ? editItem.month : "",
            prefno: editItem ? editItem.prefno : "",
            receivefrom: editItem ? editItem.receivefrom : "",
            receivemode: editItem ? editItem.receivemode : "",
            receiveto: editItem ? editItem.receiveto : "",
            amount: editItem ? editItem.amount : "",
            companyid: editItem ? editItem.companyid : "",
            userid: editItem ? editItem.userid : "",
            org: editItem ? editItem.org : "",
            financialyear: editItem ? editItem.financialyear : ""
        });
    }, [dispatch, loadData, editItem]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == "receivemode") {
            setData((prevData) => ({
                ...prevData,
                [name]: value,
                receiveto: "",
            }));
        }

        if (name == "date") {
            const dateObj = new Date(value);
            const month = dateObj.toLocaleString('default', { month: 'long' });
            setData((prev) => ({
                ...prev,
                month: month,
            }))
        }

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setShowEmptyError(false);
    };

    const handleUpdate = async () => {
   
        if (
            data.date == "" ||
            data.receivefrom == "" ||
            data.receivemode == "" ||
            data.receiveto == "" ||
            data.amount == ""
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        else{
            dispatch(updateReceipt(data, handleCloseEditModal))
        }
    };

    return (
        <>
            <MdEdit className="editIcon" onClick={handleEditModal} />
            <Modal
                size="xl"
                show={showEditModal}
                onHide={handleCloseEditModal}
                backdrop="static"
                keyboard={false}
                centered={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update <span className="form-title">Receipt</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="form-lable-name">
                        <Container fluid>
                            <Row lg={2} md={1}>
                                <Col>
                                    <Row lg={2} md={1}>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Receipt No.</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Receipt No"
                                                    name="receiptid"
                                                    value={data.receiptid}
                                                    disabled
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>
                                                    Date <span className="star">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="date"
                                                    value={data.date}
                                                    min={`${finalcialYear[0]}-04-01`}
                                                    max={`${new Date().toISOString().split("T")[0]}`}
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    isInvalid={data.date === "" && showEmptyError}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Select Date
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Reference No./ Cheque No.</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Reference No./ Cheque No."
                                            name="prefno"
                                            value={data.prefno}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row lg={2} md={1}>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            Receive From <span className="star">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            name="receivefrom"
                                            value={data.receivefrom}
                                            onChange={handleChange}
                                            isInvalid={data.receivefrom === "" && showEmptyError}
                                        >
                                            <option value="">--Select--</option>
                                            {ledgerData.length > 0 ? (
                                                ledgerData.map((item, index) => {
                                                    const { ledgername } = item;
                                                    return (
                                                        <option key={index} value={ledgername}>
                                                            {ledgername}
                                                        </option>
                                                    );
                                                })
                                            ) : (
                                                <option value="">No Receive from Data Available</option>
                                            )}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Select Paid To
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Row lg={2} md={1}>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>
                                                    Receive Mode <span className="star">*</span>
                                                </Form.Label>
                                                <Form.Select
                                                    name="receivemode"
                                                    value={data.receivemode}
                                                    onChange={handleChange}
                                                    isInvalid={data.receivemode === "" && showEmptyError}
                                                >
                                                    <option value="">--Select--</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="Bank Transfer">Bank Transfer</option>
                                                    <option value="Cheque">Cheque</option>
                                                    <option value="UPI">UPI</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Select Payment Mode
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>
                                                    Receive To <span className="star">*</span>
                                                </Form.Label>
                                                <Form.Select
                                                    name="receiveto"
                                                    value={data.receiveto}
                                                    onChange={handleChange}
                                                    isInvalid={data.receiveto === "" && showEmptyError}
                                                >
                                                    <option value="">--Select--</option>
                                                    {data.receivemode === "Cash"
                                                        ?
                                                        // bankAllData.map((item, index) => {
                                                        //     const { bank, branchname, accountnumber } = item;
                                                        //     return (
                                                        //         <option key={index} value={`${bank}, ${branchname}, ${accountnumber}`}>
                                                        //             {`${bank}, [${branchname}], ${accountnumber}`}
                                                        //         </option>
                                                        //     );
                                                        // })
                                                        <option value="Company Cash Account">Company Cash Account</option>
                                                        : data.paymentmode !== "" &&
                                                        bankAllData && bankAllData.map((item, index) => {
                                                            const { bank, branchname, accountnumber } =
                                                                item;
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={`${bank}, ${branchname}, ${accountnumber}`}
                                                                >
                                                                    {`${bank}, [${branchname}], ${accountnumber}`}
                                                                </option>
                                                            );
                                                        })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Select Paid Through
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row lg={2} md={1}>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            Amount <span className="star">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Amount"
                                            name="amount"
                                            value={data.amount}
                                            onChange={handleChange}
                                            isInvalid={data.amount === "" && showEmptyError}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Enter Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start">
                    <Button
                        type="button"
                        className="commonBtn smallBtn"
                        onClick={handleUpdate}
                    >
                        Update
                    </Button>
                    <Button
                        type="button"
                        className="commonBtn smallBtn cancelBtn"
                        onClick={handleCloseEditModal}
                    >
                       Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditReceipt;
