import { FETCH_MANUALJOURNAL_DOCUMENT, FETCH_MANUAL_JOURNAL_COUNT, FETCH_MANUAL_JOURNAL_DATA } from "../actionTypes/manualjournal.actiontypes";

  const initialState = {
    manualjournalPagiData: {},
    manualjournalCount:0,
    manualjournalDocument:[]
  };
  
  export const manualjournalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_MANUAL_JOURNAL_DATA: {
        return { ...state, manualjournalPagiData: { ...payload } };
      }
  
      case FETCH_MANUAL_JOURNAL_COUNT: {
        return { ...state, manualjournalCount: payload  };
      }

      case FETCH_MANUALJOURNAL_DOCUMENT: {
        return { ...state, manualjournalDocument: payload  };
      }
  
      default: {
        return state;
      }
    }
  };
  