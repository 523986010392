import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import PersonalDetails from "./PersonalDetails";
import DocumentUpload from "./DocumentUpload";
import OfficialDetails from "./OfficialDetails";
import ContactAddress from "./ContactAddress";
import BankInformation from "./BankInformation";
import { City, State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import {
  empAadharUpload,
  empIDUpload,
  empImageUpload,
  updateEmployee,
} from "../../../../redux/actions/employee.actions";
import { notifyError, notifyWarning } from "../../../../constants/toastAlert";
import axios from "axios";
import { empGetByIDPost } from "../../../../constants/api";
const states = State.getStatesOfCountry("IN");

const EditEmployeetab = () => {
  const { id } = useParams();
  const empImage = useSelector((state) => state.employeeData.empImage);
  const empIDImage = useSelector((state) => state.employeeData.empID);
  const empAadharImage = useSelector((state) => state.employeeData.empAadhar);
  const { companyid, userid, companyname, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [key, setKey] = useState("personaldetails");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [currentCities, setCurrentCities] = useState([]);
  const [permanentCities, setPermanentCities] = useState([]);
  const [data, setData] = useState({
    employeeid: "",
    fullname: "",
    phoneno: "",
    aadharno: "",
    emailid: "",
    dob: "",
    age: "",
    gender: "Male",
    photo: "",
    idproof: "",
    aadhar: "",
    department: "",
    designation: "",
    joiningdate: "",
    issale: "",
    currentcountry: "India",
    currentstate: "",
    currentcity: "",
    currentaddress: "",
    currentpincode: "",
    permanentcountry: "India",
    permanentstate: "",
    permanentcity: "",
    permanentaddress: "",
    permanentpincode: "",
    bankname: "",
    bankbranch: "",
    bankaccno: "",
    bankifsccode: "",
    remarks: "",
    company: companyname,
    companyid,
    branch: "",
    branchid,
    userid,
    org,
    status: "",
    id: id,
  });

  const switchToNextTab = () => {
    // Determine the next tab's event key based on the current active tab
    let nextTabKey = "";
    switch (key) {
      case "personaldetails":
        nextTabKey = "documentupload";
        break;
      case "documentupload":
        nextTabKey = "officialdetails";
        break;
      case "officialdetails":
        nextTabKey = "contactaddress";
        break;
      case "contactaddress":
        nextTabKey = "bankinformation";
        break;
      default:
        nextTabKey = "";
    }
    if (nextTabKey) {
      setKey(nextTabKey);
    }
  };
  const handleSelectKey = (key) => {
    setKey(key);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name == "currentstate") {
      if (value) {
        setData((prev) => ({
          ...prev,
          [name]: value,
          currentcity: "",
        }));
      } else {
        setData((prev) => ({
          ...prev,
          currentstate: "",
          currentcity: "",
        }));
      }
    }

    if (name == "permanentstate") {
      if (value) {
        setData((prev) => ({
          ...prev,
          [name]: value,
          permanentcity: "",
        }));
      } else {
        setData((prev) => ({
          ...prev,
          permanentstate: "",
          permanentcity: "",
        }));
      }
    }
    if (name === "dob") {
      const birthDate = new Date(value);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();
      setData((prevData) => ({
        ...prevData,
        age: age,
      }));
    }

    if (name === "picture") {
      dispatch(empImageUpload(files));
    }
    if (name === "idpicture") {
      dispatch(empIDUpload(files));
    }
    if (name === "aadharpicture") {
      dispatch(empAadharUpload(files));
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    if (!data.fullname || !data.phoneno || !data.dob || !data.gender) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    if (key == "officialdetails") {
      if (
        !data.branch ||
        !data.department ||
        !data.designation ||
        !data.joiningdate ||
        !data.issale
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }
    if (key == "contactaddress") {
      if (
        !data.currentaddress ||
        !data.currentpincode ||
        !data.permanentaddress ||
        !data.permanentpincode
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }
    if (key == "bankinformation") {
      if (
        !data.bankname ||
        !data.bankbranch ||
        !data.bankifsccode ||
        !data.bankaccno
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
      if (data.bankifsccode.length !== 11) {
        return notifyError("IFSC Code must be exact 11 characters");
      }
      let ifsctest = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(data.bankifsccode);
      if (ifsctest == false) {
        return notifyError("Invalid IFSC Code");
      }
    }

    if (submitButtonText == "Update") {
      dispatch(updateEmployee(data, navigate));
    }
    if (!isLastTab) {
      switchToNextTab();
    }
  };

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      photo: empImage || data.photo,
      idproof: empIDImage || data.idproof,
      aadhar: empAadharImage || data.aadhar,
    }));
    if (data.currentstate) {
      const selectedState = states.find(
        (state) => state.name === data.currentstate
      );
      const citiesOfSelectedState = City.getCitiesOfState(
        "IN",
        selectedState.isoCode
      );
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setCurrentCities(cityNames);
    } else {
      setCurrentCities([]);
    }

    if (data.permanentstate) {
      const selectedState = states.find(
        (state) => state.name === data.permanentstate
      );
      const citiesOfSelectedState = City.getCitiesOfState(
        "IN",
        selectedState.isoCode
      );
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setPermanentCities(cityNames);
    } else {
      setPermanentCities([]);
    }
  }, [
    data.currentstate,
    data.permanentstate,
    empImage,
    empIDImage,
    empAadharImage,
  ]);

  useEffect(() => {
    axios
      .post(empGetByIDPost, { id })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
          photo: res.data[0].photo?.[0],
          idproof: res.data[0].idproof?.[0],
          aadhar: res.data[0].aadhar?.[0],
        }));
      })
      .catch((err) => {
        console.log(err);
        notifyWarning(err);
      });
  }, []);

  const isLastTab = key === "bankinformation";
  const submitButtonText = isLastTab ? "Update" : "Next";
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/employeemanagement/employee")}
            />
            Update <span className="form-title"> Employee</span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="personaldetails"
            id="justify-tab-example"
            className="justify-content-end tab-margin"
            activeKey={key}
            onSelect={handleSelectKey}
          >
            <Tab
              eventKey="personaldetails"
              title="Personal Details"
              className="tabview"
            >
              <PersonalDetails
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
            <Tab
              eventKey="documentupload"
              title="Document Upload"
              className="tabview"
            >
              <DocumentUpload
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
            <Tab
              eventKey="officialdetails"
              title="Official Details"
              className="tabview"
            >
              <OfficialDetails
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
            <Tab
              eventKey="contactaddress"
              title="Contact Address"
              className="tabview"
            >
              <ContactAddress
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
                states={states}
                currentCities={currentCities}
                permanentCities={permanentCities}
                setData={setData}
              />
            </Tab>
            <Tab
              eventKey="bankinformation"
              title="Bank Information"
              className="tabview"
            >
              <BankInformation
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
          </Tabs>
        </Col>
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleUpdate}>
            {submitButtonText}
          </Button>

          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/employeemanagement/employee")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Row>
    </Container>
  );
};

export default EditEmployeetab;
