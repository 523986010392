import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import DataSpinner from '../../../constants/DataSpinner';
import NoRecordFoud from '../../../constants/NoRecordFoud';
import { salesOrderPagiData, salesOrderUpdate, termsAndConditionData } from '../../../redux/actions/salesOrder.actions';
import { reloadPage } from '../../../redux/actions/load.action';
import { salesInvoicePagiData } from '../../../redux/actions/salesInvoice.actions';
import { notifyError, notifyWarning } from '../../../constants/toastAlert';
import ReactToPrint from 'react-to-print';
import googlepe from "../../../assets/images/googlepe.png";
import icici from "../../../assets/images/icici.png";
import paymentlogo from "../../../assets/images/paymentlogo.png";
import phonepe from "../../../assets/images/phonepe.png";
import upilogo from "../../../assets/images/upilogo.png";
import {
    EditorState,
    convertFromRaw,
    convertToRaw,
    ContentState,
} from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { customerPagiData } from '../../../redux/actions/customer.actions';
import { indianFormatDate } from '../../../constants/indianFormatDate';
import vs_logo from "../../../assets/venturesathi-logo.png";
import { billInfoData } from '../../../redux/actions/auth.actions';

function ViewSalesOrder() {
    const URL = extractAndFormatURLPart("sales");
    const { id } = useParams();
    const containerRef = useRef(null);

    //Redux part
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.loadReducer);
    const { companyid, userid, companyname, branchid, branch, org, name, } = useSelector((state) => state.authManager.userData.data[0]) || {};
    const { companyaddress } = useSelector((state) => state.authManager.userData) || {};
    const companyName = useSelector(state => state.companyData.companyData.data) || [];
    const { data: salesOrderAllData } = useSelector((state) => state.salesOrderData.salesOrderPagiData) || [];
    const salesOrderData = useMemo(() => {
        return !salesOrderAllData ? [] : salesOrderAllData.filter((item) => item.id == id);
    }, [salesOrderAllData, id]);
    const { data: salesInvoiceAllData } = useSelector((state) => state.salesInvoiceData.salesInvoicePagiData) || [];
    const salesInvoiceFilterData = !salesInvoiceAllData ? [] : salesInvoiceAllData && Array.isArray(salesInvoiceAllData) && salesInvoiceAllData?.filter((item) => item.saleorderno == salesOrderData[0]?.saleorderno);
    const { termsAndConditionAllData } = useSelector((state) => state.salesOrderData) || [];
    const termAndCondition = termsAndConditionAllData?.filter((item) => item.status === "Active" && item.type == "Sales Order");
    const { billinfo } = useSelector((state) => state.authManager);
    // console.log("billinfo", billinfo)

    //state
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [data, setData] = useState({
        id: salesOrderData[0] ? salesOrderData[0].id : "--",
        adjustment: salesOrderData[0] ? salesOrderData[0].adjustment : "--",
        branch: salesOrderData[0] ? salesOrderData[0].branch : "--",
        company: salesOrderData[0] ? salesOrderData[0].company : "--",
        createdby: salesOrderData[0] ? salesOrderData[0].createdby : "--",
        customerid: salesOrderData[0] ? salesOrderData[0].customerid : "--",
        customername: salesOrderData[0] ? salesOrderData[0].customername : "--",
        customernote: salesOrderData[0] ? salesOrderData[0].customernote : "--",
        deliverytype: salesOrderData[0] ? salesOrderData[0].deliverytype : "--",
        discount: salesOrderData[0] ? salesOrderData[0].discount : "--",
        discountamount: salesOrderData[0] ? salesOrderData[0].discountamount : "--",
        exshippingdate: salesOrderData[0] ? salesOrderData[0].exshippingdate : "--",
        finalamount: salesOrderData[0] ? salesOrderData[0].finalamount : "--",
        financialyear: salesOrderData[0] ? salesOrderData[0].financialyear : "--",
        invoicestatus: salesOrderData[0] ? salesOrderData[0].invoicestatus : "--",
        showgst: salesOrderData[0] ? salesOrderData[0].showgst == "true" ? true : false : true,
        itemarray: salesOrderData[0] ? salesOrderData[0].itemarray : "--",
        month: salesOrderData[0] ? salesOrderData[0].month : "--",
        orderstatus: salesOrderData[0] ? salesOrderData[0].orderstatus : "--",
        org: salesOrderData[0] ? salesOrderData[0].org : "--",
        project: salesOrderData[0] ? salesOrderData[0].project : "--",
        projectid: salesOrderData[0] ? salesOrderData[0].projectid : "--",
        quoteno: salesOrderData[0] ? salesOrderData[0].quoteno : "--",
        saleorderdate: salesOrderData[0] ? salesOrderData[0].saleorderdate : "--",
        saleorderno: salesOrderData[0] ? salesOrderData[0].saleorderno : "--",
        salesperson: salesOrderData[0] ? salesOrderData[0].salesperson : "--",
        subtotal: salesOrderData[0] ? salesOrderData[0].subtotal : "--",
        tandc: salesOrderData[0] ? salesOrderData[0].tandc : "--",
        tax: salesOrderData[0] ? salesOrderData[0].tax : "--",
        uploads: salesOrderData[0] ? salesOrderData[0].uploads : [],
        bastate: salesOrderData[0] ? salesOrderData[0].bastate : "--",
        bacity: salesOrderData[0] ? salesOrderData[0].bacity : "--",
        bapincode: salesOrderData[0] ? salesOrderData[0].bapincode : "--",
        bacountry: salesOrderData[0] ? salesOrderData[0].bacountry : "--",
        baaddress: salesOrderData[0] ? salesOrderData[0].baaddress : "--",
        taxableamount: salesOrderData[0] ? salesOrderData[0].taxableamount : "--",
        orderstatus: salesOrderData[0] ? salesOrderData[0].orderstatus : "--",
        documents: salesOrderData[0] ? salesOrderData[0].documents : "--",
        gst: salesOrderData[0] ? salesOrderData[0].gst : "--",
        grandtotal: salesOrderData[0] ? salesOrderData[0].grandtotal : "--",
        companyid: salesOrderData[0] ? salesOrderData[0].companyid : "--",
        branch: salesOrderData[0] ? salesOrderData[0].branch : "--",
        branchid: salesOrderData[0] ? salesOrderData[0].branchid : "--",
        userid: salesOrderData[0] ? salesOrderData[0].userid : "--",
        category: salesOrderData[0] ? salesOrderData[0].category : "--",
        brand: salesOrderData[0] ? salesOrderData[0].brand : "--",
        items: salesOrderData[0] ? salesOrderData[0].items : "--",
        suggesteditems: salesOrderData[0] ? salesOrderData[0].suggesteditems : "--",
        customernamealldata: salesOrderData[0] ? salesOrderData[0].customernamealldata : [],

    });
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);

    //default data backend needed
    const backendData = {
        keyword: "₹ΘθϖΠ",
        userid: userid,
        branchid: branchid,
        companyid: companyid,
        branch: branch,
        companyname: companyname,
        org: org,
    }

    //useeffect part start
    useEffect(() => {
        dispatch(reloadPage(true));
        dispatch(billInfoData({ org }));
    }, []);

    useEffect(() => {
        setLoading(!loading);
        dispatch(salesOrderPagiData(backendData, setLoading));
        dispatch(salesInvoicePagiData(backendData, setLoading));
        dispatch(termsAndConditionData(backendData, setLoading));


    }, [dispatch, loadData]);

    useEffect(() => {
        if (salesOrderData.length > 0) {
            // setData({
            //     ...data,
            //     id: salesOrderData[0] ? salesOrderData[0].id : "--",
            //     adjustment: salesOrderData[0] ? salesOrderData[0].adjustment : "--",
            //     branch: salesOrderData[0] ? salesOrderData[0].branch : "--",
            //     company: salesOrderData[0] ? salesOrderData[0].company : "--",
            //     createdby: salesOrderData[0] ? salesOrderData[0].createdby : "--",
            //     customerid: salesOrderData[0] ? salesOrderData[0].customerid : "--",
            //     customername: salesOrderData[0] ? salesOrderData[0].customername : "--",
            //     customernote: salesOrderData[0] ? salesOrderData[0].customernote : "--",
            //     showgst: salesOrderData[0] ? salesOrderData[0].showgst == "true" ? true : false : true,
            //     deliverytype: salesOrderData[0] ? salesOrderData[0].deliverytype : "--",
            //     discount: salesOrderData[0] ? salesOrderData[0].discount : "--",
            //     discountamount: salesOrderData[0] ? salesOrderData[0].discountamount : "--",
            //     exshippingdate: salesOrderData[0] ? salesOrderData[0].exshippingdate : "--",
            //     finalamount: salesOrderData[0] ? salesOrderData[0].finalamount : "--",
            //     financialyear: salesOrderData[0] ? salesOrderData[0].financialyear : "--",
            //     invoicestatus: salesOrderData[0] ? salesOrderData[0].invoicestatus : "--",
            //     itemarray: salesOrderData[0] ? salesOrderData[0]?.itemarray : "--",
            //     month: salesOrderData[0] ? salesOrderData[0].month : "--",
            //     orderstatus: salesOrderData[0] ? salesOrderData[0].orderstatus : "--",
            //     org: salesOrderData[0] ? salesOrderData[0].org : "--",
            //     project: salesOrderData[0] ? salesOrderData[0].project : "--",
            //     projectid: salesOrderData[0] ? salesOrderData[0].projectid : "--",
            //     quoteno: salesOrderData[0] ? salesOrderData[0].quoteno : "--",
            //     saleorderdate: salesOrderData[0] ? salesOrderData[0].saleorderdate : "--",
            //     saleorderno: salesOrderData[0] ? salesOrderData[0].saleorderno : "--",
            //     salesperson: salesOrderData[0] ? salesOrderData[0].salesperson : "--",
            //     subtotal: salesOrderData[0] ? salesOrderData[0].subtotal : "--",
            //     tandc: salesOrderData[0] ? salesOrderData[0].tandc : "--",
            //     tax: salesOrderData[0] ? salesOrderData[0].tax : "--",
            //     uploads: salesOrderData[0] ? salesOrderData[0].uploads : [],
            //     bastate: salesOrderData[0] ? salesOrderData[0].bastate : "--",
            //     bacity: salesOrderData[0] ? salesOrderData[0].bacity : "--",
            //     bapincode: salesOrderData[0] ? salesOrderData[0].bapincode : "--",
            //     bacountry: salesOrderData[0] ? salesOrderData[0].bacountry : "--",
            //     baaddress: salesOrderData[0] ? salesOrderData[0].baaddress : "--",
            //     taxableamount: salesOrderData[0] ? salesOrderData[0].taxableamount : "--",
            //     orderstatus: salesOrderData[0] ? salesOrderData[0].orderstatus : "--",
            //     documents: salesOrderData[0] ? salesOrderData[0].documents : "--",
            //     gst: salesOrderData[0] ? salesOrderData[0].gst : "--",
            //     grandtotal: salesOrderData[0] ? salesOrderData[0].grandtotal : "--",
            //     companyid: salesOrderData[0] ? salesOrderData[0].companyid : "--",
            //     branch: salesOrderData[0] ? salesOrderData[0].branch : "--",
            //     branchid: salesOrderData[0] ? salesOrderData[0].branchid : "--",
            //     userid: salesOrderData[0] ? salesOrderData[0].userid : "--",
            //     category: salesOrderData[0] ? salesOrderData[0].category : "--",
            //     brand: salesOrderData[0] ? salesOrderData[0].brand : "--",
            //     items: salesOrderData[0] ? salesOrderData[0].items : "--",
            //     suggesteditems: salesOrderData[0] ? salesOrderData[0].suggesteditems : "--",
            //     customernamealldata: salesOrderData[0] ? salesOrderData[0].customernamealldata : [],
            // })
            setData({
                ...data,
                showgst: salesOrderData[0] ? salesOrderData[0].showgst == "true" ? true : false : true,
                ...salesOrderData[0]
            });
            setTableData(salesOrderData[0] ? salesOrderData[0].itemarray : "--");
        }

    }, [salesOrderData])
    //useeffect part end

    const navigate = useNavigate();




    const handleAcceptAndSend = () => {
        if (data.orderstatus != "Approved") {
            if (
                !data.customername ||
                !data.saleorderdate ||
                !data.deliverytype ||
                !data.createdby
            ) {
                setShowEmptyError(true);
                notifyError("You have empty fields");
                return;
            }
            const formData = {
                ...data,
                orderstatus: "Approved",
                id: id
            };

            dispatch(salesOrderUpdate(formData, navigate));
        }
        else {
            alert("What's app Integration under construction.")
        }
    };

    // const handlePrint = () => {
    //     const printWindow = window.open("", "", "width=1000,height=1000");
    //     const modalContent = containerRef.current;
    //     const tablepartToPrint = modalContent.querySelector(".print-table").innerHTML;
    //     printWindow.document.open();
    //     printWindow.document.write(`
    //            <html>
    //             <head>
    //             <link rel="stylesheet" type="text/css" href="ReceiveGoods.css" media="print">
    //             <style>
    //               body {
    //               font-family: Arial, sans-serif;
    //               font-size:1.2rem
    //               }

    //               .modalHeader{
    //                 display:flex;
    //                 justify-content:space-between;
    //                 padding-bottom:0.5rem;
    //                 border-bottom:2px solid grey;
    //                 width:100%;
    //               }

    //               .printTittle{
    //                 margin-bottom: -0.2rem;
    //               }

    //               .gayab{
    //                 display:none;
    //               }

    //               .listview{
    //                 width: 100%;
    //                 display : flex;
    //                 justify-content:space-between;
    //                 gap:1rem;
    //                 margin-bottom:0rem
    //               }

    //               .details-container{
    //                 display:flex;
    //                 justify-content:space-between;
    //                 gap:4rem;
    //               }

    //               .details-container span:last-child{
    //                 margin-left:4rem
    //               }

    //               .container{
    //                 margin: 0.7rem 0rem 0rem 0rem;
    //                 font-size:1.3rem;
    //                 font-weight:500;
    //                 display:block;
    //                 padding:0rem 2rem 0rem 0rem; 
    //               }

    //               .container>span{
    //                 text-align:right;
    //               }

    //               .listview-total{
    //                 text-align:right;
    //                 width: 100%;
    //                 display : flex;
    //                 justify-content:space-between;
    //                 flex-direction:row-reverse;
    //               }

    //               .calculation-container{
    //                 display:flex;
    //                 justify-content:flex-end;
    //                 gap:4rem;

    //               }

    //               .calculation-container span{
    //                 font-size:1.8rem;
    //               }

    //               .calculation-container>span:first-child{                   
    //                 text-align:end;
    //               }

    //               .text-bold{
    //                 font-size:1.3rem;
    //                 font-weight:600;
    //               }

    //               hr{
    //                 display: none;
    //               }

    //               table{
    //                 width:100%;
    //                 text-align: center;
    //               }
    //               thead {
    //                 background-color: #2E4374;
    //                 color: #F5F7F8;
    //               }

    //               th{
    //                 padding: 0.5rem 2.50rem;
    //                 border-inline: 0.5px solid #d0d5dd;
    //               }

    //               tbody{
    //                 padding: 1rem;
    //                 background:#F5F7F8;
    //                 font-size:1rem;
    //                 font-weight:normal
    //               }

    //               tbody tr{
    //                 border-bottom:3px solid grey;
    //               }

    //               tbody tr td{
    //                 padding:0.5rem 0rem;
    //               }
    //             </style>
    //           <title>Print</title>
    //           </head>
    //           <body>
    //             <div class="listview">
    //                 <h1>Sales Order</h1>
    //                 <h2>Status:<span>${data.orderstatus} </span></h2>
    //             </div>

    //             <div class="listview">
    //               <div>
    //                 <p>From :</p>
    //                 <p>${data.company}</p>
    //                 <p>${companyaddress.hoaddress}</p>
    //                 <p>${companyaddress.city} ${companyaddress.state}</p>
    //                 <p>${companyaddress.pincode}</p>
    //                 <p class"details-container">
    //                     <span>Customer Id:</span>
    //                     <span>${data.customerid}</span>
    //                 </p>
    //                 <p class"details-container">
    //                     <span>Created By:</span>
    //                     <span>${data.createdby}</span>
    //                 </p>
    //                 <p class"details-container">
    //                     <span>Sales Order No.:</span>
    //                     <span>${data.saleorderno}</span>
    //                 </p>
    //                 <p class"details-container">
    //                     <span>Expected Shipment Date:</span>
    //                     <span>${data.exshippingdate}</span>
    //                 </p>
    //               </div>
    //               <div>
    //                 <p>To :</p>
    //                 <p>${data.customername}</p>
    //                 <p>${data.baaddress}</p>
    //                 <p>${data.bastate} ${data.bacity}</p>
    //                 <p >${data.bapincode}</p>
    //                 <p class"details-container">
    //                     <span>Quotation No.:</span>
    //                     <span>${data.quoteno}</span>
    //                 </p>
    //                 <p class"details-container">
    //                     <span>Sales Order Date:</span>
    //                     <span>${data.saleorderdate}</span>
    //                 </p>
    //                 <p class"details-container">
    //                     <span>Delivery Type:</span>
    //                     <span>${data.deliverytype}</span>
    //                 </p>
    //               </div>
    //           </div>
    //     `);
    //     printWindow.document.write(tablepartToPrint);
    //     printWindow.document.write(`       
    //               <p class="calculation-container">
    //                 <span>Subtotal:</span>
    //                 <span class="text-bold">${data.subtotal}</span>
    //               </p>
    //               <p class="calculation-container">
    //                 <span>Discount:</span>
    //                 <span>${data.discount}%</span>
    //                 <span>-${data.discountamount}</span>
    //               </p>
    //               <p class="calculation-container">
    //                 <span>Taxable Amount:</span>
    //                 <span>${data.taxableamount}</span>
    //               </p>
    //               <p class="calculation-container">
    //                 <span>GST:</span>
    //                 <span>${data.tax}</span>
    //               </p>
    //               <p class="calculation-container">
    //                 <span>Adjustment:</span>
    //                 <span>${data.adjustment}</span>
    //               </p>
    //               <p class="calculation-container">
    //                 <span >Grand Total:</span>
    //                 <span class="text-bold">${data.finalamount}</span>
    //               </p>        
    //     `);
    //     printWindow.document.write(`</body></html>`);
    //     printWindow.document.close();
    //     printWindow.print();
    //     printWindow.close();
    // };

    const handleReject = () => {
        if (
            !data.customername ||
            !data.saleorderdate ||
            !data.deliverytype ||
            !data.createdby
        ) {
            setShowEmptyError(true);
            notifyError("You have empty fields");
            return;
        }
        const formData = {
            ...data,
            orderstatus: "Rejected",
        };
        dispatch(salesOrderUpdate(formData, navigate));
    };

    return (
        <Container fluid >
            <Row>
                <Col className="colStyle p-0">
                    <span>{URL}</span>
                </Col>
            </Row>
            <Row className="form-container">
                <Col sm={12}>
                    <Row>
                        <Col>
                            <h1>
                                <FaArrowLeftLong
                                    className="left-arrow"
                                    onClick={() => navigate("/sales/salesOrder")}
                                />
                                Sales <span className="form-title">Order</span>
                            </h1>
                        </Col>
                        <Col>

                            <h3 className='mr-2'>Status:<span className={
                                data.orderstatus == "Approved" ? "Approved-status" :
                                    data.orderstatus === "Rejected" ? "Rejected-status" :
                                        data.orderstatus === "Close" ? "Close-status" :
                                            data.orderstatus === "Draft" ? "Draft-status" : ""}>{data.orderstatus}</span></h3>
                        </Col>
                    </Row>
                </Col>
                <hr />
                <Col >
                    <Row>
                        <Col md={6}>
                            <p>
                                <span className="viewpage-title from-SalesOrder">Bill To:</span>{" "}
                            </p>
                            <p className='mb-0'>
                                <span className="viewpage-title companyname">{data.customername}</span>
                            </p>
                            <p className='mb-0'>
                                <span className="viewpage-value d-block">{data.customernamealldata ? data.customernamealldata[0]?.baaddress : "--"}</span>
                                <span className="viewpage-value d-block">{data.customernamealldata ? data.customernamealldata[0]?.bacity : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.bastate : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.bacountry : "--"}</span>
                                <span className="viewpage-value d-block">{data.customernamealldata ? data.customernamealldata[0]?.bapincode : "--"}</span>
                            </p>


                            <p className='mt-5'>
                                <span className="viewpage-title heading-title">Customer Id</span>{" "}
                                <span className="viewpage-value">:{!data.customerid ? "--" : data.customerid}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Project Id</span>{" "}
                                <span className="viewpage-value">:{!data.projectid ? "--" : data.projectid}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Created By</span>{" "}
                                <span className="viewpage-value">:{!data.createdby ? "--" : data.createdby}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Sales Order No</span>{" "}
                                <span className="viewpage-value">:{!data.saleorderno ? "--" : data.saleorderno}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Expected Shipment Date</span>{" "}
                                <span className="viewpage-value">:{!data.exshippingdate ? "--" : data.exshippingdate}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Sales Person</span>{" "}
                                <span className="viewpage-value">:{!data.salesperson ? "--" : data.salesperson}</span>
                            </p>
                        </Col>
                        <Col md={6}>
                            <p>
                                <span className="viewpage-title from-SalesOrder">Ship To:</span>
                            </p>
                            <p className='mb-0'>
                                <span className="viewpage-title companyname">{data.customername}</span>
                            </p>
                            <p>
                                <span className="viewpage-value d-block">{data.customernamealldata ? data.customernamealldata[0]?.saaddress : "--"}</span>
                                <span className="viewpage-value d-block">{data.customernamealldata ? data.customernamealldata[0]?.sacity : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.sastate : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.sacountry : "--"}</span>
                                <span className="viewpage-value d-block">{data.customernamealldata ? data.customernamealldata[0]?.sapincode : "--"}</span>

                            </p>
                            <p className='mt-5'>
                                <span className="viewpage-title heading-title">Customer Name</span>{" "}
                                <span className="viewpage-value">:{!data.customername ? "--" : data.customername}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Project</span>{" "}
                                <span className="viewpage-value">:{!data.project ? "--" : data.project}</span>
                            </p>

                            <p>
                                <span className="viewpage-title heading-title">Quotation No.</span>{" "}
                                <span className="viewpage-value">:{!data.quoteno ? "--" : data.quoteno}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Sales Order Date</span>{" "}
                                <span className="viewpage-value">:{!data.saleorderdate ? "--" : data.saleorderdate}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Delivery Type</span>{" "}
                                <span className="viewpage-value">:{!data.deliverytype ? "--" : data.deliverytype}</span>
                            </p>
                            <p>
                                <span className="viewpage-title heading-title">Customer Notes</span>{" "}
                                <span className="viewpage-value">:{!data.customernote ? "--" : data.customernote}</span>
                            </p>
                        </Col>
                    </Row>
                    <hr />
                    <Row >
                        <Col md={12} className="table-main-container print-table">
                            <Table responsive>
                                <thead className="tableContainer">
                                    <tr>
                                        <th>SL No</th>
                                        <th>Category</th>
                                        <th>Brand</th>
                                        <th>Item Name</th>
                                        <th>Series</th>
                                        <th>Item ID</th>
                                        <th>UOM</th>
                                        <th>QTY</th>
                                        {!data.showgst && <th>Rate Incl. GST</th>}
                                        <th>Rate</th>
                                        {data.showgst && <th>GST%</th>}
                                        <th>Discount %</th>
                                        <th>Add. Discount %</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadData || loading ? (
                                        <>
                                            <DataSpinner count="12" />
                                        </>
                                    ) : tableData.length > 0 ? (
                                        Array.isArray(tableData) && tableData.map((row, index) => {
                                            const {
                                                id,
                                                category,
                                                brand,
                                                itemname,
                                                series,
                                                itemid,
                                                unit,
                                                qty,
                                                rateinclgst,
                                                rate,
                                                gst,
                                                discount,
                                                amount,
                                                additionaldiscount

                                            } = row;
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1} </td>

                                                    <td>{category} </td>

                                                    <td>{brand}</td>

                                                    <td>{itemname}</td>

                                                    <td>{series}</td>

                                                    <td>{itemid}</td>

                                                    <td>{unit}</td>

                                                    <td>{qty}</td>

                                                    {!data.showgst && <td>{rateinclgst}</td>}

                                                    <td>{rate}</td>

                                                    {data.showgst && <td>{gst}</td>}

                                                    <td>{discount}</td>

                                                    <td>{additionaldiscount}</td>

                                                    <td>{amount}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <NoRecordFoud count="12" name="Sales Order" />

                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}></Col>
                        <Col>
                            <p className='mt-3 mr-5'>
                                <span className="viewpage-title">Sub Total :</span>{" "}
                            </p>
                        </Col>
                        <Col>
                            <p className='mt-3 text-end'>
                                <span className="viewpage-value">{!data.subtotal ? "--" : data.subtotal}</span>
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}></Col>
                        <Col>
                            {data.showgst && <p className=''>
                                <span className="viewpage-title">GST :</span>{" "}
                            </p>}
                        </Col>
                        <Col>
                            {data.showgst && <p className='text-end'>
                                <span className="viewpage-value calculation-value">{data.tax}</span>
                            </p>}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}></Col>
                        <Col>
                            <p className=''>
                                <span className="viewpage-title calculation-header">Adjustment:</span>{" "}
                            </p>
                        </Col>
                        <Col>
                            <p className='text-end'>
                                <span className="viewpage-value calculation-value">{data.adjustment}</span>
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={9}></Col>
                        <Col>
                            <p className=''>
                                <span className="viewpage-title">Grand Total:</span>{" "}
                            </p>
                        </Col>
                        <Col>
                            <p className='text-end'>
                                <span className="viewpage-value grandtotal">{data.finalamount}</span>
                            </p>
                        </Col>
                    </Row>

                </Col>
                <hr />
                <Row >
                    <Col md={12} className="table-main-container print-table">
                        <Table responsive>
                            <thead className="tableContainer">
                                <tr>
                                    <th>SL No</th>
                                    <th>Sales Invoice No</th>
                                    <th>Sales Invoice Amount</th>
                                    <th>Balance Due</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadData || loading ? (
                                    <>
                                        <DataSpinner count="7" />
                                    </>
                                ) : salesInvoiceFilterData.length > 0 ? (
                                    salesInvoiceFilterData.map((row, index) => {
                                        const {
                                            adjustmentamount,
                                            architect,
                                            balancedue,
                                            branchid,
                                            btaddress,
                                            btcity,
                                            btpin,
                                            btstate,
                                            cgst,
                                            commonid,
                                            commonname,
                                            commonnamealldata,
                                            companyid,
                                            contractor,
                                            createdby,
                                            discount,
                                            discountaccount,
                                            discountamount,
                                            einvoiceno,
                                            ewaybillno,
                                            financialyear,
                                            frightcharges,
                                            gino,
                                            grandtotal,
                                            id,
                                            igst,
                                            invoicedate,
                                            invoiceno,
                                            invoicetype,
                                            isfrightcharges,
                                            istandc,
                                            itemarray,
                                            month,
                                            org,
                                            paymentduedate,
                                            paymentterms,
                                            purchaseinvoiceno,
                                            purchaseorderno,
                                            remarks,
                                            saleorderno,
                                            salesperson,
                                            samestate,
                                            sgst,
                                            showgst,
                                            signature,
                                            staddress,
                                            status,
                                            stcity,
                                            stpin,
                                            ststate,
                                            subtotal,
                                            tandc,
                                            taxableamount,
                                            tcs,
                                            tcsvalue,
                                            tds,
                                            tdsvalue,
                                            transportertype,
                                            transpotername,
                                            tsamount,
                                            uploads,
                                            userid,
                                            vehicleno
                                        } = row;
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{invoiceno}</td>
                                                <td>{grandtotal}</td>
                                                <td>{balancedue}</td>
                                                <td className={
                                                    status == "Partially Paid" ? "Approved-status" :
                                                        status == "Unpaid" ? "Unpaid-status" :
                                                            status === "Overdue" ? "Rejected-status" :
                                                                status === "Paid" ? "Approved-status" :
                                                                    status === "Draft" ? "Draft-status" : ""}
                                                >
                                                    {status}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <NoRecordFoud count="7" name="Sales Invoice" />

                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <hr />
                <Row className='d-none'>
                    <Col ref={containerRef}>
                        <div className="print-section">
                            <table>
                                <tr className="first-section">
                                    <td className="saanvi_logo">
                                        <img src={billinfo.brandlogo ? billinfo.brandlogo : vs_logo} alt="image not available" className='saanvi-logo' />
                                    </td>
                                    <td className="saanvi-brand-container">
                                        <h1 className="saanvi-brand">{billinfo.brandname ? billinfo.brandname : "--"}</h1>
                                        <p className="saanvi-deails">{billinfo.address ? billinfo.address : "--"}</p>
                                        <p className='saanvi-deails'>
                                            <span className='saanvi-gst'>GST :</span> <span className='saanvi-deails so'>{billinfo.gstno ? billinfo.gstno : "--"}</span>
                                            <span className="saanvi-gst">PAN :</span> <span>{billinfo.pan ? billinfo.pan : "--"}</span>
                                        </p>
                                        <p className='saanvi-deails'>
                                            <span className='saanvi-deails'>State Name :</span> <span className='saanvi-deails'>{billinfo.state ? billinfo.state : "--"} </span>
                                            <span className='saanvi-deails'>Code :</span> <span className='saanvi-deails'>{billinfo.statecode ? billinfo.statecode : "--"}</span>
                                        </p>
                                        <p className='saanvi-deails'>
                                            <span className='saanvi-deails'>Email : {billinfo.email ? billinfo.email : "--"}</span>
                                        </p>
                                    </td>
                                    <td className="saanvi-contact-details">
                                        <p className="printButton">{data.invoicetype != "Return To Vendor" ? "INVOICE" : "DEBIT NOTE"}</p>
                                        <p className="saanvi-deails">
                                            <span className="saanvi-details-section cd">Phone No: </span>
                                            <span className="saanvi-contact">{billinfo.phonenumber ? billinfo.phonenumber : "--"}</span>
                                        </p>
                                        <p className="saanvi-deails">
                                            <span className="saanvi-details-section cd">Website: </span>
                                            <span>{billinfo.websitename ? billinfo.websitename : "--"}</span>
                                        </p>
                                        <p className="saanvi-deails">
                                            <span className="saanvi-details-section cd">Sales Order No.:  </span>
                                            <span>{data.saleorderno}</span>
                                        </p>
                                    </td>
                                </tr>
                                {/* <tr className="second-section">
                                    <td className="gst-section" colSpan="2">
                                        <span className="gst saanvi-deails">GSTIN: {companyaddress.gstno}</span>
                                        <span className='saanvi-deails'>PAN: {companyaddress.pan}</span>
                                    </td>
                                    <td className="invoiceno-section">
                                        <span className='saanvi-deails'>Order No: {data.saleorderno}</span>
                                    </td>
                                </tr> */}
                                <tr className="third-section">
                                    <td className="customer-details-section" colSpan="3">
                                        <span className='saanvi-deails'>Customer Details</span>
                                    </td>
                                </tr>
                                <tr className="four-section">
                                    <td colSpan="3">
                                        <div className="address-section">
                                            <div className="first-col">
                                                <p>
                                                    <span className="btitle">Bill To :</span>
                                                    <span className="desc">{data.customername}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">Address :</span>
                                                    <span className="desc">{data.customernamealldata ? data.customernamealldata[0]?.baaddress : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.bacity : "--"}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">State :</span>
                                                    <span className="desc part">{data.customernamealldata ? data.customernamealldata[0]?.bastate : "--"}- {data.customernamealldata ? data.customernamealldata[0]?.bapincode : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.bacountry : "--"}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">Phone</span>
                                                    <span className="desc part">{data.customernamealldata ? data.customernamealldata[0]?.phone : "--"}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">GSTIN</span>
                                                    <span className="desc">{data.customernamealldata ? data.customernamealldata[0]?.bdgstno : "--"}</span>
                                                </p>
                                            </div>
                                            <div className="second-col">
                                                <p>
                                                    <span className="btitle">Ship To:</span>
                                                    <span className="desc">{data.customername}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">Address</span>
                                                    <span className="desc">{data.customernamealldata ? data.customernamealldata[0]?.saaddress : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.sacity : "--"}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">State</span>
                                                    <span className="desc">{data.customernamealldata ? data.customernamealldata[0]?.sastate : "--"} - {data.customernamealldata ? data.customernamealldata[0]?.sapincode : "--"}, {data.customernamealldata ? data.customernamealldata[0]?.sacountry : "--"}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">Phone</span>
                                                    <span className="desc part">{data.customernamealldata ? data.customernamealldata[0]?.bdworkphone : "--"}</span>
                                                </p>
                                                <p>
                                                    <span className="btitle">GSTIN</span>
                                                    <span className="desc">{data.customernamealldata ? data.customernamealldata[0]?.bdgstno : "--"}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="five-section">
                                    <td colSpan="3">
                                        <div className="payment-section">
                                            <div className="payment-details w-50">
                                                <p className='mb-0'>
                                                    <span className="btitle cd">Customer ID:</span>
                                                    <span className="desc">{data.customerid}</span>
                                                </p>
                                                <p className='mb-0'>
                                                    <span className="btitle cd">Created By:</span>
                                                    <span className="desc">{data.createdby}</span>
                                                </p>
                                                <p className='mb-0'>
                                                    <span className="btitle cd">Ex. Shipment Date:</span>
                                                    <span className="desc">{data.exshippingdate}</span>
                                                </p>
                                            </div>
                                            <div className="payment-details w-50">
                                                <p className='mb-0'>
                                                    <span className="btitle cd">Quotation No.:</span>
                                                    <span className="desc">{data.quoteno}</span>
                                                </p>
                                                <p className='mb-0'>
                                                    <span className="btitle cd">Sales Order Date</span>
                                                    <span className="desc">{indianFormatDate(data.saleorderdate)}</span>
                                                </p>
                                                <p className='mb-0'>
                                                    <span className="btitle cd">Delivery Type:</span>
                                                    <span className="desc">{data.deliverytype}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="six-section">
                                    <td colSpan="3">
                                        <div>
                                            <Table responsive className="table-color fs-4">
                                                <thead>
                                                    <tr className="table-head-color">
                                                        <th>#</th>
                                                        <th>Item Name</th>
                                                        <th>Item ID</th>
                                                        <th>HSN/SAC</th>
                                                        <th>UOM</th>
                                                        <th>QTY</th>
                                                        {!data.showgst && <th>
                                                            <span className='d-block'>Rate</span>
                                                            <span className=''>(Include of tax)</span>
                                                        </th>}
                                                        <th>Rate</th>
                                                        {data.showgst && <th>GST%</th>}
                                                        <th>Dis. %</th>
                                                        <th><span className='d-block'>Additional</span>
                                                            <span className=''>Dis. %</span></th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tablebody'>
                                                    {loadData || loading ? (
                                                        <>
                                                            <DataSpinner count="14" />
                                                        </>
                                                    ) : data && data.itemarray && Array.isArray(data.itemarray) && data.itemarray.length > 0 ? (
                                                        data.itemarray.map((row, index) => {
                                                            let {
                                                                asondate,
                                                                billingtype,
                                                                branchid,
                                                                brand,
                                                                category,
                                                                cessvaluationtype,
                                                                chartofaccount,
                                                                companyid,
                                                                dispatchqty,
                                                                qty,
                                                                rateinclgst,
                                                                discount,
                                                                grade,
                                                                gst,
                                                                hsncode,
                                                                hsndescription,
                                                                id,
                                                                image,
                                                                inhandquantity,
                                                                inventoryaccount,
                                                                inventorydescription,
                                                                inventorytype,
                                                                itemdescription,
                                                                itemid,
                                                                itemname,
                                                                series,
                                                                mpn,
                                                                openingqty,
                                                                org,
                                                                purchaseaccount,
                                                                purchasedescription,
                                                                rake,
                                                                rateperunit,
                                                                reorderpoint,
                                                                saleaccount,
                                                                saledescription,
                                                                size,
                                                                sku,
                                                                taxability,
                                                                thickness,
                                                                unit,
                                                                rate,
                                                                updatedprice,
                                                                userid,
                                                                value,
                                                                warehouse,
                                                                account,
                                                                additionaldiscount,
                                                                amount
                                                            } = row;

                                                            return (
                                                                <tr key={id} >
                                                                    <td>{index + 1}</td>

                                                                    <td> {!itemname ? "--" : itemname}</td>

                                                                    <td>{!itemid ? "--" : itemid}</td>

                                                                    <td>{!hsncode ? "--" : hsncode}</td>

                                                                    <td>{!unit ? "--" : unit}</td>

                                                                    <td>{!qty ? "--" : qty}</td>

                                                                    {!data.showgst && <td>{!rateinclgst ? "--" : rateinclgst}</td>}

                                                                    <td>{!rate ? "--" : rate}</td>

                                                                    {data.showgst && <td> {!gst ? "--" : gst}</td>}

                                                                    <td>{!discount ? "--" : discount}</td>

                                                                    <td>{!additionaldiscount ? "--" : additionaldiscount}</td>

                                                                    <td>{!amount ? "--" : amount}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoRecordFoud count="14" name="Sales Order" />
                                                    )}
                                                    {new Array(2).fill("")?.map((emtr, i) => (
                                                        <tr key={i}>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {!data.showgst && <td></td>}
                                                            <td></td>
                                                            {data.showgst && <td></td>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        {!data.showgst && <td></td>}
                                                        <td></td>
                                                        {data.showgst && <td></td>}
                                                        <td></td>
                                                        <td></td>
                                                        <td><hr /></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td className="print-total">Sub Total</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        {!data.showgst && <td></td>}
                                                        <td></td>
                                                        {data.showgst && <td></td>}
                                                        <td></td>
                                                        <td></td>
                                                        <td className="font-bold td-top-border">{(Number(data.subtotal)).toFixed(2)}</td>
                                                    </tr>
                                                    {data.showgst && <tr>
                                                        <td></td>
                                                        <td className="print-total">GST</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        {!data.showgst && <td></td>}
                                                        <td></td>
                                                        {data.showgst && <td></td>}
                                                        <td></td>
                                                        <td></td>
                                                        <td className="font-bold td-top-border">{(Number(data.tax)).toFixed(2)}</td>
                                                    </tr>}
                                                    {(data.adjustment != "0" && data.adjustment != "") && <tr>
                                                        <td></td>
                                                        <td className="print-total">Adjustment</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        {!data.showgst && <td></td>}
                                                        <td></td>
                                                        {data.showgst && <td></td>}
                                                        <td></td>
                                                        <td></td>
                                                        <td className="font-bold td-top-border">{data.adjustment}</td>
                                                    </tr>}
                                                    {new Array(8).fill("")?.map((emtr, i) => (
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {!data.showgst && <td></td>}
                                                            <td></td>
                                                            {data.showgst && <td></td>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                    <tr className="tableContainer">
                                                        <td></td>
                                                        <td className="print-total">Total</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="font-bold">
                                                            {data && data.itemarray && Array.isArray(data.itemarray) && data.itemarray?.reduce((qty, curr) => {
                                                                return Number(qty) + Number(curr.qty);
                                                            }, 0)}
                                                        </td>
                                                        {!data.showgst && <td></td>}
                                                        <td></td>
                                                        {data.showgst && <td></td>}
                                                        <td></td>
                                                        <td></td>
                                                        <td className="font-bold">{data.finalamount}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>

                                    </td>
                                </tr >
                                {/* <tr className="seven-section">
                                    <td colSpan="3">
                                        <Table responsive className="table-color fs-4 tableborder">
                                            <thead >
                                                <tr >
                                                    <th rowSpan={2} >Item ID</th>
                                                    <th rowSpan={2} >Amount</th>
                                                    {(data.showgst == "true") ? <th colSpan={4} >GST</th> : null}
                                                    <th rowSpan={2}>Total</th>
                                                </tr>

                                                <tr>
                                                    {(data.showgst == "true") ? <th colSpan={2}>%</th> : null}
                                                    {(data.showgst == "true") ? <th colSpan={2}>Amount</th> : null}
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {
                                                    data && data.itemarray && Array.isArray(data.itemarray) && data.itemarray?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index} className='tableborder'>
                                                                    <td>{item.itemid}</td>
                                                                    <td>{item.amount}</td>
                                                                    {(data.showgst == "true") ? <td colSpan={2}>{Number(item.gst)}</td> : null}
                                                                    {(data.showgst == "true") ? <td colSpan={2}>{((Number(item.rate) * Number(item.gst)) / 100)?.toFixed(2)}</td> : null}
                                                                </tr>

                                                            </>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td>Adjustment:- {data.adjustment}</td>
                                                    <td>{data.subtotal}</td>
                                                    <td colSpan={4}>{data.showgst == "true" ? (data && data.itemarray && Array.isArray(data.itemarray) && data.itemarray?.reduce((acc, curr) => {
                                                        return acc + (curr.qty * ((curr.rate * curr.gst) / 100));
                                                    }, 0)).toFixed(2) : 0}</td>
                                                    <td>{data.finalamount}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr> */}

                                <tr className="eight-section">

                                    <td colSpan="3">
                                        <div className='saanvi-info-container'>
                                            <div className='saanvi-info'>
                                                <div className='bank-details'>Bank Details</div>
                                                <div className='bank-info'>
                                                    <p className='mb-0'>
                                                        <span className="btitle">Bank:</span>
                                                        <span className="desc">{billinfo.bank ? billinfo.bank : "--"}</span>
                                                    </p>
                                                    <p className='mb-0'>
                                                        <span className="btitle">Account Number:</span>
                                                        <span className="desc">{billinfo.accountnumber ? billinfo.accountnumber : "--"}</span>
                                                    </p>
                                                    <p className='mb-0'>
                                                        <span className="btitle">IFSC Code:</span>
                                                        <span className="desc">{billinfo.ifsccode ? billinfo.ifsccode : "--"}</span>
                                                    </p>
                                                    <p className='mb-0'>
                                                        <span className="btitle">Branch:</span>
                                                        <span className="desc"> {billinfo.branch ? billinfo.branch : "--"}</span>
                                                    </p>
                                                </div>
                                                <div className='tandc'>
                                                    <Form.Group className="mb-3" controlId="tandc">
                                                        <Form.Label>Terms and Conditions</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            as="textarea"
                                                            rows={3}
                                                            name="tandc"
                                                            value={data.tandc}
                                                            className='tandcbox'
                                                        // isInvalid={data.description === "" && showEmptyError}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            Enter Terms and Conditions
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className='my-auto'>
                                                <Image
                                                    src={billinfo.qrcode}
                                                    alt='No Qr Image'
                                                    width="150"
                                                    height="150"
                                                />
                                                <h4>Merchant Name: {billinfo.accountholdername ? billinfo.accountholdername : "--"}</h4>
                                                <h4>Mobile Number:{billinfo.phonenumber ? billinfo.phonenumber : "--"}</h4>
                                            </div>
                                            <div className='saanvi-info'>
                                                <p className='saanv-certificate-section'>
                                                    <span className='certified'>Certified that particulars given above are true</span>
                                                    <span className='certified-section'>For {billinfo.brandname ? billinfo.brandname : "--"}</span>
                                                </p>
                                                {/* <div className='signature-section'></div> */}
                                                <p className='authorized'>Authorized Signature </p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="nine-section">
                                    <td colSpan={3}>
                                        <div className='text-center'>
                                            <h4>POWERED BY VENTURESATHI BUSINESS SERVICES LLP</h4>
                                            <h5>This is a Computer Generated Bill </h5>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Col sm={12} className="d-flex gap-3">
                    <span className='w-15'>
                        <Button className="commonBtn" onClick={handleAcceptAndSend}>
                            {loadData ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />)
                                : (
                                    data.orderstatus == "Approved" ? "Send" : "Approved and Send"
                                )}
                        </Button>
                    </span>
                    <span>
                        <ReactToPrint
                            trigger={() => (
                                <Button className="commonBtn smallBtn cancelBtn">
                                    Print
                                </Button>
                            )}
                            content={() => containerRef.current}
                        />

                    </span>
                    {data.orderstatus == "Approved" && <span>
                        <Button className="commonBtn smallBtn cancelBtn" onClick={handleReject}>
                            Reject
                        </Button>
                    </span>}
                </Col>
            </Row>
        </Container>
    )
}

export default ViewSalesOrder