import React from 'react'
import ListTablePayment from './ListTablePayment';
import { Col, Container, Row } from 'react-bootstrap';

function Payment() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <ListTablePayment/>
        </Col>
      </Row>
    </Container>
  )
}

export default Payment;