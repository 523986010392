import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import PersonalDetails from "./PersonalDetails";
import DocumentUpload from "./DocumentUpload";
import OfficialDetails from "./OfficialDetails";
import ContactAddress from "./ContactAddress";
import BankInformation from "./BankInformation";
import { City, State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "../../../../constants/toastAlert";
import {
  addEmployee,
  empAadharUpload,
  empIDUpload,
  empImageUpload,
  employeeData,
} from "../../../../redux/actions/employee.actions";
import axios from "axios";
import { idgenerateEmpID } from "../../../../constants/api";
import { getCurrentPreviousYears } from "../../../../constants/currentPreviousYear";
// import { clearWaitingQueue } from "react-toastify/dist/core/store";
const states = State.getStatesOfCountry("IN");

const AddEmployeeTab = () => {
  const empData =
    useSelector((state) => state.employeeData.employeeData.data) || [];
  const empImage = useSelector((state) => state.employeeData.empImage);
  const empIDImage = useSelector((state) => state.employeeData.empID);
  const empAadharImage = useSelector((state) => state.employeeData.empAadhar);
  const { companyid, userid, companyname, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [key, setKey] = useState("personaldetails");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [selectedCurrentState, setSelectedCurrentState] = useState("");
  const [currentCities, setCurrentCities] = useState([]);
  const [selectedPermanentState, setSelectedPermanentState] = useState("");
  const [permanentCities, setPermanentCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    employeeid: "",
    fullname: "",
    phoneno: "",
    aadharno: "",
    emailid: "",
    dob: "",
    age: "",
    gender: "Male",
    photo: [],
    idproof: [],
    aadhar: [],
    department: "",
    designation: "",
    joiningdate: "",
    issale: "",
    currentcountry: "India",
    currentstate: "",
    currentcity: "",
    currentaddress: "",
    currentpincode: "",
    permanentcountry: "India",
    permanentstate: "",
    permanentcity: "",
    permanentaddress: "",
    permanentpincode: "",
    bankname: "",
    bankbranch: "",
    bankaccno: "",
    bankifsccode: "",
    remarks: "",
    company: companyname,
    companyid,
    branch: "",
    branchid,
    userid,
    org,
    status: "Active",
  });

  const switchToNextTab = () => {
    // Determine the next tab's event key based on the current active tab
    let nextTabKey = "";
    switch (key) {
      case "personaldetails":
        nextTabKey = "documentupload";
        break;
      case "documentupload":
        nextTabKey = "officialdetails";
        break;
      case "officialdetails":
        nextTabKey = "contactaddress";
        break;
      case "contactaddress":
        nextTabKey = "bankinformation";
        break;
      default:
        nextTabKey = "";
    }
    if (nextTabKey) {
      setKey(nextTabKey);
    }
  };
  const handleSelectKey = (key) => {
    setKey(key);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name == "currentstate") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);
        setSelectedCurrentState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          currentcity: "",
        }));
      } else {
        setSelectedCurrentState("");
        setData((prev) => ({
          ...prev,
          currentstate: "",
          currentcity: "",
        }));
      }
    }

    if (name == "permanentstate") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);
        setSelectedPermanentState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          permanentcity: "",
        }));
      } else {
        setSelectedPermanentState("");
        setData((prev) => ({
          ...prev,
          permanentstate: "",
          permanentcity: "",
        }));
      }
    }
    if (name === "dob") {
      const birthDate = new Date(value);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear();
      setData((prevData) => ({
        ...prevData,
        age: age,
      }));
    }

    if (name === "picture") {
      dispatch(empImageUpload(files));
    }
    if (name === "idpicture") {
      dispatch(empIDUpload(files));
    }
    if (name === "aadharpicture") {
      dispatch(empAadharUpload(files));
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!data.fullname || !data.phoneno || !data.dob || !data.gender) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    if (key == "officialdetails") {
      if (
        !data.branch ||
        !data.department ||
        !data.designation ||
        !data.joiningdate ||
        !data.issale
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }
    if (key == "contactaddress") {
      if (
        !data.currentaddress ||
        !data.currentpincode ||
        !data.permanentaddress ||
        !data.permanentpincode
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }
    if (key == "bankinformation") {
      if (
        !data.bankname ||
        !data.bankbranch ||
        !data.bankifsccode ||
        !data.bankaccno
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
      if (data.bankifsccode.length !== 11) {
        return notifyError("IFSC Code must be exact 11 characters");
      }
      let ifsctest = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(data.bankifsccode);
      if (ifsctest == false) {
        return notifyError("Invalid IFSC Code");
      }
    }

    if (submitButtonText == "Save") {
      dispatch(addEmployee(data, navigate));
    }
    if (!isLastTab) {
      switchToNextTab();
    }
  };

  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    keyword: "" || "₹ΘθϖΠ",
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(employeeData(backendData, setLoading));
  }, [dispatch]);

  const generateCategoryID = () => {
    if (empData.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = empData[0].employeeid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      employeeid: `EMP/${getCurrentPreviousYears()}/${paddedCount}`,
    }));
  }, []);

  useEffect(() => {
    axios
      .post(idgenerateEmpID, { userid, branchid, companyid })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          photo: empImage || [],
          idproof: empIDImage || [],
          aadhar: empAadharImage || [],
        }));
      })
      .catch((err) => console.log(err));

    if (selectedCurrentState) {
      const citiesOfSelectedState = City.getCitiesOfState(
        "IN",
        selectedCurrentState
      );
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setCurrentCities(cityNames);
    } else {
      setCurrentCities([]);
    }

    if (selectedPermanentState) {
      const citiesOfSelectedState = City.getCitiesOfState(
        "IN",
        selectedPermanentState
      );
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setPermanentCities(cityNames);
    } else {
      setPermanentCities([]);
    }
  }, [
    selectedCurrentState,
    selectedPermanentState,
    empImage,
    empIDImage,
    empAadharImage,
  ]);

  const isLastTab = key === "bankinformation";
  const submitButtonText = isLastTab ? "Save" : "Next";

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/employeemanagement/employee")}
            />
            Employee <span className="form-title"> Registration</span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="personaldetails"
            id="justify-tab-example"
            className="justify-content-end tab-margin"
            activeKey={key}
            onSelect={handleSelectKey}
          >
            <Tab
              eventKey="personaldetails"
              title="Personal Details"
              className="tabview"
            >
              <PersonalDetails
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
            <Tab
              eventKey="documentupload"
              title="Document Upload"
              className="tabview"
            >
              <DocumentUpload
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
            <Tab
              eventKey="officialdetails"
              title="Official Details"
              className="tabview"
            >
              <OfficialDetails
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
            <Tab
              eventKey="contactaddress"
              title="Contact Address"
              className="tabview"
            >
              <ContactAddress
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
                states={states}
                currentCities={currentCities}
                permanentCities={permanentCities}
                selectedCurrentState={selectedCurrentState}
                setSelectedPermanentState={setSelectedPermanentState}
                setData={setData}
              />
            </Tab>
            <Tab
              eventKey="bankinformation"
              title="Bank Information"
              className="tabview"
            >
              <BankInformation
                data={data}
                handleChange={handleChange}
                showEmptyError={showEmptyError}
              />
            </Tab>
          </Tabs>
        </Col>
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSubmit}>
            {submitButtonText}
          </Button>

          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/employeemanagement/employee")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Row>
    </Container>
  );
};

export default AddEmployeeTab;
