import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Payment from "./Payment/Payment";
import Receipt from "./Receipt/Receipt";
import ContraEntry from "./ContraEntry/ContraEntry";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";

const Transaction = () => {
  const URL = extractAndFormatURLPart("banking");
  //state
  const [currentTab, setCurrentTab] = useState("Payment");

  const handleTabSelect = (key) => {
    switch (key) {
      case "contraentry":
        setCurrentTab("Contra Entry");
        break;
      case "receipt":
        setCurrentTab("Receipt");
        break;
      default:
        setCurrentTab("Payment");
    }
  };

  return (
    <Container fluid>
      <Row >
        <Col className="colStyle"><span>{URL + " / " + currentTab}</span></Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="payment"
            id="justify-tab-example"
            className="mb-3"
            //variant='pills'
            //fill
            onSelect={handleTabSelect}
          >
            <Tab eventKey="payment" title="Payment" className="tabview">
              <Payment />
            </Tab>
            <Tab eventKey="receipt" title="Receipt" className="tabview">
              <Receipt />
            </Tab>
            <Tab eventKey="contraentry" title="Contra Entry" className="tabview">
              <ContraEntry />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default Transaction