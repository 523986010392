import { notifyError } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { customerPagiDataPost } from "../../constants/api";
import { FETCH_CUSTOMER_DATA } from "../actionTypes/customer.actionTypes";

//Get all customer data using pagination wise
export const customerPagiData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(customerPagiDataPost, backendData);
      const initialData = res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_CUSTOMER_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching customer data ${error}`);
      notifyError(error.message);
    }
  };
