import axios from "axios";
import { addSuggestedItemApi, deleteSuggestedItemApi, getSuggestedItemApi, updateSuggestedItemApi } from "../../constants/api";
import { ADD_SUGGESTED_ITEM_DATA, FETCH_SUGGESTED_ITEM_DATA, UPDATE_SUGGESTED_ITEM_DATA } from "../actionTypes/suggesteditems.actiontypes";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";

 
  //Get all warehouse data using pagination wise
  export const suggestedItemData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getSuggestedItemApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_SUGGESTED_ITEM_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in fetching suggested items data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete stocklocation data dispatcher
  export const deleteSuggestedItemData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteSuggestedItemApi, backendData);
        if (res.data.message == "Suggested items deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting suggested items data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new vendor data
  export const addSuggestedItem = (suggesteditemdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addSuggestedItemApi, suggesteditemdata);
  
      if (res.data.message === "Suggested items added successfully") {
        dispatch({ type: ADD_SUGGESTED_ITEM_DATA });
        notifySuccess(res.data.message);
        navigate("/inventory/suggestedItem");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in adding suggested item data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateSuggestedItem = (suggesteditemdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updateSuggestedItemApi, suggesteditemdata);
  
      if (res.data.message === "Suggested items updated successfully") {
        dispatch({ type: UPDATE_SUGGESTED_ITEM_DATA});
        notifySuccess(res.data.message);
        navigate("/inventory/suggestedItem");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating suggested item data ${error}`);
      notifyError(error.response.data.message);
    }
  };