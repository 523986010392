import {
  DELETE_COMPANY_DATA,
  FETCH_COMPANY_DATA,
  STATUS_CHANGE,
} from "../actionTypes/company.actiontypes";

const initialState = {
  companyData: {},
};

//
export const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMPANY_DATA: {
      return { ...state, companyData: { ...payload } };
    }
    
    default: {
      return state;
    }
  }
};
