import { notifyError } from "./toastAlert";

export const validatePhone = (value) =>
  value.length != 10 ? notifyError("Number Should be 10 digits") : "";

export const validatePin = (value) =>
  value.length != 6 ? notifyError("Pin Should be 6 digits") : "";

export const validatePan = (panNumber) => {
  // Check if the PAN number is ten characters long
  if (panNumber.length !== 10) {
    notifyError("PAN number must be ten characters long.");
    return;
  }

  // Check if the first five characters are uppercase alphabets
  if (!/^[A-Z]{5}$/.test(panNumber.substring(0, 5))) {
    notifyError(
      "The first five characters of PAN should be uppercase alphabets."
    );
    return;
  }

  // Check if the next four characters are numbers (0-9)
  if (!/^[0-9]{4}$/.test(panNumber.substring(5, 9))) {
    notifyError("The numeric part of PAN should contain four digits.");
    return;
  }

  // Check if the last character is an uppercase alphabet
  if (!/^[A-Z]$/.test(panNumber[9])) {
    notifyError("The last character of PAN should be an uppercase alphabet.");
    return;
  }
};

export const validateGST = (gstNumber) => {
  // Check if the GST number is 15 characters long
  if (gstNumber.length !== 15) {
    notifyError("GST number must be 15 characters long.");
    return;
  }

  // Check if the first two characters are numbers (00 to 99)
  const firstTwoDigits = parseInt(gstNumber.substring(0, 2), 10);
  if (isNaN(firstTwoDigits) || firstTwoDigits < 0 || firstTwoDigits > 99) {
    notifyError(
      "The first two characters of GST should be numbers (00 to 99)."
    );
    return;
  }

  // Check if the next five characters are uppercase alphabets
  if (!/^[A-Z]{5}$/.test(gstNumber.substring(2, 7))) {
    notifyError(" Characters 3 to 7 of GST should be uppercase alphabets.");
    return;
  }

  // Check if the next four characters are numbers (0000 to 9999)
  const nextFourDigits = parseInt(gstNumber.substring(7, 11), 10);
  if (isNaN(nextFourDigits) || nextFourDigits < 0 || nextFourDigits > 9999) {
    notifyError("Characters 8 to 11 of GST should be numbers (0000 to 9999).");
    return;
  }

  // Check if the next character is an uppercase alphabet
  if (!/^[A-Z]{1}$/.test(gstNumber[11])) {
    notifyError("Character 12 of GST should be an uppercase alphabet.");
    return;
  }

  // Check if character 13 is a number from 1 to 9
  const entityCode = parseInt(gstNumber[12], 10);
  if (isNaN(entityCode) || entityCode < 1 || entityCode > 9) {
    notifyError("Character 13 of GST should be a number from 1 to 9.");
    return;
  }

  // Check if character 14 is 'Z'
  if (gstNumber[13] !== "Z") {
    notifyError("Character 14 of GST should be 'Z'.");
    return;
  }

  // Check if character 15 is an alphanumeric character (number or alphabet)
  if (!/^[0-9A-Z]{1}$/.test(gstNumber[14])) {
    notifyError("Character 15 of GST should be a number or an alphabet.");
    return;
  }
};

export const validateBankAccount = (accountNumber) => {
  // Check if the account number is within the specified length range
  if (accountNumber.length < 9 || accountNumber.length > 18) {
    notifyError("Account number should be between 9 to 18 digits.");
    return;
  }

  // Check for the presence of whitespaces
  if (/\s/.test(accountNumber)) {
    notifyError(
      "Invalid bank account number format. Whitespaces are not allowed."
    );
    return;
  }

  // Check for the presence of special characters
  if (/[^0-9]/.test(accountNumber)) {
    notifyError("Special characters are not allowed. in bank account");
    return;
  }
};

export const validateAadhar = (aadharNumber) => {
  // Check if the Aadhaar number has 12 digits
  const cleanAadharNumber = aadharNumber.replace(/\s/g, ""); // Remove white spaces
  if (cleanAadharNumber.length !== 12) {
    notifyError("Aadhaar number must have exactly 12 digits.");
    return;
  }

  // Check if the Aadhaar number starts with 0 or 1
  if (cleanAadharNumber[0] === "0" || cleanAadharNumber[0] === "1") {
    notifyError("Aadhaar number should not start with 0 or 1.");
    return;
  }

  // Check if the Aadhaar number contains only digits
  if (!/^\d+$/.test(cleanAadharNumber)) {
    notifyError(
      "Aadhaar number should not contain alphabets or special characters."
    );
    return;
  }
};

export const validateIFSC = (ifscCode) => {
  // Check if the IFSC code is 11 characters long
  if (ifscCode.length !== 11) {
    notifyError("IFSC code must be 11 characters long.");
    return;
  }

  // Check if the first four characters are uppercase alphabets
  if (!/^[A-Z]{4}$/.test(ifscCode.substring(0, 4))) {
    notifyError(
      "The first four characters of IFSC should be uppercase alphabets."
    );
    return;
  }

  // Check if the fifth character is '0'
  if (ifscCode[4] !== "0") {
    notifyError(" The fifth character of IFSC should be '0'.");
    return;
  }

  // Check if the last six characters are alphanumeric
  if (!/^[A-Za-z0-9]{6}$/.test(ifscCode.substring(5))) {
    notifyError("The last six characters of IFSC should be alphanumeric.");
    return;
  }
};

export const validateAge = (age) => {
  // Convert age to an integer
  const parsedAge = parseInt(age, 10);

  // Check if the age is 18 or above
  if (parsedAge < 18) {
    notifyError("Age must be 18 years or older.");
    return;
  }
};

export const validateEmail = (email) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the regular expression
  if (!emailRegex.test(email)) {
    notifyError("Invalid email format. Please enter a valid email address.");
    return;
  }
};
