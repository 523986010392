import { FETCH_PURCHASETARGET_DASHBOARD_DATA } from "../actionTypes/purchaseTarget.types";


const initialState = {

    purchaseTargetDashboardData: {},

  };
  
  //
  export const  purchaseTargetDashboardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_PURCHASETARGET_DASHBOARD_DATA: {
        return { ...state, purchaseTargetDashboardData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };