import { FETCH_COA_COUNT, FETCH_COA_DATA, FETCH_COA_DATA1 } from "../actionTypes/chartofaccount.actiontypes";

  const initialState = {
    coaPagiData: {},
    coaCount:0,
    coaPagiData1:{}
  };
  
  export const coaReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_COA_DATA: {
        return { ...state, coaPagiData: { ...payload } };
      }
      case FETCH_COA_DATA1: {
        return { ...state, coaPagiData1: { ...payload } };
      }
      case FETCH_COA_COUNT: {
        return { ...state, coaCount: payload  };
      }
  
      default: {
        return state;
      }
    }
  };
  