import { FETCH_BILL_DATA, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_USER } from "../actionTypes/auth.actiontypes";
const storedToken = localStorage.getItem('token');
const storedUserData = localStorage.getItem('userData');

const authState = {
  loading: false,
  error: null,
  token: storedToken ? storedToken : null,
  userData: storedUserData ? JSON.parse(storedUserData) : {},
  billinfo: {}
};

export const authReducer = (state = authState, { type, payload }) => {
  switch (type) {

    case LOGIN_REQUEST: {
      return { ...state, loading: true, error: null, token: null, userData: {} };
    }

    case LOGIN_SUCCESS: {
      return { ...state, loading: false, token: payload.token, userData: payload, error: null };
    }

    case LOGIN_FAILURE: {
      return { ...state, loading: false, error: payload, userData: {}, token: null };
    }

    case LOGOUT_USER: {
      return { ...state, ...payload };
    }

    case FETCH_BILL_DATA: {
      return { ...state, billinfo: payload }

    }

    default: {
      return state;
    }
  }

};


