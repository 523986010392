import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdDangerous } from "react-icons/md";

const CancelModal = ({
  show,
  handleShow,
  handleClose,
  name,
  handleCancel,
  remarks,
  setRemarks,
  description,
  setDescription,
}) => {
  return (
    <>
      <MdDangerous className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="f1">Please Confirm!!!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="f1">
          Do you want to cancel the {name}?
          <br />
          <hr />
          <Form.Group className="mb-3" controlId="formBasicID">
            <Form.Label>Specify Reason</Form.Label>
            <Form.Select
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            >
              <option value="">--Select--</option>
              <option value="Change in Requirements">
                Change in Requirements
              </option>
              <option value="Price Discrepancy">Price Discrepancy</option>
              <option value="Scope Changes">Scope Changes</option>
              <option value="Timing Issues">Timing Issues</option>
              <option value="Budget Constraints">Budget Constraints</option>
              <option value="Other">Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please Specify Reason
            </Form.Control.Feedback>
          </Form.Group>
          {remarks && remarks == "Other" && (
            <Form.Group className="mb-3" controlId="formBasicDescription">
              <Form.Label>Describe Other Reason</Form.Label>
              <textarea
                className="form-control"
                rows="4"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              ></textarea>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="commonBtn w-25" onClick={handleCancel}>
            Yes, Sure
          </Button>
          <Button className="commonBtn cancelBtn w-25" onClick={handleClose}>
            No, I don't
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelModal;
