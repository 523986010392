import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ViewBank = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const {
    bankid,
    bank,
    accountnumber,
    branchname,
    ifsccode,
    address,
    accounttype,
    account,
  } = item || [];
  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title">Bank Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <p>
                  <span className="viewpage-title">Bank ID:</span>{" "}
                  <span className="viewpage-value">{bankid}</span>
                </p>
                <p>
                  <span className="viewpage-title">Account No.:</span>{" "}
                  <span className="viewpage-value">{accountnumber}</span>
                </p>
                <p>
                  <span className="viewpage-title">IFSC Code:</span>{" "}
                  <span className="viewpage-value">{ifsccode}</span>
                </p>
                <p>
                  <span className="viewpage-title">Account Type:</span>{" "}
                  <span className="viewpage-value">{accounttype}</span>
                </p>
                <p>
                  <span className="viewpage-title">Account:</span>{" "}
                  <span className="viewpage-value">{account}</span>
                </p>
              </Col>
              <Col>
                <p>
                  <span className="viewpage-title">Bank Name:</span>{" "}
                  <span className="viewpage-value">{bank}</span>
                </p>
                <p>
                  <span className="viewpage-title">Bank Branch:</span>{" "}
                  <span className="viewpage-value">{branchname}</span>
                </p>
                <p>
                  <span className="viewpage-title">Bank Address:</span>{" "}
                  <span className="viewpage-value">{address}</span>
                </p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewBank;
