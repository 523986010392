import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import ListTableSalesInvoice from './ListTableSalesInvoice';

function SalesInvoice() {
    const URL = extractAndFormatURLPart("sales");
    return (
        <Container fluid>
            <Row>
                <Col className="colStyle p-0">
                    <span>{URL}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListTableSalesInvoice />
                </Col>
            </Row>
        </Container>
    );
}

export default SalesInvoice;