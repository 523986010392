import { FETCH_PT_DATA } from "../actionTypes/pt.actiontypes";


  const initialState = {

    ptData: [],

  };
  
  //
  export const  ptReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_PT_DATA: {
        return { ...state, ptData: [...payload]  };
      }
      
      default: {
        return state;
      }
    }
  };