import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import { updateLedger } from "../../../redux/actions/ledger.actions";

const EditAccountLedger = ({
  showEditModal,
  handleEditModal,
  editItem,
  handleCloseEditModal,
}) => {
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const { ledgerCount } = useSelector((state) => state.ledgerData) || 0;
  const { data: coaAllData } = useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  const isLedgerYesCoaData = coaAllData ? coaAllData.filter((item) => item.isledger === "Yes") : [];

  //Default Data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
  }

  //state part
  const [loadState, setLoadState] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [account, setAccount] = useState([]);
  const [data, setData] = useState({
    id: editItem ? editItem.id : "",
    ledgerid: editItem ? editItem.ledgerid : "",
    ledgername: editItem ? editItem.ledgername : "",
    ledgertype: editItem ? editItem.ledgertype : "",
    istds: editItem ? editItem.istds : "",
    isinventory: editItem ? editItem.isinventory : "",
    accountid: editItem ? editItem.accountid : "",
    accountname: editItem ? editItem.accountname : "",
    accounttype: editItem ? editItem.accounttype : "",
    openingbalance: editItem ? editItem.openingbalance : "",
    debitcredit: editItem ? editItem.debitcredit : "",
    asondate: editItem ? editItem.asondate : "",
    userid: editItem ? editItem.userid : "",
    branchid: editItem ? editItem.branchid : "",
    companyid: editItem ? editItem.companyid : ""
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!loading);
    dispatch(coaPagiData(backendData, setLoading));
    setData({
      id: editItem ? editItem.id : "",
      ledgerid: editItem ? editItem.ledgerid : "",
      ledgername: editItem ? editItem.ledgername : "",
      ledgertype: editItem ? editItem.ledgertype : "",
      istds: editItem ? editItem.istds : "",
      isinventory: editItem ? editItem.isinventory : "",
      accountid: editItem ? editItem.accountid : "",
      accountname: editItem ? editItem.accountname : "",
      accounttype: editItem ? editItem.accounttype : "",
      openingbalance: editItem ? editItem.openingbalance : "",
      debitcredit: editItem ? editItem.debitcredit : "",
      asondate: editItem ? editItem.asondate : "",
      userid: editItem ? editItem.userid : "",
      branchid: editItem ? editItem.branchid : "",
      companyid: editItem ? editItem.companyid : ""
    });


  }, [editItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "accountname") {
      const selectedAccount = isLedgerYesCoaData.find((itm) => itm.accountname === value);
      if (selectedAccount) {
        setData((prevData) => ({
          ...prevData,
          accountid: selectedAccount.accountid,
          accounttype: selectedAccount.accounttype,
          [name]: value,
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          accountid: "",
          accounttype: "",
          [name]: value,
        }));
      }
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setShowEmptyError(false);
  };

  //filter part
  const groupedData = {}; // This object will store gledgers grouped by accounttype
  isLedgerYesCoaData && isLedgerYesCoaData.forEach((item) => {
    const { accounttype, accountname } = item;

    // Check if the accounttype already exists in groupedData
    if (!groupedData[accounttype]) {
      groupedData[accounttype] = [];
    }
    // Add the accountname to the corresponding accounttype
    groupedData[accounttype].push(accountname);
  });

  const handleSave = async () => {
    if (
      data.ledgername == "" ||
      data.accountname == "" ||
      data.openingbalance == "" ||
      data.debitcredit == "" ||
      data.asondate == ""
    ) {
      setShowEmptyError(true);
      // return notifyError("You have empty field");
    }
    dispatch(updateLedger(data, handleCloseEditModal, setData));
  };

  return (
    <>
      <MdEdit className="editIcon" onClick={handleEditModal} />

      <Modal
        size="xl"
        show={showEditModal}
        onHide={handleCloseEditModal}
        backdrop={true}
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Update <span className="form-title">Ledger</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={2} md={1}>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Ledger ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ledger ID"
                        name="ledgerid"
                        value={data.ledgerid}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Other</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Other"
                        name="ledgertype"
                        value={data.ledgertype}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Ledger Name <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Ledger Name"
                    name="ledgername"
                    value={data.ledgername}
                    onChange={handleChange}
                    //required
                    isInvalid={data.ledgername === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Ledger Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row lg={2} md={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Account <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="accountname"
                    value={data.accountname}
                    onChange={handleChange}
                    isInvalid={data.accountname === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {Object.entries(groupedData).map(
                      ([accounttype, accountname], index) => (
                        <optgroup label={accounttype} key={index}>
                          {accountname.map((accountname, accountnameIndex) => (
                            <option value={accountname} key={accountnameIndex}>
                              {accountname}
                            </option>
                          ))}
                        </optgroup>
                      )
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Account
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        TDS Applicable?<span className="star"> *</span>
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="istds"
                          label="Yes"
                          value="Yes"
                          checked={data.istds === "Yes"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                          name="istds"
                          label="No"
                          value="No"
                          checked={data.istds === "No"}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Inventory values are affected?
                        <span className="star"> *</span>
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="isinventory"
                          label="Yes"
                          value="Yes"
                          checked={data.isinventory === "Yes"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                          name="isinventory"
                          label="No"
                          value="No"
                          checked={data.isinventory === "No"}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row lg={2} md={1}>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Opening Balance <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        style={{ textAlign: "right" }}
                        type="number"
                        placeholder="0.00"
                        name="openingbalance"
                        value={data.openingbalance}
                        onChange={handleChange}
                        //required
                        isInvalid={data.openingbalance === "" && showEmptyError}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Debit/Credit <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="debitcredit"
                        value={data.debitcredit}
                        onChange={handleChange}
                        isInvalid={data.debitcredit === "" && showEmptyError}
                      >
                        <option value="">--Select--</option>
                        <option value="Debit">Debit</option>
                        <option value="Credit">Credit</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select Debit or Credit
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row md={1} sm={1}>
                  <Col md={5}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        As on Date <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="asondate"
                        value={data.asondate}
                        min={`${parseInt(finalYear[0].replace('"', ''), 10)}-04-01`}
                        onChange={handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                        max={new Date().toISOString().split("T")[0]}
                        isInvalid={data.asondate === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Choose Date
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSave}
          >
            Update
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleCloseEditModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAccountLedger;
