import { FETCH_WAREHOUSE_DATA } from "../actionTypes/warehouse.actiontypes";

  
  const initialState = {

    warehouseData: {},

  };
  
  //
  export const warehouseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_WAREHOUSE_DATA: {
        return { ...state, warehouseData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };