import {
  FETCH_RECEIPT_COUNT,
  FETCH_RECEIPT_DATA,
} from "../actionTypes/receipt.actiontypes";

const initialState = {
  receiptPagiData: {},
  receiptCount: 0,
};

export const receiptReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RECEIPT_DATA: {
      return { ...state, receiptPagiData: { ...payload } };
    }

    case FETCH_RECEIPT_COUNT: {
      return { ...state, receiptCount: payload };
    }

    default: {
      return state;
    }
  }
};
