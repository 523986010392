import React from "react";

const TableHeading = ({ data }) => {
  return (
    <thead className="tableContainer">
      <tr>
        {data.length > 0
          ? data.map((ele, index) => <th key={index}>{ele}</th>)
          : []}
      </tr>
    </thead>
  );
};

export default TableHeading;
