import axios from "axios";
import { addPurchasePaymentApi, deletePurchasePaymentApi, getPurchasePaymentApi, updatePurchasePaymentApi } from "../../constants/api";
import { ADD_PURCHASE_PAYMENT_DATA, FETCH_PURCHASE_PAYMENT_DATA, UPDATE_PURCHASE_PAYMENT_DATA } from "../actionTypes/purchasePayment.actionTypes";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { notifyError, notifySuccess } from "../../constants/toastAlert";

  //Get all purchase payment
  export const purchasePaymentData = (backendData, setLoading) => async (dispatch) => {
    
    try {
      const res = await axios.post(getPurchasePaymentApi, backendData);
     
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_PURCHASE_PAYMENT_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching purchase payment data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete purchase payment data dispatcher
  export const deletePurchasePaymentData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deletePurchasePaymentApi, backendData);
        if (res.data.message == "Purchase payment deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting purchase payment data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new purchase payment
  export const addPurchasePayment = (purchasepaymentdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addPurchasePaymentApi, purchasepaymentdata);
  
      if (res.data.message === "Purchase payment added successfully") {
        dispatch({ type: ADD_PURCHASE_PAYMENT_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/purchasePayment");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding purchase payment data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating purchase payment
  export const updatePurchasePayment = (purchasepaymentdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updatePurchasePaymentApi, purchasepaymentdata);
  
      if (res.data.message === "Purchase payment updated successfully") {
        dispatch({ type: UPDATE_PURCHASE_PAYMENT_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/purchasePayment");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating purchase payment data ${error}`);
      notifyError(error.response.data.message);
    }
  };