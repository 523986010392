export const allReports = [
  {
    id: 1,
    type: "Account Reports",
    contents: [
      {
        link: "/reports/balanceSheet",
        name: "Balance Sheet",
      },
      {
        link: "/reports/profitAndLoss",
        name: "Profit and Loss",
      },
      {
        link: "/reports/trialBalance",
        name: "Trial balance",
      },
      {
        link: "/reports/ledgerReport",
        name: "Ledger Report",
      },
      {
        link: "/reports/cashBook",
        name: "Cash Book",
      }
    ],
  },
  {
    id: 2,
    type: "Sale Reports",
    contents: [
      {
        link: "/sellreport",
        name: "Sale Report(Invoice)",
      },
      {
        link: "/sell-report",
        name: "Sale Report(Item)",
      },
      {
        link: "/reports/sellReportCommission",
        name: "Sale Report(Commission)",
      },
    ],
  },
  {
    id: 3,
    type: "Inventory Reports",
    contents: [
      {
        link: "/stock-report",
        name: "Stock Report",
      },
      {
        link: "/reports/itemAgeReport",
        name: "Stock Report(Age)",
      },
      {
        link: "/inventory-movement",
        name: "Inventory Movement",
      },
     
     
    ],
  },
  {
    id: 4,
    type: "Purchase Reports",
    contents: [
      {
        link: "/purchase-report",
        name: "Purchase Report",
      },
    ],
  },
];
