import { FETCH_LEDGER_COUNT, FETCH_LEDGER_DATA } from "../actionTypes/ledger.actiontypes";

  const initialState = {
    ledgerPagiData: {},
    ledgerCount:0,

  };
  
  export const ledgerReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_LEDGER_DATA: {
        return { ...state, ledgerPagiData: { ...payload } };
      }
  
      case FETCH_LEDGER_COUNT: {
        return { ...state, ledgerCount: payload  };
      }
  
      default: {
        return state;
      }
    }
  };
  