import React from "react";
import Signupform from "./Signupform";

function Signup() {
  return (
    <section className="sinup-signin">
      <div className="container">
        <div className="row h-100 align-items-center">
          <div className="col-lg-12 d-flex justify-content-center">
            <Signupform />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
