import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AddPageButton = ({ text, handleAddPage,link }) => {
    const navigate = useNavigate()
    
  return (
    <Button className="commonBtn mt-2" onClick={()=>navigate(link)}>
      Add {text}
    </Button>
  );
};

export default AddPageButton;
