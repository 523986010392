import axios from "axios";
import { quoteGetpagePost } from "../../constants/api";
import { notifyError } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { FETCH_QUOTATION_DATA } from "../actionTypes/quotation.actiontypes";


export const quotationPagiData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(quoteGetpagePost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_QUOTATION_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in fetching quotation data ${error}`);
      notifyError(error.message);
    }
  };