import {
  receiptAddPost,
  receiptCountPost,
  receiptDeletePost,
  receiptPagiDataPost,
  receiptUpdatePost,
} from "../../constants/api";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_RECEIPT_COUNT,
  FETCH_RECEIPT_DATA,
} from "../actionTypes/receipt.actiontypes";

//Get all receipt data using pagination wise
export const receiptPagiData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(receiptPagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_RECEIPT_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching Receipt data ${error}`);
      notifyError(error.message);
      setLoading(false);
    }
  };

//Get receipt auto increment count
export const receiptAutoCount = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(receiptCountPost, backendData);
      const initialCount = Number(res.data.receiptCount) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: FETCH_RECEIPT_COUNT, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching receipt auto count data ${error}`);
      notifyError(error.message);
    }
  };

//Acion dispatcher for adding new Receipt data
export const addReceipt = (backendData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(receiptAddPost, backendData);
    if (res.data.message === 'Receipt added successfully') {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    handleClose();
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new Receipt data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating Receipt data
export const updateReceipt =(backendData, handleCloseEditModal) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(receiptUpdatePost, backendData);
      if (res.data.message === "Receipt updated successfully") {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleCloseEditModal();
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating Receipt data ${error}`);
      notifyError(error.response.data.message);
    }
  };

//Delete Receipt data
export const deleteReceipt = (backendData, handleClose) => async (dispatch) => {
  try {
    const res = await axios.post(receiptDeletePost, backendData);
    
    if (res.data.message == 'Receipt deleted successfully') {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    handleClose();
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in deleting Receipt data ${error}`);
    notifyError(error.message);
  }
};
