import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ImEye } from "react-icons/im";
import { FaTrainTram } from "react-icons/fa6";
import { FcCancel } from "react-icons/fc";
import {
  notifyError,
  notifySuccess,
} from "../../../../constants/toastAlert";
import "./Einvoice.css";
import CancelEinvoiceModal from "./CancelEinvoice";
import { IoEyeOutline } from "react-icons/io5";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";

function ListTable() {
  const navigate = useNavigate();
  const URL = extractAndFormatURLPart("einvoice");
  const loadData = useSelector((state) => state.loadReducer);
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelIrn, setCancelIrn] = useState("");
  const [data, setData] = useState([]);

  const handleCancelModalClose = () => setShowCancelModal(false);

  const handleCancelModalShow = (irn) => {
    setCancelIrn(irn);
    setShowCancelModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post("https://erp.venturesathi.co.in/api/v1/einvoice/getfromtable", { companyid: localData.companyid });
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [localData.companyid]);

  const handleCancelEinvoice = async () => {
    try {
      const res = await axios.post("https://erp.venturesathi.co.in/api/v1/einvoice/cancel", { irn: cancelIrn });
      if (res.data.status_cd === "1") {
        notifySuccess(res.data.status_desc);
      } else {
        notifyError(res.data.status_desc);
      }
    } catch (error) {
      console.log(error);
    }
    setShowCancelModal(false);
  };

  return (
    <Container >
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row className="table-wrapper-container">
        <Col lg={10}>
          <p className="section-title form-section ptag">
            List of All <span className="form-title">E-Invoice</span>
          </p>
        </Col>
        <Col lg={2}>
          <Button
            className="commonBtn mt-2"
            onClick={() => navigate("/einvoice/add")}
          >
            Add New
          </Button>
        </Col>
      </Row>
      <Row >
        <Col md={12} className="table-main-container" >
          <Table responsive>
            <thead className="tableContainer">
              <tr>
                <th>S.N.</th>
                <th>Invoice No</th>
                <th>Invoice Type</th>
                <th>IRN No</th>
                <th>Status</th>
                <th>EWB</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loadData ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) : data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td >{item.invoiceno}</td>
                    <td style={item.invtype == "INV" ? { color: "green" } : item.invtype == "CRN" ? { color: "blue" } : {}}>{item.invtype}</td>
                    <td>{item.irn}</td>
                    <td
                      style={
                        item.status === "ACT"
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {item.status === "ACT" ? "Active" : "Canceled"}
                    </td>
                    <td style={{ color: "green" }}>{item.ewbno ? "✔" : "-"}</td>
                    <td>
                      <div
                        style={{ display: "flex", gap: "13px", height: "100%" }}
                      >
                        <div
                          onClick={() => navigate(`/einvoice/view/${item.irn}`)}
                          className="clickable-icon"
                        >
                          <IoEyeOutline />

                        </div>
                        {!item.ewbno ? (
                          <div
                            onClick={() =>
                              navigate(`/einvoice/ewb/${item.irn}`)
                            }
                            className="clickable-icon"
                          >
                            <FaTrainTram />
                          </div>
                        ) : (
                          <div className="disabled-icon clickable-icon">
                            <FaTrainTram />
                          </div>
                        )}
                        <div
                          onClick={() => handleCancelModalShow(item.irn)}
                          className="clickable-icon"
                        >
                          <FcCancel />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">
                    <div className="datanotfound-center-text">
                      No Record Found!! 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <CancelEinvoiceModal
        show={showCancelModal}
        handleClose={handleCancelModalClose}
        handleCancel={handleCancelEinvoice}
      />
    </Container>
  );
}

export default ListTable;
