import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reloadPage } from "../../../redux/actions/load.action";
import { notifyError } from "../../../constants/toastAlert";
import {
  addCoa,
  coaAutoCount,
  coaPagiData,
} from "../../../redux/actions/chartofaccount.actions";
import { BsPlusCircle } from "react-icons/bs";
import { coaPagiDataPost, coaParentAccountPost } from "../../../constants/api";
import axios from "axios";
import { getCurrentPreviousYears } from "../../../constants/currentPreviousYear";

const AddAccount = () => {
  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const companyName =
    useSelector((state) => state.companyData.companyData.data) || [];
  // const { coaCount } = useSelector((state) => state.chartOfAccountData) || 0;
  // const { data: coaData } = useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  // const coaAllData = !coaData ? [] : coaData;

  //state
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    coaid: "",
    category: "",
    subcategory: "",
    gledger: "",
    isledger: "No",
  });
  const [loading, setLoading] = useState(false);
  const [showAccountTypeFeild, setShowAccountTypeFeild] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState("");

  const parentAccount = async () => {
    const backendKey = {
      type: data.category,
      userid: userid,
      companyid: companyid,
      branchid: branchid,
    };

    try {
      const res = await axios.post(coaParentAccountPost, backendKey);
      setSubCategoryData(res.data.length > 0 ? res.data : []);
    } catch (err) {
      console.log(`Error in fetching account type data ${err}`);
    }
  };

  const defaultbackendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
  }

  useEffect(() => {
    parentAccount();
    setLoading(!loading);
    setShowAccountTypeFeild(false);
    // dispatch(coaAutoCount({ userid }, setLoading));
    // dispatch(coaPagiData(defaultbackendData, setLoading));
  
    axios.post(coaPagiDataPost, defaultbackendData)
      .then((res) => {
        let currentCount = res.data && res.data.data && res.data.data.length > 0 ?
          String(parseInt(res.data.data[0].accountid.split("/")[2]) + 1)
          : "1";
        let paddedCount = currentCount.toString().padStart(4, "0");
        let result = `COA/${getCurrentPreviousYears()}/${paddedCount}`;
        setData({
          ...data,
          coaid: result,
        });
      })
      .catch(e => console.log(e));
  }, [dispatch, show, data.category]);

  //Auto generate Id start
  // function autoGenerateID() {
  //   if (coaAllData.length === 0) {
  //     return "1";
  //   } else {
  //     const currentId = coaAllData[0]?.accountid.split("/")[2];
  //     return String(parseInt(currentId) + 1);
  //   }
  // };

  //Auto generate Id end

  //Default data backend needed
  const backendData = {
    accountid: data.coaid,
    parentaccount: data.category,
    accounttype: data.subcategory,
    accountname: data.gledger,
    isledger: data.isledger,
    companyid: companyid,
    branchid: branchid,
    userid: userid,
  };


  const handleShow = () => setShow(true);

  const handleClose = () => {
    setData({
      coaid: "",
      category: "",
      subcategory: "",
      gledger: "",
      isledger: "",
    });
    setShow(false);
    dispatch(reloadPage(true));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setShowEmptyError(false);
  };

  const handleSave = async () => {
    if (data.category == "" || data.subcategory == "" || data.gledger == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    dispatch(addCoa(backendData, handleClose));
  };

  return (
    <>
      <Button className="commonBtn mt-2" onClick={handleShow}>
        Add Account
      </Button>
      <Modal show={show} size="md" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Create <span className="form-title">Account</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Account Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Account Code"
                    name="coaid"
                    value={data.coaid}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Parent Account (Category) <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="category"
                    value={data.category}
                    onChange={handleChange}
                    isInvalid={data.category === "" && showEmptyError}
                  >
                    <option className="invisible" value="">
                      --Select--
                    </option>
                    <option value="Assets">Assets</option>
                    <option value="Liability">Liability</option>
                    <option value="Income">Income</option>
                    <option value="Expenses">Expenses</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Choose Category
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="d-flex justify-content-between">
                    <span>
                      Account Type (Sub-category){" "}
                      <span className="star">*</span>
                    </span>
                    <span
                      className="form-title-sibling"
                      onClick={() =>
                        setShowAccountTypeFeild(!showAccountTypeFeild)
                      }
                    >
                      <BsPlusCircle /> Add
                    </span>
                  </Form.Label>
                  <Form.Select
                    disabled={data.category === "" || showAccountTypeFeild}
                    name="subcategory"
                    value={data.subcategory}
                    onChange={handleChange}
                    required
                    isInvalid={data.subcategory === "" && showEmptyError}
                  >
                    <option value="">Select</option>
                    {subCategoryData && subCategoryData.length > 0 ? (
                      subCategoryData.map((item, index) => {
                        const { accounttype } = item;
                        return (
                          <option key={index} value={accounttype}>
                            {accounttype}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Subcategory available</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Choose Sub Category
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {showAccountTypeFeild && (
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="newaccounttype">
                    <Form.Label>Create New Account Type</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Account Type"
                      name="subcategory"
                      value={data.subcategory}
                      //disabled
                      onChange={(e) =>
                        setData({ ...data, subcategory: e.target.value })
                      }
                      required
                      isInvalid={data.newaccounttype === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter New Account Type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Account Name (General Ledger){" "}
                    <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Account Name"
                    name="gledger"
                    value={data.gledger}
                    //disabled
                    onChange={handleChange}
                    required
                    isInvalid={data.gledger === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter General Ledger
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Ledger Applicable? <span className="star">*</span>
                  </Form.Label>
                  <Form className="d-flex gap-5">
                    <Form.Check
                      type="checkbox"
                      name="isledger"
                      label="Yes"
                      value="Yes"
                      checked={data.isledger === "Yes"}
                      onChange={handleChange}
                    />
                    <Form.Check
                      type="checkbox"
                      name="isledger"
                      label="No"
                      value="No"
                      checked={data.isledger === "No"}
                      onChange={handleChange}
                    />
                  </Form>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSave}
          >
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddAccount;
