import { FETCH_PURCHABILLS_DATA } from "../actionTypes/purchaseBills.actiontypes";

const initialState = {

    purchaseBillsData: {},

  };
  
  //
  export const  purchaseBillsReducer = (state = initialState, { type, payload }) => {
   
    switch (type) {
      case FETCH_PURCHABILLS_DATA: {
        return { ...state, purchaseBillsData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };