import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListTableBank from "./ListTableBank";

const Bank = () => {
  const URL = extractAndFormatURLPart("banking");

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTableBank />
        </Col>
      </Row>
    </Container>
  );
};

export default Bank;
