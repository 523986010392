import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    FormControl,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
// import {
//   coaallcoarecords,
//   coacoabysitepost,
//   manualjournaladdmanualjournalpost,
//   manualjournalallmanualjournalget,
//   manualjournalupdatemanualjournalpost,
// } from "../../../constants/api";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { BsFillExclamationTriangleFill, BsPlusCircle } from "react-icons/bs";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { notifyError, notifySuccess, notifyWarning } from "../../../constants/toastAlert";
import { useDispatch, useSelector } from "react-redux";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { manualJournalPagiData, updateManualJournal } from "../../../redux/actions/manualjournal.actions";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import { ledgerPagiData } from "../../../redux/actions/ledger.actions";


const EditJournal = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const URL = extractAndFormatURLPart("accounting");
    const finalcialYear = localStorage.getItem("financialyear");
    const finalYear = finalcialYear.trim().split("-");

    //Redux part
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.loadReducer);
    const { companyid, userid, companyname, branchid } = useSelector((state) => state.authManager.userData.data[0]) || {};
    const { manualjournalDocument } = useSelector((state) => state.manualJournalData) || 0;
    const { data: coaAllData } = useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
    const { data: ledgerAllData } = useSelector((state) => state.ledgerData.ledgerPagiData) || [];
    const { data: manualJournalAllData } = useSelector((state) => state.manualJournalData.manualjournalPagiData) || 0;
    const filteredData = manualJournalAllData && manualJournalAllData.length > 0 ? manualJournalAllData.filter((item) => item.id == id) : [];

    //sate part
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [showbtn, setShowBtn] = useState(true);
    const [itemData, setItemData] = useState([]);
    const [totaldebit, setTotaldebit] = useState(0.0);
    const [totalcredit, setTotalcredit] = useState(0.0);
    const [show, setShow] = useState(false);
    const [data, setData] = useState({
        date: "",
        month: "",
        journalno: "",
        refno: "",
        note: "",
        journalarray: "",
        status: "",
        rawdocuments: "",
        totaldebits: 0,
        totalcredits: 0,
        documents: [],
        refno: "",
        userid: userid,
        branchid: branchid,
        companyid: companyid,
        financialyear: finalcialYear
    });
    const [loading, setLoading] = useState(false);



    //Default data backend needed
    const backendData = {
        keyword: "₹ΘθϖΠ",
        userid: userid,
        companyid: companyid,
        branchid: branchid,
    }

    useEffect(() => {
        setLoading(!loading);
        dispatch(manualJournalPagiData(backendData, setLoading));
        dispatch(coaPagiData(backendData, setLoading));
        dispatch(ledgerPagiData(backendData, setLoading));
        setData({
            ...data,
            id: filteredData[0].id,
            date: filteredData[0].date.split("T")[0],
            month: filteredData[0].month,
            journalno: filteredData[0].journalno,
            refno: filteredData[0].refno,
            note: filteredData[0].note,
            journalarray: filteredData[0].journalarray,
            status: filteredData[0].status,
            rawdocuments: filteredData[0].rawdocuments,
            totaldebits: filteredData[0].totaldebits,
            totalcredits: filteredData[0].totalcredits,
            documents: filteredData[0].documents,
            refno: filteredData[0].refno,
            userid: filteredData[0].userid,
            branchid: filteredData[0].branchid,
            companyid: filteredData[0].companyid,
            financialyear: filteredData[0].financialyear,
        });
    }, []);

    const handleLeftArrow = () => {
        navigate("/accounting/manualJournal");
    };

    const handleAddNewRow = () => {
        setData((prevState) => ({
            ...prevState,
            journalarray: [
                ...prevState.journalarray,
                { account: "", ledger: "", description: "", debits: "", credits: "", gledger: "", },
            ], // Add a new object at the end of the array
        }));
    };

    const handleTableRowChange = (e, rowIndex) => {
        setData((prevState) => {
            const updatedJournalArray = prevState.journalarray.map((item, i) =>
                i === rowIndex ? { ...item, [e.target.name]: e.target.value } : item
            );

            const newtotalDebit = updatedJournalArray.reduce((total, row) => {
                return total + (parseInt(row.debits) || 0);
            }, 0);

            const newtotalCredit = updatedJournalArray.reduce((total, row) => {
                return total + (parseInt(row.credits) || 0);
            }, 0);

            return {
                ...prevState,
                journalarray: updatedJournalArray,
                totalcredits: newtotalCredit,
                totaldebits: newtotalDebit,
            };
        });
    };

    const handleTableRowDelete = (rowIndex) => {


        setData((prevState) => {
            const updatedJournalArray = prevState.journalarray.filter(
                (item, i) => i !== rowIndex
            );

            const newtotalDebit = updatedJournalArray.reduce((total, row) => {
                return total + (parseInt(row.debits) || 0);
            }, 0);

            const newtotalCredit = updatedJournalArray.reduce((total, row) => {
                return total + (parseInt(row.credits) || 0);
            }, 0);

            return {
                ...prevState,
                journalarray: updatedJournalArray,
                totalcredits: newtotalCredit,
                totaldebits: newtotalDebit,
            };
        });
    };

    const handlePublish = async () => {
        if (data.date == "") {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        if (data.totaldebits - data.totalcredits != 0) {
            setShowEmptyError(true);
            return notifyError("Please ensure that Debits and Credits are equal");
        }
        if (data.totaldebits == 0 && data.totalcredits == 0) {
            setShowEmptyError(true);
            return notifyError("Please ensure that Debits and Credits are equal");
        }
        const formData = {
            ...data,
            status: "Publish",
            totaldebits: totaldebit,
            totalcredits: totalcredit,
        };

        dispatch(updateManualJournal(formData, navigate));
    };

    const handleDraft = async () => {
        if (data.date == "") {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        if (data.totaldebits - data.totalcredits != 0) {
            setShowEmptyError(true);
            return notifyError("Please ensure that Debits and Credits are equal");
        }
        if (data.totaldebits == 0 && data.totalcredits == 0) {
            setShowEmptyError(true);
            return notifyError("Please ensure that Debits and Credits are equal");
        }

        // let multipledocumentResponse;
        // try {
        //     // multipledocumentResponse = await handleUploadFile(data.rawdocuments);
        // } catch (error) {
        //     console.error("Error:", error);
        // }
        // const updatedFormData = {
        //     ...data,
        //     status: "Draft",
        //     documents: multipledocumentResponse ? multipledocumentResponse : [],
        // };
        // const res = await axios.post(manualjournalupdatemanualjournalpost, {
        //     ...updatedFormData,
        //     financialyear: sessionDataString,
        // });
        // if (res.data.message == "ManualJournal Updated successfully") {
        //     setData({
        //         id: "",
        //         date: "",
        //         journalno: "",
        //         note: "",
        //         refno: "",
        //         journalarray: itemData,
        //         status: "",
        //         rawdocuments: "",
        //         documents: [],
        //         totaldebits: 0.0,
        //         totalcredits: 0.0,
        //         userid: userId,
        //         siteid: siteId,
        //         org: org,
        //     });
        //     notifyWarning("journal updated as draft..");
        //     navigate("/accounting/manualJournal");
        //     setLoadState(!loadState);
        // } else {
        //     notifyWarning(res.data.message);
        // }
        const formData = {
            ...data,
            status: "Draft",
            totaldebits: totaldebit,
            totalcredits: totalcredit,
        };

        dispatch(updateManualJournal(formData, navigate));
    };

    //   const handleUploadFile = async (files) => {
    //     try {
    //       const formData = new FormData();
    //       for (let i = 0; i < files.length; i++) {
    //         formData.append("file", files[i]);
    //       }

    //       const response = await axios.post(
    //         "http://localhost:8081/manualjournal/multipledocumentupload",
    //         { ...formData, financialyear: sessionDataString },
    //         { headers: { "Content-Type": "multipart/form-data" } }
    //       );

    //       return response.data.files; // Return the entire response object
    //     } catch (error) {
    //       console.error("Error uploading file:", error.message);
    //       throw error;
    //     }
    //   };

    const handleCancel = () => {
        setShow(true);
    };
    const handleStay = () => {
        setShow(false);
    };
    const handleDiscard = () => {
        navigate("/accounting/manualJournal");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setShowEmptyError(false);
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="colStyle">
                        <span>{URL}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-container main">
                            <Form>
                                <h1>
                                    <FaArrowLeftLong
                                        className="left-arrow"
                                        onClick={() => navigate("/accounting/manualJournal")}
                                    />{" "}
                                    <span className="form-title main"> Update Journal</span>
                                </h1>
                                <hr />
                                <Row md={12}>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formBasicDate">
                                            <Form.Label>
                                                Date<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Date"
                                                name="date"
                                                value={data.date}
                                                min={`${parseInt(finalYear[0].replace('"', ''), 10)}-04-01`}
                                                max={`${parseInt(finalYear[1].replace('"', ''), 10)}-03-31`}
                                                onChange={handleChange}
                                                onKeyDown={(e) => e.preventDefault()}
                                                isInvalid={data.date === "" && showEmptyError}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Date
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formBasicJournalNo">
                                            <Form.Label>
                                                Journal No.<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Journal No."
                                                name="journalno"
                                                value={data.journalno}
                                                onChange={handleChange}
                                                required
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="formBasicName">
                                            <Form.Label>Reference No.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Reference No."
                                                name="refno"
                                                value={data.refno}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row md={6}>
                                    <Col md={6}>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicDescription"
                                        >
                                            <Form.Label>Note</Form.Label>
                                            <textarea
                                                className="form-control"
                                                rows="4"
                                                placeholder="Type here..."
                                                name="note"
                                                value={data.note}
                                                onChange={handleChange}
                                                required
                                            ></textarea>
                                            <Form.Control.Feedback type="invalid">
                                                Enter a description
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                            <hr />
                            <Table responsive className="text-center">
                                <thead className="tableContainer">
                                    <tr>
                                        <th>Account</th>
                                        <th>Ledger</th>
                                        <th>Description</th>
                                        <th>Debits</th>
                                        <th>Credits</th>
                                    </tr>
                                </thead>
                                {data.journalarray.length > 0 ? (
                                    <tbody>
                                        {data.journalarray.map((row, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        name="account"
                                                        onChange={(e) => handleTableRowChange(e, index)}
                                                        value={row.account}
                                                        isInvalid={row.account === "" && showEmptyError}
                                                    >
                                                        <option value="">--Select--</option>
                                                        {coaAllData && coaAllData.length > 0 ? (
                                                            Object.entries(
                                                                coaAllData.reduce((groups, item) => {
                                                                    if (!groups[item.accounttype]) {
                                                                        groups[item.accounttype] = [];
                                                                    }
                                                                    groups[item.accounttype].push(item.accountname);
                                                                    return groups;
                                                                }, {})
                                                            ).map(([accounttype, accountname], index) => (
                                                                <optgroup key={index} label={accounttype}>
                                                                    {accountname.map((name, nameIndex) => (
                                                                        <option key={nameIndex} value={name}>
                                                                            {name}
                                                                        </option>
                                                                    ))}
                                                                </optgroup>
                                                            ))
                                                        ) : (
                                                            <option value="">No Account available</option>
                                                        )}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Choose Account
                                                    </Form.Control.Feedback>
                                                </td>

                                                <td>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        name="ledger"
                                                        className="text-center"
                                                        onChange={(e) => handleTableRowChange(e, index)}
                                                        value={row.ledger}
                                                        isInvalid={row.ledger === "" && showEmptyError}
                                                    >
                                                        <option value="">--Select--</option>
                                                        {ledgerAllData && ledgerAllData.length > 0 ? (
                                                            ledgerAllData.map((item, index) => {
                                                                const { ledgername } = item;
                                                                return (
                                                                    <option key={index} value={ledgername}>
                                                                        {ledgername}
                                                                    </option>
                                                                );
                                                            })
                                                        ) : (
                                                            <option value="">No Ledger available</option>
                                                        )}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Choose ledger
                                                    </Form.Control.Feedback>
                                                </td>

                                                <td>
                                                    <Form.Control
                                                        className="text-center"
                                                        name="description"
                                                        placeholder="Description"
                                                        onChange={(e) => handleTableRowChange(e, index)}
                                                        value={row.description}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Description
                                                    </Form.Control.Feedback>
                                                </td>

                                                <td>
                                                    <Form.Control
                                                        disabled={row.credits}
                                                        style={{ textAlign: "right" }}
                                                        type="number"
                                                        name="debits"
                                                        placeholder="0.00"
                                                        onChange={(e) => handleTableRowChange(e, index)}
                                                        value={row.debits}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Debit
                                                    </Form.Control.Feedback>
                                                </td>

                                                <td>
                                                    <div className="d-flex gap-3">
                                                        <Form.Control
                                                            disabled={row.debits}
                                                            style={{ textAlign: "right" }}
                                                            type="number"
                                                            placeholder="0.00"
                                                            name="credits"
                                                            onChange={(e) => handleTableRowChange(e, index)}
                                                            value={row.credits}
                                                        />
                                                        {showbtn && (
                                                            <MdDelete
                                                                className="deleteIcon table-delete"
                                                                onClick={() => handleTableRowDelete(index)}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <NoRecordFoud count="5" name="data" />
                                    </tbody>
                                )}
                            </Table>
                            <Row>
                                <Col>
                                    <span className="form-title-sibling" onClick={handleAddNewRow}>
                                        <BsPlusCircle />  Add Row
                                    </span>
                                </Col>
                            </Row>
                            <Container fluid>
                                <Row>
                                    <Col></Col>
                                    <Col
                                        style={{
                                            background: "var(--text, #F5F5F5)",
                                            borderRadius: "16px",
                                            padding: "30px",
                                            fontSize: "20px",
                                        }}
                                    >
                                        <Row>
                                            <Col>
                                                {" "}
                                                <Form.Label>Total</Form.Label>
                                            </Col>
                                            <Col>
                                                <p>{data.totaldebits}</p>
                                            </Col>
                                            <Col>
                                                <p>{data.totalcredits}</p>
                                            </Col>
                                        </Row>
                                        <Row className="star">
                                            <Col>
                                                <Form.Label>Difference</Form.Label>
                                            </Col>
                                            <Col>
                                                <p>
                                                    {data.totaldebits < data.totalcredits
                                                        ? data.totalcredits - data.totaldebits
                                                        : "0.00"}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p>
                                                    {data.totaldebits > data.totalcredits
                                                        ? data.totaldebits - data.totalcredits
                                                        : "0.00"}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                            <Row md={12}>
                                <Col md={2} className="w-25">
                                    <Form.Label>Attach Document</Form.Label>

                                    <FormControl
                                        type="file"
                                        name="rawdocuments"
                                        onChange={(e) => {
                                            const file = e.target.files; // Assuming you only want to upload one file

                                            if (file.length > 5) {
                                                notifyWarning(
                                                    "Please select a maximum of 5 documents."
                                                );
                                                e.target.value = null;
                                                return;
                                            }
                                            setData((prevData) => ({
                                                ...prevData,
                                                documents: file,
                                            }));
                                        }}
                                        multiple
                                    />

                                </Col>
                                <Col md={10}>
                                    {" "}
                                    {data.documents.length > 0
                                        ? data.documents.map((item, index) => (
                                            <div key={index} className="d-flex">
                                                <a href={item} target="_blank">
                                                    {item}
                                                </a>
                                            </div>
                                        ))
                                        : ""}
                                </Col>
                            </Row>
                            <hr />
                            <Row md={6}>
                                <Col>
                                    <Button
                                        className="commonBtn"
                                        onClick={handlePublish}
                                    >
                                        Update as Publish
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className="commonBtn cancelBtn mx-3"
                                        onClick={handleDraft}
                                    >
                                        Update as Draft
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        className="commonBtn smallBtn cancelBtn mx-3"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Modal show={show} size="md" backdrop="static">
                                <Modal.Body>
                                    <div style={{ margin: "20px" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "20px",
                                                fontSize: "26px",
                                            }}
                                        >
                                            <span style={{ color: "#FB6514" }}>
                                                <BsFillExclamationTriangleFill />
                                            </span>
                                            <span className="mt-2"> Leave this page ?</span>
                                        </div>
                                        <div
                                            className="mt-4"
                                            style={{ textAlign: "center", fontSize: "18px" }}
                                        >
                                            If you leave, your unsaved changes will be discarded.
                                        </div>
                                        <div className="d-flex justify-content-center gap-3 mt-4">
                                            <Button
                                                className="commonBtn smallBtn"
                                                onClick={handleStay}
                                            >
                                                Stay Here
                                            </Button>
                                            <Button
                                                className="commonBtn cancelBtn w-75"
                                                onClick={handleDiscard}
                                            >
                                                Leave and Discard Changes
                                            </Button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default EditJournal;
