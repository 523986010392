import React from "react";

const NoRecordFoud = ({ count, name }) => {
  return (
    <tr>
      <td colSpan={count}>
        <div className="datanotfound-center-text">No {name} Found!! 🤔</div>
      </td>
    </tr>
  );
};

export default NoRecordFoud;
