import axios from "axios";
import { addPurchasebillsApi, getPurchasebillApi, updatePurchaseBillsApi } from "../../constants/api";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { ADD_PURCHASEBILLS_DATA, FETCH_PURCHABILLS_DATA,UPDATE_PURCHASEBILLS_DATA } from "../actionTypes/purchaseBills.actiontypes";

 //Get all warehouse data using pagination wise
 export const purchaseBillsData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getPurchasebillApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];;
      if (initialData) {
        dispatch({ type: FETCH_PURCHABILLS_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching purchase bills data ${error}`);
      notifyError(error.message);
    }
  };


  export const addPurchaseBills= (purchasebillsdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addPurchasebillsApi, purchasebillsdata);
  
      if (res.data.message === "Purchase bill added successfully") {
        dispatch({ type: ADD_PURCHASEBILLS_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/purchaseBills");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding purchase bills data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updatePurchaseBills = (purchasebillsdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updatePurchaseBillsApi, purchasebillsdata);
  
      if (res.data.message === "Purchase bill updated successfully") {
        dispatch({ type: UPDATE_PURCHASEBILLS_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/purchaseBills");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating purchase bills data ${error}`);
      notifyError(error.response.data.message);
    }
  };