export const initialData = (res) =>
  res.data && res.data.data && res.data.data.length > 0 ? res.data.data : [];

 export function aggregateAmounts(itemArray) {
    // Use reduce to aggregate amounts for each account
    const aggregatedArray = itemArray.reduce((acc, item) => {
      // Find if account already exists in the accumulator array
      const existingAccount = acc.find((entry) => entry.account === item.account);
  
      if (existingAccount) {
        // If account exists, update its amount
        existingAccount.amount += item.discountamount;
      } else {
        // If account doesn't exist, add it to the accumulator array
        acc.push({ account: item.account, amount: item.discountamount });
      }
  
      return acc;
    }, []);
  
    return aggregatedArray;
  }
