import React, { useState } from "react";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { indianFormatDate } from "../../../constants/indianFormatDate";

const ViewEmployee = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const {
    employeeid,
    fullname,
    phoneno,
    aadharno,
    emailid,
    dob,
    age,
    gender,
    photo,
    idproof,
    aadhar,
    department,
    designation,
    joiningdate,
    issale,
    currentcountry,
    currentstate,
    currentcity,
    currentaddress,
    currentpincode,
    permanentcountry,
    permanentstate,
    permanentcity,
    permanentaddress,
    permanentpincode,
    bankname,
    bankbranch,
    bankaccno,
    bankifsccode,
    remarks,
    company,
    companyid,
    branch,
    branchid,
    userid,
    org,
    status,
  } = item || {};
  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">Employee Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Personal Details</h3>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Image
                  style={{ width: "30%" }}
                  src={photo}
                  alt={fullname}
                  rounded
                />
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">Employee ID:</Col>
                  <Col className="viewpage-value">
                    {employeeid ? employeeid : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Name:</Col>
                  <Col className="viewpage-value">
                    {fullname ? fullname : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Phone No:</Col>
                  <Col className="viewpage-value">
                    {phoneno ? phoneno : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Aadhar No:</Col>
                  <Col className="viewpage-value">
                    {aadharno ? aadharno : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">DOB:</Col>
                  <Col className="viewpage-value">
                    {dob ? indianFormatDate(dob) : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Age:</Col>
                  <Col className="viewpage-value">{age ? age : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Gender:</Col>
                  <Col className="viewpage-value">{gender ? gender : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Email ID:</Col>
                  <Col className="viewpage-value">
                    {emailid ? emailid : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Official Details</h3>
              </Col>
              <Col>
                <h3 className="mt-3 mb-3">Bank Information</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="viewpage-title">Branch:</Col>
                  <Col className="viewpage-value">{branch ? branch : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Department:</Col>
                  <Col className="viewpage-value">
                    {department ? department : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Designation:</Col>
                  <Col className="viewpage-value">
                    {designation ? designation : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Joining Date:</Col>
                  <Col className="viewpage-value">
                    {joiningdate ? indianFormatDate(joiningdate) : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Is a Sales Person?</Col>
                  <Col className="viewpage-value">{issale ? issale : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">Bank Name:</Col>
                  <Col className="viewpage-value">
                    {bankname ? bankname : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Branch:</Col>
                  <Col className="viewpage-value">
                    {bankbranch ? bankbranch : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Account No:</Col>
                  <Col className="viewpage-value">
                    {bankaccno ? bankaccno : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">IFSC Code:</Col>
                  <Col className="viewpage-value">
                    {bankifsccode ? bankifsccode : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Remarks:</Col>
                  <Col className="viewpage-value">
                    {remarks ? remarks : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Current Address</h3>
              </Col>
              <Col>
                <h3 className="mt-3 mb-3">Permanent Address</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="viewpage-title">Country:</Col>
                  <Col className="viewpage-value">
                    {currentcountry ? currentcountry : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">State:</Col>
                  <Col className="viewpage-value">
                    {currentstate ? currentstate : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">City:</Col>
                  <Col className="viewpage-value">
                    {currentcity ? currentcity : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Address:</Col>
                  <Col className="viewpage-value">
                    {currentaddress ? currentaddress : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Pincode:</Col>
                  <Col className="viewpage-value">
                    {currentpincode ? currentpincode : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">Country:</Col>
                  <Col className="viewpage-value">
                    {permanentcountry ? permanentcountry : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">State:</Col>
                  <Col className="viewpage-value">
                    {permanentstate ? permanentstate : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">City:</Col>
                  <Col className="viewpage-value">
                    {permanentcity ? permanentcity : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Address:</Col>
                  <Col className="viewpage-value">
                    {permanentaddress ? permanentaddress : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Pincode:</Col>
                  <Col className="viewpage-value">
                    {permanentpincode ? permanentpincode : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Col className="viewpage-title text-decoration-underline">
                  Aadhar
                </Col>
                <Image
                  style={{ width: "30%" }}
                  src={aadhar}
                  alt={fullname}
                  rounded
                />
              </Col>
              <Col>
                <Col className="viewpage-title text-decoration-underline">
                  ID
                </Col>
                <Image
                  style={{ width: "30%" }}
                  src={idproof}
                  alt={fullname}
                  rounded
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewEmployee;
