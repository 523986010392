
import { FETCH_SALESTARGET_DASHBOARD_DATA } from "../actionTypes/salesTarget.actiontypes";


const initialState = {

    salesTargetDashboardData: {},

  };
  
  //
  export const  salesTargetDashboardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_SALESTARGET_DASHBOARD_DATA: {
        return { ...state, salesTargetDashboardData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };