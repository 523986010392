import React, { useEffect, useRef, useState } from "react";
// import "../../styles/navbar.css";
// import search_slider from "../../assets/images/search_slider.png";
import { AiFillQuestionCircle } from "react-icons/ai";
import { IoIosNotifications } from "react-icons/io";
import {
  MdArrowForwardIos,
  MdBrightness7,
  MdOutlineFactory,
} from "react-icons/md";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Button,
  FormControl,
  ButtonGroup,
  NavDropdown,
  Nav,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reloadPage, setSearchKeyword } from "../../redux/actions/load.action";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { FaAngleDown, FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { notifySuccess } from "../../constants/toastAlerts";
// import LogoutModalForm from "./LogoutModalForm";
// import HelpModalForm from "./Help/HelpModalForm";
// import Notifications from "./Navbar/Notifications";
import profileImage from "../../assets/profile_image.jpg";
import ViewProfile from "./ViewProfile/ViewProfile";
import { userLogout } from "../../redux/actions/auth.actions";
import HelpModalForm from "./Help/HelpModalForm";
// import HelpModalForm from "./Help/HelpModalForm";

const Header = ({ isOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, designationname, name, lastlogindate, lastlogintime } = useSelector((state) => state.authManager.userData && state.authManager.userData.data && state.authManager.userData.data[0]) || {};


  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [allSite, setAllSite] = useState([]);
  const financialyear = sessionStorage.getItem("finalcialYear");
  const [show, setShow] = useState(false);
  const [helpModalShow, setHelpModalShow] = useState(false); // state for help modal
  const [activeIndex, setActiveIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleShowNotification = () => setShowModal(true);
  const handleCloseNotification = () => {
    setShowModal(false);
  };

  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  const avatarChildRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (avatarChildRef.current && !avatarChildRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   dispatch(reloadPage(true));
  // }, []);

  const handleViewProfile = (event) => {
    event.stopPropagation();
    setShow(true);
  };

  const handleViewProfileClose = () => {
    setShow(false);
  };

  const handleProfileImgContainer = () => {
    setOpen(!open);
  }

  const handleLogout = () => {
    dispatch(userLogout(userid, navigate))
  }

  const handleHelpModalShow = () => {
    setHelpModalShow(true);
  };

  const handleHelpModalClose = () => {
    setHelpModalShow(false);
  };

  return (
    <>
      <Navbar className="bg-body-tertiary ">
        <Container className={`header-section ${isOpen ? "main-container" : ""}`}
        >
          <div className="header-avatar ">
            <div className="icon-container">
              <div className="help" onClick={handleHelpModalShow}>
                <OverlayTrigger
                  placement="left"
                  overlay={renderTooltip("Support")}
                >
                  <span className="question_icon" >
                    <AiFillQuestionCircle />
                    <p>Help</p>
                  </span>
                </OverlayTrigger>
              </div>
              <HelpModalForm
                helpModalShow={helpModalShow}
                handleHelpModalShow={handleHelpModalShow}
                handleHelpModalClose={handleHelpModalClose}
              />

              <div className="notification-container">
                <OverlayTrigger placement="left" overlay={renderTooltip("Notifications")}>
                  <span className="notification-icon">
                    <IoIosNotifications />
                  </span>
                </OverlayTrigger>
              </div>
              {/* <Notifications
                notifications={notifications}
                showModal={showModal}
                handleShowNotification={handleShowNotification}
                handleCloseNotification={handleCloseNotification}
                handleNotificationClose={(notificationId) => {
                  // Handle removing the notification with the given ID
                  const updatedNotifications = notifications.filter(
                    (notification) => notification.id !== notificationId
                  );
                  setNotifications(updatedNotifications);
                }}
              />  */}
            </div>

            <div className="avatar-container" >
              <div className="" onClick={handleProfileImgContainer}>
                <Image src={profileImage} alt="Profile Image not available" width="45" height="45" roundedCircle />
              </div>
              {
                open &&
                <div className="avatar-child-container" ref={avatarChildRef}>
                  <span>
                    <Image src={profileImage} alt="Profile Image not available" width="80" height="80" roundedCircle />
                  </span>
                  <span>
                    <p className="user-role">{designationname}</p>
                    <p className="user-role">{companyname}</p>
                    <p className="user-name">{name}</p>
                    <p className="user-prevLogin-time">{`Last Login at: ${lastlogindate} ${lastlogintime} `}</p>
                  </span>
                  <span className="button-container">
                    <Button className="commonBtn smallBtn" onClick={handleViewProfile}>View Profile</Button>
                    <Button className="commonBtn smallBtn cancelBtn" onClick={handleLogout}>Logout</Button>
                  </span>

                </div>
              }
              {show ? <span>
                <ViewProfile show={show} handleViewProfileClose={handleViewProfileClose} />
              </span> : null}
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
