import { FETCH_QUOTATION_DATA } from "../actionTypes/quotation.actiontypes";


const initialState = {

    quotationPagiData: {},

  };
  
  //
  export const  quotationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_QUOTATION_DATA: {
        return { ...state, quotationPagiData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };