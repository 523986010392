import axios from "axios";
import { addSalesTargetApi, deleteSalesTargetApi, getSalesTargetApi, updateSalesTargetApi } from "../../constants/api";
import { ADD_SALESTARGET_DATA, FETCH_SALESTARGET_DATA, UPDATE_SALESTARGET_DATA } from "../actionTypes/salesTarget.actiontypes";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";

  
  //Get all warehouse data using pagination wise
  export const salesTargetData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getSalesTargetApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_SALESTARGET_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in fetching sales target data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete stocklocation data dispatcher
  export const deleteSalesTargetData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteSalesTargetApi, backendData);
        if (res.data.message == "Sale target deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting sales target data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new vendor data
  export const addSalesTarget = (salestargetdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addSalesTargetApi, salestargetdata);
  
      if (res.data.message === "Sale target added successfully") {
        dispatch({ type: ADD_SALESTARGET_DATA });
        notifySuccess(res.data.message);
        navigate("/sales/salesTarget");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in adding sales target data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateSalesTarget = (salestargetdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updateSalesTargetApi, salestargetdata);
  
      if (res.data.message === "Sale target updated successfully") {
        dispatch({ type: UPDATE_SALESTARGET_DATA });
        notifySuccess(res.data.message);
        navigate("/sales/salesTarget");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating sales target data ${error}`);
      notifyError(error.response.data.message);
    }
  };