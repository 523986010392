import {
  BankCountPost,
  BankDeletePost,
  BankPagiDataPost,
  bankAddPost,
  bankUpdatePost,
} from "../../constants/api";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_BANK_COUNT,
  FETCH_BANK_DATA,
} from "../actionTypes/bank.actiontypes";

//Get all bank data using pagination wise
export const bankPagiData = (backendData, setLoading) => async (dispatch) => {
  try {
    const res = await axios.post(BankPagiDataPost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
    if (initialData) {
      dispatch({ type: FETCH_BANK_DATA, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching Bank data ${error}`);
    notifyError(error.message);
    setLoading(false);
  }
};

//Get bank auto increment count
export const bankAutoCount = (backendData, setLoading) => async (dispatch) => {
  try {
    const res = await axios.post(BankCountPost, backendData);
    const initialCount = Number(res.data.count) + 1 || 0;
    if (initialCount != 0) {
      dispatch({ type: FETCH_BANK_COUNT, payload: initialCount });
    } else {
      notifyError("Count not comming from backend");
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching bank auto count data ${error}`);
    notifyError(error.message);
  }
};

//Acion dispatcher for adding new bank data
export const addBank = (backendData, handleCancel) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(bankAddPost, backendData);
    if (res.data.message === 'Bank added successfully') {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    handleCancel();
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new Bank data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating bank data
export const updateBank =(backendData, handleCancel) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(bankUpdatePost, backendData);
      if (res.data.message === "Bank updated successfully") {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleCancel();
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating Bank data ${error}`);
      notifyError(error.response.data.message);
    }
  };

//Delete Bank data
export const deleteBank = (backendData, handleClose) => async (dispatch) => {
  try {
    const res = await axios.post(BankDeletePost, backendData);
    if (res.data.message == 'Bank deleted successfully') {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    handleClose();
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in deleting Bank data ${error}`);
    notifyError(error.message);
  }
};
