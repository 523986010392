import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ListTablePriceUpdate from './ListTablePriceUpdate'
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';

const PriceUpdate = () => {
    const URL = extractAndFormatURLPart("admin");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTablePriceUpdate />
        </Col>
      </Row>
    </Container>
  )
}

export default PriceUpdate