import {
  ledgerAddPost,
  ledgerCountPost,
  ledgerDeletePost,
  ledgerPagiDataPost,
  ledgerUpdatePost,
} from "../../constants/api";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_LEDGER_COUNT,
  FETCH_LEDGER_DATA,
} from "../actionTypes/ledger.actiontypes";

//Get all ledger data using pagination wise
export const ledgerPagiData = (backendData, setLoading) => async (dispatch) => {
  try {
    const res = await axios.post(ledgerPagiDataPost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
    if (initialData) {
      dispatch({ type: FETCH_LEDGER_DATA, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching ledger data ${error}`);
    notifyError(error.message);
  }
};

//Get ledger auto increment count
export const ledgerAutoCount =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(ledgerCountPost, backendData);
      const initialCount = Number(res.data.legerCount) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: FETCH_LEDGER_COUNT, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching ledger auto count data ${error}`);
      notifyError(error.message);
    }
  };

//Acion dispatcher for adding new ledger data
export const addLedger = (backendData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(ledgerAddPost, backendData);
    if (res.data.message === "Ledger added successfully") {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }

    handleClose();
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new ledger data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating Ledger data
export const updateLedger = (backendData, handleCloseEditModal, setData) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(ledgerUpdatePost, backendData);
      if (res.data.message === 'Ledger updated successfully') {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      setData({
        id: "",
        ledgerid:"",
        ledgername: "",
        ledgertype: "",
        istds: "",
        isinventory: "",
        accountid: "",
        accountname: "",
        accounttype: "",
        openingbalance:"",
        debitcredit:"",
        asondate:"",
        userid: "",
        branchid:  "",
        companyid: "",
      });
      handleCloseEditModal();
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating ledger data ${error}`);
      notifyError(error.response.data.message);
    }
  };

//Delete Ledger data
export const deleteLedger = (backendData, handleClose) => async (dispatch) => {
  try {
    const res = await axios.post(ledgerDeletePost, backendData);
    if (res.data.message == "Ledger deleted successfully") {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    handleClose();
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in deleting ledger data ${error}`);
    notifyError(error.message);
  }
};
