import { FETCH_CUSTOMER_DATA } from "../actionTypes/customer.actionTypes";

const initialState = {
  customerPagiData: {},
};

export const customerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMER_DATA: {
      return { ...state, customerPagiData: { ...payload } };
    }

    default: {
      return state;
    }
  }
};
