import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { companyData } from "../../redux/actions/company.actions";
import { extractAndFormatURLPart } from "../../constants/breadCrumb";
import { useNavigate } from "react-router-dom";
import "./dashboard.css"
import { FaUsers } from "react-icons/fa6";
import { FcSalesPerformance } from "react-icons/fc";
import { RiBox3Fill } from "react-icons/ri";
import { FaFileInvoice } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import Cards from "./Card";
import axios from "axios";
import { auditLogPost, customerGetPost, itemGetItemPost, salesInvoicePagiDataPost, salesOrderPagiDataPost, userUserpaginationPost } from "../../constants/api";
import { reloadPage } from "../../redux/actions/load.action";
import { result } from "lodash";
import { BarChart, LineChart, PieChart, Pie, Cell, Legend, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  const navigate = useNavigate();
  const URL = extractAndFormatURLPart("dashboard");
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const COLORS = ['#0088FE', '#FF8042'];

  //state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    users: 0,
    orders: 0,
    invoices: 0,
    audits: 0,
    orderdata: [],
    invoicedata: [],
    invoicealldata: [],
    items: [],
    customerdata: [],
    customeralldata: [],
    itemsalldata: [],

  });
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [currentOrderInvoice, setCurrentOrderInvoice] = useState('invoice');
  const selectedMonthIndex = months.indexOf(selectedMonth) + 1;

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid } =
    useSelector((state) => state.authManager.userData.data[0]) || {};


  //default data backend needed
  const companybackendData = {
    companyid,
    userid,
    companyname,
    keyword: "₹ΘθϖΠ",
    branchid,
  };

  useEffect(() => {
    setLoading(!loading);
    fetchData();
    // dispatch(companyData(companybackendData, setLoading));
  }, [dispatch, loadData, selectedMonth]);

  useEffect(() => {
    if (data.customeralldata && data.itemsalldata && data.invoicealldata) {
      const itemPurchaseData = aggregateItemPurchases(data.invoicealldata);
      
      setData({...data, itempurchase:itemPurchaseData})
    }
  }, [data.customeralldata])

  const fetchData = async () => {
    try {
      const apiCalls = [
        axios.post(userUserpaginationPost, companybackendData),
        axios.post(salesOrderPagiDataPost, companybackendData),
        axios.post(salesInvoicePagiDataPost, companybackendData),
        axios.post(auditLogPost, companybackendData),
        axios.post(itemGetItemPost, companybackendData),
        axios.post(customerGetPost, companybackendData),
      ];

      const [userres, salesorderres, salesinvoiceres, auditlogres, itemres, customerres] = await Promise.allSettled(apiCalls);

      // Process each result individually
      if (userres.status === "fulfilled") {
        const userPaginationData = userres.value.data && userres.value.data.data && userres.value.data.data.length || 0;
        setData((prev) => ({ ...prev, users: userPaginationData }));
      } else {
        console.error("Total Users API failed:", userres.reason);
      }

      if (salesorderres.status === "fulfilled") {
        const salesOrderData = salesorderres.value.data && salesorderres.value.data.data;
        const filteredData = salesOrderData.filter((item) => {
          const invoiceMonth = new Date(item.saleorderdate).getMonth() + 1;
          return invoiceMonth === selectedMonthIndex;
        });

        // Group sales totals by date
        const salesByDate = filteredData.reduce((acc, order) => {
          const date = order.saleorderdate;
          const total = parseFloat(order.finalamount) || 0;

          // Aggregate totals by date
          if (acc[date]) {
            acc[date] += total;
          } else {
            acc[date] = total;
          }

          return acc;
        }, {});

        const chartData = Object.keys(salesByDate).map((date) => ({
          date,
          total: salesByDate[date],
        }));
        setData((prev) => ({ ...prev, orders: salesOrderData.length || 0, orderdata: chartData }));
      } else {
        console.error("Total Sales Order API failed:", salesorderres.reason);
      }

      if (salesinvoiceres.status === "fulfilled") {
        const salesInvoiceData = salesinvoiceres.value.data && salesinvoiceres.value.data.data;
        const filteredData = salesInvoiceData.filter((item) => {
          const invoiceMonth = new Date(item.invoicedate).getMonth() + 1;
          return invoiceMonth === selectedMonthIndex;
        });

        // Group sales totals by date
        const salesByDate = filteredData.reduce((acc, invoice) => {
          const date = invoice.invoicedate;
          const total = parseFloat(invoice.grandtotal) || 0;

          // Aggregate totals by date
          if (acc[date]) {
            acc[date] += total;
          } else {
            acc[date] = total;
          }

          return acc;
        }, {});

        const chartData = Object.keys(salesByDate).map((date) => ({
          date,
          total: salesByDate[date],
        }));

        setData((prev) => ({ ...prev, invoices: salesInvoiceData.length || 0, invoicedata: chartData, invoicealldata: salesInvoiceData }));
      } else {
        console.error("Total Sales Invoice API failed:", salesinvoiceres.reason);
      }

      if (auditlogres.status === "fulfilled") {
        const auditlogData = auditlogres.value && auditlogres.value.data && auditlogres.value.data.length || 0;
        setData((prev) => ({ ...prev, audits: auditlogData }));
      } else {
        console.error("Total Audit log API failed:", auditlogres.reason);
      }

      if (itemres.status === "fulfilled") {
        const itemData = itemres.value && itemres.value.data && itemres.value.data.data;
        // console.log("itemData", itemData);
        const chartData = itemData.map((item) => ({
          itemname: item.itemname,
          category: item.category,
          brand: item.brand,
          series: item.series,
          stock: item.inhandquantity || 0,

        }));
        // console.log("chartData", chartData);
        setData((prev) => ({ ...prev, items: chartData, itemsalldata: itemData }));
      } else {
        console.error("Total Item API failed:", itemres.reason);
      }

      if (customerres.status === "fulfilled") {
        const customerData = customerres.value && customerres.value.data && customerres.value.data.data;
        // console.log("customerData", customerData);
        // console.log("salesInvoiceData", data.invoicealldata)
        const institutionalCustomers = customerData.filter((item) => item.customertype == "Institutional");
        const retailCustomers = customerData.filter((item) => item.customertype === "Retail");

        const pieChartData = [
          { name: "Institutional", value: institutionalCustomers.length || 0 },
          { name: "Retail", value: retailCustomers.length || 0 },
        ];

        setData((prev) => ({ ...prev, customerdata: pieChartData, customeralldata: customerData }));
      } else {
        console.error("Total Customer API failed:", customerres.reason);
      }

    } catch (error) {
      console.log("error", error);
    }
  };

  const aggregateItemPurchases = (invoiceData) => {
    const itemPurchaseCount = invoiceData?.reduce((acc, invoice) => {
      invoice.itemarray.forEach((item) => {
        const { itemname, invoiceqty } = item;
        if (acc[itemname]) {
          acc[itemname] += parseFloat(invoiceqty) || 0;
        } else {
          acc[itemname] = parseFloat(invoiceqty) || 0;
        }
      });
      return acc;
    }, {});
 
    // Convert the aggregated data into an array of objects
    return Object.keys(itemPurchaseCount).map((itemName) => ({
      itemname: itemName,
      quantity: itemPurchaseCount[itemName],
    }));
  };
  
  // console.log("data.customerdata", data.customerdata)
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col lg="3" md="6" sm="12">
          <Cards
            location="/admin/user"
            title="Total Users"
            count={data.users}
            icon={<FaUsers />}
            iconname="user"
          />
        </Col>
        <Col lg="3" md="6" sm="12">
          <Cards
            location="/sales/salesOrder"
            title="Total Orders"
            count={data.orders}
            icon={<RiBox3Fill />}
            iconname="order"
          />
        </Col>
        <Col lg="3" md="6" sm="12">
          <Cards
            location="/sales/salesInvoice"
            title="Total Invoices"
            count={data.invoices}
            icon={<FaFileInvoice />}
            iconname="invoice"
          />
        </Col>
        <Col lg="3" md="6" sm="12">
          <Cards
            location="/auditLog"
            title="Total Operational logs"
            count={data.audits}
            icon={<FaHistory />}
            iconname="audit"
          />
        </Col>

        <Col lg="12" md="12" sm="12" className="mt-4">
          <Card className="card-stats">
            <Card.Body>
              <Row >
                <Col className="d-flex justify-content-between">
                  <p className="card-Title">Sales Order/Invoice</p>
                  <Form className="d-flex gap-5 align-items-center">
                    <Form.Group controlId="orderInvoice">
                      <Form.Check
                        type="radio"
                        label="Sales Order"
                        value="order"
                        checked={currentOrderInvoice === 'order'}
                        onChange={() => setCurrentOrderInvoice('order')}
                        name="currentOrderInvoice"
                        inline
                      />
                      <Form.Check
                        type="radio"
                        label="Sales Invoice"
                        value="invoice"
                        checked={currentOrderInvoice === 'invoice'}
                        onChange={() => setCurrentOrderInvoice('invoice')}
                        name="currentOrderInvoice"
                        inline
                      />
                    </Form.Group>

                    <Form.Group controlId="monthSelect">
                      <Form.Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                        {months.map((month, index) => (
                          <option key={index} value={month}>{month}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <ResponsiveContainer width="100%" height={450}>
                  <LineChart data={currentOrderInvoice === "invoice" ? data.invoicedata : data.orderdata} margin={{ top: 10, right: 50, left: 10, bottom: 5 }} onClick={() => navigate(`${currentOrderInvoice == "invoice" ? "/sales/salesInvoice" : "/sales/salesOrder" }`)}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" fontSize={13} />
                    <YAxis fontSize={13} />
                    <Tooltip />
                    <Line type="monotone" dataKey="total" stroke="#8884d8" dot={{ r: 3 }} activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="12" md="12" sm="12" className="mt-4">
          <Card className="card-stats">
            <Card.Body>
              <Row >
                <Col className="d-flex justify-content-between">
                  <p className="card-Title">Item Stock Details</p>
                </Col>
              </Row>
              <Row>
                <ResponsiveContainer width="100%" height={450}>
                  <BarChart data={data.items} margin={{ top: 10, right: 50, left: 10, bottom: 5 }} onClick={() => navigate("/inventory/item")}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="itemname" fontSize={13} />
                    <YAxis fontSize={13} />
                    <Tooltip contentStyle={{ fontSize: '16px' }} />
                    <Bar dataKey="stock" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="3" md="6" sm="12" className="mt-4">
          <Card className="card-stats">
            <Card.Body>
              <Row >
                <Col className="d-flex justify-content-between">
                  <p className="card-Title">Customers</p>
                </Col>
              </Row>
              <Row>
                <ResponsiveContainer width="100%" height={350} >
                  <PieChart>
                    <Pie
                      data={data.customerdata}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={140}
                      fill="#8884d8"
                      onClick={() => navigate("/sales/customer")}
                    // label
                    >
                      {data.customerdata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="top" height={16} />
                  </PieChart>
                </ResponsiveContainer>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg="9" md="6" sm="12" className="mt-4">
          <Card className="card-stats">
            <Card.Body>
              <Row >
                <Col className="d-flex justify-content-between">
                  <p className="card-Title">Most Purchase Item</p>
                </Col>
              </Row>
              <Row>
                <Row>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                      data={data.itempurchase}
                      margin={{ top: 10, right: 50, left: 10, bottom: 5 }}
                      onClick={() => navigate("/sales/salesInvoice")}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="itemname" fontSize={13} />
                      <YAxis fontSize={13} />
                      <Tooltip contentStyle={{ fontSize: '16px' }} />
                      <Legend />
                      <Bar dataKey="quantity" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Container>
  );
};

export default Dashboard;
