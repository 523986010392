import { FETCH_AADHAR_IMAGE, FETCH_EMPLOYEE_DATA, FETCH_EMPLOYEE_IMAGE, FETCH_ID_IMAGE } from "../actionTypes/employee.actiontypes";

  const initialState = {

    employeeData: {},
    empImage:"",
    empID:"",
    empAadhar:"",

  };
  
  //
  export const  employeeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_EMPLOYEE_DATA: {
        return { ...state, employeeData: { ...payload } };
      }
      case FETCH_EMPLOYEE_IMAGE: {
        return { ...state, empImage: payload };
      }
      case FETCH_ID_IMAGE: {
        return { ...state, empID: payload };
      }
      case FETCH_AADHAR_IMAGE: {
        return { ...state, empAadhar: payload };
      }
      
      default: {
        return state;
      }
    }
  };