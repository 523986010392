import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ViewBranch = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const {
    address,
    branchid,
    branchname,
    city,
    company,
    companyid,
    country,
    description,
    gstno,
    id,
    pin,
    state,
    status,
    userid,
  } = item || [];
  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">Branch Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <p>
                  <span className="viewpage-title">User ID:</span>{" "}
                  <span className="viewpage-value">{userid}</span>
                </p>
                <p>
                  <span className="viewpage-title">Branch Id:</span>{" "}
                  <span className="viewpage-value">
                    {branchid}
                  </span>
                </p>
                <p>
                  <span className="viewpage-title">Pin:</span>{" "}
                  <span className="viewpage-value">
                    {pin}
                  </span>
                </p>
                <p>
                  <span className="viewpage-title">Branch Name:</span>{" "}
                  <span className="viewpage-value">
                    {branchname ? branchname : "---"}
                  </span>
                </p>
                <p>
                  <span className="viewpage-title">Company:</span>{" "}
                  <span className="viewpage-value">{company}</span>
                </p>
                <p>
                  <span className="viewpage-title">Company id:</span>{" "}
                  <span className="viewpage-value">
                    {companyid ? companyid : "---"}
                  </span>
                </p>
              </Col>
              <Col>
                <p>
                  <span className="viewpage-title">GSTIN:</span>{" "}
                  <span className="viewpage-value">{gstno}</span>
                </p>
                <p>
                  <span className="viewpage-title">Address:</span>{" "}
                  <span className="viewpage-value">
                    {address ? address : "---"}
                  </span>
                </p>
                <p>
                  <span className="viewpage-title">State:</span>{" "}
                  <span className="viewpage-value">
                    {state ? state : "---"}
                  </span>
                </p>
                <p>
                  <span className="viewpage-title">City:</span>{" "}
                  <span className="viewpage-value">
                    {city ? city : "---"}
                  </span>
                </p>
                <p>
                  <span className="viewpage-title">Status:</span>{" "}
                  <span className="viewpage-value">
                    {status ? status : "---"}
                  </span>
                </p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewBranch;
