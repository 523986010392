import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { categoryPagiData } from '../../../redux/actions/category.actions';
import { itemPagiData } from '../../../redux/actions/item.actions';
import { Col, Container, Modal, Row, Button, Form, Spinner, Table, Image } from "react-bootstrap";
import { BsPlusCircle } from 'react-icons/bs';
import DataSpinner from '../../../constants/DataSpinner';
import NoRecordFoud from '../../../constants/NoRecordFoud';
import GlobalPagination from '../../../constants/GlobalPagination';
import SearchField from '../../../constants/SearchField';

function CategoryListModal({ show, handleCategoryCancel, handleCategoryShow, handleAdd, data, setData, isFilter }) {
    //state part
    const [loading, setLoading] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [filterBrand, setFilterBrand] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectAll, setSelectAll] = useState(false);

    //Redux part
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.loadReducer);
    const { companyid, userid, companyname, branchid, branch, org, name } = useSelector((state) => state.authManager.userData.data[0]) || {};
    const { data: categoryAllData } = useSelector((state) => state.categoryData.categoryPagiData) || [];
    const itemData = useSelector((state) => state.itemData.itemPagiData) || [];
    const itemAllData = !itemData.data ? [] : itemData.data

    //pagination data
    const totalPagesFromAPI = {
        totalRecords: itemData.totalItems ? itemData.totalItems : 0,
        totalPages: itemData.totalPages ? itemData.totalPages : 0,
    };

    //default data backend needed
    const backendData = {
        keyword: searchKeyword || "₹ΘθϖΠ",
        userid: userid,
        branchid: branchid,
        companyid: companyid,
        branch: branch,
        companyname: companyname,
        page: currentPage,
        pageSize: pageSize,
        org: org
    }

    useEffect(() => {
        setLoading(!loading);
        dispatch(categoryPagiData(backendData, setLoading));
        dispatch(itemPagiData(backendData, setLoading));
        if (isFilter) {
            setFilterItems([])
            setSelectAll(false);
        }

        if (data.category) {
            const filterBrand = itemAllData && itemAllData.filter(item => item.category === data.category);
            setFilterBrand(filterBrand);
        }

        if (data.brand) {
            const filterItems = itemAllData && itemAllData.filter(item => item.brand === data.brand);

            setFilterItems(filterItems);
        }

    }, [dispatch, loadData, data.category, data.items, isFilter]);

    useEffect(() => {
        setLoading(!loading);
        dispatch(itemPagiData(backendData, setLoading));

    }, [dispatch, pageSize, currentPage, searchKeyword]);

    // onchange part
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "category") {
            const filterBrand = itemAllData && itemAllData.filter(item => item.category === value);

            setFilterBrand(filterBrand);
        }

        if (name === "brand") {
            const filterItems = itemAllData && itemAllData.filter(item => item.brand === value);
            setFilterItems(filterItems);
        }

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));


    };

    // Handle select all checkbox
    const handleSelectAll = (e) => {
        const { checked } = e.target;
        setSelectAll(checked);
        if (checked) {
            setData((prevState) => ({
                ...prevState,
                items: [...itemAllData.map(item => ({ ...item, rate: item.mop, qty: 0, remainingqty: 0, amount: 0 }))]
            }));
        } else {
            setData((prevState) => ({
                ...prevState,
                items: []
            }));
        }
    };
    
    const handleRowSelect = (e, row) => {
        const { checked } = e.target;
        if (checked) {
            // If the item is checked, add it to the items array in state
            setData(prevState => ({
                ...prevState,
                items: [...prevState.items, { ...row, rate: row.mop, qty: 0, remainingqty: 0, amount: 0 }]
            }));
        } else {
            // If the item is unchecked, remove it from the items array in state
            setData(prevState => ({
                ...prevState,
                items: prevState.items.filter(item => item.id !== row.id)
            }));
        }
    };

    return (
        <>
            {
                < span className="form-title-sibling" onClick={handleCategoryShow()}>
                    <BsPlusCircle /> Choose Item
                </span >
            }
            <Modal
                size="xl"
                show={show}
                onHide={handleCategoryCancel}
                backdrop="static"
                keyboard={false}
                centered={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="form-title">Category</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <h2>1. Add Item By Searching on any Column name</h2>
                            <Col>
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        <SearchField
                                            searchKeyword={searchKeyword}
                                            setSearchKeyword={setSearchKeyword}
                                        />
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Col>
                            <Col md={12} className="table-main-container">
                                <Table responsive>
                                    <thead className="tableContainer">
                                        <tr>
                                            <th>Image</th>
                                            <th>Item Name</th>
                                            <th>Category</th>
                                            <th>Brand</th>
                                            <th>Series</th>
                                            <th>Item No</th>
                                            <th>Unit</th>
                                            <th>In Hand Stock</th>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loadData || loading ? (
                                            <DataSpinner count="9" />
                                        ) : itemAllData.length > 0 ? (
                                            itemAllData.map((item, index) => {
                                                const {
                                                    asondate,
                                                    billingtype,
                                                    branchid,
                                                    brand,
                                                    category,
                                                    cessvaluationtype,
                                                    chartofaccount,
                                                    companyid,
                                                    discount,
                                                    gst,
                                                    hsncode,
                                                    hsndescription,
                                                    id,
                                                    image,
                                                    inhandquantity,
                                                    inventoryaccount,
                                                    inventorydescription,
                                                    inventorytype,
                                                    itemdescription,
                                                    itemid,
                                                    itemname,
                                                    mpn,
                                                    openingqty,
                                                    orate,
                                                    org,
                                                    purchaseaccount,
                                                    purchasedescription,
                                                    rake,
                                                    rateperunit,
                                                    reorderpoint,
                                                    saleaccount,
                                                    saledescription,
                                                    series,
                                                    size,
                                                    sku,
                                                    taxability,
                                                    thickness,
                                                    unit,
                                                    updatedprice,
                                                    userid,
                                                    value,
                                                    warehouse,
                                                } = item;
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Image
                                                                src={!image ? [] : image[0]}
                                                                alt='No Image'
                                                                width="120"
                                                                height="45"
                                                            />
                                                        </td>
                                                        <td>{itemname}</td>
                                                        <td>{category}</td>
                                                        <td>{brand}</td>
                                                        <td>{series}</td>
                                                        <td>{itemid}</td>
                                                        <td>{unit}</td>
                                                        <td>{inhandquantity}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={data.items.some(row => row.id === id)}
                                                                onChange={(e) => handleRowSelect(e, item)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <NoRecordFoud count="9" name="Items" />
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <GlobalPagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPagesFromAPI={totalPagesFromAPI}
                                setPageSize={setPageSize}
                            />
                        </Row>
                        <hr />
                        <Row>
                            <h2>2. Add Item Using filter Wise</h2>
                            <Form className="form-lable-name">
                                <Container fluid>
                                    <Row md={12}>

                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>
                                                    Category
                                                </Form.Label>
                                                <Form.Select
                                                    name="category"
                                                    value={data.category}
                                                    onChange={handleChange}
                                                    isInvalid={data.category === "" && showEmptyError}
                                                >
                                                    <option value="">--Select--</option>
                                                    {categoryAllData && (
                                                        categoryAllData.map((item, index) => {
                                                            const { category, categoryid, id } = item;
                                                            return (
                                                                <option key={index} value={category}>
                                                                    {category}
                                                                </option>
                                                            );
                                                        })
                                                    )}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Select Category
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>
                                                    Brand
                                                </Form.Label>
                                                <Form.Select
                                                    name="brand"
                                                    value={data.brand}
                                                    onChange={handleChange}
                                                    isInvalid={data.brand === "" && showEmptyError}
                                                >
                                                    <option value="">--Select--</option>
                                                    {filterBrand && (
                                                        filterBrand.map((item, index) => {
                                                            const { brand } = item;
                                                            return (
                                                                <option key={index} value={brand}>
                                                                    {brand}
                                                                </option>
                                                            );
                                                        })
                                                    )}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Select Brand
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                {/* <Form.Label>
                                            Items
                                        </Form.Label> */}
                                                {/* <Form.Select
                                            name="items"
                                            value={data.items}
                                            onChange={handleChange}
                                            isInvalid={data.items === "" && showEmptyError}
                                        >
                                            <option value="">--Select--</option>
                                            {filterItems && (
                                                filterItems.map((item, index) => {
                                                    const { id, image, itemname } = item;
                                                    return (
                                                        <option key={index} value={itemname}>
                                                            {itemname}
                                                        </option>
                                                    );
                                                })
                                            )}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Select Items
                                        </Form.Control.Feedback> */}
                                                <Col>
                                                    <Form.Label>
                                                        Items<span className="star"> *</span>
                                                    </Form.Label>
                                                    <div className="viewpage-table-container">
                                                        <Table responsive className="mb-0">
                                                            <tbody className="table-main-container">
                                                                {filterItems && filterItems.length > 0 &&
                                                                    filterItems.map((row, index) => {
                                                                        const { id, image, itemname } = row;
                                                                        return (<tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{itemname}</td>
                                                                            <td>
                                                                                <Image
                                                                                    src={image}
                                                                                    alt='No Image'
                                                                                    width="100"
                                                                                    height="45"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={data.items.some(item => item.id === row.id)} // Check if the item is already in the items array
                                                                                    onChange={(e) => handleRowSelect(e, row)}
                                                                                />
                                                                            </td>
                                                                        </tr>);

                                                                    })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start">
                    <Button
                        type="button"
                        className="commonBtn smallBtn"
                        onClick={handleAdd}
                    >
                        {!show ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />)
                            : (
                                "Add"
                            )}
                    </Button>
                    <Button
                        type="button"
                        className="commonBtn smallBtn cancelBtn"
                        onClick={handleCategoryCancel}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CategoryListModal