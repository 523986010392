import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getProjectApi } from "../../../constants/api";
import ViewProject from "../../Projects/ProjectManagement/ViewProject";

const ViewCustomer = ({ item }) => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;

  const navigate = useNavigate();
  const [showCustomer, setShowCustomer] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const {
    customertype,
    customerid,
    fullname,
    phone,
    dob,
    customeremail,
    remarks,
    mobileno,
    bacountry,
    bastate,
    bacity,
    baaddress,
    bapincode,
    sacountry,
    sastate,
    sacity,
    saaddress,
    sapincode,
    banka,
    bankbrancha,
    accountnoa,
    ifsca,
    bankb,
    bankbranchb,
    accountnob,
    ifscb,
    bdowner,
    bdcompany,
    bdworkphone,
    bdmobile,
    bdemail,
    bdgstno,
    bdpan,
    tcs,
    tds,
  } = item || [];

  useEffect(() => {
    axios
      .post(getProjectApi, {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "",
      })
      .then((res) => {
        let initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];

        setData(initialData.filter((ele) => ele.customerid == customerid));
      })
      .catch((err) => console.log(err));
  }, [customerid]);

  const handleViewProject = () => {
    setShowCustomer(true);
  };

  return (
    <>
      <AiFillEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span
              className="form-title colStyle mr-2"
              style={{ marginRight: "10rem" }}
            >
              Detailed View
            </span>
            <Button
              className="editIcon cancelBtn"
              onClick={() =>
                navigate("/sales/projectManagement/addProjectManagement")
              }
            >
              + New Project
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <h3 className="boldItem">
                {customertype == "Retail"
                  ? "Customer Details"
                  : "Contact Person Details"}
              </h3>
              <Col>
                <Row>
                  <Col className="viewpage-title">Customer ID:</Col>{" "}
                  <Col className="viewpage-value">{customerid}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">
                    {customertype == "Retail" ? "Phone:" : "Work Phone:"}
                  </Col>
                  <Col className="viewpage-value">{phone}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Email ID:</Col>{" "}
                  <Col className="viewpage-value">
                    {customeremail ? customeremail : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">Name:</Col>{" "}
                  <Col className="viewpage-value">{fullname}</Col>
                </Row>
                {customertype == "Retail" ? (
                  <Row>
                    <Col className="viewpage-title">DOB:</Col>{" "}
                    <Col className="viewpage-value">
                      {dob ? indianFormatDate(dob) : "--"}
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col className="viewpage-title">Mobile No:</Col>{" "}
                    <Col className="viewpage-value">{mobileno}</Col>
                  </Row>
                )}
                {customertype == "Retail" ? (
                  <Row>
                    <Col className="viewpage-title">Remarks:</Col>{" "}
                    <Col className="viewpage-value">
                      {remarks ? remarks : "--"}
                    </Col>
                  </Row>
                ) : (
                  <Row></Row>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Row>
                  <Col className="viewpage-title">TCS</Col>
                  <Col className="viewpage-value">{tcs ? tcs : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">TDS</Col>
                  <Col className="viewpage-value">{tds ? tds : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <h3 className="boldItem">Address</h3>
              <Col>
                <h4 className="boldItem">Billing Address</h4>
                <Row>
                  <Col className="viewpage-title">Country:</Col>{" "}
                  <Col className="viewpage-value">
                    {bacountry ? bacountry : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">State:</Col>{" "}
                  <Col className="viewpage-value">
                    {bastate ? bastate : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">City:</Col>{" "}
                  <Col className="viewpage-value">{bacity ? bacity : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Address:</Col>{" "}
                  <Col className="viewpage-value">
                    {baaddress ? baaddress : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Pin Code:</Col>{" "}
                  <Col className="viewpage-value">
                    {bapincode ? bapincode : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <h4 className="boldItem">Shipping Address</h4>
                <Row>
                  <Col className="viewpage-title">Country:</Col>{" "}
                  <Col className="viewpage-value">
                    {sacountry ? sacountry : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">State:</Col>{" "}
                  <Col className="viewpage-value">
                    {sastate ? sastate : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">City:</Col>{" "}
                  <Col className="viewpage-value">{sacity ? sacity : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Address:</Col>{" "}
                  <Col className="viewpage-value">
                    {saaddress ? saaddress : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Pin Code:</Col>{" "}
                  <Col className="viewpage-value">
                    {sapincode ? sapincode : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <h3 className="boldItem">Bank Details</h3>
            <Row>
              <Col>
                {customertype != "Retail" ? (
                  <h4 className="boldItem">Primary</h4>
                ) : (
                  ""
                )}
                <Row>
                  <Col className="viewpage-title">Bank Name:</Col>{" "}
                  <Col className="viewpage-value">{banka ? banka : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Account Number:</Col>{" "}
                  <Col className="viewpage-value">
                    {accountnoa ? accountnoa : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Branch:</Col>{" "}
                  <Col className="viewpage-value">
                    {bankbrancha ? bankbrancha : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">IFSC Code:</Col>{" "}
                  <Col className="viewpage-value">{ifsca ? ifsca : "--"}</Col>
                </Row>
              </Col>
              <Col
                className={customertype == "Retail" ? "deactivated_icons" : ""}
              >
                <h4 className="boldItem">Secondary</h4>
                <Row>
                  <Col className="viewpage-title">Bank Name:</Col>{" "}
                  <Col className="viewpage-value">{bankb ? bankb : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Account Number:</Col>{" "}
                  <Col className="viewpage-value">
                    {accountnob ? accountnob : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Branch:</Col>{" "}
                  <Col className="viewpage-value">
                    {bankbranchb ? bankbranchb : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">IFSC Code:</Col>{" "}
                  <Col className="viewpage-value">{ifscb ? ifscb : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            {customertype != "Retail" ? (
              <>
                {" "}
                <Row>
                  <h3 className="boldItem">Bussiness Details</h3>
                  <Col>
                    <Row>
                      <Col className="viewpage-title">Owner Name:</Col>{" "}
                      <Col className="viewpage-value">
                        {bdowner ? bdowner : "--"}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="viewpage-title">Work Phone No.:</Col>{" "}
                      <Col className="viewpage-value">
                        {bdworkphone ? bdworkphone : "--"}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="viewpage-title">Email:</Col>{" "}
                      <Col className="viewpage-value">
                        {bdemail ? bdemail : "--"}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="viewpage-title">GST:</Col>{" "}
                      <Col className="viewpage-value">
                        {bdgstno ? bdgstno : "--"}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col className="viewpage-title">Company Name:</Col>{" "}
                      <Col className="viewpage-value">
                        {bdcompany ? bdcompany : "--"}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="viewpage-title">Mobile No.:</Col>{" "}
                      <Col className="viewpage-value">
                        {bdmobile ? bdmobile : "--"}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="viewpage-title">Remarks:</Col>{" "}
                      <Col className="viewpage-value">
                        {remarks ? remarks : "--"}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="viewpage-title">PAN:</Col>{" "}
                      <Col className="viewpage-value">
                        {bdpan ? bdpan : "--"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
              </>
            ) : (
              ""
            )}
            <h3 className="boldItem">Project Details</h3>
            <Row>
              <Col md={12} className="table-main-container">
                <Table responsive>
                  <TableHeading
                    data={[
                      "Project ID",
                      "Customer Name",
                      "Project Name",
                      "Location",
                      "Architect",
                      "Contractor",
                      "Start Date",
                      "End date",
                      "Status",
                    ]}
                  />
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td onClick={handleViewProject}>
                            {showCustomer ? (
                              <ViewProject
                                item={item}
                                showCustomer={showCustomer}
                                setShowCustomer={setShowCustomer}
                              />
                            ) : (
                              item.projectid
                            )}
                          </td>
                          <td>
                            {item.customertype == "Retail"
                              ? item.fullname
                              : item.company}
                          </td>
                          <td>{item.projectname}</td>
                          <td>{item.projectlocation}</td>
                          <td>{item.architect}</td>
                          <td>{item.contractor}</td>
                          <td>
                            {item.projectstartdate
                              ? indianFormatDate(item.projectstartdate)
                              : ""}
                          </td>
                          <td>
                            {item.projectenddate
                              ? indianFormatDate(item.projectenddate)
                              : ""}
                          </td>
                          <td>{item.status}</td>
                        </tr>
                      ))
                    ) : (
                      <NoRecordFoud count="9" name="Projects" />
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewCustomer;
