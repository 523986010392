import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import ListTablePurchasePayment from './ListTablePurchasePayment';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';

function PurchasePayment() {
  const URL = extractAndFormatURLPart("purchase");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTablePurchasePayment />
        </Col>
      </Row>
    </Container>
  );
}

export default PurchasePayment