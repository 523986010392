import React from 'react';
import { FormControl } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi";

function SearchField({ searchKeyword, setSearchKeyword }) {
    return (
        <div className="inputFieldIcon">
            <FormControl
                type="text"
                placeholder="Search..."
                aria-label="Search..."
                aria-describedby="basic-addon2"
                className="my-2"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <span className="iconPosition">
                <BiSearch />
            </span>
        </div>
    )
}

export default SearchField;