import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const PrimaryContactForm = ({
  data,
  setData,
  handleChange,
  showEmptyError,
  isVendorEmailErr,
  isVendorMobileNoErr,
  isVendorWorkPhoneErr,
  isCpEmailErr,
  isCpMobileNoErr,
  isCpWorkPhoneErr,
}) => {
  const handleVendorCategoryChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value, commission: "" }));
  };

  const handleSameAsVendor=(e)=>{
    const{name,checked}=e.target;
     if(checked){
       setData((prev)=>({...prev,[name]:checked,cpfullname:data.fullname,cpworkphoneno:data.workphoneno,cpmobileno:data.mobileno,cpemail:data.email}))
     }else{
      setData((prev)=>({...prev,[name]:checked,cpfullname:"",cpworkphoneno:"",cpmobileno:"",cpemail:""}))
     }

  }

  return (
    <Container fluid>
      <Row className="form-container">
        <Col sm={12}>
          <h3>
            <span className="form-title"> Vendor Details</span>
          </h3>
        </Col>
        <Col sm={12}>
          <Form className="form-lable-name">
            <Row>
              <Col>
                <Row>
                  <Col
                    md={
                      data.vendorcategory == "Architect" ||
                      data.vendorcategory == "Contractor"
                        ? 6
                        : 12
                    }
                  >
                    <Form.Group className="mb-3" controlId="vendorcategory">
                      <Form.Label>
                        Vendor Category<span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="company"
                        name="vendorcategory"
                        value={data.vendorcategory}
                        onChange={handleVendorCategoryChange}
                        isInvalid={!data.vendorcategory && showEmptyError}
                      >
                        <option value="">Select Vendor Category</option>
                        <option value="Supplier">Supplier</option>
                        <option value="Transporter">Transporter</option>
                        <option value="Service Provider">
                          Service Provider
                        </option>
                        <option value="Architect">Architect</option>
                        <option value="Contractor">Contractor</option>
                        <option value="Others">Others</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select Vendor Category
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {data.vendorcategory == "Architect" ||
                  data.vendorcategory == "Contractor" ? (
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="branchname">
                        <Form.Label>Commission %</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Commission Percentage"
                          name="commission"
                          value={data.commission}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="branchid">
                  <Form.Label>
                    Full Name<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="fullname"
                    value={data.fullname}
                    onChange={handleChange}
                    isInvalid={!data.fullname && showEmptyError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Full Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
            <Col md={6}>
                <Form.Group className="mb-3" controlId="branchname">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    name="companyname"
                    value={data.companyname}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    isInvalid={isVendorEmailErr}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Work Phone No <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        //placeholder="Enter ESIC Number"
                        name="workphoneno"
                        value={data.workphoneno}
                        onChange={handleChange}
                        //required
                        isInvalid={
                          (!data.workphoneno && showEmptyError) ||
                          isVendorWorkPhoneErr
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Work Phone Number
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Mobile No</Form.Label>
                      <Form.Control
                        type="number"
                        //placeholder="Enter ESIC Number"
                        name="mobileno"
                        value={data.mobileno}
                        onChange={handleChange}
                        //required
                        isInvalid={isVendorMobileNoErr}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                   Enter ESIC Number
                 </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}></Col>
            </Row>
            <hr />
            <Row>
              <Col className="colStyle">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="form-title"> Contact Person</span>
                   <Form.Check
                    inline
                    label="Same as Vendor Details"
                    name="samevendor"
                    type="checkbox"
                    className="form-title"
                    checked={data.samevendor}
                    onChange={handleSameAsVendor}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="branchid">
                  <Form.Label>
                    Full Name<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Contct Person Name"
                    name="cpfullname"
                    value={data.cpfullname}
                    onChange={handleChange}
                    isInvalid={!data.cpfullname && showEmptyError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Full Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Contact Person Email"
                    name="cpemail"
                    value={data.cpemail}
                    onChange={handleChange}
                    isInvalid={isCpEmailErr}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Work Phone No <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        //placeholder="Enter ESIC Number"
                        name="cpworkphoneno"
                        value={data.cpworkphoneno}
                        onChange={handleChange}
                        //required
                        isInvalid={
                          (!data.cpworkphoneno && showEmptyError) ||
                          isCpWorkPhoneErr
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Work Phone Number
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Mobile No</Form.Label>
                      <Form.Control
                        type="number"
                        //placeholder="Enter ESIC Number"
                        name="cpmobileno"
                        value={data.cpmobileno}
                        onChange={handleChange}
                        //required
                        isInvalid={isCpMobileNoErr}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                   Enter ESIC Number
                 </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}></Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default PrimaryContactForm;
