

  
//   import { RELOAD } from "../actionTypes/load.actiontypes";

import axios from "axios";
import { addStockPost, deleteStock, stockPagiDataPost, stockStatusChange, updateStockPost } from "../../constants/api";
import { ADD_STOCKLOCATION_DATA, FETCH_STOCKLOCATION_DATA, UPDATE_STOCKLOCATION } from "../actionTypes/stocklocation.actionTypes";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { notifyError, notifySuccess, notifyWarning } from "../../constants/toastAlert";

  
  //Get all warehouse data using pagination wise
  export const stockData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(stockPagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_STOCKLOCATION_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching stocklocation data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete stocklocation data dispatcher
  export const deleteStockData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteStock, backendData);
        if (res.data.message == "Location deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting stocklocation data ${error}`);
        notifyError(error.message);
      }
    };
  
  //Status change dispatcher
  export const statusChangeStock = (backendData) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(stockStatusChange, backendData);
      if (res.data.message === "Location Activated") {
        notifySuccess(res.data.message);
      } else {
        notifyWarning(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.log(`Error in changing stocklocation status ${error}`);
      notifyError(error.message);
    }
  };
  
  //Acion dispatcher for adding new company data
  export const addStock = (stockdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addStockPost, stockdata);
  
      if (res.data.message === "Location added successfully") {
        dispatch({ type: ADD_STOCKLOCATION_DATA });
        notifySuccess(res.data.message);
        navigate("/admin/stockLocation");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding stocklocation data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateStock = (stockData, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updateStockPost, stockData);
  
      if (res.data.message === "Location updated successfully") {
        dispatch({ type: UPDATE_STOCKLOCATION });
        notifySuccess(res.data.message);
        navigate("/admin/stockLocation");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating stocklocation data ${error}`);
      notifyError(error.response.data.message);
    }
  };