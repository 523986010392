import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const AddressForm = ({
  data,
  handleChange,
  showEmptyError,
  states,
  billingCities,
  shippingCities,
  setData,

}) => {
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {

      setData((prev) => ({
        ...prev,
        shippingattention: data.billingattention,
        shippingcountry: data.billingcountry,
        shippingstate: data.billingstate,
        shippingcity: data.billingcity,
        shippingaddress: data.billingaddress,
        shippingpincode: data.billingpincode,
      }));
    } else {

      setData((prev) => ({
        ...prev,
        shippingattention: "",
        shippingcountry: "",
        shippingstate: "",
        shippingcity: "",
        shippingaddress: "",
        shippingpincode: "",
      }));
    }
  };

  return (
    <Container fluid>
      <Row className="form-container">
        <Form className="form-lable-name">
          <Row>
            <Col md={6}>
              <Form.Label>Billing Address</Form.Label>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <Form.Label>Shipping Address</Form.Label>
                </Col>
                <Col>
                  <Form.Check
                    inline
                    label="Same as billing address"
                    name="group1"
                    type="checkbox"
                    className="form-label"
                    onChange={handleCheckboxChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Attention1</Form.Label>
                <Form.Control
                  type="text"
                  name="billingattention"
                  value={data.billingattention}
                  onChange={handleChange}
                  placeholder="Enter Attention.. "
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Attention2</Form.Label>
                <Form.Control
                  type="text"
                  name="shippingattention"
                  value={data.shippingattention}
                  onChange={handleChange}
                  placeholder="Enter Attention.. "
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="vendorcategory">
                <Form.Label>
                  Country <span className="star">*</span>
                </Form.Label>
                <Form.Select
                  aria-label="country"
                  name="billingcountry"
                  value={data.billingcountry}
                  disabled
                >
                  <option value="India">India</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="vendorcategory">
                <Form.Label>
                  Country <span className="star">*</span>
                </Form.Label>
                <Form.Select
                  aria-label="country"
                  name="shippingcountry"
                  value={data.shippingcountry}
                  disabled
                >
                  <option value="India">India</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      State <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="billingstate"
                      value={data.billingstate}
                      onChange={handleChange}
                      isInvalid={!data.billingstate && showEmptyError}
                    >
                      <option value="">Select</option>
                      {states.map((state) => {
                        return (
                          <option value={state.name} key={state.isoCode}>
                            {state.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      City <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="billingcity"
                      value={data.billingcity}
                      onChange={handleChange}
                      isInvalid={!data.billingcity && showEmptyError}
                    >
                      <option value="">Select</option>
                      {billingCities.map((city, ind) => {
                        return (
                          <option value={city} key={ind}>
                            {city}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      State <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="shippingstate"
                      value={data.shippingstate}
                      onChange={handleChange}
                      isInvalid={!data.shippingstate && showEmptyError}
                    >
                      <option value="">Select</option>
                      {states.map((state) => {
                        return (
                          <option value={state.name} key={state.isoCode}>
                            {state.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      City <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="shippingcity"
                      value={data.shippingcity}
                      onChange={handleChange}
                      isInvalid={!data.shippingcity && showEmptyError}
                    >
                      <option value="">Select</option>
                      {shippingCities.map((city, ind) => {
                        return (
                          <option value={city} key={ind}>
                            {city}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Address 
                </Form.Label>
                <Form.Control
                  type="text"
                  name="billingaddress"
                  value={data.billingaddress}
                  onChange={handleChange}
                  placeholder="Enter Billing Address.. "
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Address 
                </Form.Label>
                <Form.Control
                  type="text"
                  name="shippingaddress"
                  value={data.shippingaddress}
                  onChange={handleChange}
                  placeholder="Enter Shipping Address.. "
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Pin Code 
                </Form.Label>
                <Form.Control
                  type="number"
                  name="billingpincode"
                  value={data.billingpincode}
                  onChange={handleChange}
                  placeholder="Enter Pincode "

                />

              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Pin Code 
                </Form.Label>
                <Form.Control
                  type="number"
                  name="shippingpincode"
                  value={data.shippingpincode}
                  onChange={handleChange}
                  placeholder="Enter Pincode "

                />

              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default AddressForm;
