import React, { useState } from 'react'
import ListTable from './ListTable';

function Einvoice() {
 
    return (
        <>
      <ListTable/>
        </>
      );

}

export default Einvoice