import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, FormControl, Row } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { IoBusiness } from "react-icons/io5";
import { Link } from "react-router-dom";
import { allReports } from "../../../constants/reports";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";

const Reports = () => {
  const URL = extractAndFormatURLPart("reports");
  const accountReports = allReports.filter(
    (report) => report.type === "Account Reports"
  );
  const saleReports = allReports.filter(
    (report) => report.type === "Sale Reports"
  );
  const inventoryReports = allReports.filter(
    (report) => report.type === "Inventory Reports"
  );
  const purchaseReports = allReports.filter(
    (report) => report.type === "Purchase Reports"
  );

  return (
    <>
      <Row>
        <Col className="colStyle">
          <span>{URL}</span>
        </Col>
      </Row>
      <Container
        fluid
        className="pt-3 pl-5"
        style={{
          // width: "83.75rem",
          height: "550px",
          borderRadius: "24px",
          // boxShadow: "0px -1px 6px 0px rgba(0, 0, 0, 0.12)",
          background: " #FFF",
        }}
      >
        <Row className="justify-content-end"></Row>
        <Row style={{ paddingLeft: "10px" }}>
          {accountReports.length > 0 ? (
            accountReports.map((items, index) => (
              <Col>
                <Row className="justify-content-center">
                  <Form.Label className="section-title">
                    <span>
                      <IoBusiness />
                    </span>
                    <span> {items.type}</span>
                  </Form.Label>
                </Row>
                <br />
                <Row lg={4}>
                  {items.contents.map((ele, ind) => (
                    <Col>
                      <Link style={{ textDecoration: "none" }} to={ele.link}>
                        <Card body>{ele.name}</Card>
                      </Link>
                      <br />
                    </Col>
                  ))}
                </Row>
              </Col>
            ))
          ) : (
            <Col></Col>
          )}
        </Row>
        <Row style={{ paddingLeft: "10px" }}>
          {saleReports.length > 0 ? (
            saleReports.map((items, index) => (
              <Col>
                <Row className="justify-content-center">
                  <Form.Label className="section-title">
                    <span>
                      <IoBusiness />
                    </span>
                    <span> {items.type}</span>
                  </Form.Label>
                </Row>
                <br />
                <Row lg={4}>
                  {items.contents.map((ele, ind) => (
                    <Col>
                      <Link style={{ textDecoration: "none" }} to={ele.link}>
                        <Card body>{ele.name}</Card>
                      </Link>
                      <br />
                    </Col>
                  ))}
                </Row>
              </Col>
            ))
          ) : (
            <Col></Col>
          )}
        </Row>
        <Row style={{ paddingLeft: "10px" }}>
          {inventoryReports.length > 0 ? (
            inventoryReports.map((items, index) => (
              <Col>
                <Row className="justify-content-center">
                  <Form.Label className="section-title">
                    <span>
                      <IoBusiness />
                    </span>
                    <span> {items.type}</span>
                  </Form.Label>
                </Row>
                <br />
                <Row lg={4}>
                  {items.contents.map((ele, ind) => (
                    <Col>
                      <Link style={{ textDecoration: "none" }} to={ele.link}>
                        <Card body>{ele.name}</Card>
                      </Link>
                      <br />
                    </Col>
                  ))}
                </Row>
              </Col>
            ))
          ) : (
            <Col></Col>
          )}
        </Row>
        <Row style={{ paddingLeft: "10px" }}>
          {purchaseReports.length > 0 ? (
            purchaseReports.map((items, index) => (
              <Col>
                <Row className="justify-content-center">
                  <Form.Label className="section-title">
                    <span>
                      <IoBusiness />
                    </span>
                    <span> {items.type}</span>
                  </Form.Label>
                </Row>
                <br />
                <Row lg={4}>
                  {items.contents.map((ele, ind) => (
                    <Col>
                      <Link style={{ textDecoration: "none" }} to={ele.link}>
                        <Card body>{ele.name}</Card>
                      </Link>
                      <br />
                    </Col>
                  ))}
                </Row>
              </Col>
            ))
          ) : (
            <Col></Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Reports;
