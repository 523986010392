import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "../../../constants/toastAlert";
import {
  addProject,
  projectData,
} from "../../../redux/actions/projects.actions";
import axios from "axios";
import { projectIdAutocount } from "../../../constants/api";
import { vendorData } from "../../../redux/actions/vendor.actions";
import Select from "react-select";
import { getCurrentPreviousYears } from "../../../constants/currentPreviousYear";

function AddProject({
  showProjectModal,
  handleProjectShow,
  handleProjectCancel,
}) {
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);

  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];
  const [customerMaster, setCustomerMaster] = useState([]);
  const projectID =
    useSelector((state) => state.projectData.projectData.data) || [];
  const [data, setData] = useState({
    type: "",
    projectid: "",
    backendcustomerid:"",
    customerid: "",
    customertype: "Institutional",
    customerdetails: "",
    customerfullname: "",
    projectname: "",
    projectstartdate: "",
    projectenddate: "",
    projectlocation: "",
    architect: "",
    contractor: "",
    status: "Planned",
    attachments: [],
    documents: "",
    remarks: "",
    company: "",
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    branch: branch,
    org: "₹ΘθϖΠ",
    architectarray: [],
    contractorarray: [],
  });

  const handleChange = (e) => {
    if (e.value) {
      setData((prev) => ({
        ...prev,
        customertype: e.type,
        customerfullname: e.type == "Institutional" ? "" : e.name,
        company: e.type == "Retail" ? "" : e.name,
        customerid: e.value,
        customerdetails: e.label,
        backendcustomerid:e.b_id
      }));
    } else {
      const { name, value } = e.target;
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (name == "architect") {
        if (value) {
          const findVendor = vendorMaster.find(
            (vendor) =>
              vendor.vendorcategory == "Architect" && vendor.fullname == value
          );

          setData((prev) => ({
            ...prev,
            architect: findVendor.fullname,
            architectarray: [{ ...findVendor }],
          }));
        } else {
          setData((prev) => ({
            ...prev,
            architect: "",
            architectarray: [],
          }));
        }
      }

      if (name == "contractor") {
        if (value) {
          const findVendor = vendorMaster.find(
            (vendor) =>
              vendor.vendorcategory == "Contractor" && vendor.fullname == value
          );

          setData((prev) => ({
            ...prev,
            contractor: findVendor.fullname,
            contractorarray: [{ ...findVendor }],
          }));
        } else {
          setData((prev) => ({
            ...prev,
            contractor: "",
            contractorarray: [],
          }));
        }
      }
    }
  };

  const handleFileChange = (event) => {
    if (event.target.name === "documents") {
      const files = event.target.files;
      handleUploadFile(files);
    }
    // handleUploadFile(data.documents);
  };

  const handleUploadFile = async (files) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(
        "https://erp.venturesathi.co.in/api/v1/projects/fileupload",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      response.data.files.length > 0
        ? setData((prev) => ({ ...prev, attachments: response.data.files }))
        : setData((prev) => ({ ...prev, attachments: [] }));

      return response.data.files; // Return the entire response object
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  const handleSave = async () => {
    if (
      !data.projectid ||
      !data.projectname ||
      !data.status ||
      !data.projectenddate ||
      !data.projectstartdate
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty fields");
    }
    dispatch(addProject(data));
    setData((prev) => ({
      ...prev,
      type: "",
      projectid: "",
      customerid: "",
      customertype: "Institutional",
      customerdetails: "",
      customerfullname: "",
      projectname: "",
      projectstartdate: "",
      projectenddate: "",
      projectlocation: "",
      architect: "",
      contractor: "",
      status: "Planned",
      attachments: [],
      documents: "",
      remarks: "",
      company: "",
      companyid: companyid,
      userid: userid,
      branchid: branchid,
      branch: branch,
      org: "₹ΘθϖΠ",
      architectarray: [],
      contractorarray: [],
    }));
    handleProjectCancel();
  };

  // Ranjan Started
  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    keyword: "" || "₹ΘθϖΠ",
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(projectData(backendData, setLoading));
  }, [dispatch]);

  const generateCategoryID = () => {
    if (projectID.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = projectID[0].projectid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      projectid: `PROJ/${getCurrentPreviousYears()}/${paddedCount}`,
    }));
  }, [projectID]);

  // Ranjan Ended

  const fetchCustomerData = async () => {
    try {
      const res = await axios.post(

        "https://erp.venturesathi.co.in/api/v1/customer/getallcustomerpage",

        {
          keyword: "₹ΘθϖΠ",
          userid: userid,
          branchid: branchid,
          companyid: companyid,
          branch: branch,
          companyname: companyname,
          org: org,
        }
      );
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data.data : [];

      setCustomerMaster(
        initialData?.map((customer) => ({
          value: customer.customerid,
          b_id: customer.id,
          label: `${customer.customerid}-${
            customer.customertype == "Retail"
              ? customer.fullname
              : customer.bdcompany
          }`,
          type: customer.customertype,
          name:
            customer.customertype == "Retail"
              ? customer.fullname
              : customer.bdcompany,
        }))
      );
      // dispatch(reloadPage(false));
    } catch (error) {
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(vendorData({ companyid, userid, keyword: "" }, setLoading));
    fetchCustomerData();
  }, []);
  return (
    <>
      <span className="form-title-sibling" onClick={handleProjectShow}>
        <BsPlusCircle /> Add Project
      </span>
      <Modal
        size="xl"
        show={showProjectModal}
        onHide={handleProjectCancel}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title">Add Project</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form className="form-lable-name">
              <div className="d-flex gap-4">
                <div className="w-100">
                  <Container fluid>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Customer ID <span className="star">*</span>
                              </Form.Label>
                              <Select
                                // name="customerid"
                                value={{
                                  value: data.customerid,
                                  label: data.customerdetails,
                                }}
                                onChange={handleChange}
                                options={customerMaster}
                                isSearchable
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Project ID<span className="star"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                disabled
                                name="projectid"
                                value={data.projectid}
                                onChange={handleChange}
                                isInvalid={!data.projectid && showEmptyError}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          {data.customertype == "Institutional" ? (
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Company Name<span className="star"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  // placeholder="Enter Company Name"
                                  name="company"
                                  value={data.company}
                                  // onChange={handleChange}
                                  isInvalid={!data.company && showEmptyError}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Customer Name<span className="star"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  // placeholder="Enter Customer Name"
                                  name="customerfullname"
                                  value={data.customerfullname}
                                  // onChange={handleChange}
                                  isInvalid={!data.customerid && showEmptyError}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          )}
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Project Name<span className="star"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Project Name"
                                name="projectname"
                                value={data.projectname}
                                onChange={handleChange}
                                isInvalid={!data.customerid && showEmptyError}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Project Start Date.{" "}
                                <span className="star"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                name="projectstartdate"
                                value={data.projectstartdate}
                                onChange={handleChange}
                                isInvalid={
                                  !data.projectstartdate && showEmptyError
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Project End Date.{" "}
                                <span className="star"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                name="projectenddate"
                                value={data.projectenddate}
                                onChange={handleChange}
                                isInvalid={
                                  !data.projectenddate && showEmptyError
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Project Location</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Project Location"
                            name="projectlocation"
                            value={data.projectlocation}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Architect</Form.Label>
                              <Form.Select
                                name="architect"
                                value={data.architect}
                                onChange={handleChange}
                                // isInvalid={!data.architect && showEmptyError}
                              >
                                <option value="">Select</option>
                                {vendorMaster
                                  .filter(
                                    (vendor) =>
                                      vendor.vendorcategory == "Architect"
                                  )
                                  .map((vendor) => {
                                    return (
                                      <option
                                        value={vendor.fullname}
                                        key={vendor.id}
                                      >
                                        {vendor.fullname}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Contractor</Form.Label>
                              <Form.Select
                                name="contractor"
                                value={data.contractor}
                                onChange={handleChange}
                                // isInvalid={!data.contractor && showEmptyError}
                              >
                                <option value="">Select</option>
                                {vendorMaster
                                  .filter(
                                    (vendor) =>
                                      vendor.vendorcategory == "Contractor"
                                  )
                                  .map((vendor) => {
                                    return (
                                      <option
                                        value={vendor.fullname}
                                        key={vendor.id}
                                      >
                                        {vendor.fullname}
                                      </option>
                                    );
                                  })}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Remarks.."
                            name="remarks"
                            value={data.remarks}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Status <span className="star">*</span>
                              </Form.Label>
                              <Form.Select
                                name="status"
                                value={data.status}
                                onChange={handleChange}
                                isInvalid={!data.status && showEmptyError}
                              >
                                <option value="">Select</option>
                                <option value="Planned">Planned</option>
                                <option value="In Progress">In Progress</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Completed">Completed</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3" controlId="formFile">
                              <Form.Label>Attach Files</Form.Label>
                              <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                name="documents"
                                // value={data.documents}
                                multiple
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleProjectCancel}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddProject;
