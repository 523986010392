export const indianFormatDate = (date) => {
    let d;
    if (date.includes("T")) {
        d = new Date(date);
    } else {
        d = new Date(date);
    }

    // Ensure d is a valid Date object before proceeding
    if (isNaN(d.getTime())) {
        return "Invalid Date"; // Handle the invalid date case
    }

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
}
