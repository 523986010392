import {
  legacy_createStore,
  combineReducers,
  compose,
  applyMiddleware,
} from "redux";
import { thunk } from "redux-thunk";
import { authReducer } from "./reducers/auth.reducers";
import { companyReducer } from "./reducers/company.reducer";
import loadReducer from "./reducers/load.reducer";
import { branchReducer } from "./reducers/branch.reducers";
import { warehouseReducer } from "./reducers/warehouse.reducer";
import { stockReducer } from "./reducers/stocklocation.reducers";
import { vendorReducer } from "./reducers/vendor.reducers";
import { purchaseReducer } from "./reducers/purchaseOrder.reducers";
import { coaReducer } from "./reducers/chartofaccount.reducers";
import { ptReducer } from "./reducers/pt.reducers";
import { userReducer } from "./reducers/module.reducers";
import { projectReducer } from "./reducers/projects.reducers";
import { manualjournalReducer } from "./reducers/manualjournal.reducers";
import { projectTeamsReducer } from "./reducers/projectTeams.reducers";
import { ledgerReducer } from "./reducers/ledger.reducers";
import { tdsReducer } from "./reducers/tds.reducers";
import { tcsReducer } from "./reducers/tcs.reducers";
import { bankReducer } from "./reducers/bank.reducers";
import { purchaseBillsReducer } from "./reducers/purchaseBills.reducers";
import { paymentReducer } from "./reducers/payment.reducers";
import { receiptReducer } from "./reducers/receipt.reducers";
import { contraEntryReducer } from "./reducers/contraEntry.reducers";
import { purchaseTargetReducer } from "./reducers/purchaseTarget.reducers";
import { salesTargetReducer } from "./reducers/salesTarget.reducers";
import { quotationReducer } from "./reducers/quotation.reducers";
import { salesOrderReducer } from "./reducers/salesOrder.reducers";
import { purchaseTargetDashboardReducer } from "./reducers/purchaseTargetDashboard.reducers";
import { suggestedItemReducer } from "./reducers/suggesteditems.reducers";
import { customerReducer } from "./reducers/customer.actionstypes";
import { categoryReducer } from "./reducers/category.reducers";
import { itemReducer } from "./reducers/item.reducers";
import { employeeReducer } from "./reducers/employee.reducers";
import { purchasePaymentReducer } from "./reducers/purchasePayment.reducers";
import { salesInvoiceReducer } from "./reducers/salesInvoice.reducers";
import { mtoutReducer } from "./reducers/mtout.reducers";
import { mtinReducer } from "./reducers/mtin.reducers";
import { salePaymentReducer } from "./reducers/salepayment.reducers";
import { salesTargetDashboardReducer } from "./reducers/salesTargetDashboard.reducers";
import { creditNoteReducer } from "./reducers/creditNote.reducers";

// root reducer
const root_reducer = combineReducers({
  authManager: authReducer,
  companyData: companyReducer,
  branchData: branchReducer,
  loadReducer: loadReducer,
  warehouseData: warehouseReducer,
  stockData: stockReducer,
  vendorData: vendorReducer,
  purchaseOrderData: purchaseReducer,
  manualJournalData: manualjournalReducer,
  chartOfAccountData: coaReducer,
  ledgerData: ledgerReducer,
  ptData: ptReducer,
  userReducer: userReducer,
  projectData: projectReducer,
  projectTeamsData: projectTeamsReducer,
  tdsData: tdsReducer,
  tcsData: tcsReducer,
  bankData: bankReducer,
  purchaseBillsData: purchaseBillsReducer,
  paymentData: paymentReducer,
  receiptData: receiptReducer,
  contraEntryData: contraEntryReducer,
  purchaseTargetData: purchaseTargetReducer,
  salesTargetData: salesTargetReducer,
  quotationData: quotationReducer,
  salesOrderData: salesOrderReducer,
  salesInvoiceData: salesInvoiceReducer,
  purchaseTargetDashboardData: purchaseTargetDashboardReducer,
  salesTargetDashboardData: salesTargetDashboardReducer,
  suggestedItemData: suggestedItemReducer,
  customerData: customerReducer,
  categoryData: categoryReducer,
  itemData: itemReducer,
  employeeData: employeeReducer,
  purchasePaymentData: purchasePaymentReducer,
  issueGoodData: mtoutReducer,
  receiveGoodData: mtinReducer,
  salePaymentData: salePaymentReducer,
  creditNoteData: creditNoteReducer,
});

//for redux devtool extension on chrome
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// store
export const store = legacy_createStore(
  root_reducer,
  composeEnhancers(applyMiddleware(thunk))
);
