import React from 'react'
import ListTableVendor from './ListTableVendor'
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import { Col, Container, Row } from 'react-bootstrap';

const Vendor = () => {
    const URL = extractAndFormatURLPart("purchase");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTableVendor />
        </Col>
      </Row>
    </Container>
  )
}

export default Vendor