

  
//   import { RELOAD } from "../actionTypes/load.actiontypes";

import axios from "axios";
import { addptApi, deleteptApi, getptApi } from "../../constants/api";
import { ADD_PT_DATA, FETCH_PT_DATA } from "../actionTypes/pt.actiontypes";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";



  
  //Get all warehouse data using pagination wise
  export const paymentTermData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getptApi, backendData);
      const initialData =
        res.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_PT_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching payment term data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete stocklocation data dispatcher
  export const deleteptData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteptApi, backendData);
        if (res.data.message == "Payment Term deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting payment term data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new vendor data
  export const addptData = (ptData, handleCancel) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addptApi, ptData);
  
      if (res.data.message === "Payment Term added successfully") {
        dispatch({ type: ADD_PT_DATA });
        notifySuccess(res.data.message);
        handleCancel();
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding payment term data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
//   //Acion dispatcher for updating new company data
//   export const updatePurchaseOrder = (purchaseOrderdata, navigate) => async (dispatch) => {
//     try {
//       //     dispatch({ type: RELOAD, payload: true });
//       const res = await axios.post(editPurchaseOrderApi, purchaseOrderdata);
  
//       if (res.data.message === "Purchase order updated successfully") {
//         dispatch({ type: UPDATE_PURCHASEORDER_DATA });
//         notifySuccess(res.data.message);
//         navigate("/purchase/purchaseorder");
//       } else {
//         notifyError(res.data.message);
//       }
//       dispatch({ type: RELOAD, payload: true });
//     } catch (error) {
//       // dispatch({ type: LOGIN_FAILURE, payload: error.message });

//       notifyError(error.response.data.message);
//     }
//   };