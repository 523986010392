import React, { useEffect, useState } from "react";
// import ListTableChart from "./ListTableChart";
import { Col, Container, Row } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListTableChart from "./ListTableChart";

const ChartOfAccount = () => {
  const URL = extractAndFormatURLPart("accounting");
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListTableChart />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChartOfAccount;
