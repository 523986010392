

import axios from "axios";
import { notifyError, notifySuccess, notifyWarning } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { ADD_WAREHOUSE_DATA, FETCH_WAREHOUSE_DATA, UPDATE_WAREHOUSE } from "../actionTypes/warehouse.actiontypes";
import { warehousePagiDataPost,deleteWarehouse, warehouseStatusChange, addWarehousePost, updateWarehousePost } from "../../constants/api";

  
//   import { RELOAD } from "../actionTypes/load.actiontypes";
  
  //Get all warehouse data using pagination wise
  export const warehouseData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(warehousePagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_WAREHOUSE_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching warehouse data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete company data dispatcher
  export const deleteWarehouseData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteWarehouse, backendData);
        if (res.data.message == "Warehouse deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting warehouse data ${error}`);
        notifyError(error.message);
      }
    };
  
  //Status change dispatcher
  export const statusChange = (backendData) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(warehouseStatusChange, backendData);
      if (res.data.message === "Warehouse Activated") {
        notifySuccess(res.data.message);
      } else {
        notifyWarning(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.log(`Error in changing warehouse status ${error}`);
      notifyError(error.message);
    }
  };
  
  //Acion dispatcher for adding new company data
  export const addWarehouse = (warehousedata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addWarehousePost, warehousedata);
  
      if (res.data.message === "Warehouse added successfully") {
        dispatch({ type: ADD_WAREHOUSE_DATA });
        notifySuccess(res.data.message);
        navigate("/admin/godownOrShowroom");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding warehouse data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateWarehouse = (warehouseData, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updateWarehousePost, warehouseData);
  
      if (res.data.message === "Warehouse updated successfully") {
        dispatch({ type: UPDATE_WAREHOUSE });
        notifySuccess(res.data.message);
        navigate("/admin/godownOrShowroom");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating warehouse data ${error}`);
      notifyError(error.response.data.message);
    }
  };