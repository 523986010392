import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { Categories } from "./ListItem";
import { BsPlusCircle } from "react-icons/bs";
import { GiConfirmed } from "react-icons/gi";
import axios from "axios";
import { useDispatch } from "react-redux";
import { reloadPage } from "../../../redux/actions/load.action";
import { itemGetItemPost, itemUpdateItemPost } from "../../../constants/api";
import { initialData } from "../../../constants/resData";
import { MdModeEditOutline } from "react-icons/md";

const EditItem = ({id}) => {
  
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;
  let org = localData.org;
  //const { id } = useParams();
  const dispatch = useDispatch();
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;
  const URL = extractAndFormatURLPart("inventory");
  const navigate = useNavigate();

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [inputText, setInputText] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState([]);
  const [categoryID, setCategoryId] = useState("");
  let cat = categories;
  const [data, setData] = useState({});
  const [inputUnit, setInputUnit] = useState(false);
  const [units, setUnits] = useState([]);
  const [series, setSeries] = useState([]);
  const [open, setOpen] = useState(false);

  const handleLeftArrow = () => {
    navigate("/inventory/item");
  };
  const toggleInput = () => {
    setInputText(!inputText);
  };
  const toggleUnit = () => {
    setInputUnit(!inputUnit);
  };

  const handleUpdate = async () => {
    if (
      data.itemname == "" ||
      data.category == "" ||
      data.taxability == ""
      //data.series == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    } else if (
      data.itemname == "" ||
      data.category == "" ||
      (data.taxability == "Taxable" && data.gst == "")
      //data.series == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    const updatedFormData = {
      ...data,
    };
    const res = await axios.post(itemUpdateItemPost, updatedFormData);
    if (res.data.message == "Item updated Successfully") {
      notifySuccess("item updated");
      dispatch(reloadPage(true));
      setOpen(false);
    } else {
      notifyWarning(res.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name == "series") {
      let selectedSeries = series.find((s) => s.series == value);
      setData((prevData) => ({
        ...prevData,
        series: value,
        rateperunit: selectedSeries ? selectedSeries.rate : 0,
      }));
    }

    setShowEmptyError(false);
  };

  useEffect(() => {
    //dynamic categoryid generate
    axios

      .post("https://erp.venturesathi.co.in/api/v1/idgenerate/categoryid", {
        userid: userID,
      })

      .then((res) => {
        setCategoryId(`CAT-${+res.data.count + 1}`);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleCategory = async () => {
    if (data.category == "") {
      setShowEmptyError(true);
      return notifyError("Category Can't be empty");
    }

    await axios.post("https://erp.venturesathi.co.in/api/v1/item/addcategory", {
      category: data.category,
      categoryid: categoryID,
      userid: userID,
      companyid: companyID,
      branchid: branchID,
      org: org,
    });
    dispatch(reloadPage(true));
  };

  useEffect(() => {
    
    axios
      .post(itemGetItemPost, {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: "" || org,
      })
      .then((res) => {
        const filteredData =
          res.data && res.data.data.length > 0
            ? res.data.data.filter((elem) => {
                return elem.id == id;
              })
            : [];
        setData({ ...filteredData[0] });
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    //dynamic category generate
    axios

      .post("https://erp.venturesathi.co.in/api/v1/item/category", {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: org || "",
      })
      .then((res) => {
        setCategories(initialData(res));
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //dynamic Unit generate
    axios
      .post("https://erp.venturesathi.co.in/api/v1/item/uom")

      .then((res) => {
        setUnits(res && res.data && res.data);
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //dynamic brand generate
    axios

      .post("https://erp.venturesathi.co.in/api/v1/item/getbrand", {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: org || "",
      })
      .then((res) => {
        setBrand(initialData(res));
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, [data.brand]);

  const handleUploadFile = async (files) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(
        "https://erp.venturesathi.co.in/api/v1/item/fileupload",

        { ...formData },
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return response.data.files; // Return the entire response object
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  useEffect(() => {
    //Series based on brand
    axios

      .post("https://erp.venturesathi.co.in/api/v1/item/getseries", {
        keyword: "" || org,
      })

      .then((res) => {
        const initialData = res.data && res.data.length > 0 ? res.data : [];
        setSeries(initialData.filter((series) => series.brand == data.brand));
      })
      .catch((err) => console.log(err));
  }, [data.brand]);
  return (
    <>
      <MdModeEditOutline
        className="editIcon mr-2"
        onClick={() => setOpen(true)}
      />
      <Modal
        show={open}
        size="xl"
        onHide={() => setOpen(false)}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col className="colStyle p-0">
                <span>{URL}</span>
              </Col>
            </Row>
            <div className="form-container">
              <Form className="form-lable-name">
                <h1>
                  <FaArrowLeftLong
                    className="left-arrow"
                    onClick={() => setOpen(false)}
                  />
                  Edit <span className="form-title main"> Item</span>
                </h1>
                <Container fluid>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>
                          Item Name<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Item Name"
                          name="itemname"
                          value={data.itemname}
                          onChange={handleChange}
                          isInvalid={data.itemname === "" && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Item Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row md={2} sm={1}>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>Item No</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              placeholder="Item No"
                              name="itemid"
                              value={data.itemid}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>
                              Category<span className="star"> *</span>
                            </Form.Label>
                            <InputGroup>
                              {!inputText ? (
                                <Form.Select
                                  name="category"
                                  value={data.category}
                                  onChange={handleChange}
                                  isInvalid={
                                    data.category === "" && showEmptyError
                                  }
                                >
                                  <option>--Select--</option>
                                  {categories.length > 0 &&
                                    categories.map((ele, index) => (
                                      <option key={index} value={ele.category}>
                                        {ele.category}
                                      </option>
                                    ))}
                                </Form.Select>
                              ) : (
                                <Form.Control
                                  type="text"
                                  placeholder="Add Category"
                                  name="category"
                                  value={data.category}
                                  onChange={handleChange}
                                />
                              )}
                              <InputGroup.Text
                                variant="outline-secondary"
                                onClick={toggleInput}
                              >
                                {!inputText ? (
                                  <BsPlusCircle />
                                ) : (
                                  <GiConfirmed onClick={handleCategory} />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">
                              Choose a category
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Row md={2} sm={1}>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>
                              Make/Brand <span className="star"> *</span>
                            </Form.Label>
                            <Form.Select
                              name="brand"
                              value={data.brand}
                              onChange={handleChange}
                              isInvalid={data.brand === "" && showEmptyError}
                            >
                              <option>--Select--</option>
                              {brand.length > 0 &&
                                brand.map((ele, index) => (
                                  <option key={index} value={ele.brand}>
                                    {ele.brand}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Choose a brand
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>Series</Form.Label>
                            <Form.Select
                              name="series"
                              value={data.series}
                              onChange={handleChange}
                              //isInvalid={data.series === "" && showEmptyError}
                            >
                              <option value="">--Select--</option>
                              {series.length > 0 &&
                                series.map((ele, index) => (
                                  <option key={index} value={ele.series}>
                                    {ele.series}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row md={2} sm={1}>
                        <Col>
                          <Row md={2} sm={1}>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicID"
                              >
                                <Form.Label>UOM</Form.Label>
                                <InputGroup>
                                  {!inputUnit ? (
                                    <Form.Select
                                      name="unit"
                                      value={data.unit}
                                      onChange={handleChange}
                                      isInvalid={
                                        data.unit === "" && showEmptyError
                                      }
                                    >
                                      <option>--Select--</option>
                                      {units.length > 0 &&
                                        units.map((ele, index) => (
                                          <option key={index} value={ele.unit}>
                                            {ele.unit}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  ) : (
                                    <Form.Control
                                      type="text"
                                      placeholder="Add Unit"
                                      name="unit"
                                      value={data.unit}
                                      onChange={handleChange}
                                    />
                                  )}
                                  <InputGroup.Text
                                    variant="outline-secondary"
                                    onClick={toggleUnit}
                                  >
                                    {!inputUnit ? <BsPlusCircle /> : <></>}
                                  </InputGroup.Text>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                  Choose a unit
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicID"
                              >
                                <Form.Label>Thickness</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="thickness"
                                  value={data.thickness}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>Size</Form.Label>
                            <Form.Control
                              type="text"
                              name="size"
                              value={data.size}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Row md={2} sm={1}>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>Item Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="description"
                              name="itemdescription"
                              value={data.itemdescription}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>MOP(Market Operating Price)</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Market Operating Price"
                              name="mop"
                              value={data.mop}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row md={2} sm={1}>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>SKU No.</Form.Label>
                            <Form.Control
                              type="text"
                              name="sku"
                              value={data.sku}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>
                              MPN
                              <OverlayTrigger
                                placement="right"
                                overlay={renderTooltip(
                                  "Manufacturing Part Number unambiguously identifies a part number"
                                )}
                              >
                                <span>
                                  <AiOutlineQuestionCircle />
                                </span>
                              </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="mpn"
                              value={data.mpn}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Row md={2} sm={1}>
                        <Col>
                          <Form.Group className="mb-3" controlId="formBasicID">
                            <Form.Label>
                              Taxability Type <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="taxability"
                              value={data.taxability}
                              onChange={handleChange}
                              isInvalid={
                                data.taxability === "" && showEmptyError
                              }
                            >
                              <option value="">--Select--</option>
                              <option value="Exempt">Exempt</option>
                              <option value="Nil Rated">Nil Rated</option>
                              <option value="Non-GST">Non-GST</option>
                              <option value="Taxable">Taxable</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Select Taxability Type
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className={
                              data.taxability == "Taxable"
                                ? "mb-3"
                                : "mb-3 deactivated_icons"
                            }
                            controlId="formBasicID"
                          >
                            <Form.Label>
                              GST Rate %<span className="star"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="gst"
                              value={data.gst}
                              onChange={handleChange}
                              isInvalid={data.gst === "" && showEmptyError}
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter GST Rate
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row md={2} sm={1}>
                        <Col>
                          <Form.Label>HSN/SAC</Form.Label>
                          <Form.Control
                            type="number"
                            name="hsncode"
                            minLength={4}
                            maxLength={8}
                            required
                            value={data.hsncode}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <h3 className="boldItem">Additional Information</h3>
                  <Row lg={5} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>Opening Qty</Form.Label>
                        <Form.Control
                          type="number"
                          name="openingqty"
                          value={data.openingqty}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>Rate</Form.Label>
                        <Form.Control
                          type="number"
                          name="rateperunit"
                          value={data.rateperunit}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>Value</Form.Label>
                        <Form.Control
                          disabled
                          type="number"
                          name="value"
                          value={
                            (data.value = (
                              data.openingqty * data.rateperunit
                            ).toFixed(2))
                          }
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>As On Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="asondate"
                          value={data.asondate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicID">
                        <Form.Label>Reorder Point</Form.Label>
                        <Form.Control
                          type="number"
                          name="reorderpoint"
                          value={data.reorderpoint}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </Form>
              <Modal.Footer className="d-flex justify-content-start gap-2 mt-5">
                <Button className="commonBtn smallBtn" onClick={handleUpdate}>
                  Update
                </Button>
                <Button
                  className="commonBtn smallBtn cancelBtn"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditItem;
