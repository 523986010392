import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ViewVendor = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const {
    fullname,
    companyname,
    vendorcategory,
    workphoneno,
    mobileno,
    email,
    cpfullname,
    cpworkphoneno,
    cpmobileno,
    cpemail,
    gstno,
    pan,
    billingattention,
    billingcountry,
    billingstate,
    billingcity,
    billingaddress,
    billingpincode,
    shippingattention,
    shippingcountry,
    shippingstate,
    shippingcity,
    shippingaddress,
    shippingpincode,
    bankname,
    bankbranch,
    accountnumber,
    ifsccode,
    remarks,
    id,
  } = item || {};
  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">Vendor Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Primary Information</h3>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Vendor ID:</Col>
                  <Col className="viewpage-value">{id ? `VD-${id}` : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Name:</Col>
                  <Col className="viewpage-value">{fullname ? fullname : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Company Name:</Col>
                  <Col className="viewpage-value">{companyname ? companyname : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Vendor Type:</Col>
                  <Col className="viewpage-value">{vendorcategory ? vendorcategory : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Work Phone No:</Col>
                  <Col className="viewpage-value">{workphoneno ? workphoneno : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Mobile No:</Col>
                  <Col className="viewpage-value">{mobileno ? mobileno : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Email:</Col>
                  <Col className="viewpage-value">{email ? email : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">{vendorcategory=="Contractor" || vendorcategory=="Architect"?"Commision % :":null} </Col>
                  <Col className="viewpage-value">{vendorcategory=="Contractor" || vendorcategory=="Architect"?item.commission:null}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Contact Person</h3>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Full Name:</Col>
                  <Col className="viewpage-value">{cpfullname ? cpfullname : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Work Phone:</Col>
                  <Col className="viewpage-value">{cpworkphoneno ? cpworkphoneno : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Mobile No:</Col>
                  <Col className="viewpage-value">{cpmobileno ? cpmobileno : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Email:</Col>
                  <Col className="viewpage-value">{cpemail ? cpemail : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Statutory</h3>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">PAN NO:</Col>
                  <Col className="viewpage-value">{pan ? pan : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">GST NO:</Col>
                  <Col className="viewpage-value">{gstno ? gstno : "--"}</Col>
                </Row>
              </Col>
            </Row>
            
            <hr />
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Address</h3>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Billing Address</h3>
              </Col>
              <Col> <h3 className="mt-3 mb-3">Shipping Address</h3></Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Attention:</Col>
                  <Col className="viewpage-value">{billingattention ? billingattention : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Attention:</Col>
                  <Col className="viewpage-value">{shippingattention ? shippingattention : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Country:</Col>
                  <Col className="viewpage-value">{billingcountry ? billingcountry : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Country:</Col>
                  <Col className="viewpage-value">{shippingcountry ? shippingcountry : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">State:</Col>
                  <Col className="viewpage-value">{billingstate ? billingstate : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">State:</Col>
                  <Col className="viewpage-value">{shippingstate ? shippingstate : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">City:</Col>
                  <Col className="viewpage-value">{billingcity ? billingcity : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">City:</Col>
                  <Col className="viewpage-value">{shippingcity ? shippingcity : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Address:</Col>
                  <Col className="viewpage-value">{billingaddress ? billingaddress : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Address:</Col>
                  <Col className="viewpage-value">{shippingaddress ? shippingaddress : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Pin Code:</Col>
                  <Col className="viewpage-value">{billingpincode ? billingpincode : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Pin Code:</Col>
                  <Col className="viewpage-value">{shippingpincode ? shippingpincode : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h3 className="mt-3 mb-3">Bank information</h3>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Bank Name:</Col>
                  <Col className="viewpage-value">{bankname ? bankname : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Branch:</Col>
                  <Col className="viewpage-value">{bankbranch ? bankbranch : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Account No:</Col>
                  <Col className="viewpage-value">{accountnumber ? accountnumber : "--"}</Col>
                </Row>
              </Col>
              <Col>
              <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">IFSC CODE:</Col>
                  <Col className="viewpage-value">{ifsccode ? ifsccode : "--"}</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewVendor;
