import React from 'react'
import { Modal } from 'react-bootstrap'

const TicketModal = ({ticketModalShow,handleTicketModalShow,handleTicketModalClose,countLength}) => {
  return (
    <Modal
      show={ticketModalShow}
      onHide={handleTicketModalClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{fontSize:"14px"}}
    >
      <Modal.Header closeButton style={{margin:"5px 0px 15px 0px"}}>
        
        <span className="form-title" id="contained-modal-title-vcenter" style={{fontWeight:"bold",fontSize:"20px"}}>Issue Raised Successful</span>
      </Modal.Header>
      <Modal.Body style={{textAlign:"center"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<path d="M20 2.5C24.6413 2.5 29.0925 4.34374 32.3744 7.62563C35.6563 10.9075 37.5 15.3587 37.5 20C37.5 24.6413 35.6563 29.0925 32.3744 32.3744C29.0925 35.6563 24.6413 37.5 20 37.5C15.3587 37.5 10.9075 35.6563 7.62563 32.3744C4.34374 29.0925 2.5 24.6413 2.5 20C2.5 15.3587 4.34374 10.9075 7.62563 7.62563C10.9075 4.34374 15.3587 2.5 20 2.5ZM17.82 23.4525L13.9325 19.5625C13.7931 19.4231 13.6277 19.3126 13.4456 19.2372C13.2635 19.1617 13.0683 19.1229 12.8713 19.1229C12.6742 19.1229 12.479 19.1617 12.2969 19.2372C12.1148 19.3126 11.9494 19.4231 11.81 19.5625C11.5285 19.844 11.3704 20.2257 11.3704 20.6238C11.3704 21.0218 11.5285 21.4035 11.81 21.685L16.76 26.635C16.899 26.7751 17.0643 26.8862 17.2464 26.9621C17.4286 27.038 17.6239 27.077 17.8212 27.077C18.0186 27.077 18.2139 27.038 18.3961 26.9621C18.5782 26.8862 18.7435 26.7751 18.8825 26.635L29.1325 16.3825C29.2737 16.2437 29.3861 16.0783 29.4631 15.8959C29.5401 15.7135 29.5802 15.5176 29.5811 15.3196C29.582 15.1216 29.5437 14.9254 29.4684 14.7422C29.3931 14.5591 29.2823 14.3927 29.1424 14.2526C29.0025 14.1125 28.8362 14.0015 28.6531 13.926C28.4701 13.8505 28.2739 13.812 28.0759 13.8126C27.8779 13.8133 27.682 13.8532 27.4995 13.93C27.317 14.0068 27.1515 14.1189 27.0125 14.26L17.82 23.4525Z" fill="#039855" />
</svg>

<br /><br />
        <h5>
          Ticket ID:
        </h5>
        <h5 style={{margin:"10px 0px 20px 0px"}}>{countLength}</h5>
        <p>Your issue has been successfully raised.We will investigate the matter and provide a response to the email address you have provided</p>
      </Modal.Body>
      
    </Modal>
  )
}

export default TicketModal