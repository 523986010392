import {
  companyDeletePost,
  companyPagiDataPost,
  companyStatusChangePost,
  companyAddPost,
  companyUpdatePost,
} from "../../constants/api";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../constants/toastAlert";
import axios from "axios";
import {
  DELETE_COMPANY_DATA,
  FETCH_COMPANY_DATA,
  STATUS_CHANGE,
  ADD_COMPANY_DATA,
  UPDATE_COMPANY,
} from "../actionTypes/company.actiontypes";

import { RELOAD } from "../actionTypes/load.actiontypes";

//Get all company data using pagination wise
export const companyData = (backendData, setLoading) => async (dispatch) => {
  try {
    const res = await axios.post(companyPagiDataPost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
    if (initialData) {
      dispatch({ type: FETCH_COMPANY_DATA, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching company data ${error}`);
    notifyError(error.message);
  }
};

//Delete company data dispatcher
export const deleteCompanyData =
  (backendData, handleClose) => async (dispatch) => {
    try {
      const res = await axios.post(companyDeletePost, backendData);
      if (res.data.message == "Company deleted successfully") {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleClose();
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.log(`Error in deleting company data ${error}`);
      notifyError(error.message);
    }
  };

//Status change dispatcher
export const statusChange = (backendData) => async (dispatch) => {
  try {
    // dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(companyStatusChangePost, backendData);
    if (res.data.message === "Company Activated") {
      notifySuccess(res.data.message);
    } else {
      notifyWarning(res.data.message);
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in changing company status ${error}`);
    notifyError(error.message);
  }
};

//Acion dispatcher for adding new company data
export const addCompany = (companydata, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(companyAddPost, companydata);
    if (res.data.message === "Company added successfully") {
      dispatch({ type: ADD_COMPANY_DATA });
      notifySuccess(res.data.message);
      navigate("/admin/companyManagement/");
    } else {
      notifyError(res.data.message);
      dispatch({ type: RELOAD, payload: false });
    }
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    notifyError(error.response.data.message);
    dispatch({ type: RELOAD, payload: false });
  }
};

//Acion dispatcher for updating new company data
export const updateCompany = (companydata, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(companyUpdatePost, companydata);
    if (res.data.message === "Company updated successfully") {
      dispatch({ type: UPDATE_COMPANY });
      notifySuccess(res.data.message);
      navigate("/admin/companyManagement/");
    } else {
      notifyError(res.data.message);
      dispatch({ type: RELOAD, payload: false });
    }
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in updating company data ${error}`);
    notifyError(error.response.data.message);
    dispatch({ type: RELOAD, payload: false });
  }
};
