//login with otp starts here

// import React, { useEffect, useState } from "react";
// import {
//   Badge,
//   Button,
//   Col,
//   Container,
//   Form,
//   FormLabel,
//   InputGroup,
//   Row,
//   Spinner,
// } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import sanviLogo from "../../assets/Saanvi_enterprises.png";
// import { BsEye, BsEyeSlash } from "react-icons/bs";
// import { userLogin, userLogout } from "../../redux/actions/auth.actions";
// import { notifyError, notifySuccess } from "../../constants/toastAlert";
// import { useNavigate } from "react-router-dom";
// import { LOGOUT_USER } from "../../redux/actionTypes/auth.actiontypes";
// import axios from "axios";
// import OtpModal from "./OtpModal";
// import { loginUserPost } from "../../constants/api";

// function Login() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [showModal, setShowModal] = useState(false);
//   const[loading,setLoading]=useState(false);
//    // for ip address check
//    const [ipAddress, setIpAddress] = useState(null);

//   const auth = useSelector((state) => state.authManager);
//   const [userRole, setuserRole] = useState("Company");
//   const [showEmptyError, setShowEmptyError] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [data,setData]=useState({
//     companyaddress:{},
//     data:[],
//     token:"",
//     otp:""
//   })
//   const [cred, setCred] = useState({
//     userid: "",
//     password: "",
//     financialyear: "",
//     usertype: userRole,
//     ip: "",
//   });

 

  
//   const handleOpenModal = () => {
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleSignIn = (otp) => {
//     if(otp==data.otp){
//       dispatch(userLogin(data,cred, navigate));
//       setShowModal(false);
//     }else{
//       notifyError("Invalid Otp..");
//     }
    
//   };

//   useEffect(() => {
//     axios
//       .get("https://api.ipify.org?format=json")
//       .then((response) => {
//         // setIpAddress(response.data.ip);

//         // check ip address
//         async function getIpInfo(ip) {
//           try {
//             const response = await axios.get(`http://ip-api.com/json/${ip}`);
//             const { city, regionName, country, isp } = response.data;
//             const device = "Unknown"; // You may need to implement logic to determine device based on IP, this is just a placeholder
//             return {
//               city,
//               regionName,
//               country,
//               device,
//               isp,
//             };
//           } catch (error) {
//             console.error("Error fetching IP information:", error);
//             return null;
//           }
//         }

//         // Example usage
//         const ipAddress = response.data.ip; // Replace with the IP address you want to lookup
//         getIpInfo(ipAddress).then((ipInfo) => {
//           if (ipInfo) {

//             setCred({ ...cred, ip: response.data.ip, city:ipInfo.city, regionName:ipInfo.regionName, country:ipInfo.country, isp:ipInfo.isp });
//           } else {
//             
//           }
//         });
//         // check ip address

       
//       })
//       .catch((error) => {
//         console.error("Error fetching IP:", error);
//       });
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     setCred({ ...cred, [name]: value });
//   };

//   // const handleSubmit = () => {
//   //   if (cred.userid == "" || cred.password == "" || cred.financialyear == "") {
//   //     setShowEmptyError(true);
//   //     return notifyError("You have empty field");
//   //   }

//   //   dispatch(userLogin(cred, navigate));
//   // };

//   const handleLogin= async()=>{

//     if (cred.userid == "" || cred.password == "" || cred.financialyear == "") {
//       setShowEmptyError(true);
//       return notifyError("You have empty field");
//     }

//     try {
//       setLoading(true)
//       let res = await axios.post(loginUserPost, cred);
      
//       if(res.data.token){
//         setLoading(false)
//         setData((prev)=>({...prev,...res.data}))
         
//         notifySuccess("Otp Sent to Email");
//         handleOpenModal();
//       }else{
//         setLoading(false)
//         notifyError("Something Went Wrong");
//       }
//     } catch (error) {
//       setLoading(false)
//       console.log(error);
//       notifyError(error.response.data.message);
//     }

//   }

//   const today = new Date();
//   const formatDate = (date) => {
//     return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
//       2,
//       "0"
//     )}-${String(date.getDate()).padStart(2, "0")}`;
//   };

//   const currentYear = today.getFullYear();

//   let financialYearStart;
//   let financialYearEnd;

//   if (today.getMonth() >= 3) {
//     financialYearStart = new Date(currentYear, 3, 1);
//   } else {
//     financialYearStart = new Date(currentYear - 1, 3, 1);
//   }
//   if (today.getMonth() >= 3) {
//     financialYearEnd = new Date(currentYear + 1, 2, 31);
//   } else {
//     financialYearEnd = new Date(currentYear, 2, 31);
//   }

//   const formattedStartOfYear = formatDate(financialYearStart);
//   const formattedEndOfYear = formatDate(financialYearEnd);
//   const startyear = formattedStartOfYear.split("-").map(Number)[0];
//   const endyear = formattedEndOfYear.split("-").map(Number)[0];

//   useEffect(() => {
//     setCred((prev) => ({ ...prev, usertype: userRole }));
//   }, [userRole]);

//   return (
//     <Container fluid>
//       <Row className="auth-page ">
//         <Col className="login-image-container">
//           <div className="login-text">
//             <h1>Welcome!</h1>
//             <h2>Continue as {userRole}</h2>
//           </div>
//         </Col>
//         <Col className="login-form-container">
//           <Row>
//             <h2 className="text-right">
//               {" "}
//               <img src={sanviLogo} width={"200px"} alt="Logo" />
//               {/* &nbsp;Saanvi Enterprises */}
//             </h2>
//           </Row>
//           <Row className="form-container-login">
//             <Row>
//               <h1 className="login-heading">Sign in to</h1>
//             </Row>
//             <Row className="badge-container">
//               <Col onClick={() => setuserRole("Company")}>
//                 {userRole == "Company" ? (
//                   <Badge pill className="badges">
//                     Company
//                   </Badge>
//                 ) : (
//                   <span className="badge-nav">Company</span>
//                 )}
//               </Col>
//               <Col onClick={() => setuserRole("Franchise")}>
//                 {userRole == "Franchise" ? (
//                   <Badge pill className="badges">
//                     Franchise
//                   </Badge>
//                 ) : (
//                   <span className="badge-nav">Franchise</span>
//                 )}
//               </Col>
//               <Col onClick={() => setuserRole("Sub-Dealer")}>
//                 {userRole == "Sub-Dealer" ? (
//                   <Badge pill className="badges">
//                     Sub-Dealer
//                   </Badge>
//                 ) : (
//                   <span className="badge-nav">Sub-Dealer</span>
//                 )}
//               </Col>
//             </Row>
//             <Row>
//               <Form>
//                 <Container fluid>
//                   <Row>
//                     <Form.Group className="mb-4">
//                       <Form.Label className="form-label">
//                         User Id <span className="star">*</span>
//                       </Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter User Id"
//                         name="userid"
//                         className="login-inputs"
//                         value={cred.userid}
//                         onChange={handleChange}
//                         autoComplete="off"
//                         maxLength={12}
//                         pattern="[A-Za-z0-9]*"
//                         title="Please enter only alphanumeric characters"
//                         isInvalid={cred.userid === "" && showEmptyError}
//                       />
//                     </Form.Group>
//                   </Row>
//                   <Row>
//                     {/* <Form.Group className="mb-4">
//                       <Form.Label className="form-label">Password <span className="star">*</span></Form.Label>
//                       <Form.Control
//                         type="password"
//                         placeholder="Enter Password"
//                         name="password"
//                         className="login-inputs"
//                         value={cred.password}
//                         onChange={handleChange}
//                         autoComplete="off"
//                         isInvalid={cred.password == "" && showEmptyError}
//                       />
//                     </Form.Group> */}
//                     <Form.Group controlId="formBasicPassword" className="mb-4">
//                       <Form.Label className="form-label">
//                         Password <span className="star"> *</span>
//                       </Form.Label>
//                       <InputGroup hasValidation>
//                         <Form.Control
//                           className="login-inputs"
//                           type={showPassword ? "text" : "password"}
//                           placeholder="Password"
//                           name="password"
//                           value={cred.password}
//                           onChange={handleChange}
//                           autoComplete="password"
//                           isInvalid={cred.password === "" && showEmptyError}
//                           required
//                         />
//                         <InputGroup.Text
//                           variant="outline-secondary"
//                           onClick={() => setShowPassword(!showPassword)}
//                           className="login-inputs"
//                         >
//                           {showPassword ? <BsEye /> : <BsEyeSlash />}
//                         </InputGroup.Text>
//                         <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
//                       </InputGroup>
//                     </Form.Group>
//                   </Row>

//                   <Row>
//                     <Form.Group className="mb-4">
//                       <Form.Label className="form-label">
//                         Financial Year <span className="star">*</span>
//                       </Form.Label>
//                       <Form.Select
//                         name="financialyear"
//                         className="login-inputs"
//                         onChange={handleChange}
//                         value={cred.financialyear}
//                         isInvalid={cred.financialyear == "" && showEmptyError}
//                       >
//                         <option value="">Select</option>
//                         <option value={`${startyear - 1}-${endyear - 1}`}>
//                           {startyear - 1}-{endyear - 1}
//                         </option>
//                         <option value={`${startyear}-${endyear}`}>
//                           {startyear}-{endyear}
//                         </option>
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>
//                   <Row className="mb-4 d-flex justify-content-between " >
//                     <Col style={{display:"none"}}>
//                       <Form.Check
//                         type="checkbox"
//                         className="form-label"
//                         id="default-checkbox"
//                         label="Remember Me"
//                         inline
//                       />
//                     </Col>
//                     <Col>
//                       <p className="form-label support text-right" style={{display:"none"}}>
//                         Forgot Password?
//                       </p>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col className="d-grid gap-2 mb-4">
//                       <Button
//                         size="lg"
//                         className="login-btn login-inputs"
//                         onClick={handleLogin}
//                       >
//                         {loading ? (
//                           <Spinner
//                             as="span"
//                             animation="border"
//                             size="lg"
//                             role="status"
//                             aria-hidden="true"
//                             aria-disabled
//                           />
//                         ) : (
//                           "Login"
//                         )}
//                       </Button>
//                     </Col>
                   
//                   </Row>
//                   <Row>
//                     <Col className="mb-4 form-label text-center">
//                       <span style={{display:"none"}}>Having Trouble ? </span>
//                       <span className="support" style={{display:"none"}}>Get Support</span>
//                     </Col>
//                   </Row>
//                 </Container>
//               </Form>
//             </Row>
//           </Row>
//         </Col>
//       </Row>
//       <OtpModal
//         show={showModal}
//         handleClose={handleCloseModal}
//         handleSignIn={handleSignIn}
//       />
//     </Container>
//   );
// }

// export default Login;




//login with otp ends here



//login without otp starts here
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  FormLabel,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sanviLogo from "../../assets/venturesathi-logo.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { userLogin, userLogout } from "../../redux/actions/auth.actions";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { useNavigate } from "react-router-dom";
import { LOGOUT_USER } from "../../redux/actionTypes/auth.actiontypes";
import axios from "axios";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authManager);
  const [userRole, setuserRole] = useState("Company");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [cred, setCred] = useState({
    userid: "",
    password: "",
    financialyear: "",
    usertype: userRole,
    ip: "",
  });

  // for ip address check
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        // setIpAddress(response.data.ip);

        // check ip address
        async function getIpInfo(ip) {
          try {
            // const response = await axios.get(`http://ip-api.com/json/${ip}`);
            const response = await axios.get(`https://ipapi.co/${ip}/json/`);
          
            const { city, region, country, org } = response.data;
            const device = "Unknown"; // You may need to implement logic to determine device based on IP, this is just a placeholder
            return {
              city,
              regionName:region,
              country,
              device,
              isp:org,
            };
          } catch (error) {
            console.error("Error fetching IP information:", error);
            return null;
          }
        }

        // Example usage
        const ipAddress = response.data.ip; // Replace with the IP address you want to lookup
        getIpInfo(ipAddress).then((ipInfo) => {
          if (ipInfo) {

            setCred({ ...cred, ip: response.data.ip, city:ipInfo.city, regionName:ipInfo.regionName, country:ipInfo.country, isp:ipInfo.isp });
          } else {
            console.log("Failed to fetch IP information.");
          }
        });
        // check ip address

       
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCred({ ...cred, [name]: value });
  };

  const handleSubmit = () => {
    if (cred.userid == "" || cred.password == "" || cred.financialyear == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    dispatch(userLogin(cred, navigate));
  };

  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const currentYear = today.getFullYear();

  let financialYearStart;
  let financialYearEnd;

  if (today.getMonth() >= 3) {
    financialYearStart = new Date(currentYear, 3, 1);
  } else {
    financialYearStart = new Date(currentYear - 1, 3, 1);
  }
  if (today.getMonth() >= 3) {
    financialYearEnd = new Date(currentYear + 1, 2, 31);
  } else {
    financialYearEnd = new Date(currentYear, 2, 31);
  }

  const formattedStartOfYear = formatDate(financialYearStart);
  const formattedEndOfYear = formatDate(financialYearEnd);
  const startyear = formattedStartOfYear.split("-").map(Number)[0];
  const endyear = formattedEndOfYear.split("-").map(Number)[0];

  useEffect(() => {
    setCred((prev) => ({ ...prev, usertype: userRole }));
  }, [userRole]);

  return (
    <Container fluid>
      <Row className="auth-page ">
        <Col className={`login-image-container ${userRole}`}>
          <div className="login-text">
            <h1>Welcome!</h1>
            <h2>Continue as {userRole}</h2>
          </div>
        </Col>
        <Col className="login-form-container">
          <Row>
            <h2 className="text-right">
              {" "}
              <img src={sanviLogo} width={"200px"} alt="Logo" />
              {/* &nbsp;Saanvi Enterprises */}
            </h2>
          </Row>
          <Row className="form-container-login">
            <Row>
              <h1 className="login-heading">Sign in to</h1>
            </Row>
            <Row className="badge-container">
              <Col onClick={() => setuserRole("Company")}>
                {userRole == "Company" ? (
                  <Badge pill className="badges">
                    Company
                  </Badge>
                ) : (
                  <span className="badge-nav">Company</span>
                )}
              </Col>
              <Col onClick={() => setuserRole("Franchise")}>
                {userRole == "Franchise" ? (
                  <Badge pill className="badges">
                    Franchise
                  </Badge>
                ) : (
                  <span className="badge-nav">Franchise</span>
                )}
              </Col>
              <Col onClick={() => setuserRole("Sub-Dealer")}>
                {userRole == "Sub-Dealer" ? (
                  <Badge pill className="badges">
                    Sub-Dealer
                  </Badge>
                ) : (
                  <span className="badge-nav">Sub-Dealer</span>
                )}
              </Col>
            </Row>
            <Row>
              <Form>
                <Container fluid>
                  <Row>
                    <Form.Group className="mb-4">
                      <Form.Label className="form-label">
                        User Id <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter User Id"
                        name="userid"
                        className="login-inputs"
                        value={cred.userid}
                        onChange={handleChange}
                        autoComplete="off"
                        maxLength={12}
                        pattern="[A-Za-z0-9]*"
                        title="Please enter only alphanumeric characters"
                        isInvalid={cred.userid === "" && showEmptyError}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    {/* <Form.Group className="mb-4">
                      <Form.Label className="form-label">Password <span className="star">*</span></Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Password"
                        name="password"
                        className="login-inputs"
                        value={cred.password}
                        onChange={handleChange}
                        autoComplete="off"
                        isInvalid={cred.password == "" && showEmptyError}
                      />
                    </Form.Group> */}
                    <Form.Group controlId="formBasicPassword" className="mb-4">
                      <Form.Label className="form-label">
                        Password <span className="star"> *</span>
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          className="login-inputs"
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={cred.password}
                          onChange={handleChange}
                          autoComplete="password"
                          isInvalid={cred.password === "" && showEmptyError}
                          required
                        />
                        <InputGroup.Text
                          variant="outline-secondary"
                          onClick={() => setShowPassword(!showPassword)}
                          className="login-inputs"
                        >
                          {showPassword ? <BsEye /> : <BsEyeSlash />}
                        </InputGroup.Text>
                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group className="mb-4">
                      <Form.Label className="form-label">
                        Financial Year <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="financialyear"
                        className="login-inputs"
                        onChange={handleChange}
                        value={cred.financialyear}
                        isInvalid={cred.financialyear == "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        <option value={`${startyear - 1}-${endyear - 1}`}>
                          {startyear - 1}-{endyear - 1}
                        </option>
                        <option value={`${startyear}-${endyear}`}>
                          {startyear}-{endyear}
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4 d-flex justify-content-between " >
                    <Col style={{display:"none"}}>
                      <Form.Check
                        type="checkbox"
                        className="form-label"
                        id="default-checkbox"
                        label="Remember Me"
                        inline
                      />
                    </Col>
                    <Col>
                      <p className="form-label support text-right" style={{display:"none"}}>
                        Forgot Password?
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-grid gap-2 mb-4">
                      <Button
                        size="lg"
                        className="login-btn login-inputs"
                        onClick={handleSubmit}
                      >
                        {auth.loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-4 form-label text-center">
                      <span style={{display:"none"}}>Having Trouble ? </span>
                      <span className="support" style={{display:"none"}}>Get Support</span>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;


//login without otp ends here