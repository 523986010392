import React from "react";
import ListReceipt from "./ListReceipt";
import { Col, Container, Row } from "react-bootstrap";

function Receipt() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <ListReceipt />
        </Col>
      </Row>
    </Container>
  );
}

export default Receipt;
