import React from 'react'

function ListTableName({name}) {
    return (
        <p className="section-title form-section ptag">
            List of All <span className="form-title">{name}</span>
        </p>
    )
}

export default ListTableName