import React from "react";
import { extractAndFormatURLPart } from "../../constants/breadCrumb";
import ListAuditLog from "./ListAuditLog";
import { Col, Container, Row } from "react-bootstrap";

const AuditLog = () => {
  const URL = extractAndFormatURLPart("auditLog");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListAuditLog />
        </Col>
      </Row>
    </Container>
  );
};

export default AuditLog;
