import React, { useEffect, useState } from 'react'
import TableStockReport from './TableStockReport'
import { Col, Container, Row } from 'react-bootstrap';

function StockReports() {
    const [extractedPart, setExtractedPart] = useState("");
    //const [currentTab, setCurrentTab] = useState("Receive Goods");
    useEffect(() => {
      // document.title = 'Venturesathi Items Stock report';
      const currentURL = window.location.href; // Get the current URL
      const urlParts = currentURL.split("/"); // Split the URL by "/"
      const itemIndex = urlParts.indexOf("items"); // Find the index of "adminPanel"
      if (itemIndex !== -1 && itemIndex < urlParts.length - 1) {
        // Check if "adminPanel" is in the URL and if there's a part after it
        const desiredPart = urlParts.slice(itemIndex).join("/"); // Get the part after "adminPanel"
        const formattedPart = desiredPart
          .split("/")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" / ");
          const spacedpart = formattedPart.replace(/([a-z])([A-Z])/g, "$1 $2");
        setExtractedPart(spacedpart); // Set the extracted part in the component's state
      } else {
        console.log("The URL does not contain 'items'");
      }
    }, []);
  
  
  return (
    <Container fluid>
    <Row>
      <Col className="colStyle">
        <span >{extractedPart}</span>
      </Col>
      <Col></Col>
    </Row>
    <Row>
      <Col>
      <TableStockReport/>
      </Col>
    </Row>
  </Container>
  )
}

export default StockReports