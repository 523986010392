import { FETCH_INVOICE_OR_CHALLAN_RECEIVE, FETCH_RECEIVED_ITEM } from "../actionTypes/mtin.actiontypes";

const initialState = {
    receivegoodPagiData: {},
    invoiceChallanReceive:""
  };
  
  export const mtinReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_RECEIVED_ITEM: {
        return { ...state, receivegoodPagiData: { ...payload } };
      }
      case FETCH_INVOICE_OR_CHALLAN_RECEIVE: {
        return { ...state, invoiceChallanReceive: payload };
      }
  
  
      default: {
        return state;
      }
    }
  };