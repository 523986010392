import {
  FETCH_PAYMENT_COUNT,
  FETCH_PAYMENT_DATA,
} from "../actionTypes/payment.actiontypes";

const initialState = {
  paymentPagiData: {},
  paymentCount: 0,
};

export const paymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PAYMENT_DATA: {
      return { ...state, paymentPagiData: { ...payload } };
    }

    case FETCH_PAYMENT_COUNT: {
      return { ...state, paymentCount: payload };
    }

    default: {
      return state;
    }
  }
};
