
import {
    DELETE_CATEGORY,
    ADD_CATEGORY,
    FETCH_CATEGORY,
    UPDATE_ITEM,
    DELETE_ITEM,
    ADD_ITEM,
    FETCH_ITEM,
    ADD_OR_EDIT_ACCESS,
    FETCH_ACCESS,
    SWITCH_USER,
    UPDATE_USER,
    DELETE_USER,
    FETCH_USER,
    ADD_USER,
    UPDATE_DEPARTMENT,
    DELETE_DEPARTMENT,
    ADD_DEPARTMENT,
    FETCH_DEPARTMENT,
    UPDATE_DESIGNATION,
    DELETE_DESIGNATION,
    ADD_DESIGNATION,
    FETCH_DESIGNATION,
  } from "../actionTypes/module.actiontypes";

  
  const userState = {

    getUser: {},

  };
  
  //
  export const userReducer = (state = userState, { type, payload }) => {
    switch (type) {
      case FETCH_USER: {
        return { ...state, getUser: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };