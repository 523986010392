export function getCurrentPreviousYears() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    let currentFinancialYear;

    // Determine the current financial year based on the month
    if (currentMonth >= 4) {
        // Financial year is from April to March
        currentFinancialYear = `${currentYear}-${currentYear + 1}`;
    } else {
        currentFinancialYear = `${currentYear - 1}-${currentYear}`;
    }

    const previousYearShort = currentFinancialYear.split("-")[0].toString().slice(-2);
    const currentYearShort = currentFinancialYear.split("-")[1].toString().slice(-2);
    
    return `${previousYearShort}-${currentYearShort}`

}
