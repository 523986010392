import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const StatutoryForm = ({ data, handleChange, showEmptyError }) => {
  return (
    <Container fluid>
      <Row className="form-container">
        <Col sm={12}>
          <h3>
            <span className="form-title"> Statutory Details</span>
          </h3>
        </Col>
        <hr />
        <Form className="form-lable-name">
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  GST No.
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter GSTIN No."
                  name="gstno"
                  value={data.gstno}
                  onChange={handleChange}
                  // isInvalid={data.vendorcategory=="Supplier" && showEmptyError }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  PAN 
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter PAN No."
                  name="pan"
                  value={data.pan}
                  onChange={handleChange}
                  // isInvalid={!data.pan && showEmptyError}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default StatutoryForm;
