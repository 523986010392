import React from 'react';
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

function Cards({ location, title, count, icon, iconname }) {
    const navigate = useNavigate();

    return (
        <Card className="card-stats" onClick={() => navigate(location)}>
            <Card.Body>
                <Row>
                    <Col>
                        <div className="card-container">
                            <div>
                                <h4 className="card-title">{title}</h4>
                                <p className="count">{count}</p>
                            </div>
                            <div className={`icon-big ${iconname}`}>
                                {icon}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default Cards