export const sampleData = [
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Sofa",
      price: 599,
      description: "A comfortable sofa for your living room."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Bed",
      price: 799,
      description: "A cozy bed for a good night's sleep."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Dining Table",
      price: 299,
      description: "A stylish dining table for your family meals."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "TV Stand",
      price: 199,
      description: "A sleek TV stand for your entertainment center."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Coffee Table",
      price: 149,
      description: "A modern coffee table for your living room."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Wardrobe",
      price: 499,
      description: "A spacious wardrobe for your clothes."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Desk",
      price: 249,
      description: "A functional desk for your home office."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Bookshelf",
      price: 179,
      description: "A stylish bookshelf for your book collection."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Lamp",
      price: 49,
      description: "A decorative lamp to brighten up your space."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Rug",
      price: 99,
      description: "A cozy rug to add warmth to your floors."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Chair",
      price: 79,
      description: "A comfortable chair for your reading nook."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Side Table",
      price: 89,
      description: "A small side table for your living room."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Mirror",
      price: 69,
      description: "A decorative mirror to enhance your space."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Curtains",
      price: 39,
      description: "Stylish curtains to add privacy to your home."
    },
    {
      image: "https://st2.depositphotos.com/1203063/6105/i/380/depositphotos_61054991-stock-photo-modern-interior-with-beautiful-furniture.jpg",
      itemname: "Clock",
      price: 29,
      description: "A decorative clock to adorn your walls."
    }
  ];
  

  export const supplyTypeObj={
    "O":"Outward",
    "I":"Inward"
  }

  export const subSupplyTypeObj={
    "1":"Supply",
    "2":"Import",
    "3":"Export",
    "4":"Job Work",
    "5":"For Own Use",
    "6":"job work Returns",
    "7":"Sales Return",
    "8":"Others",
    "9":"SKD/CKD/Lots",
    "10":"Line Sales",
    "11":"Recipient Not Known",
    "12":"Exhibition or Fairs"
  }

  export const transactionTypeObj={
    1:"Regular",
    2:"Bill To - Ship To",
    3:"Bill From - Dispatch From",
    4:"Combination of 2 and 3"
  }

  export const transportModeObj={
    1:"Road",
    2:"Rail",
    3:"Air",
    4:"Ship",
    5:"inTransit"
  }

  export const vehicleTypeobj={
    "R":"Regular",
    "O":"ODC(Over Dimentional Cargo)"
  }