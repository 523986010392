import React from "react";
import { Form } from "react-bootstrap";

const FilterField = ({
  label,
  islable,
  selectedFilterValue,
  handleSelectedFilter,
  emptyValue,
  filterWords,
}) => {
  return (
    <Form.Group className="d-flex gap-2" controlId="formMonth">
      <Form.Label
        className={islable == true ? "w-50 mt-3 text-color" : "w-50 mt-3 deactivated_icons"}
      >
        {label}
      </Form.Label>
      <Form.Select
        className="mt-2 p-2"
        value={selectedFilterValue}
        onChange={handleSelectedFilter}
      >
        <option value="">{emptyValue}</option>
        {filterWords.length > 0 ? (
          filterWords.map((words,index) => (
            <option key={index} className={words.className} value={words.value}>
              {words.text}
            </option>
          ))
        ) : (
          <option>No filter value found</option>
        )}
      </Form.Select>
    </Form.Group>
  );
};

export default FilterField;
