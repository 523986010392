export const FETCH_EMPLOYEE_DATA = "FETCH_EMPLOYEE_DATA";

export const ADD_EMPLOYEE_DATA = "ADD_EMPLOYEE_DATA";

export const SWITCH_EMPLOYEE_STATUS = "SWITCH_EMPLOYEE_STATUS";

export const UPDATE_EMPLOYEE_DATA = "UPDATE_EMPLOYEE_DATA";

export const FETCH_EMPLOYEE_IMAGE = "FETCH_EMPLOYEE_IMAGE";

export const FETCH_ID_IMAGE = "FETCH_ID_IMAGE";

export const FETCH_AADHAR_IMAGE = "FETCH_AADHAR_IMAGE";
