import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import { ImEye } from "react-icons/im";
import { FcCancel } from "react-icons/fc";
import { notifyError, notifySuccess } from "../../../../constants/toastAlert";
import { FaTruckMonster } from "react-icons/fa";
import CancelEwaybill from "./CancelEwaybill";
import { IoEyeOutline } from "react-icons/io5";
import "./Ewaybill.css"
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";

function ListTable() {
  const navigate = useNavigate();
  const URL = extractAndFormatURLPart("ewaybill");
  const loadData = useSelector((state) => state.loadReducer);
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userid = localData.userid;
  let branchid = localData.branchid;
  let companyid = localData.companyid;
  let financialyear = localStorage.financialyear;
  // const financialyear = JSON.parse(financialyearString);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelEwb, setCancelEwb] = useState("");
  const [data, setData] = useState([]);
  const handleCancelModalClose = () => setShowCancelModal(false);

  const handleCancelModalShow = (ewaybillno) => {
    setCancelEwb(ewaybillno);
    setShowCancelModal(true);
  };
  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/ewaybill/get`, { companyid, financialyear })
      .then((res) => {
        setData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));
  }, []);

  // const handleCanceleEwb = (ewaybillno) => {
  //   // alert(ewaybillno)
  //   axios
  //     .post(`https://erp.venturesathi.co.in/api/v1/ewaybill/cancel`, { ewaybillno })
  //     .then((res) => {
  //       if (res.data.status_cd == "1") {
  //         notifySuccess("EWB has been canceled successfully");
  //       } else {
  //         notifyError(res.data.error.message);
  //       }
  //     });
  // };

  const handleCanceleEwb = async () => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/ewaybill/cancel`, { ewaybillno: cancelEwb })
      .then((res) => {
        if (res.data.status_cd == "1") {
          notifySuccess("EWB has been canceled successfully");
        } else {
          notifyError(res.data.error.message);
        }
      });
  };


  const partBVehicle = (invoiceno, ewaybillno) => {
    navigate("/ewaybill/partb", { state: { invoiceno: invoiceno, ewaybillno: ewaybillno } });
  }

  return (
    <Container >
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row className="table-wrapper-container">
        <Col lg={10}>
          <p className="section-title form-section ptag">
            List of All <span className="form-title">E-waybills</span>
          </p>
        </Col>
        <Col lg={2}>
          <Button
            className="commonBtn mt-2"
            onClick={() => navigate("/ewaybill/add")}
          >
            Add New
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="table-main-container">
          <Table responsive>
            <thead className="tableContainer">
              <tr>
                <th>S.N.</th>
                <th>Invoice No</th>
                <th>E-waybill no</th>
                <th>Bill Date</th>
                <th>Valid Upto</th>
                <th>Vehilce Updated Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loadData ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) : data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.invoiceno}</td>
                    <td>{item.ewaybillno}</td>
                    <td>{item.ewaybilldate}</td>
                    <td>{item.validupto}</td>
                    <td>{item.vehupddate}</td>
                    <td
                      style={
                        item.status == "ACT"
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {item.status == "ACT"
                        ? "Active"
                        : item.status == "CNL"
                          ? "Canceled"
                          : ""}
                    </td>

                    {/* <td onClick={()=>navigate(`/ewaybill/view/${item.ewaybillno}`)}><ImEye /></td> */}
                    <td>
                      <div
                        style={{ display: "flex", gap: "13px", height: "100%" }}
                      >
                        {/* ImEye icon */}
                        <div
                          onClick={() =>
                            navigate(`/ewaybill/view/${item.ewaybillno}`)
                          }
                          className="clickable-icon"
                        >
                          {/* <ImEye /> */}
                          <IoEyeOutline />

                        </div>
                        <div
                          onClick={() => handleCancelModalShow(item.ewaybillno)}
                          className="clickable-icon"
                        >
                          <FcCancel />
                        </div>
                        <div
                          onClick={() => partBVehicle(item.invoiceno, item.ewaybillno)}
                          className="clickable-icon"
                        >
                          <FaTruckMonster />

                        </div>
                        {/* FaTrainTram icon */}
                        {/* {!item.ewbno ? (
          <div
            onClick={() => navigate(`/einvoice/ewb/${item.irn}`)}
            className="clickable-icon"
          >
            <FaTrainTram />
          </div>
        ) : (
          <div className="disabled-icon">
            <FaTrainTram />
          </div>
        )}
     
     */}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">
                    <div className="datanotfound-center-text">
                      No Record Found!! 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <CancelEwaybill
        show={showCancelModal}
        handleClose={handleCancelModalClose}
        handleCancel={handleCanceleEwb}
      />

    </Container>
  );
}

export default ListTable;
