import React from 'react'
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import { Col, Container, Row } from 'react-bootstrap';
import ListSeries from './ListSeries';

const Series = () => {
    const URL = extractAndFormatURLPart("inventory");
    return (
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListSeries />
          </Col>
        </Row>
      </Container>
    );
}

export default Series