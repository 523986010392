//Desigantion Master
export const FETCH_DESIGNATION = "FETCH_DESIGNATION";
export const ADD_DESIGNATION = "ADD_DESIGNATION";
export const DELETE_DESIGNATION = "DELETE_DESIGNATION";
export const UPDATE_DESIGNATION= "UPDATE_DESIGNATION"; 

//Department Master
export const FETCH_DEPARTMENT = "FETCH_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const UPDATE_DEPARTMENT= "UPDATE_DEPARTMENT";

//User Module
export const FETCH_USER = "FETCH_USER";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER= "UPDATE_USER";
export const SWITCH_USER= "UPDATE_USER";

//Acess Control
export const FETCH_ACCESS = "FETCH_ACCESS";
export const ADD_OR_EDIT_ACCESS = "ADD_OR_EDIT_ACCESS";


//Item Module
export const FETCH_ITEM = "FETCH_ITEM";
export const ADD_ITEM = "ADD_ITEM_DATA";
export const DELETE_ITEM = "DELETE_ITEM";
export const UPDATE_ITEM= "UPDATE_ITEM";

//Category Master
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";