import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function CancelEwaybill({ show, handleClose, handleCancel }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Cancel</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{fontSize:"14px"}}>Are you sure you want to cancel this E-waybill?</Modal.Body>
      <Modal.Footer>
        <Button style={{fontSize:"14px"}} variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button style={{fontSize:"14px"}} variant="primary" onClick={handleCancel}>
          Cancel E-waybill
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelEwaybill;
