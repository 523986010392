import {
  ContraEntryAddPost,
  ContraEntryCountPost,
  ContraEntryDeletePost,
  ContraEntryPagiDataPost,
  ContraEntryUpdatePost
} from "../../constants/api";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_CONTRA_ENTRY_COUNT,
  FETCH_CONTRA_ENTRY_DATA,
} from "../actionTypes/contraEntry.actiontypes";

//Get all contraEntry data using pagination wise
export const contraEntryPagiData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(ContraEntryPagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_CONTRA_ENTRY_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching contra entry data ${error}`);
      notifyError(error.message);
      setLoading(false);
    }
  };

//Get contra entry auto increment count
export const contraEntryAutoCount =(backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(ContraEntryCountPost, backendData);
      const initialCount = Number(res.data.contraCount) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: FETCH_CONTRA_ENTRY_COUNT, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching contra entry auto count data ${error}`);
      notifyError(error.message);
    }
  };

//Acion dispatcher for adding new Contra entry data
export const addContraEntry = (backendData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(ContraEntryAddPost, backendData);
      if (res.data.message === "Contra added successfully") {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleClose();
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding new contra entry data ${error}`);
      notifyError(error.response.data.message);
    }
  };

//Acion dispatcher for updating Contra entry data
export const updateContraEntry =
  (backendData, handleCloseEditModal) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(ContraEntryUpdatePost, backendData);
      if (res.data.message === 'Contra updated successfully') {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleCloseEditModal();
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating Contra entry data ${error}`);
      notifyError(error.response.data.message);
    }
  };

//Delete ContraEntry data
export const deleteContraEntry =
  (backendData, handleClose) => async (dispatch) => {
    try {
      const res = await axios.post(ContraEntryDeletePost, backendData);
      if (res.data.message == 'Contra deleted successfully') {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleClose();
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.log(`Error in deleting Contra entry data ${error}`);
      notifyError(error.message);
    }
  };
