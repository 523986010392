

  
//   import { RELOAD } from "../actionTypes/load.actiontypes";

import axios from "axios";
import { addVendorApi, deleteVendorApi, editVendorApi, getVendorPagiApi } from "../../constants/api";
import { ADD_VENDOR_DATA, FETCH_VENDOR_DATA, UPDATE_VENDOR_DATA } from "../actionTypes/vendor.actiontypes";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { notifyError, notifySuccess } from "../../constants/toastAlert";


  
  //Get all warehouse data using pagination wise
  export const vendorData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getVendorPagiApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_VENDOR_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching vendor data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete stocklocation data dispatcher
  export const deleteVendorData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteVendorApi, backendData);
        if (res.data.message == "Vendor deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting vendor data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new vendor data
  export const addVendor = (vendordata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addVendorApi, vendordata);
  
      if (res.data.message === "Vendor added successfully") {
        dispatch({ type: ADD_VENDOR_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/vendor");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding vendor data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateVendor = (vendorData, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(editVendorApi, vendorData);
  
      if (res.data.message === "Vendor updated successfully") {
        dispatch({ type: UPDATE_VENDOR_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/vendor");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating vendor data ${error}`);
      notifyError(error.response.data.message);
    }
  };