import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListTableName from '../../../constants/ListTableName';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { MdModeEditOutline, MdOutlineRemoveRedEye } from 'react-icons/md';
import { notifyWarning } from '../../../constants/toastAlert';
import * as XLSX from "xlsx";
import { BiSolidFileExport } from 'react-icons/bi';
// import axios from 'axios';
import { creditNoteDelete, creditNotePagiData } from '../../../redux/actions/creditNote.action';
import SearchField from "../../../constants/SearchField";
import DataSpinner from "../../../constants/DataSpinner";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import GlobalPagination from "../../../constants/GlobalPagination";
import { indianFormatDate } from '../../../constants/indianFormatDate';
import { FaInternetExplorer } from 'react-icons/fa6';

function ListTableCreditNote() {
  const navigate = useNavigate();

  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [status, setStatus] = useState("");
  const statusOptions = ['Paid', 'Draft']

  //Redux part
  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid, org } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.creditNoteData.creditNotePagiData) || [];
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
    status: status
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(creditNotePagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, status]);

  function handleExport() {
    if (filteredData.length == 0) {
      return notifyWarning("No Credit Note Record to Export");
    } else {
      const invoice = filteredData.map((item, ind) => {
        const {
          account,
          adjustmentamount,
          branch,
          branchid,
          cgst,
          commonid,
          commonname,
          commonnamealldata,
          company,
          companyid,
          createdby,
          creditnotedate,
          creditnoteno,
          creditnotetype,
          customernotes,
          documents,
          financialyear,
          grandtotal,
          id,
          igst,
          itemarray,
          org,
          remarks,
          salesinvoicedate,
          salesinvoiceno,
          salesperson,
          samestate,
          sgst,
          showgst,
          status,
          subtotal,
          tandc,
          tcs,
          tcsvalue,
          tds,
          tdsvalue,
          tsamount,
          uploadfile,
          userid
        } = item;

        return {
          "ID": id,
          "CREDIT NOTE TYPE": creditnotetype,
          "NAME": commonname,
          "COMMONID": commonid,
          "SALES INVOICE NO": salesinvoiceno,
          "SALES INVOICE DEATE": salesinvoicedate,
          "CREDIT NOTE NO": creditnoteno,
          "CREDIT NOTE DATE": creditnotedate,
          "SALES PERSON": salesperson,
          "CREATED BY": createdby,
          "ACCOUNT": account,
          "REMARKS": remarks,
          "CUSTOMER NOTES": customernotes,
          "UPLOAD FILES": uploadfile.length,
          "ITEMS": itemarray,
          "SUB TOTAL": subtotal,
          "IGST": igst,
          "CGST": cgst,
          "SGST": sgst,
          "TCS": tsamount,
          "TDS": tsamount,
          "ADJUSTMENT AMOUNT": adjustmentamount,
          "GRAND TOTAL": grandtotal,
          "TERMS AND CONDITION": tandc,
          "STATUS": status,
          "USERID": userid,
          "COMPANYID": companyid,
          "BRANCHID": branchid,
          "ORG": org,
          "FINANCIALYEAR": financialyear
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(invoice);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Credit Note");
      XLSX.writeFile(workbook, "Credit Note.xlsx");
    }
  }

  const handleShow = (id) => {
    setShow(true);
    setDeleteItemId(id);
  };

  const handleEdit = (id) => {
    navigate(`/sales/creditNote/editCreditNote/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(creditNoteDelete({ id: deleteItemId }, handleClose));
  };

  const handleEinvoice = (invoiceno) => {
    navigate("/einvoice/creditnote", { state: { invoiceno: invoiceno } });
  };

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={5}>
            <ListTableName name="Credit Note" />
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Status</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                {statusOptions.map((item, index) => (
                  <option key={index} value={item} className={
                    item == "Paid" ? "Approved-status" :
                      item === "Draft" ? "Draft-status" : ""}
                  >
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {/* {crud && crud.includes("c") &&  */}
            <Button className="commonBtn mt-2" onClick={() => navigate("/sales/creditNote/addCreditNote")}>
              Add Credit Note
            </Button>
            {/* } */}
          </Col>
          <Col lg={1}>
            <Button
              style={{ boxShadow: "none" }}
              className="commonBtn mt-2 cancelBtn"
              onClick={handleExport}
            >
              <span>
                <BiSolidFileExport />
              </span>
              Export
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Credit Note No</th>
              <th>Type</th>
              <th>Name</th>
              <th>Invoice No</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="8" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  account,
                  adjustmentamount,
                  branch,
                  branchid,
                  cgst,
                  commonid,
                  commonname,
                  commonnamealldata,
                  company,
                  companyid,
                  createdby,
                  creditnotedate,
                  creditnoteno,
                  creditnotetype,
                  customernotes,
                  documents,
                  financialyear,
                  grandtotal,
                  id,
                  igst,
                  itemarray,
                  org,
                  remarks,
                  salesinvoicedate,
                  salesinvoiceno,
                  salesperson,
                  samestate,
                  sgst,
                  showgst,
                  status,
                  subtotal,
                  tandc,
                  tcs,
                  tcsvalue,
                  tds,
                  tdsvalue,
                  tsamount,
                  uploadfile,
                  userid
                } = item;

                return (
                  <tr key={index}>
                    <td>{!creditnotedate ? "--" : indianFormatDate(creditnotedate)}</td>
                    <td>{!creditnoteno ? "--" : creditnoteno}</td>
                    <td>{!creditnotetype ? "--" : creditnotetype}</td>
                    <td>{!commonname ? "--" : commonname}</td>
                    <td>{!salesinvoiceno ? "--" : salesinvoiceno}</td>
                    <td className={
                      status == "Paid" ? "Approved-status" :
                              status === "Draft" ? "Draft-status" : ""}
                    >
                      {!status ? "--" : status}
                    </td>
                    <td>{!grandtotal ? "--" : grandtotal}</td>
                    <td className="d-flex justify-content-center align-items-center gap-2">
                      <span>
                        <MdOutlineRemoveRedEye className="editIcon" onClick={() => navigate(`/sales/creditNote/viewCreditNote/${id}`)} />
                      </span>

                      {status != "Paid" ? <span>
                        <MdModeEditOutline
                          className="editIcon mr-2"
                          onClick={() => handleEdit(id)}
                        />
                      </span> : <span >
                        <MdModeEditOutline
                          className="editIcon"
                          style={{ opacity: "0" }}
                        />
                      </span>}

                      <span>
                        <DeleteConfirmation
                          show={show}
                          handleShow={() => handleShow(id)}
                          handleClose={handleClose}
                          handleDelete={handleDelete}
                          name="Credit Note"
                        />
                      </span>
                      <span>
                        <FaInternetExplorer
                          onClick={() => handleEinvoice(salesinvoiceno)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="8" name="Credit Note" />
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  )
}

export default ListTableCreditNote