import { FaBook, FaFileInvoice, FaHandHoldingUsd, FaHistory, FaHouseUser, FaPeopleCarry, FaSitemap, FaUserEdit } from "react-icons/fa";
import { MdAccountBalance, MdCategory, MdDashboard, MdInventory, MdNotes, MdOtherHouses, MdOutlineStackedBarChart, MdPayment, MdReceipt, MdViewModule, MdWarehouse } from "react-icons/md";
import { FaDropbox, FaMapLocation, FaMoneyBillTransfer, FaMoneyBills, FaUserLarge, FaUserTie } from "react-icons/fa6";
import { GiBoxUnpacking, GiOnTarget, GiReceiveMoney, GiStabbedNote, GiTargetArrows } from "react-icons/gi";
import { FaBuilding } from "react-icons/fa";
import { FaWarehouse } from "react-icons/fa6";
import { IoBagHandle, IoCalculator, IoPeopleCircleOutline, IoReceiptSharp, IoSubwayOutline } from "react-icons/io5";
import { BiSolidCategory, BiSolidDashboard, BiSolidReport, BiTaskX } from "react-icons/bi";
import { RiBillFill, RiFileEditFill, RiStackshareLine, RiTeamFill } from "react-icons/ri";
import { PiHandshakeFill, PiNoteFill, PiNotebookFill } from "react-icons/pi";
import { HiReceiptTax } from "react-icons/hi";
import { TbTargetArrow } from "react-icons/tb";
import { BsBlockquoteRight, BsPersonCheckFill } from "react-icons/bs";
import { FaFileSignature } from "react-icons/fa6";
import Reports from "../Reports/AccountReports/Reports";
import { WiDayHaze } from "react-icons/wi";
import { SiBrandfolder } from "react-icons/si";
import { TbFileInvoice } from "react-icons/tb";

export const routes1 = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MdDashboard />,
  },
  {
    path: "/admin",
    name: "Admin",
    icon: <FaUserEdit />,
    subRoutes: [

      {
        path: "/admin/companyManagement",
        name: "Company",
        icon: <FaBuilding />,
      },
      {
        path: "/admin/branch",
        name: "Branch",
        icon: <FaSitemap />,
      },
      {
        path: "/admin/godownOrShowroom",
        name: "Godown/Showroom",
        icon: <FaWarehouse />,
      },
      {
        path: "/admin/stockLocation",
        name: "Stock Location",
        icon: <FaMapLocation />,
      },
      {
        path: "/admin/user",
        name: "User",
        icon: <FaUserLarge />,
      },
      {
        path: "/admin/accessControl",
        name: "Access Control",
        icon: <MdViewModule />,
      },
      {
        path: "/admin/crud",
        name: "CRUD Control",
        icon: <MdViewModule />,
      },
      {
        path: "/admin/termcondition",
        name: "Term & Condition",
        icon: <PiHandshakeFill />
      },
      {
        path: "/admin/dayclosure",
        name: "Day Closure",
        icon: <WiDayHaze />

      },
      {
        path: "/admin/priceupdate",
        name: "Price Update",
        icon: <GiReceiveMoney />,
      },
      {
        path: "/purchase/vendor",
        name: "Vendor",
        icon: <FaUserTie />,
      },

    ],
  },
  {
    path: "/employeemanagement",
    name: "Employee Management",
    icon: <FaPeopleCarry />,
    subRoutes: [
      {
        path: "/employeemanagement/employee",
        name: "Registration",
        icon: <IoPeopleCircleOutline />,
      },

    ],
  },
  {
    path: "/inventory",
    name: "Inventory",
    icon: <MdWarehouse />,
    subRoutes: [
      {
        path: "/inventory/category",
        name: "Category",
        icon: <BiSolidCategory />,
      },
      {
        path: "/inventory/brand",
        name: "Brand",
        icon: <SiBrandfolder />,
      },
      {
        path: "/inventory/series",
        name: "Series",
        icon: <MdOutlineStackedBarChart />,
      },
      {
        path: "/inventory/item",
        name: "Items",
        icon: <GiBoxUnpacking />,
      },
      {
        path: "/inventory/suggestedItem",
        name: "Suggested Item",
        icon: <RiStackshareLine />,
      },
      {
        path: "/inventory/manageStock",
        name: "Manage Stock",
        icon: <FaDropbox />,
      },
    ],
  },

  {
    path: "/purchase",
    name: "Purchase",
    icon: <IoBagHandle />,
    subRoutes: [
      {
        path: "/purchase/purchaseorder",
        name: "Purchase Order",
        icon: <IoBagHandle />,
      },
      {
        path: "/purchase/purchaseBills",
        name: "Purchase Bills",
        icon: <FaMoneyBills />,
      },
      {
        path: "/purchase/purchasePayment",
        name: "Purchase Payment",
        icon: <MdPayment />,
      },

    ],
  },
  {
    path: "/sales",
    name: "Sales",
    icon: <IoBagHandle />,
    subRoutes: [
      {
        path: "/sales/customer",
        name: "Customer",
        icon: <FaUserTie />,
      },
      {
        path: "/sales/projectManagement",
        name: "Project Management",
        icon: <PiNoteFill />,
      },
      {
        path: "/sales/quotation",
        name: "Quotation",
        icon: <BsBlockquoteRight />,
      },
      {
        path: "/sales/salesOrder",
        name: "Sales Order",
        icon: <FaFileSignature />
      },
      {
        path: "/sales/salesInvoice",
        name: "Sales Invoice",
        icon: <FaFileInvoice />
      },
      {
        path: "/sales/salePayment",
        name: "Payment Receipt",
        icon: <MdPayment />
      },
      {

        path: "/sales/deliveryReceipt",
        name: "Delivery Receipt",
        icon: <IoReceiptSharp />
      },
      {
        path: "/sales/creditNote",
        name: "Credit Note",
        icon: <GiStabbedNote />
      },
      {

        path: "/ewaybill",
        name: "E-waybill",
        icon: <IoSubwayOutline />

      },
      {

        path: "/einvoice",
        name: "E-Invoice",
        icon: <TbFileInvoice />

      },

    ],
  },
  {
    path: "/targets",
    name: "Targets",
    icon: <GiTargetArrows />,
    subRoutes: [
      {
        path: "/targets/targetsDashboard",
        name: "Target Dashboard",
        icon: <BiSolidDashboard />
        ,
      },
      {
        path: "/purchase/purchaseTarget",
        name: "Purchase Target",
        icon: <GiOnTarget />,
      },
      {
        path: "/sales/salesTarget",
        name: "Sales Target",
        icon: <TbTargetArrow />
      },
    ],
  },

  {
    path: "/accounting",
    name: "Accounting",
    icon: <IoCalculator />,
    subRoutes: [
      {
        path: "/accounting/manualJournal",
        name: "Manual Journal",
        icon: <RiFileEditFill />,
      },
      {
        path: "/accounting/chartOfAccount",
        name: "Chart of Account",
        icon: <PiNotebookFill />,
      },
      {
        path: "/accounting/ledger",
        name: "Ledger",
        icon: <FaBook />,
      },
      {
        path: "/accounting/otherPayment",
        name: "Other Payment",
        icon: <BsPersonCheckFill />,
      },
    ],
  },
  {
    path: "/banking",
    name: "Banking",
    icon: <MdAccountBalance />,
    subRoutes: [
      {
        path: "/banking/bank",
        name: "Bank",
        icon: <MdAccountBalance />,
      },
      {
        path: "/banking/transaction",
        name: "Transaction",
        icon: <FaMoneyBillTransfer />,
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    icon: <BiSolidReport />,
  },
  {
    path: "/taxes",
    name: "Taxes",
    icon: <HiReceiptTax />,
    subRoutes: [
      {
        path: "/taxes/tdsTcs",
        name: "TDS/TCS",
        icon: <MdReceipt />,
      },
    ],
  },

  {
    path: "/log",
    name: "Log History",
    icon: <FaHistory />,

  },
  {
    path: "/auditLog",
    name: "Audit Log History",
    icon: <FaHistory />,

  },
];
