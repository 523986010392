import React, { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import BillPaymentForm from "./BillPaymentForm";
import VendorAdvanceForm from "./VendorAdvanceForm";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const AddPurchasePaymentTab = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("Bill Payment");
  const URL = extractAndFormatURLPart("purchase");

  const handleTabSelect = (key) => {
    // Define the extracted part based on the selected tab event key
    switch (key) {
      case "vendoradvance":
        setCurrentTab("Vendor Advance");
        break;
      default:
        setCurrentTab("Bill Payment");
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <div className="mt-4 mb-3">{URL + "/ " + currentTab}</div>
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/purchase/purchasePayment")}
            />
            Make <span className="form-title"> Payment</span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="billpayment"
            id="justify-tab-example"
            className="mb-3"
            //variant='pills'
            //fill
            onSelect={handleTabSelect}
          >
            <Tab
              eventKey="billpayment"
              title="Bill Payment"
              className="tabview"
            >
              <BillPaymentForm />
            </Tab>
            <Tab
              eventKey="vendoradvance"
              title="Vendor Advance"
              className="tabview"
            >
              <VendorAdvanceForm />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default AddPurchasePaymentTab;
