import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { extractAndFormatURLPart } from '../../constants/breadCrumb';
import Tds from './TDS/Tds';
import Tcs from './TCS/Tcs';
// import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
// import FirmManagement from './FirmManagement';



const TaxesTab = () => {
  const [currentTab, setCurrentTab] = useState("TDS");
  const URL = extractAndFormatURLPart("taxes");

  const handleTabSelect = (key) => {
    // Define the extracted part based on the selected tab event key
    switch (key) {
        case "tcs":
        setCurrentTab("TCS");
        break;
    //   case "roletransition":
    //     setCurrentTab("Role Transition");
    //     break;
        default:
        setCurrentTab("TDS");
    }
  };
  return (
    <Container fluid>
      <Row >
        <Col className="colStyle">
          <span>{URL  + "/ " +currentTab}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="tds"
            id="justify-tab-example"
            className="mb-3"
            //variant='pills'
            //fill
            onSelect={handleTabSelect}
          >
            <Tab eventKey="tds" title="TDS" className="tabview">
              <Tds/>
            </Tab>
            <Tab eventKey="tcs" title="TCS" className="tabview">
              <Tcs/>
            </Tab>

          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default TaxesTab;