import { FETCH_CREDIT_NOTE_DATA, FETCH_CREDIT_NOTE_DOCUMENT } from "../actionTypes/creditNote.actiontypes";

const initialState = {
  creditNotePagiData: [],
  creditNoteDocument: "",
};

export const creditNoteReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CREDIT_NOTE_DATA: {
      return { ...state, creditNotePagiData: { ...payload } };
    }

    case FETCH_CREDIT_NOTE_DOCUMENT: {
      return { ...state, creditNoteDocument: payload };
    }

    default: {
      return state;
    }
  }
};
