import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchField from "../../../constants/SearchField";
import GlobalPagination from "../../../constants/GlobalPagination";
import { salesTargetDashboardData } from "../../../redux/actions/salesTargetDashboard.actions";
import { branchPagiData } from "../../../redux/actions/branch.action";
import { companyData } from "../../../redux/actions/company.actions";

const ListTableSalesTarget = () => {
  const financialYear = localStorage.getItem("financialyear") || "";
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const companyMaster =
    useSelector((state) => state.companyData.companyData.data) || [];
  const branchMaster =
    useSelector((state) => state.branchData.branchPagiData.data) || [];
  const salesTargetData = useSelector(
    (state) => state.salesTargetDashboardData.salesTargetDashboardData
  );
  const filteredData = !salesTargetData.data ? [] : salesTargetData.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: salesTargetData.totalItems ? salesTargetData.totalItems : 0,
    totalPages: salesTargetData.totalPages ? salesTargetData.totalPages : 0,
  };

  const [data, setData] = useState({
    from: "",
    to: "",
    targetfor: "",
    commonname:"",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));

    if (name == "targetfor") {
      setData((prev) => ({
        ...prev,
        [name]: value,
        commonname:"",
      }));
    }

  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      salesTargetDashboardData(
        {
          vendor: data.vendor,
          targettype: data.targettype,
          from: data.from,
          to: data.to,
          userid,
          branchid,
          keyword: searchKeyword || "₹ΘθϖΠ",
          companyid,
          page: currentPage,
          pageSize: pageSize,
        },
        setLoading
      )
    );
    dispatch(
      branchPagiData(
        { userid, branchid, companyid, branch, keyword: "" },
        setLoading
      )
    );
    dispatch(companyData({ userid, companyid, keyword: "" }, setLoading));
  }, [
    dispatch,
    pageSize,
    currentPage,
    loadData,
    searchKeyword,
   data.targetfor,data.commonname,data.from,data.to
  ]);



  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row className="p-3">
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Target For <span className="star">*</span>
              </Form.Label>
              <Form.Select
                name="targetfor"
                value={data.targetfor}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="Branch">Branch</option>
                <option value="Franchise">Franchise</option>
                <option value="Sub-Dealer">Sub-Dealer</option>
              </Form.Select>
            </Form.Group>
          </Col>
          {data.targetfor == "Branch" ? (
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Common Name <span className="star">*</span>
                </Form.Label>
                <Form.Select
                  name="commonname"
                  value={data.commonname}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {branchMaster.map((branch) => (
                    <option value={branch.branchname} key={branch.id}>
                      {branch.branchname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          ) : data.targetfor == "Franchise" ? (
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Common Name <span className="star">*</span>
                </Form.Label>
                <Form.Select
                  name="commonname"
                  value={data.commonname}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {companyMaster
                    .filter((company) => company.type == "Franchise")
                    .map((company) => (
                      <option value={company.companyname} key={companyname.id}>
                        {company.companyname}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          ) : (
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Common Name <span className="star">*</span>
                </Form.Label>
                <Form.Select
                  name="commonname"
                  value={data.commonname}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {companyMaster
                    .filter((company) => company.type == "Sub-Dealer")
                    .map((company) => (
                      <option value={company.companyname} key={companyname.id}>
                        {company.companyname}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>From</Form.Label>
              <Form.Control
                type="date"
                name="from"
                value={data.from}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>To</Form.Label>
              <Form.Control
                type="date"
                name="to"
                value={data.to}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Sales Target</span>
            </p>
          </Col>
          <Col></Col>
          <Col lg={3}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container ">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Camapaign No</th>
              <th>Campaign Name</th>
              <th>Target For</th>
              <th>Name</th>
              <th>Set By</th>
              <th>Item Name</th>
              <th>Category</th>
              <th>Target</th>
              <th>Total Achievement</th>
              <th>Achievement %</th>
            </tr>
          </thead>
          <tbody>
            {loading || loadData ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((purchase, ind) => (
                <tr key={ind}>
                  <td>{purchase.campno ? purchase.campno : "--"}</td>
                  <td>{purchase.campname ? purchase.campname : "--"}</td>
                  <td>{purchase.targetfor ? purchase.targetfor : "--"}</td>
                  <td>{purchase.commonname ? purchase.commonname
                   : "--"}</td>
                   <td>{purchase.setby ? purchase.setby : "--"}</td>
                  <td>{purchase.itemname ? purchase.itemname : "--"}</td>
                  <td>{purchase.category ? purchase.category : "--"}</td> 
                  <td>{purchase.tier1 ? purchase.tier1 : "--"}</td> 
                  <td>{purchase.setBy=="Qty" ? purchase.total_qty:purchase.total_amount}</td> 
                  <td>{purchase.achievementpercentage ? purchase.achievementpercentage : "--"}</td> 
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>

      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTableSalesTarget;
