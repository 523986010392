import React, { useState } from "react";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { indianFormatDate } from "../../../constants/indianFormatDate";

const ViewItem = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const {
    itemname,
    category,
    grade,
    thickness,
    itemdescription,
    taxability,
    gst,
    itemid,
    brand,
    unit,
    size,
    sku,
    mpn,
    cessvaluationtype,
    openingqty,
    asondate,
    rateperunit,
    reorderpoint,
    value,
    image,
    series,
    hsncode,
  } = item || [];

  return (
    <>
      <AiFillEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">Item Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col className="viewpage-title">Item Name:</Col>{" "}
                  <Col className="viewpage-value">{itemname}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Category:</Col>{" "}
                  <Col className="viewpage-value">{category}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Series:</Col>{" "}
                  <Col className="viewpage-value">{series ? series : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Thickness:</Col>{" "}
                  <Col className="viewpage-value">
                    {thickness ? thickness : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Item Description:</Col>{" "}
                  <Col className="viewpage-value">
                    {itemdescription ? itemdescription : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Taxability Type:</Col>{" "}
                  <Col className="viewpage-value">{taxability}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">GST Rate %:</Col>{" "}
                  <Col className="viewpage-value">{gst ? gst : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">Item No:</Col>{" "}
                  <Col className="viewpage-value">{itemid}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Make/Brand:</Col>{" "}
                  <Col className="viewpage-value">{brand ? brand : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">UOM:</Col>{" "}
                  <Col className="viewpage-value">{unit ? unit : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Size:</Col>{" "}
                  <Col className="viewpage-value">{size ? size : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">SKU No:</Col>{" "}
                  <Col className="viewpage-value">{sku ? sku : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">MPN:</Col>{" "}
                  <Col className="viewpage-value">{mpn ? mpn : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">HSN/SAC:</Col>{" "}
                  <Col className="viewpage-value">
                    {hsncode ? hsncode : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <h3 className="boldItem">Additional Information</h3>
              <Col>
                <Row>
                  <Col className="viewpage-title">Opening Qty:</Col>{" "}
                  <Col className="viewpage-value">
                    {openingqty ? openingqty : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Value:</Col>{" "}
                  <Col className="viewpage-value">{value ? value : "--"}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">As on Date:</Col>{" "}
                  <Col className="viewpage-value">
                    {asondate ? indianFormatDate(asondate) : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">Rate:</Col>{" "}
                  <Col className="viewpage-value">
                    {rateperunit ? rateperunit : "--"}
                  </Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Reorder Point:</Col>{" "}
                  <Col className="viewpage-value">
                    {reorderpoint ? reorderpoint : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              {image && image.length > 0
                ? image.map((photo, index) => (
                    <Col lg={3} key={index}>
                      <Image
                        style={{ width: "100%" }}
                        src={photo}
                        alt="Image has been broken"
                        rounded
                        fluid
                      />
                    </Col>
                  ))
                : "No image has been uploaded"}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewItem;
