
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ListTable from '../godownOrShowrooms/ListTable';


function GodownOrShowRoom() {

  return (
    <Container fluid>
      <Row>
        <Col>
          <ListTable />
        </Col>
      </Row>
    </Container>
  )
}

export default GodownOrShowRoom