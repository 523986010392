//login with otp redux actions starts here


// import { loginUserPost, logoutUserPost } from "../../constants/api";
// import { notifyError, notifySuccess } from "../../constants/toastAlert";
// import {
//   LOGIN_FAILURE,
//   LOGIN_REQUEST,
//   LOGIN_SUCCESS,
//   LOGOUT_USER,
// } from "../actionTypes/auth.actiontypes";
// import axios from "axios";

// const data = {
//   loading: false,
//   error: null,
//   token: null,
//   userData: {
//     data:[{}]
//   },
// };

// //Acion dispatcher for user login
// export const userLogin = (data,cred,navigate) =>  (dispatch) => {

//       localStorage.setItem("token", JSON.stringify(data.token));
//       localStorage.setItem("userData", JSON.stringify({companyaddress:data.companyaddress,token:data.token,message:data.message,data:data.data}));
//       localStorage.setItem("financialyear", JSON.stringify(cred.financialyear));
//       dispatch({ type: LOGIN_SUCCESS, payload: data });
//       notifySuccess(data.message);
//       navigate("/dashboard");

// };

// //Acion dispatcher for user logout
// export const userLogout = (userid, navigate) => async (dispatch) => {
//   try {
//     let res = await axios.post(logoutUserPost, {userid});
//     if (res.data.message == 'User logout successfully') {
//       notifySuccess("Logout Successfully");
//       localStorage.clear();
//       navigate("/login");
//       dispatch({ type: LOGOUT_USER, payload:data});
//     } else {
//       notifyError(res.data.message);
//     }
//   } catch (error) {
//     console.log(`Error in logout ${error}`);
//   }
// };


//login with otp redux actions ends here---------->



//login without otp redux actions starts here---------->


import { billInfoGetPost, loginUserPost, logoutUserPost } from "../../constants/api";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import {
  FETCH_BILL_DATA,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_USER,
} from "../actionTypes/auth.actiontypes";
import axios from "axios";

const data = {
  loading: false,
  error: null,
  token: null,
  userData: {
    data: [{}]
  },
};

//Acion dispatcher for user login
export const userLogin = (cred, navigate) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    let data = await axios.post(loginUserPost, cred);
    if (data.data.token) {
      localStorage.setItem("token", JSON.stringify(data.data.token));
      localStorage.setItem("userData", JSON.stringify(data.data));
      localStorage.setItem("financialyear", cred.financialyear);
      dispatch({ type: LOGIN_SUCCESS, payload: data.data });
      notifySuccess(data.data.message);
      navigate("/home");
    } else {
      dispatch({ type: LOGIN_FAILURE, payload: data.data.message });
      notifyError(data.data.message);
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: LOGIN_FAILURE, payload: error.response.data.message });
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for user logout
export const userLogout = (userid, navigate) => async (dispatch) => {
  try {
    let res = await axios.post(logoutUserPost, { userid });
    if (res.data.message == 'User logout successfully') {
      notifySuccess("Logout Successfully");
      localStorage.clear();
      navigate("/login");
      dispatch({ type: LOGOUT_USER, payload: data });
    } else {
      notifyError(res.data.message);
    }
  } catch (error) {
    console.log(`Error in logout ${error}`);
  }
};

//Action dispatcher for billinformation
export const billInfoData = (backendData) => async (dispatch) => {
  try {
    const res = await axios.post(billInfoGetPost, backendData);
    const result = res.data && res.data.data.length > 0 && res.data.data[0];
    if (result) {
      dispatch({type:FETCH_BILL_DATA, payload:result})
      // setData({ ...data, ...result });
    }
  } catch (error) {
    console.log(`Error while fetching bill info details, ${error}`);
  }
}



//login without otp redux actions ends here---------->