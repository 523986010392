import { FETCH_SUGGESTED_ITEM_DATA } from "../actionTypes/suggesteditems.actiontypes";


const initialState = {

    suggestedItemData: {},

  };
  
  //
  export const  suggestedItemReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_SUGGESTED_ITEM_DATA: {
        return { ...state, suggestedItemData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };