import React from "react";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListCrud from "./ListCrud";
import { Col, Container, Row } from "react-bootstrap";
function Crud() {
  const URL = extractAndFormatURLPart("admin");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListCrud />
        </Col>
      </Row>
    </Container>
  );
};


export default Crud