import React, { useState } from "react";
import { Button, Container, Form, Modal, Row } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function AddCustomerTypeModal() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const handleShow = () => setShow(true);
    const [custType, setCustType] = useState("");
    const handleClose = () => {
        setShow(false);
        setShowEmptyError(false);
    };
    const handleProceed = () => {
        if (custType == "") {
            setShowEmptyError(true);
            return;
        } else {
            setShow(false);
            navigate(`/sales/salesInvoice/addCustomer`, {
                state: { 
                    modalData: custType,
                },
            });
        }
    };
    return (
        <>
            <span className="form-title-sibling" onClick={handleShow}>
                <BsPlusCircle /> Add Customer
            </span >
            <Modal
                size="md"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add <span className="form-title main">Customer</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Form.Group className="mb-3" controlId="formBasicID">
                                <Form.Label>
                                    Select Customer Type <span className="star">*</span>
                                </Form.Label>
                                <Form.Select
                                    name="taxability"
                                    value={custType}
                                    onChange={(e) => setCustType(e.target.value)}
                                    isInvalid={custType === "" && showEmptyError}
                                >
                                    <option value="">--Select--</option>
                                    <option value="Retail">Retail</option>
                                    <option value="Institutional">Institutional</option>
                                </Form.Select>
                                <br />
                                <h5 className={showEmptyError ? "deactivated_icons" : ""}>
                                    Retail is B2C and Institutional are B2B Customers
                                </h5>
                                <Form.Control.Feedback type="invalid">
                                    Customer type is mandatory to proceed
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button className="commonBtn smallBtn" onClick={handleProceed}>
                        Next
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddCustomerTypeModal