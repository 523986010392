import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bankPagiData } from "../../../../redux/actions/bank.actions";
import { ledgerPagiData } from "../../../../redux/actions/ledger.actions";
import {
  addReceipt,
  receiptAutoCount,
} from "../../../../redux/actions/receipt.actions";
import { notifyError } from "../../../../constants/toastAlert";
import { getCurrentPreviousYears } from "../../../../constants/currentPreviousYear";

const AddReceipt = () => {
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  // const { receiptCount } = useSelector((state) => state.receiptData) || 0;
  const { data: bankAllData } =
    useSelector((state) => state.bankData.bankPagiData) || [];
  const { data: ledgerAllData } =
    useSelector((state) => state.ledgerData.ledgerPagiData) || [];
  const ledgerData = !ledgerAllData
    ? []
    : ledgerAllData.filter((item) => item.ledgertype == "Other");
    const {data : receiptData } = useSelector((state) => state.receiptData.receiptPagiData) || [];
    const receiptAllData = !receiptData ? [] : receiptData;
  
  //state
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [receiptid, setReceiptid] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    receiptid: "",
    date: "",
    month: "",
    prefno: "",
    receivefrom: "",
    receivemode: "",
    receiveto: "",
    amount: "",
    companyid: companyid,
    userid: userid,
    org: org,
    financialyear: finalcialYear,
  });

  //Default Data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
  };

  useEffect(() => {
    setLoading(!loading);
    // dispatch(receiptAutoCount(backendData, setLoading));
    dispatch(bankPagiData(backendData, setLoading));
    dispatch(ledgerPagiData(backendData, setLoading));
    let count = autoGenerateID();
    let paddedCount = count.toString().padStart(4, "0");
    let result = `RT/${getCurrentPreviousYears()}/${paddedCount}`;
    setData({
      ...data,
      receiptid: result,
      companyid: companyid,
      userid: userid,
      org: org,
      financialyear: finalcialYear,
    });
  }, [dispatch, loadData, show]);

  //Auto generate Id start
  function autoGenerateID() {
    if (receiptAllData.length === 0) {
      return "1";
    } else {
      const currentId = receiptAllData[0].receiptid.split("/")[2];
      return String(parseInt(currentId) + 1);
    }
  };

  //Auto generate Id end

  const handleSave = async () => {
    if (
      data.date == "" ||
      data.receivefrom == "" ||
      data.receivemode == "" ||
      data.receiveto == "" ||
      data.amount == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    } else {
      dispatch(addReceipt(data, handleClose));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "receivemode") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        receiveto: "",
      }));
    }

    if (name == "date") {
      const dateObj = new Date(value);
      const month = dateObj.toLocaleString("default", { month: "long" });
      setData((prev) => ({
        ...prev,
        month: month,
      }));
    }

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setShowEmptyError(false);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setData({
      receiptid: "",
      date: "",
      month: "",
      prefno: "",
      receivefrom: "",
      receivemode: "",
      receiveto: "",
      amount: "",
      companyid: "",
      userid: "",
      org: "",
      financialyear: "",
    });
    // dispatch(reloadPage(true));
  };

  return (
    <>
      <Button className="commonBtn mt-2" onClick={handleShow}>
        Add Receipt
      </Button>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add <span className="form-title">Receipt</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-lable-name">
            <Container fluid>
              <Row lg={2} md={1}>
                <Col>
                  <Row lg={2} md={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Receipt No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Receipt No"
                          name="receiptid"
                          value={data.receiptid}
                          disabled
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Date <span className="star">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="date"
                          value={data.date}
                          min={`${parseInt(
                            finalYear[0].replace('"', ""),
                            10
                          )}-04-01`}
                          max={`${parseInt(
                            finalYear[1].replace('"', ""),
                            10
                          )}-03-31`}
                          onChange={handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                          isInvalid={data.date === "" && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Select Date
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Reference No./ Cheque No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Reference No./ Cheque No."
                      name="prefno"
                      value={data.prefno}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row lg={2} md={1}>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Receive From <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="receivefrom"
                      value={data.receivefrom}
                      onChange={handleChange}
                      isInvalid={data.receivefrom === "" && showEmptyError}
                    >
                      <option value="">--Select--</option>
                      {ledgerData.length > 0 ? (
                        ledgerData.map((item, index) => {
                          const { ledgername } = item;
                          return (
                            <option key={index} value={ledgername}>
                              {ledgername}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">No Receive from Data Available</option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Receive from
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Row lg={2} md={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Receive Mode <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="receivemode"
                          value={data.receivemode}
                          onChange={handleChange}
                          isInvalid={data.receivemode === "" && showEmptyError}
                        >
                          <option value="">--Select--</option>
                          <option value="Cash">Cash</option>
                          <option value="Bank Transfer">Bank Transfer</option>
                          <option value="Cheque">Cheque</option>
                          <option value="UPI">UPI</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Select Receive Mode
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Receive To <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="receiveto"
                          value={data.receiveto}
                          onChange={handleChange}
                          isInvalid={data.receiveto === "" && showEmptyError}
                        >
                          <option value="">Select</option>
                          {data.receivemode === "Cash" ? (
                            // bankAllData.map((item, index) => {
                            //     const { bank, branchname, accountnumber } = item;
                            //     return (
                            //         <option key={index} value={`${bank}, ${branchname}, ${accountnumber}`}>
                            //             {`${bank}, [${branchname}], ${accountnumber}`}
                            //         </option>
                            //     );
                            // })
                            <option value="Company Cash Account">
                              Company Cash Account
                            </option>
                          ) : (
                            data.paymentmode !== "" &&
                            bankAllData &&
                            bankAllData.map((item, index) => {
                              const { bank, branchname, accountnumber } = item;
                              return (
                                <option
                                  key={index}
                                  value={`${bank}, ${branchname}, ${accountnumber}`}
                                >
                                  {`${bank}, [${branchname}], ${accountnumber}`}
                                </option>
                              );
                            })
                          )}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Select Receive To
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row lg={2} md={1}>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Amount <span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Amount"
                      name="amount"
                      value={data.amount}
                      onChange={handleChange}
                      isInvalid={data.amount === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Amount
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSave}
          >
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddReceipt;
