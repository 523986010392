import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { notifyError, notifySuccess } from "../../../../constants/toastAlert";
import { bankPagiData } from "../../../../redux/actions/bank.actions";
import { ledgerPagiData } from "../../../../redux/actions/ledger.actions";
import { updatePayment } from "../../../../redux/actions/payment.actions";

function EditPaymentForm({
  showEditModal,
  handleEditModal,
  editItem,
  handleCloseEditModal,
}) {
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");

  //State
  const paymentData = ["Cash", "Bank Transfer", "Cheque", "UPI"];
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [allPaidThrough, setAllPaidThrough] = useState([]);
  const [bankdata, setBankdata] = useState([]);
  const [paidTodata, setPaidToData] = useState([]);
  const [data, setData] = useState({
    id: editItem ? editItem.id : "",
    paymentid: editItem ? editItem.paymentid : "",
    date: editItem ? editItem.date : "",
    month: editItem ? editItem.month : "",
    paymentmode: editItem ? editItem.paymentmode : "",
    paidthrough: editItem ? editItem.paidthrough : "",
    amount: editItem ? editItem.amount : "",
    prefno: editItem ? editItem.prefno : "",
    paidto: editItem ? editItem.paidto : "",
    org: editItem ? editItem.org : "",
    userid: editItem ? editItem.userid : "",
    companyid: editItem ? editItem.companyid : "",
    branchid: editItem ? editItem.branchid : "",
    financialyear: editItem ? editItem.financialyear : "",
  });

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, org } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const { paymentCount } = useSelector((state) => state.paymentData) || 0;
  const { data: bankAllData } = useSelector((state) => state.bankData.bankPagiData) || [];
  const { data: ledgerAllData } = useSelector((state) => state.ledgerData.ledgerPagiData) || [];
  const ledgerData = !ledgerAllData ? [] : ledgerAllData.filter((item) => item.ledgertype == "Other");

  //Default data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid
  }

  const fetchEditData = () => {
    setData({
      id: editItem ? editItem.id : "",
      paymentid: editItem ? editItem.paymentid : "",
      date: editItem ? editItem.date : "",
      month: editItem ? editItem.month : "",
      paymentmode: editItem ? editItem.paymentmode : "",
      paidthrough: editItem ? editItem.paidthrough : "",
      amount: editItem ? editItem.amount : "",
      prefno: editItem ? editItem.prefno : "",
      paidto: editItem ? editItem.paidto : "",
      org: editItem ? editItem.org : "",
      userid: editItem ? editItem.userid : "",
      companyid: editItem ? editItem.companyid : "",
      branchid: editItem ? editItem.branchid : "",
      financialyear: editItem ? editItem.financialyear : ""
    });
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(bankPagiData(backendData, setLoading));
    dispatch(ledgerPagiData(backendData, setLoading));
    fetchEditData();
  }, [dispatch, loadData, show, editItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "paymentmode") {
      setData((prev) => ({
        ...prev,
        paymentmode: "",
        paidthrough: "",
      }))
    }

    if(name == "date"){
      const dateObj = new Date(value);
      const month = dateObj.toLocaleString('default', { month: 'long' });
      setData((prev) => ({
        ...prev,
        month: month,
      }))
    }

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // setShowEmptyError(false);
  };

  const handleSubmit = async () => {
    if (
      data.paymentid == "" ||
      data.date == "" ||
      data.paymentmode == "" ||
      data.paidthrough == "" ||
      data.amount == "" ||
      data.paidto == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    } else {
      dispatch(updatePayment(data, handleCloseEditModal))
    }
  };

  return (
    <>
      <MdEdit className="editIcon" onClick={handleEditModal} />
      <Modal
        size="xl"
        show={showEditModal}
        onHide={handleCloseEditModal}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit <span className="form-title">Payment</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-lable-name">
            <Container fluid>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicId">
                        <Form.Label>Payment No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Payment No."
                          name="paymentid"
                          value={data.paymentid}
                          onChange={handleChange}
                          required
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formBasicId">
                        <Form.Label>
                          Date<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Select Date"
                          name="date"
                          value={data.date}
                          min={`${parseInt(finalYear[0].replace('"', ''), 10)}-04-01`}
                          max={`${parseInt(finalYear[1].replace('"', ''), 10)}-03-31`}
                          onChange={handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                          required
                          isInvalid={data.date === "" && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Select Date
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicSite">
                        <Form.Label>
                          Payment Mode<span className="star"> *</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="paymentmode"
                          onChange={handleChange}
                          value={data.paymentmode}
                          isInvalid={data.paymentmode === "" && showEmptyError}
                        >
                          <option value="">Select</option>
                          {paymentData.length > 0 ? (
                            paymentData.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))
                          ) : (
                            <option value="">No site names available</option>
                          )}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Select Payment Mode
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicSite">
                        <Form.Label>
                          Paid Through<span className="star"> *</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="paidthrough"
                          onChange={handleChange}
                          value={data.paidthrough}
                          isInvalid={data.paidthrough === "" && showEmptyError}
                        >
                          <option value="">Select</option>
                          {data.paymentmode === "Cash"
                            ?
                            // allPaidThrough.map((item, index) => {
                            //     const { cashname } = item;
                            //     return (
                            //       <option key={index} value={cashname}>
                            //         {cashname}
                            //       </option>
                            //     );
                            //   })
                            <option value="Company Cash Account">Company Cash Account</option>
                            : data.paymentmode !== "" &&
                            bankAllData.map((item, index) => {
                              const { bank, branchname, accountnumber } =
                                item;
                              return (
                                <option
                                  key={index}
                                  value={`${bank}, ${branchname}, ${accountnumber}`}
                                >
                                  {`${bank}, [${branchname}], ${accountnumber}`}
                                </option>
                              );
                            })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Select Paid through
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formBasicId">
                        <Form.Label>
                          Amount <span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Amount"
                          name="amount"
                          value={data.amount}
                          onChange={handleChange}
                          required
                          isInvalid={data.amount === "" && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Amount
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col lg={12}>
                      <Form.Group controlId="formBasicId">
                        <Form.Label>Reference No./Cheque No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Reference No./Cheque No."
                          name="prefno"
                          value={data.prefno}
                          onChange={handleChange}
                          required
                        // disabled
                        // isInvalid={data.prefno === "" && showEmptyError}
                        />
                        <Form.Control.Feedback type="invalid">
                          Enter Reference No./Cheque No.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3" controlId="formBasicSite">
                        <Form.Label>
                          Paid To<span className="star"> *</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="paidto"
                          onChange={handleChange}
                          value={data.paidto}
                          isInvalid={data.paidto === "" && showEmptyError}
                        >
                          <option value="">Select</option>
                          {ledgerData.length > 0 ? (
                            ledgerData.map((item, index) => {
                              const { ledgername } = item;
                              return (
                                <option key={index} value={ledgername}>
                                  {ledgername}
                                </option>
                              );
                            })
                          ) : (
                            <option value="">No Paid to Data Available</option>
                          )}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Select Paid To
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSubmit}
          >
            {loadData ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />)
              : (
                "Update"
              )}
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleCloseEditModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditPaymentForm;
