import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
// import { notifyError } from "../../../constants/toastAlerts";
import TicketModal from "../Ticket/TicketModal";
import { notifyError } from "../../../constants/toastAlert";
import { useSelector } from "react-redux";

// let sessionData = JSON.parse(sessionStorage.getItem("userData")).data[0] || [];





// const modulesArray = [
//   {
//     moduleName: "Admin",
//     submodules: ["Company","Branch", "Godown/Showroom","User", "Access Control", "Stock Location", "Price Update"],
//   },
//   {
//     moduleName: "Employee Management",
//     submodules: ["Registration"],
//   },
//   {
//     moduleName: "Inventory",
//     submodules: ["Items", "Category", "Suggested Item", "Manage Stock"],
//   },
//   {
//     moduleName: "Purchase",
//     submodules: [
//       "Vendor",
//       "Purchase Order",
//       "Purchase Bills",
//       "Purchase Payment",
//     ],
//   },
//   {
//     moduleName: "Sales",
//     submodules: ["Customer", "Quotation", "Sales Order", "Sales Invoice", "Delivery Receipt"],
//   },
//   {
//     moduleName: "Target",
//     submodules: [
//       "Target Dashboard",
//       "Purchase Target",
//       "Sales Target"
//     ],
//   },
//   {
//     moduleName: "Projects",
//     submodules: ["Project Management"],
//   },
//   {
//     moduleName: "Accounting",
//     submodules: ["Manual Journal", "Chart of Account", "Ledger"],
//   },
//   {
//     moduleName: "Banking",
//     submodules: ["Bank", "Transaction"],
//   },
//   {
//     moduleName: "Reports",
//     submodules: ["Account Reports", "Purchase Reports", "Inventory Reports"],
//   },
//   {
//     moduleName: "Taxes",
//     submodules: ["TDS/TCS"],
//   },
// ];

const HelpModalForm = ({
  helpModalShow,
  handleHelpModalShow,
  handleHelpModalClose,
}) => {
  // const localData = JSON.parse(localStorage.getItem("userData")).data[0]; 
  // let userid = localData.userid;
  // let branchid = localData.branchid;
  // let companyid = localData.companyid;
  // let org = localData.org
  const { companyid, userid, org, branchid } =
    useSelector((state) => state && state.authManager && state.authManager.userData && state.authManager.userData.data && state.authManager.userData.data[0]) || {};

  let financialyearString = localStorage.financialyear || "";
  // const financialyear = JSON.parse(financialyearString);
  const [countLength, setCountLength] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketModalShow, SetTicketModalShow] = useState(false); // state for ticket modal
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [modulesArray, setModulesArray] = useState([]);

  useEffect(() => {
    axios.post('https://erp.venturesathi.co.in/api/v1/module/get')

      .then(response => {
        const fetchedModules = response.data;
        setModulesArray(fetchedModules);
      })
      .catch(error => {
        console.error('Error fetching module data:', error);
      });
  }, []);

  const handleTicketModalShow = () => {
    SetTicketModalShow(true)
  }

  const handleTicketModalClose = () => {
    SetTicketModalShow(false)
  }

  const handleCancel = () => {
    setData(
      {
        userid,
        email: "",
        module: "",
        submodule: "",
        description: "",
        documenturl: "",
        org,
        companyid,
        branchid,
      }
    )
    setShowEmptyError(false)
    handleHelpModalClose()
  }


  useEffect(() => {
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/help/count`, {

        userid: data.userid,
        financialyear: financialyearString
      })
      .then((res) => {
        setCountLength(`00${+(res.data.helpCount + 1)}`);
      })
      .catch((err) => console.log(err));
  }, [isLoading]);

  const [data, setData] = useState({
    userid: userid,
    email: "",
    module: "",
    submodule: "",
    description: "",
    documenturl: "",
    org,
    companyid,
    branchid,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // If the changed field is an input file (image), update the state with the selected image file
    if (name === "documenturl" && files && files.length > 0) {
      setData((prevData) => ({
        ...prevData,
        [name]: files[0], // Assuming you want to store only the first selected file
      }));
    } else {
      // For other fields, update the state based on user input
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const filteredModule = modulesArray.find(
    (module) => module.module === data.module
  );

  // for image upload

  const handleUploadPhoto = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(

        "https://erp.venturesathi.co.in/api/v1/help/upload",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data; // Return the entire response object
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  const handleSave = async () => {
    if (!data.email || !data.module || !data.submodule || !data.description) {
      setShowEmptyError(true);
      notifyError("You have Empty Fields")
    } else {
      try {
        setIsLoading(true)
        const imgUrl = await handleUploadPhoto(data.documenturl) || "";
        // const imgUrl = "https://www.google.com"
        const res = await axios.post("https://erp.venturesathi.co.in/api/v1/help/add", {

          data,
          imgUrl,
          countLength
        });
        setIsLoading(false);
        setData(
          {
            userid,
            email: "",
            module: "",
            submodule: "",
            description: "",
            documenturl: "",
            org,
            branchid,
            companyid,
          }
        )
        handleHelpModalClose()
        handleTicketModalShow()

      } catch (error) {
        console.log(error);
        setIsLoading(false)
        notifyError(error.message);
      }
    }
  };


  return (
    <>
      <Modal
        size="xl"
        show={helpModalShow}
        onHide={handleHelpModalClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title">Help</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-container">
            <Form>
              <div className="d-flex gap-4">
                <div className="w-100">
                  <Form.Group className="mb-3" controlId="formBasicId">
                    <Form.Label>
                      User ID<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control disabled value={data.userid} type="text" />
                    <Form.Control.Feedback type="invalid">
                      Enter User ID
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicSite">
                    <Form.Label>
                      Module<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      onChange={handleChange}
                      name="module"
                      value={data.module}
                      isInvalid={data.module === "" && showEmptyError}
                    >
                      <option>Select Module</option>
                      {modulesArray.map((module, ind) => {
                        return <option key={ind}>{module.module}</option>;
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Enter Module
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      Description <span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      as="textarea"
                      rows={3}
                      required
                      name="description"
                      value={data.description}
                      onChange={handleChange}
                      isInvalid={data.description === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter your issue in the Description
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="w-100">
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      E-mail ID<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter E-mail ID"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      isInvalid={data.email === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter E-mail ID
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicRole">
                    <Form.Label>
                      Sub Module<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="submodule"
                      onChange={handleChange}
                      value={data.submodule}
                      isInvalid={data.submodule === "" && showEmptyError}
                    >
                      <option value="">Select Submodule</option>
                      {filteredModule
                        ? filteredModule.submodule.map((submodule, ind) => {
                          return <option key={ind}>{submodule}</option>;
                        })
                        : null}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Sub Module
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formFile" style={
                    {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",

                    }
                  }>
                    <div>
                      <Form.Label>Attach Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleChange}
                        name="documenturl"
                        accept="image/*"
                      />
                    </div>
                    {data.documenturl && data.documenturl instanceof File && (
                      <img
                        src={URL.createObjectURL(data.documenturl)}
                        alt="selected image"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100px',
                          marginTop: '20px',
                          borderRadius: '5px',
                          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                        }} />
                    )}
                  </Form.Group>

                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2">
          {/* <Button
          type="button"
          className="commonBtn smallBtn"
          onClick={handleSave}
        >
          {isLoading?"Submitting..":"Submit"}
        </Button> */}
          {
            !isLoading ? <Button
              type="button"
              className="commonBtn smallBtn"
              onClick={handleSave}
            > Submit
            </Button> : <Button className="commonBtn smallBtn" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />

            </Button>
          }
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <TicketModal ticketModalShow={ticketModalShow} handleTicketModalClose={handleTicketModalClose} handleTicketModalShow={handleTicketModalShow} countLength={countLength} />
    </>
  );
};

export default HelpModalForm;
