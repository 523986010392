import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import ListTableSalesOrder from './ListTableSalesOrder';

function SalesOrder() {
    const URL = extractAndFormatURLPart("sales");
    return (
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListTableSalesOrder />
          </Col>
        </Row>
      </Container>
    )
}

export default SalesOrder;