import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  validateBankAccount,
  validateIFSC,
} from "../../../../constants/validationOnBlur";

const BankInformation = ({ data, handleChange, showEmptyError }) => {
  return (
    <Container fluid>
      <Row className="form-container">
        <Col>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicID">
                <Form.Label>
                  Bank Name<span className="star"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Bank Name"
                  name="bankname"
                  value={data.bankname}
                  onChange={handleChange}
                  isInvalid={data.bankname === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Bank Name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicID">
                <Form.Label>
                  Branch<span className="star"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Branch Name"
                  name="bankbranch"
                  value={data.bankbranch}
                  onChange={handleChange}
                  isInvalid={data.bankbranch === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Branch Name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicID">
                <Form.Label>
                  Account Number<span className="star"> *</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min="10"
                  max="15"
                  placeholder="Account Number"
                  name="bankaccno"
                  value={data.bankaccno}
                  onChange={handleChange}
                  onBlur={() => validateBankAccount(data.bankaccno)}
                  isInvalid={data.bankaccno === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Account Number
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicID">
                <Form.Label>
                  IFSC Code<span className="star"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="IFSC Code"
                  name="bankifsccode"
                  value={data.bankifsccode}
                  onChange={handleChange}
                  onBlur={() => validateIFSC(data.bankifsccode)}
                  isInvalid={data.bankifsccode === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter IFSC Code
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicID">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Remarks..."
                  name="remarks"
                  value={data.remarks}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default BankInformation;
