import { FETCH_INVOICE_OR_CHALLAN, FETCH_ISSUED_ITEM } from "../actionTypes/mtout.actiontypes";

const initialState = {
    issuegoodPagiData: {},
    invoiceChallan:""
  };
  
  export const mtoutReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_ISSUED_ITEM: {
        return { ...state, issuegoodPagiData: { ...payload } };
      }
      case FETCH_INVOICE_OR_CHALLAN: {
        return { ...state, invoiceChallan: payload };
      }
  
  
      default: {
        return state;
      }
    }
  };