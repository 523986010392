import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import TableHeading from "../../../constants/TableHeading";

const ViewIssueGoods = ({ data }) => {
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=1000,height=1000");

    const modalContent = containerRef.current.innerHTML;

    printWindow.document.open();
    printWindow.document.write(`
                <html>
                <head>
                <link rel="stylesheet" type="text/css" href="ReceiveGoods.css" media="print">
                <style>
                body {
                    font-family: Arial, sans-serif;
                    font-size:1.2rem
                }
    
                .modalHeader{
                    display:flex;
                    justify-content:space-between;
                    gap:50px;
                }
    
                .form-title {
                    font-size:2.5rem;
                    color: rgba(118, 74, 240, 0.81);
                }
    
                .active-status {
                    color: green;
                }
    
                .inactive-status {
                    color: red;
                }
    
                .viewpage-title {
                    display: inline-block;
                    width: 160px;
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin-right: 3rem;
                }
                  
                .viewpage-value {
                    font-size: 1rem;
                    font-weight: 500;
                }
    
                .modalBody{
                    display:flex;
                    justify-content:space-between;
                    border-bottom:2px solid grey;
                    padding-bottom:0.8rem;
                }
                .modalColumn{
                    display:flex;
                }
    
                .printTittle{
                    margin-bottom: -0.2rem;
                }
    
                .printTittle:last-child{
                    // margin-bottom: 1rem;
                }
    
                .printTittle>.viewpage-title{
                    display: inline-block;
                    width: 150px;
                    font-size: 1.15rem;
                    font-weight: 500;
                }
    
                .printTittle .viewpage-value{
                    font-size: 1.05rem;
                }
    
                .btn-close{
                    display:none
                }
    
                hr{
                    display: none;
                }
    
                // .tableContainer {
                //     --bs-table-bg: #eaecf0;
                // }
    
                table{
                    width:100%;
                    text-align: center;
                }
                
                thead {
                    background-color: #2E4374;
                    color: #F5F7F8;
                }
    
                th{
                    padding: 0.5rem 2.50rem;
                    border-inline: 0.5px solid #d0d5dd;
                }
    
                tbody{
                    padding: 1rem;
                    background:#F5F7F8;
                    font-size:1rem;
                    font-weight:normal
                }
    
                tbody tr{
                    border-bottom:3px solid grey;
                }
    
                tbody tr td{
                    padding:0.5rem 0rem;
                }
    
                .totalprint{
                    width:100%;
                    display:flex;
                    justify-content:flex-end;
                    margin-top:1rem;
                }
    
                .totalprint label{
                    margin-top:0.8rem;
                    margin-right:1.3rem;
                }
    
                .totalprint input{
                  font-size:1.6rem;
                  text-align:center;
                }
                </style>
                <title>Print</title>
                </head>
                <body>
                <h3>ISSUE GOODS</h3>
                <div class="modalHeader">
                <span><h2>SAANVI ENTERPRISES</h2>
                <h4>GSTIN: 36AAACH7409R1Z2</h4>
                <h4>PAN: AADCS0472N</h4>
                <p>M, 8, near Sudha Nursing Home, Basanti Nagar, Rourkela, Odisha 769012</p>
                </span>
                  <span><h4>GI NO: ${data.gino}</h4>
                  <h4>GI Date: ${data.gidate}</h4><h4>Dispatch Date: ${data.dispatchdate
      }</h4></span>
                </div>
                <div class="modalHeader">
                <span>
                <h4>Issue To</h4>
                <h3>${data.issueto}</h3>
                <p>${data.issuetoname} - Address</p>
                <h4>Issue By: ${data.issuedby}</h4>
                </span>
                <span>
                <h4>${data.issueto == "Company"
        ? `Company ID: ${data.psorder}`
        : data.issueto == "Franchise"
          ? `Franchise ID: ${data.psorder}`
          : data.issueto == "Sub-Dealer"
            ? `Sub-Dealer ID: ${data.psorder}`
            : data.issueto == "Return To Vendor"
              ? `Vendor ID: ${data.psorder}`
              : data.issueto == "Customer"
                ? `Customer ID: ${data.psorder}`
                : ""
      }</h4>
                <h4>${data.issueto == "Customer"
        ? `Sale Invoice No: ${data.psorder}`
        : data.issueto == "Return To Vendor"
          ? `Purchase Bill No: ${data.psorder}`
          : ""
      }</h4>
                
                <h4>Vehicle Type: ${data.vehicletype}</h4>
                <h4>Vehicle No: ${data.vehicleno}</h4>
                <h4>E-Way Bill: ${data.ewaybill}</h4>
                <h4>Checked By: ${data.checkedby}</h4>
                <h4>Remarks: ${data.remarks}</h4>
                </span>
                </div>
                
            `);
    printWindow.document.write(modalContent);
    printWindow.document.write(`
    <br/>
    <br/>
    <br/>
    <p>____________________</p>
    <p>Authorized Signature</p>
    `);
    printWindow.document.write(`</body></html>`);
    printWindow.document.close();

    // Print and close the print window
    printWindow.print();
    printWindow.close();
  };

  return (
    <>
      <AiFillEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">Summary</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col className="viewpage-title">Issue To:</Col>{" "}
                  <Col className="viewpage-value">{data.issueto}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">
                    {data.issueto == "Company"
                      ? "Company ID"
                      : data.issueto == "Franchise"
                        ? "Franchise ID"
                        : data.issueto == "Sub-Dealer"
                          ? "Sub-Dealer ID"
                          : data.issueto == "Return To Vendor"
                            ? "Vendor ID"
                            : data.issueto == "Customer" && "Customer ID"}
                    :
                  </Col>{" "}
                  <Col className="viewpage-value">{data.issuetoid}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">GI No:</Col>{" "}
                  <Col className="viewpage-value">{data.gino}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">GI Date:</Col>{" "}
                  <Col className="viewpage-value">{data.gidate}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">E-Way Bill:</Col>{" "}
                  <Col className="viewpage-value">{data.ewaybill}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Remarks:</Col>{" "}
                  <Col className="viewpage-value">{data.remarks}</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="viewpage-title">
                    {data.issueto == "Company"
                      ? "Company"
                      : data.issueto == "Franchise"
                        ? "Franchise"
                        : data.issueto == "Sub-Dealer"
                          ? "Sub-Dealer"
                          : data.issueto == "Return To Vendor"
                            ? "Vendor"
                            : data.issueto == "Customer" && "Customer"}
                    :
                  </Col>{" "}
                  <Col className="viewpage-value">{data.issuetoname}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">
                    {data.issueto == "Franchise" ||
                      data.issueto == "Sub-Dealer" ||
                      data.issueto == "Return To Vendor"
                      ? "Purchase Bill No"
                      : data.issueto == "Customer" && "Sale Invoice No"}
                    :
                  </Col>{" "}
                  <Col className="viewpage-value">{data.psorder}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Vehicle Type:</Col>{" "}
                  <Col className="viewpage-value">{data.vehicletype}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Vehicle No:</Col>{" "}
                  <Col className="viewpage-value">{data.vehicleno}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Issued By:</Col>{" "}
                  <Col className="viewpage-value">{data.issuedby}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Checked By:</Col>{" "}
                  <Col className="viewpage-value">{data.checkedby}</Col>
                </Row>
                <Row>
                  <Col className="viewpage-title">Dispatch Date:</Col>{" "}
                  <Col className="viewpage-value">{data.dispatchdate}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row ref={containerRef}>
              <Table className="table-main-container" responsive>
                <TableHeading
                  data={[
                    "#",
                    "Item Name",
                    "Item ID",
                    "UOM",
                    "Qty",
                    "Dispatch Qty",
                    "Rate",
                    "Amount",
                  ]}
                />
                <tbody>
                  {data.itemarray &&
                    data.itemarray.length > 0 &&
                    data.itemarray.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.itemname}</td>
                        <td>{item.itemid}</td>
                        <td>{item.unit}</td>
                        <td>{item.qty}</td>
                        <td>{item.dispatchqty}</td>
                        <td>{item.rate}</td>
                        <td>{item.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Row>
                <Col md={9}></Col>
                <Col>
                  <Row className="totalprint">
                    <Col className="viewpage-title">Sub Total:</Col>{" "}
                    <Col className="viewpage-value">{data.subtotal}</Col>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button className="commonBtn smallBtn" onClick={handlePrint}>
            Print
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewIssueGoods;
