import { notifyError } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { categoryPagiDataPost } from "../../constants/api";
import { FETCH_CUSTOMER_DATA } from "../actionTypes/customer.actionTypes";
import { FETCH_CATEGORY_DATA } from "../actionTypes/category.actiontypes";

//Get all customer data using pagination wise
export const categoryPagiData =(backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(categoryPagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_CATEGORY_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching category data ${error}`);
      notifyError(error.message);
    }
  };
