import React from 'react'

import { Col, Container, Row } from 'react-bootstrap';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import ListTableSuggestedItem from './ListTableSuggestedItem';


const SuggestedItem = () => {
    const URL = extractAndFormatURLPart("inventory");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTableSuggestedItem />
        </Col>
      </Row>
    </Container>
  )
}

export default SuggestedItem