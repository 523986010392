import React, { useState } from "react";
import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
const ViewSuggestedItem = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const {
    groupid,
    groupname,
    createdby,
    category,
    brand,
    primaryitem,
    itemarray,
  } = item || {};

  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" d-flex w-100 justify-content-between gap-10">
            <div className="form-title ">Summary</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          <Container>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Group ID:</Col>
                  <Col className="viewpage-value">{groupid ? groupid : "--"}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Group Name:</Col>
                  <Col className="viewpage-value">
                    {groupname ? groupname : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Created By:</Col>
                  <Col className="viewpage-value">
                    {createdby ? createdby : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Primary Item:</Col>
                  <Col className="viewpage-value">{primaryitem ? primaryitem : "--"}</Col>
                </Row>
                
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Brand Name:</Col>
                  <Col className="viewpage-value">
                    {brand ? brand : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Category:</Col>
                  <Col className="viewpage-value">
                    {category ? category : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <br />
            <Col md={12} className="table-main-container">
              <Table responsive>
                <thead className="tableContainer">
                  <tr className="bg-secondary">
                    <th>SI No.</th>
                    <th>Item ID</th>
                    <th>Item Name</th>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody>
                  {itemarray.length > 0 ? (
                    itemarray.map((item, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{ind + 1}</td>
                          <td>{item.itemid?item.itemid:"--"}</td>
                          <td>{item.itemname?item.itemname:"--"}</td>
                          <td><img src={!item.image || !item.image.length>0?"":item.image[0]} alt="No Image" width={40} height={40} className="rounded-3"/></td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">
                        <div className="datanotfound-center-text">
                          No target Found 🤔
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <br />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewSuggestedItem;
