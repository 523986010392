import {
  creditNoteAddPost,
  creditNoteDeletePost,
  creditNoteMultipleDocumentUploadPost,
  creditNotePagiDataPost,
  creditNoteUpdatePost,
} from "../../constants/api";
import axios from "axios";
import {
  FETCH_CREDIT_NOTE_DATA,
  FETCH_CREDIT_NOTE_DOCUMENT,
} from "../actionTypes/creditNote.actiontypes";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { notifyError, notifySuccess, notifyWarning } from "../../constants/toastAlert";

//Get all salesInvoice data using pagination wise
export const creditNotePagiData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(creditNotePagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_CREDIT_NOTE_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching CreditNote data ${error}`);
      notifyError(error.message);
    }
  };

//Action dispatcher for document upload
export const creditNoteDocmnentUpload = (backendData) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
    const res = await axios.post(creditNoteMultipleDocumentUploadPost,formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
   
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: FETCH_CREDIT_NOTE_DOCUMENT, payload: fileurl });
    } else {
      notifyWarning("File not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading credit Note file:", error.message);
    notifyError(error.message);
    throw error;
  }
};

//Acion dispatcher for adding new creditNote data
export const creditNoteAdd = (backendData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(creditNoteAddPost, backendData);
    if (res.data.message === "Credit note added successfully") {
      notifySuccess(res.data.message);
      navigate("/sales/creditNote");
    } else {
      notifyError(res.data.message);
    }
  } catch (error) {
    console.log(`Error in adding new credit Note data ${error}`);
    notifyError(error.res.data.message);
  }
};

//Acion dispatcher for updating credit notes data
export const creditNoteUpdate = (backendData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(creditNoteUpdatePost, backendData);
    
    if (res.data.message === 'Credit note updated successfully') {
      notifySuccess(res.data.message);
      navigate("/sales/creditNote");
    } else {
      notifyError(res.data.message);
    }
    navigate("/sales/creditNote");
  } catch (error) {
    console.log(`Error in updating credit Note data ${error}`);
    notifyError(error.res.data.message);
  }
};

//Delete credit Note data
export const creditNoteDelete =
  (backendData, handleClose) => async (dispatch) => {
    try {
      const res = await axios.post(creditNoteDeletePost, backendData);
      if (res.data.message == "Credit Note deleted successfully") {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleClose();
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.log(`Error in deleting Credit Note data ${error}`);
      notifyError(error.message);
    }
  };
