import axios from "axios";
import { getPurchasetargetDashboardApi } from "../../constants/api";
import { FETCH_PURCHASETARGET_DASHBOARD_DATA } from "../actionTypes/purchaseTarget.types";
import { notifyError } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";

export const purchaseTargetDashboardData = (backendData, setLoading) => async (dispatch) => {
    
    
    try {
      const res = await axios.post(getPurchasetargetDashboardApi, backendData);
      
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_PURCHASETARGET_DASHBOARD_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in fetching purchase target dashboard data ${error}`);
      notifyError(error.message);
    }
  };