import axios from "axios";
import {
  addSalePayment,
  fileUploadSalePayment,
  getAllSalePaymentPagi,
  getSalePaymentByID,
  salePaymentID,
} from "../../constants/api";
import { notifyError, notifySuccess, notifyWarning } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  ADD_SALE_PAYMENT,
  GET_ALL_SALE_PAYMENT,
  GET_SALE_PAYMENT_BY_ID,
  SALE_PAYMENT_ID_GENERATE,
  UPLOAD_FILE,
} from "../actionTypes/salepayment.actiontypes";

//Get all Sale Payment data using pagination wise
export const salePaymentData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getAllSalePaymentPagi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: GET_ALL_SALE_PAYMENT, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching Sale payment data ${error}`);
      notifyError(error.message);
    }
  };

//Acion dispatcher for adding new sale payment
export const addSalePaymentData = (paymentdata, navigate) => async (dispatch) => {
  try {
    const res = await axios.post(addSalePayment, paymentdata);
    if (res.statusText === "OK") {
      dispatch({ type: ADD_SALE_PAYMENT });
      notifySuccess(res.data.message);
      navigate("/sales/salePayment");
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in adding Sale Payment data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Action dispatcher for document upload
export const salePaymentFileUpload = (backendData) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
    
    const res = await axios.post(fileUploadSalePayment, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: UPLOAD_FILE, payload: fileurl });
    } else {
      notifyWarning("File not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading file:", error.message);
    throw error;
    notifyError(error.message);
  }
};

//Get Sale Payment by ID
export const salePaymentDataByID =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getSalePaymentByID, backendData);
      const initialData = res.data && res.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: GET_SALE_PAYMENT_BY_ID, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching Sale payment data ${error}`);
      notifyError(error.message);
    }
  };

//Action dispatcher for ID Generate
export const generateSalePaymentID =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(salePaymentID, backendData);
      
      const initialCount = Number(res.data.salepaymentCount) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: SALE_PAYMENT_ID_GENERATE, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching salepayment auto count data ${error}`);
      notifyError(error.message);
    }
  };
