import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Table } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  addptData,
  deleteptData,
  paymentTermData,
} from "../../../redux/actions/pt.actions";
import { MdDelete } from "react-icons/md";
import { notifyError } from "../../../constants/toastAlert";

const TermsMaster = ({ show, setShow, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data);
  const ptdata = useSelector((state) => state.ptData.ptData);
  const loadData = useSelector((state) => state.loadReducer);
  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const branchid = userData[0].branchid;
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [data, setData] = useState({
    termname: "",
    days: "",
    companyid,
    userid,
    branchid,
  });

  const handleDelete = (id) => {
    dispatch(deleteptData({ id }, handleClose));
  };

  const handlechange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (!data.termname || !data.days) {
      setShowEmptyError(true);
      return notifyError("You have empty fields");
    }

    dispatch(addptData(data, handleCloseModal));
  };

  const handleCloseModal = () => {
    setData((prev) => ({
      ...prev,
      termname: "",
      days: "",
    }));
    setShowEmptyError(false);
    setShowForm(false);
    handleClose();
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(paymentTermData({ companyid, userid, branchid }, setLoading));
  }, [dispatch, loadData]);

  return (
    <>
      <span className="form-title-sibling" onClick={() => setShow(true)}>
        <BsPlusCircle /> Manage Terms
      </span>
      <Modal show={show} onHide={handleCloseModal} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Manage <span className="form-title">Payment Terms</span>
          </Modal.Title>
        </Modal.Header>
        <br />
        <br />
        <Modal.Body>
          <Col className="table-main-container">
            <Table responsive>
              <thead className="tableContainer">
                <tr>
                  <th>Term Name</th>
                  <th>No Of Days</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ptdata.length > 0 ? (
                  ptdata.map((ele, ind) => {
                    return (
                      <tr key={ele.id}>
                        <td>{ele.termname}</td>
                        <td>{ele.days}</td>
                        <td
                          className="text-danger"
                          onClick={() => handleDelete(ele.id)}
                        >
                          <MdDelete />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center mt-2 mb-2">
                    <br />
                    No Payment Terms Found 🤔
                  </tr>
                )}
                {showForm ? (
                  <tr>
                    <td>
                      <Form.Control
                        type="text"
                        name="termname"
                        value={data.termname}
                        onChange={handlechange}
                        isInvalid={!data.termname && showEmptyError}
                      ></Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        name="days"
                        value={data.days}
                        onChange={handlechange}
                        isInvalid={!data.days && showEmptyError}
                      ></Form.Control>
                    </td>
                    <td>
                      <Button className="commonBtn" onClick={handleSave}>
                        Save
                      </Button>
                    </td>
                    <td>
                      <Button
                        className="commonBtn cancelBtn"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </Button>
                    </td>
                  </tr>
                ) : null}
              </tbody>
              <br />
            </Table>
          </Col>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2">
          <h4 className="form-title" onClick={() => setShowForm(true)}>
            <BsPlusCircle /> Add New
          </h4>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TermsMaster;

