import {
  coaAddPost,
  coaCountPost,
  coaPagiDataPost,
  coaUpdatePost,
  deleteCoaPost,
} from "../../constants/api";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";

import { FETCH_COA_COUNT, FETCH_COA_DATA, FETCH_COA_DATA1 } from "../actionTypes/chartofaccount.actiontypes";

//Get all COA data using pagination wise
export const coaPagiData = (backendData, setLoading) => async (dispatch) => {
  try {

    const res = await axios.post(coaPagiDataPost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      
    if (initialData) {
      dispatch({ type: FETCH_COA_DATA, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching branch data ${error}`);
    notifyError(error.message);
  }
};
export const coaPagiData1 = (backendData, setLoading) => async (dispatch) => {
  try {

    const res = await axios.post(coaPagiDataPost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
    if (initialData) {
      dispatch({ type: FETCH_COA_DATA1, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching branch data ${error}`);
    notifyError(error.message);
  }
};

//Get COA auto increment count
export const coaAutoCount =(backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(coaCountPost, backendData);
      const initialCount = Number(res.data.count) + 1 || 0;
        if(initialCount != 0) {
        dispatch({ type: FETCH_COA_COUNT, payload: initialCount });
      } else {
        notifyError('Count not comming from backend');
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching auto count data ${error}`);
      notifyError(error.message);
    }
  };

//Status change dispatcher
//   export const branchStatusChange = (backendData) => async (dispatch) => {
//     try {
//       dispatch({ type: RELOAD, payload: true });
//       const res = await axios.post(branchStatusChangePost, backendData);
//       if (res.data.message === "Branch Activated") {
//         notifySuccess(res.data.message);
//       } else {
//         notifyWarning(res.data.message);
//       }
//       dispatch({ type: RELOAD, payload: true });
//     } catch (error) {
//       console.log(`Error in changing company status ${error}`);
//       notifyError(error.message);
//     }
//   };

//Acion dispatcher for adding new coa data
export const addCoa = (backendData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(coaAddPost, backendData);
    if (res.data.message === "Account added successfully") {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    
    handleClose();
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new COA data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating coa data
export const updateCoa = (backendData, handleCloseEditModal, setData) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(coaUpdatePost, backendData);
    if (res.data.message === "Account updated successfully") {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    setData({
      coaid: "",
      category: "",
      subcategory: "",
      gledger: "",
      isledger: "",
    });
    handleCloseEditModal();
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in updating coa data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Delete chart of account data
export const deleteCoa =
(backendData, handleClose) => async (dispatch) => {
try {
  const res = await axios.post(deleteCoaPost, backendData);
 
  if (res.data.message == 'Account deleted successfully') {
    notifySuccess(res.data.message);
  } else {
    notifyError(res.data.message);
  }
  handleClose();
  dispatch({ type: RELOAD, payload: true });
} catch (error) {
  console.log(`Error in deleting COA data ${error}`);
  notifyError(error.message);
}
};
