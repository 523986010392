import {
  FETCH_ISSUE_GOODS_DATA,
  FETCH_SALES_INVOICE_COUNT,
  FETCH_SALES_INVOICE_DATA,
  FETCH_SALES_INVOICE_DOCUMENT,
  FETCH_SALES_INVOICE_SINGLE_DATA,
  FETCH_SALES_ORDER_ITEM_DATA,
} from "../actionTypes/salesInvoice.actiontypes";

const initialState = {
  salesInvoicePagiData: {},
  salesInvoiceSingleData: [],
  salesInvoiceCount: 0,
  salesInvoiceDocument: "",
  issueGoodsPagiData: [],
  salesInvoiceItemData:[]
};

export const salesInvoiceReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_SALES_INVOICE_DATA: {
      return { ...state, salesInvoicePagiData: { ...payload } };
    }

    case FETCH_SALES_INVOICE_SINGLE_DATA: {
      return { ...state, salesInvoiceSingleData: { ...payload } };
    }

    case FETCH_SALES_INVOICE_COUNT: {
      return { ...state, salesInvoiceCount: payload };
    }

    case FETCH_SALES_INVOICE_DOCUMENT: {
      return { ...state, salesInvoiceDocument: payload };
    }

    case FETCH_ISSUE_GOODS_DATA: {
      return { ...state, issueGoodsPagiData: { ...payload } };
    }

    case FETCH_SALES_ORDER_ITEM_DATA: {
      return { ...state, salesInvoiceItemData: payload };
    }

    default: {
      return state;
    }
  }
};
