import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";

import { Editor as WysiwygEditor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const ViewModal = ({termname, description}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  return (
    <>
      <MdOutlineRemoveRedEye className="viewIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">{termname}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
        
              <Col style={{fontSize:"16px"}}>
              <WysiwygEditor
                        editorState={EditorState.createWithContent(
                          convertFromRaw(JSON.parse(description))
                        )}
                        toolbarHidden
                        readOnly
                      />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewModal;
