import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import vs_logo from "../../../assets/venturesathi-logo.png";

const PurchaseOrderPrint = React.forwardRef(({ data, billinfo }, ref) => {
  console.log("billinfo", billinfo)
  const iscgst =
    data.vendorgst[0] == data.locationgst[0] &&
    data.vendorgst[1] == data.locationgst[1];

  return (
    <div className="print-content" ref={ref}>
      <div
        className="dr-logo-heading dr-logo-headingp bb"
      >
        <div>
          <img src={billinfo.brandlogo ? billinfo.brandlogo : vs_logo} alt="logo" width={120} height={50} />
        </div>
        <div >
          <h3>{billinfo.brandname ? billinfo.brandname : "--"}</h3>
          <h4>{billinfo.address ? billinfo.address : "--"}</h4>
          <h4 className="font-bold">GSTIN/UIN: {billinfo.gstno ? billinfo.gstno : "--"}</h4>
          <h4>State Name : {billinfo.state ? billinfo.state : "--"}, Code : {billinfo.statecode ? billinfo.statecode : "--"}</h4>
          <h4> E-Mail : {billinfo.email ? billinfo.email : "--"}</h4>
        </div>
        <div>
          <Button className="commonBtn mb-1" style={{ border: "none" }}>
            Purchase Order
          </Button>
          <h4 className="font-bold">Phone No:- {billinfo.phonenumber ? billinfo.phonenumber : "--"}</h4>
          <h4>Website:- {billinfo.websitename ? billinfo.websitename : "--"}</h4>
          <h4 className="font-bold">Purchase Order:- {data.purchaseorderno}</h4>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center bb ">
        <div style={{width:"48%"}} className="px-2">
          <h5 className="font-bold">Bill To:- {data.vendorname ? data.vendorname : "--"}</h5>
          <h5>Address:- {data.address}</h5>
          <h5>GST:- {data.vendorgst}</h5>
        </div>
        <div style={{width:"48%"}} className="px-2">
          <h5 className="font-bold">Ship To:- {billinfo.brandname ? billinfo.brandname : "--"}</h5>
          <h5>Address:- {data.deliveryaddress}</h5>
          <h5>GST:- {data.locationgst}</h5>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center py-1"
        style={{ borderBottom: "1px solid black" }}
      >
        <div style={{width:"30%"}} className="px-2">
          <h5 className="font-bold">Payment Terms:- {data.paymentterms}</h5>
          <h5>Payment Due Date:- {data.paymentduedate}</h5>
          <h5>Payment Due Date:- {data.contactperson}</h5>
        </div>
        <div style={{width:"30%"}} className="px-2">
          <h5 className="font-bold">Delivery Type:- {data.deliverytype}</h5>
          <h5>Expected Delivery Date:- {data.expecteddeliverydate}</h5>
          <h5>Delivery Instructions:- {data.deliveryinstruction}</h5>
        </div>
        <div style={{width:"30%"}} className="px-2">
          <h5 className="font-bold">Purchase Date:- {data.purchasedate}</h5>
          <h5>Assigned By:- {data.assignedby}</h5>
        </div>
      </div>
      <Table responsive className="table-color fs-4">
        <thead>
          <tr>
          <th className="th-color">S.I</th>
            <th className="th-color">Item Name</th>
            <th className="th-color">Unit</th>
            <th className="th-color">QTY</th>
            <th className="th-color">Rate</th>
            <th className="th-color">GST%</th>
            <th className="th-color">Discount%</th>
            <th className="th-color">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.itemarray?.arr?.length > 0 ? (
            data.itemarray.arr.map((item, ind) => {
              return (
                <tr key={ind}>
                    <td>{ind+1}</td>
                  <td className="tddata-left">
                    {item.itemname}
                  </td>
                  <td>{item.unit}</td>
                  <td>{item.qty}</td>
                  <td>{item.rate}</td>
                  <td>{item.gst}</td>
                  <td>{item.discount}</td>
                  <td>{item.discountamount}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8">
                <div className="datanotfound-center-text">No Item Found 🤔</div>
              </td>
            </tr>
          )}
          {new Array(5).fill("")?.map((emtr, i) => (
            <tr key={i}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <hr />
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="tddata-right">Sub Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.subtotal}</td>
          </tr>
          {iscgst ? (
            <tr>
              <td></td>
              <td className="tddata-right">CGST</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="font-bold td-top-border">{data.cgst}</td>
            </tr>
          ) : null}
          {iscgst ? (
            <tr>
              <td></td>
              <td className="tddata-right">SGST</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="font-bold td-top-border">{data.sgst}</td>
            </tr>
          ) : null}
          {!iscgst ? (
            <tr>
              <td></td>
              <td className="tddata-right">IGST</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="font-bold td-top-border">{data.igst}</td>
            </tr>
          ) : null}
          <tr>
            <td></td>
            <td className="tddata-right">Adjustment</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.adjustment}</td>
          </tr>
          {new Array(5).fill("")?.map((emtr, i) => (
            <tr key={i}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
          <tr className="table-row-bg">
           <td></td>
            <td></td>
            <td className="tddata-right">Total</td>
            <td className="font-bold">
              {data.itemarray?.arr?.reduce((acc, curr) => {
                return Number(acc) + Number(curr.qty);
              }, 0)}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold">{data.finalamount}</td>
          </tr>
        </tbody>
      </Table>
      <div>
        <div className="d-flex" style={{ border: "1px solid black" }}>
          <div
            style={{ width: "65%", borderRight: "1px solid black" }}
            className="d-flex align-items-center justify-content-center"
          >
            <h4 className="text-center font-bold">Terms & Conditions</h4>
          </div>
          <div style={{ width: "35%" }}>
            <h4 className="text-center font-bold">
              Certified that particulars given above are true & correct
            </h4>
            <h4 className="text-center font-bold">For {billinfo.brandname ? billinfo.brandname : "--"}</h4>
          </div>
        </div>
        <div
          className="d-flex"
          style={{ border: "1px solid black", borderTop: "none" }}
        >
          <div style={{ width: "65%", borderRight: "1px solid black" }}>
            <h5 className="text-center font-bold">{data.tandc}</h5>
          </div>
          <div style={{ width: "35%" }}></div>
        </div>
        <div
          className="d-flex"
          style={{ border: "1px solid black", borderTop: "none" }}
        >
          <div style={{ width: "65%", borderRight: "1px solid black" }}></div>
          <div style={{ width: "35%" }}>
            <h3 className="text-center">Authorised Signature</h3>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PurchaseOrderPrint;
