

  
//   import { RELOAD } from "../actionTypes/load.actiontypes";

import axios from "axios";
import { addProjectApi, deleteProjectApi, getProjectApi, updateProjectApi } from "../../constants/api";
import { ADD_PROJECT_DATA, FETCH_PROJECT_DATA, UPDATE_PROJECT_DATA } from "../actionTypes/projects.actiontypes";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { notifyError, notifySuccess } from "../../constants/toastAlert";



  
  //Get all warehouse data using pagination wise
  export const projectData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getProjectApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];

      if (initialData) {
        dispatch({ type: FETCH_PROJECT_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching project data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete stocklocation data dispatcher
  export const deleteProjectData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteProjectApi, backendData);
        if (res.data.message == "Project deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting project data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new vendor data
  export const addProject = (projectdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addProjectApi, projectdata);
  
      if (res.data.message === "Project added successfully") {
        notifySuccess(res.data.message);
        if(navigate){
          navigate("/sales/projectManagement");
        }
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding project data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateProject = (projectdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updateProjectApi, projectdata);
  
      if (res.data.message === "Project updated successfully") {
        dispatch({ type: UPDATE_PROJECT_DATA });
        notifySuccess(res.data.message);
        if(navigate){
          navigate("/sales/projectManagement");
        }
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating project data ${error}`);
      notifyError(error.response.data.message);
    }
  };