import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ListTableSalesTarget from './ListTableSalesTarget'


const SalesTarget = () => {
  return (
    <Container fluid>
    <Row>
      <Col>
        <ListTableSalesTarget />
      </Col>
    </Row>
  </Container>
  )
}

export default SalesTarget