import { FETCH_CATEGORY_DATA } from "../actionTypes/category.actiontypes";

const initialState = {
  categoryPagiData: {},
};

export const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CATEGORY_DATA: {
      return { ...state, categoryPagiData: { ...payload } };
    }

    default: {
      return state;
    }
  }
};
