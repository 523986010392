
import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import './styles/theme.css';
import './styles/layout.css';
import './styles/module.css';
import './styles/states.css';
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <BrowserRouter>
      <ToastContainer/>
        <Router />
      </BrowserRouter>
    </>
  );
}

export default App;
