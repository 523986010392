// Assets
export const assests_sub = [
    "Property,Plant and Equipment",
    "Intangible Assets",
    "Capital work-in-progress",
    "Intangible Assets under development",
    "Non-Current Investments",
    "Currrent Investments",
    "Inventories",
    "Trade receivables",
    "Cash and Cash equivalents",
    "Loans and Advances",
    "Other Asset"
  ];

// Equity and Liabilities
export const equity_liability_sub = [
    "Equity",
    "Reserves and Surplus",
    "Loans",
    "Deposits",
    "Duties and Taxes",
    "Trade Payables",
    "Provisions",
    "Other Liabilities",
];

// Income
export const income_sub = [
    "Direct Income",
    "Closing Stock",
    "Other Income",
    
];

// Expenses
export const expenses_sub = [
    "Opening Stock",
    "Purchases",
    "Employee Benefit Expenses",
    "Finance Costs",
    "Depreciation",
    "Other Expenses",
];

