import {
  FETCH_CONTRA_ENTRY_COUNT,
  FETCH_CONTRA_ENTRY_DATA,
} from "../actionTypes/contraEntry.actiontypes";

const initialState = {
  contraEntryPagiData: {},
  contraEntryCount: 0,
};

export const contraEntryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONTRA_ENTRY_DATA: {
      return { ...state, contraEntryPagiData: { ...payload } };
    }

    case FETCH_CONTRA_ENTRY_COUNT: {
      return { ...state, contraEntryCount: payload };
    }

    default: {
      return state;
    }
  }
};
