
import axios from "axios";
import { addPurchaseTargetApi, deletePurchaseTargetApi, getPurchaseTargetApi, updatePurchaseTargetApi } from "../../constants/api";
import { ADD_PURCHASETARGET_DATA, FETCH_PURCHASETARGET_DATA, UPDATE_PURCHASETARGET_DATA } from "../actionTypes/purchaseTarget.types";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";


  
  //Get all warehouse data using pagination wise
  export const purchaseTargetData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getPurchaseTargetApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_PURCHASETARGET_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in fetching purchase target data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete stocklocation data dispatcher
  export const deletePurchaseTargetData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deletePurchaseTargetApi, backendData);
        if (res.data.message == "Purchase target deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting purchase target data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new vendor data
  export const addPurchaseTarget = (purchasetargetdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addPurchaseTargetApi, purchasetargetdata);
  
      if (res.data.message === "Purchase target added successfully") {
        dispatch({ type: ADD_PURCHASETARGET_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/purchaseTarget");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      dispatch({ type: RELOAD, payload: false });
      console.log(`Error in adding purchase target data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updatePurchaseTarget = (purchasetargetdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updatePurchaseTargetApi, purchasetargetdata);
  
      if (res.data.message === "Purchase target updated successfully") {
        dispatch({ type: UPDATE_PURCHASETARGET_DATA });
        notifySuccess(res.data.message);
        navigate("/purchase/purchaseTarget");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating purchase target data ${error}`);
      notifyError(error.response.data.message);
    }
  };