import { FETCH_SALES_ORDER_COUNT, FETCH_SALES_ORDER_DATA, FETCH_SALES_ORDER_DOCUMENT, FETCH_TERM_AND_CONDITION_DATA } from "../actionTypes/salesOrder.actiontypes";
  
  const initialState = {
    salesOrderPagiData: {},
    salesOrderCount: 0,
    salesOrderDocument:"",
    termsAndConditionAllData:[]
  };
  
  export const salesOrderReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_SALES_ORDER_DATA: {
        return { ...state, salesOrderPagiData: { ...payload } };
      }
  
      case FETCH_SALES_ORDER_COUNT: {
        return { ...state, salesOrderCount: payload };
      }

      case FETCH_SALES_ORDER_DOCUMENT: {
        return { ...state, salesOrderDocument: payload };
      }

      case FETCH_TERM_AND_CONDITION_DATA: {
        return { ...state, termsAndConditionAllData: payload };
      }
  
      default: {
        return state;
      }
    }
  };
  