import React, { useState } from "react";
import LoginFormLogo from "../../assets/login_logo_image.png";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { FaUser } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { GoOrganization } from "react-icons/go";

const Signupform = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    org: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="loginForm">
      <div className="formImages">
        <h2 className="formHeader">Sign Up</h2>
        <img src={LoginFormLogo} alt="logo image" />
      </div>
      <Form
        id="loginForm"
        //onSubmit={handleSignUp}
      >
        <Row></Row>
        <Form.Group className="form-group" controlId="formBasicId">
          <div className="inputFieldIcon">
            <Form.Control
              value={data.name}
              type="text"
              name="name"
              placeholder="Enter Name"
              onChange={handleChange}
              isInvalid={data.name === "" && showEmptyError}
            />
            <span className="iconPosition">
              {/* <FaUserNinja /> */}
              {data.name === "" && showEmptyError ? null : <FaUser />}
            </span>
          </div>
        </Form.Group>
        <Form.Group className="form-group" controlId="formBasicId">
          <div className="inputFieldIcon">
            <Form.Control
              value={data.email}
              type="email"
              name="email"
              placeholder="Enter Email"
              onChange={handleChange}
              isInvalid={data.email === "" && showEmptyError}
            />
            <span className="iconPosition">
              {data.email === "" && showEmptyError ? null : <MdMail />}
            </span>
          </div>
        </Form.Group>
        <Form.Group className="form-group" controlId="formBasicPassword">
          <div className="inputFieldIcon">
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              name="password"
              value={data.password}
              onChange={handleChange}
              isInvalid={data.password === "" && showEmptyError}
            ></Form.Control>
            <span className="iconPosition">
              {showPassword ? (
                <IoEyeOff onClick={togglePasswordVisibility} />
              ) : (
                <IoEye onClick={togglePasswordVisibility} />
              )}
            </span>
          </div>
        </Form.Group>
        <Form.Group className="form-group" controlId="formBasicOrganization">
          <div className="inputFieldIcon">
            <Form.Control
              type="text"
              placeholder="Organisation Name"
              name="org"
              value={data.org}
              onChange={handleChange}
              isInvalid={data.org === "" && showEmptyError}
            />
            <span className="iconPosition">
              {/* <SlOrganization /> */}
              {data.org === "" && showEmptyError ? null : <GoOrganization />}
            </span>
          </div>
        </Form.Group>

        <Form.Label
          style={{
            float: "right",
            margin: "0px 10px 10px 0px",
            color: "#5e5e5e",
          }}
        >
          Already have an account{" "}
          <span className="exists" onClick={() => navigate("/login")}>
            {" "}
            Login{" "}
          </span>
        </Form.Label>

        <div className="form-group">
          <Button type="submit" className="commonBtn">
            Sign Up
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Signupform;
