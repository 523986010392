import {FETCH_PROJECT_TEAMS_DATA } from "../actionTypes/projects.actiontypes";


  const initialState = {
    projectTeamsData: {},
  };
  
  //
  export const projectTeamsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_PROJECT_TEAMS_DATA: {
        return { ...state, projectTeamsData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };