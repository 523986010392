import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import SearchField from "../../../constants/SearchField";
import { DownloadTableExcel } from "react-export-table-to-excel";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import DataSpinner from "../../../constants/DataSpinner";
import TableHeading from "../../../constants/TableHeading";
import { getReceiveGood, itemGetItemPost } from "../../../constants/api";
import axios from "axios";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";

const ListTable = () => {
  const pdfRef = useRef(null);
  const userData = useSelector((state) => state.authManager.userData.data);
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const formattedStartOfMonth = formatDate(monthStart);
  const formattedEndOfMonth = formatDate(monthEnd);
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        //handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };
  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const calculateAge = (date) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const currentDate = new Date();
    const itemDate = new Date(date);
    const diffDays = Math.round(Math.abs((currentDate - itemDate) / oneDay));
    return diffDays;
  };

  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const org = userData[0].org;
  const backendData = {
    companyid: companyid,
    userid: userid,
    keyword: "" || org,
  };
  useEffect(() => {
    axios
      .post(getReceiveGood, {
        ...backendData,
        keyword: searchKeyword || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data.map((item) => ({
                ...item,
                itemarray: item.itemarray.map((subItem) => ({
                  ...subItem,
                  grn: item.grn,
                  date: item.date,
                  age: calculateAge(item.date),
                })),
              }))
            : [];
        const allObjs = initialData.flatMap((obj) => obj.itemarray);
        setData(allObjs);
      })
      .catch((err) => console.log(err));
  }, []);

  //search & filter logic
  const filteredData = !data
    ? []
    : data
        .filter((item) =>
          searchKeyword
            ? Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchKeyword.toLowerCase().trim())
              )
            : true
        )
        .filter((item) => {
          if (!fromDate || !toDate) {
            return true; // No date range specified, don't filter based on dates
          } else {
            const invoiceDate = new Date(item.date);
            const fromDateObj = new Date(fromDate);
            const toDateObj = new Date(toDate);
            return invoiceDate >= fromDateObj && invoiceDate <= toDateObj;
          }
        });

  const handleDownload = () => {
    const element = pdfRef.current;
    const options = {
      margin: 10,
      filename: "Stock Report(Item Age).pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    };
    //html2pdf(element, options);
    html2pdf().from(element).set(options).save();
  };


  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      const sortedData = [...filteredData].sort((a, b) => a.age - b.age);
      setData(sortedData);
    } else if (event.key === "ArrowDown") {
      const sortedData = [...filteredData].sort((a, b) => b.age - a.age);
      setData(sortedData);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredData]);

  return (
    <Container fluid>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={8}>
              <p className="section-title form-section ptag">
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={() => navigate("/reports")}
                />
              </p>
            </Col>
            <Col lg={2}>
              <SearchField
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
            <Col lg={1} className="d-flex gap-3">
              <span>
                <DownloadTableExcel
                  filename="Stock Report(Item Age)"
                  sheet="Sales"
                  currentTableRef={pdfRef.current}
                >
                  <Button className="commonBtn smallBtn cancelBtn mt-2">
                    {" "}
                    Excel{" "}
                  </Button>
                </DownloadTableExcel>
              </span>
              <span>
                <Button
                  className="commonBtn smallBtn cancelBtn mt-2"
                  onClick={handleDownload}
                >
                  Save pdf
                </Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={4}>
          <Form.Label className="section-title form-section ptag">
            Stock Report(Item Age)
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={4}>
          <Form.Label className="form-section ptag text-align-center">
            {`From ${fromDate ? formatDateToDDMMYYYY(fromDate) : ""} to ${
              toDate ? formatDateToDDMMYYYY(toDate) : ""
            }`}
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
            <Form.Label className="mt-3 text-color">Range</Form.Label>
            <Form.Select
              className="mt-2 p-2"
              value={selectedRange}
              onChange={handleSelectedRange}
            >
              <option
                //disabled={!isCurrentYear}
                value="Today"
              >
                Today
              </option>
              <option
                //disabled={!isCurrentYear}
                value="Yesterday"
              >
                Yesterday
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Week"
              >
                This Week
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Month"
              >
                This Month
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Quarter"
              >
                This Quarter
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Year"
              >
                This Year
              </option>
              <option value="Date Range">Date Range</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">From</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="from"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">To</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="to"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      {/*Table*/}
      <Row>
        <Col className="table-main-container">
          <Table md={12} id="table-to-export" responsive ref={pdfRef}>
            <TableHeading
              data={[
                "Date",
                "GRN",
                "Item No",
                "Item Name",
                "Category",
                "Brand",
                "Series",
                "Age(Days)",
              ]}
            />
            <tbody>
              {loading ? (
                <DataSpinner count="9" />
              ) : filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.grn}</td>
                    <td>{item.itemid}</td>
                    <td>{item.itemname}</td>
                    <td>{item.category}</td>
                    <td>{item.brand}</td>
                    <td>{item.series}</td>
                    <td>{item.age}</td>
                  </tr>
                ))
              ) : (
                <NoRecordFoud count="9" name="Items" />
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ListTable;
