import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import DataSpinner from '../../../constants/DataSpinner';
import NoRecordFoud from '../../../constants/NoRecordFoud';
import { salesOrderPagiData, termsAndConditionData } from '../../../redux/actions/salesOrder.actions';
import { reloadPage } from '../../../redux/actions/load.action';
import { salesInvoiceAdd, salesInvoiceSingleData, salesInvoiceUpdate } from '../../../redux/actions/salesInvoice.actions';
import { indianFormatDate } from '../../../constants/indianFormatDate';
import convertToIndianDateFormat from '../../../constants/IndianDate';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { companyData } from '../../../redux/actions/company.actions';
import ReactToPrint from 'react-to-print';
import { customerPagiData } from '../../../redux/actions/customer.actions';
import SaanviLogo from "../../../assets/images/saanvi.png"
import googlepe from "../../../assets/images/googlepe.png";
import icici from "../../../assets/images/icici.png";
import paymentlogo from "../../../assets/images/paymentlogo.png";
import phonepe from "../../../assets/images/phonepe.png";

import upilogo from "../../../assets/images/upilogo.png";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import vs_logo from "../../../assets/venturesathi-logo.png";
import { billInfoData } from '../../../redux/actions/auth.actions';

function ViewSalesInvoice() {
  const URL = extractAndFormatURLPart("sales");
  const { id } = useParams();
  const containerRef = useRef(null);
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  //Redux part
  const dispatch = useDispatch();
  const salesInvoiceViewData = useSelector(state => state.salesInvoiceData.salesInvoiceSingleData) || {};
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, branch, org, name } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const { companyaddress } = useSelector((state) => state.authManager.userData) || {};
  const {
    city,
    closuredate,
    emailid,
    gstno,
    hoaddress,
    msmeno,
    ownername,
    pan,
    phoneno,
    pincode,
    registrationno,
    signature,
    state,
    status,
    type,
  } = useSelector((state) => state.authManager.userData.companyaddress) || {};
  const { termsAndConditionAllData } = useSelector((state) => state.salesOrderData) || [];
  const { billinfo } = useSelector((state) => state.authManager);


  // const filterCustomer = customerAllData.filter((item) => item. )
  // const companyName = useSelector(state => state.companyData.companyData.data) || [];
  // const { data: salesOrderAllData } = useSelector((state) => state.salesInvoiceViewData.salesOrderPagiData) || [];
  // const salesInvoiceViewData = !salesOrderAllData ? [] : salesOrderAllData.filter((item) => item.id == id);

  //state
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    id: salesInvoiceViewData ? salesInvoiceViewData.id : "--",
    invoicetype: salesInvoiceViewData ? salesInvoiceViewData.invoicetype : "--",
    commonname: salesInvoiceViewData ? salesInvoiceViewData.commonname : "--",
    commonid: salesInvoiceViewData ? salesInvoiceViewData.commonid : "--",
    commonnamealldata: salesInvoiceViewData ? salesInvoiceViewData.commonnamealldata : [],
    gino: salesInvoiceViewData ? salesInvoiceViewData.gino : "--",
    saleorderno: salesInvoiceViewData ? salesInvoiceViewData.saleorderno : "--",
    purchaseorderno: salesInvoiceViewData ? salesInvoiceViewData.purchaseorderno : "--",
    purchaseinvoiceno: salesInvoiceViewData ? salesInvoiceViewData.purchaseinvoiceno : "--",
    invoiceno: salesInvoiceViewData ? salesInvoiceViewData.invoiceno : "--",
    invoicedate: salesInvoiceViewData ? salesInvoiceViewData.invoicedate : "--",
    paymentterms: salesInvoiceViewData ? salesInvoiceViewData.paymentterms : "--",
    paymentduedate: salesInvoiceViewData ? salesInvoiceViewData.paymentduedate : "--",
    transportertype: salesInvoiceViewData ? salesInvoiceViewData.transportertype : "--",
    transpotername: salesInvoiceViewData ? salesInvoiceViewData.transpotername : "--",
    vehicleno: salesInvoiceViewData ? salesInvoiceViewData.vehicleno : "--",
    salesperson: salesInvoiceViewData ? salesInvoiceViewData.salesperson : "--",
    ewaybillno: salesInvoiceViewData ? salesInvoiceViewData.ewaybillno : "--",
    einvoiceno: salesInvoiceViewData ? salesInvoiceViewData.einvoiceno : "--",
    createdby: salesInvoiceViewData ? salesInvoiceViewData.createdby : "--",
    documents: salesInvoiceViewData ? salesInvoiceViewData.documents : "--",
    uploads: salesInvoiceViewData ? salesInvoiceViewData.uploads : [],
    remarks: salesInvoiceViewData ? salesInvoiceViewData.remarks : "--",
    architect: salesInvoiceViewData ? salesInvoiceViewData.architect : "--",
    contractor: salesInvoiceViewData ? salesInvoiceViewData.contractor : "--",
    showgst: salesInvoiceViewData ? salesInvoiceViewData.showgst == "true" ? true : false : true,
    itemarray: salesInvoiceViewData ? salesInvoiceViewData.itemarray : [],
    subtotal: salesInvoiceViewData ? salesInvoiceViewData.subtotal : "--",
    discount: salesInvoiceViewData ? salesInvoiceViewData.discount : "--",
    discountamount: salesInvoiceViewData ? salesInvoiceViewData.discountamount : "--",
    discountaccount: salesInvoiceViewData ? salesInvoiceViewData.discountaccount : "--",
    taxableamount: salesInvoiceViewData ? salesInvoiceViewData.taxableamount : "--",
    cgst: salesInvoiceViewData ? salesInvoiceViewData.cgst : "--",
    sgst: salesInvoiceViewData ? salesInvoiceViewData.sgst : "--",
    igst: salesInvoiceViewData ? salesInvoiceViewData.igst : "--",
    tcs: salesInvoiceViewData ? salesInvoiceViewData.tcs == "on" ? true : false : false,
    tds: salesInvoiceViewData ? salesInvoiceViewData.tds == "on" ? true : false : false,
    tcsvalue: salesInvoiceViewData ? salesInvoiceViewData.tcsvalue : "--",
    tdsvalue: salesInvoiceViewData ? salesInvoiceViewData.tdsvalue : "--",
    tsamount: salesInvoiceViewData ? salesInvoiceViewData.tsamount : "--",
    isfrightcharges: salesInvoiceViewData ? salesInvoiceViewData.isfrightcharges == "true" ? true : false : false,
    frightcharges: salesInvoiceViewData ? salesInvoiceViewData.frightcharges : "--",
    adjustmentamount: salesInvoiceViewData ? salesInvoiceViewData.adjustmentamount : "--",
    grandtotal: salesInvoiceViewData ? salesInvoiceViewData.grandtotal : "--",
    tandc: salesInvoiceViewData ? salesInvoiceViewData.tandc : "--",
    istandc: salesInvoiceViewData ? salesInvoiceViewData.istandc : "--",
    status: salesInvoiceViewData ? salesInvoiceViewData.status : "--",
    userid: salesInvoiceViewData ? salesInvoiceViewData.userid : "--",
    companyid: salesInvoiceViewData ? salesInvoiceViewData.companyid : "--",
    branchid: salesInvoiceViewData ? salesInvoiceViewData.branchid : "--",
    company: salesInvoiceViewData ? salesInvoiceViewData.company : "--",
    org: salesInvoiceViewData ? salesInvoiceViewData.org : "--",
    financialyear: salesInvoiceViewData ? salesInvoiceViewData.financialyear : "--",
    month: salesInvoiceViewData ? salesInvoiceViewData.month : "--",
    status: salesInvoiceViewData ? salesInvoiceViewData.status : "--",
    balancedue: salesInvoiceViewData ? salesInvoiceViewData.balancedue : "",
    btstate: salesInvoiceViewData ? salesInvoiceViewData.btstate : "",
    btcity: salesInvoiceViewData ? salesInvoiceViewData.btcity : "",
    btpin: salesInvoiceViewData ? salesInvoiceViewData.btpin : "",
    btaddress: salesInvoiceViewData ? salesInvoiceViewData.btaddress : "",
    bacountry: salesInvoiceViewData ? salesInvoiceViewData.bacountry : "",
    ststate: salesInvoiceViewData ? salesInvoiceViewData.ststate : "",
    stcity: salesInvoiceViewData ? salesInvoiceViewData.stcity : "",
    stpin: salesInvoiceViewData ? salesInvoiceViewData.stpin : "",
    staddress: salesInvoiceViewData ? salesInvoiceViewData.staddress : "",
    stcountry: salesInvoiceViewData ? salesInvoiceViewData.stcountry : "",
    transpotername: salesInvoiceViewData ? salesInvoiceViewData.transpotername : "",
    samestate: salesInvoiceViewData ? salesInvoiceViewData.samestate == "true" ? true : false : true,
    commonnamealldata: salesInvoiceViewData ? salesInvoiceViewData.commonnamealldata : ""
  });

  const [loading, setLoading] = useState(false);
  //default data backend needed
  const backendData = {
    id: id,
    keyword: "₹ΘθϖΠ",
    userid: userid,
    branchid: branchid,
    companyid: companyid,
    branch: branch,
    companyname: companyname,
    org: org,
  }
  //useeffect part start
  useEffect(() => {
    dispatch(reloadPage(true));
    dispatch(billInfoData({ org }));
  }, [])

  useEffect(() => {
    setLoading(!loading);
    dispatch(salesInvoiceSingleData(backendData, setLoading));
    dispatch(customerPagiData(backendData, setLoading));
    dispatch(termsAndConditionData(backendData, setLoading));


    // setTableData((prevTableData) => [data.itemarray]);
  }, [dispatch, loadData])

  useEffect(() => {
    setData({
      ...data,
      id: salesInvoiceViewData ? salesInvoiceViewData.id : "--",
      invoicetype: salesInvoiceViewData ? salesInvoiceViewData.invoicetype : "--",
      commonname: salesInvoiceViewData ? salesInvoiceViewData.commonname : "--",
      commonid: salesInvoiceViewData ? salesInvoiceViewData.commonid : "--",
      commonnamealldata: salesInvoiceViewData ? salesInvoiceViewData.commonnamealldata : [],
      gino: salesInvoiceViewData ? salesInvoiceViewData.gino : "--",
      saleorderno: salesInvoiceViewData ? salesInvoiceViewData.saleorderno : "--",
      purchaseorderno: salesInvoiceViewData ? salesInvoiceViewData.purchaseorderno : "--",
      purchaseinvoiceno: salesInvoiceViewData ? salesInvoiceViewData.purchaseinvoiceno : "--",
      invoiceno: salesInvoiceViewData ? salesInvoiceViewData.invoiceno : "--",
      invoicedate: salesInvoiceViewData ? salesInvoiceViewData.invoicedate : "--",
      paymentterms: salesInvoiceViewData ? salesInvoiceViewData.paymentterms : "--",
      paymentduedate: salesInvoiceViewData ? salesInvoiceViewData.paymentduedate : "--",
      transportertype: salesInvoiceViewData ? salesInvoiceViewData.transportertype : "--",
      vehicleno: salesInvoiceViewData ? salesInvoiceViewData.vehicleno : "--",
      salesperson: salesInvoiceViewData ? salesInvoiceViewData.salesperson : "--",
      ewaybillno: salesInvoiceViewData ? salesInvoiceViewData.ewaybillno : "--",
      einvoiceno: salesInvoiceViewData ? salesInvoiceViewData.einvoiceno : "--",
      createdby: salesInvoiceViewData ? salesInvoiceViewData.createdby : "--",
      documents: salesInvoiceViewData ? salesInvoiceViewData.documents : "--",
      uploads: salesInvoiceViewData ? salesInvoiceViewData.uploads : [],
      remarks: salesInvoiceViewData ? salesInvoiceViewData.remarks : "--",
      architect: salesInvoiceViewData ? salesInvoiceViewData.architect : "--",
      contractor: salesInvoiceViewData ? salesInvoiceViewData.contractor : "--",
      showgst: salesInvoiceViewData ? salesInvoiceViewData.showgst == "true" ? true : false : true,
      itemarray: salesInvoiceViewData ? salesInvoiceViewData.itemarray : [],
      subtotal: salesInvoiceViewData ? salesInvoiceViewData.subtotal : "--",
      discount: salesInvoiceViewData ? salesInvoiceViewData.discount : "--",
      discountamount: salesInvoiceViewData ? salesInvoiceViewData.discountamount : "--",
      discountaccount: salesInvoiceViewData ? salesInvoiceViewData.discountaccount : "--",
      taxableamount: salesInvoiceViewData ? salesInvoiceViewData.taxableamount : "--",
      cgst: salesInvoiceViewData ? salesInvoiceViewData.cgst : "--",
      sgst: salesInvoiceViewData ? salesInvoiceViewData.sgst : "--",
      igst: salesInvoiceViewData ? salesInvoiceViewData.igst : "--",
      tcs: salesInvoiceViewData ? salesInvoiceViewData.tcs == "true" ? true : false : false,
      tds: salesInvoiceViewData ? salesInvoiceViewData.tds == "true" ? true : false : false,
      tcsvalue: salesInvoiceViewData ? salesInvoiceViewData.tcsvalue : "--",
      tdsvalue: salesInvoiceViewData ? salesInvoiceViewData.tdsvalue : "--",
      tsamount: salesInvoiceViewData ? salesInvoiceViewData.tsamount : "--",
      isfrightcharges: salesInvoiceViewData ? salesInvoiceViewData.isfrightcharges == "true" ? true : false : false,
      frightcharges: salesInvoiceViewData ? salesInvoiceViewData.frightcharges : "--",
      adjustmentamount: salesInvoiceViewData ? salesInvoiceViewData.adjustmentamount : "--",
      grandtotal: salesInvoiceViewData ? salesInvoiceViewData.grandtotal : "--",
      tandc: salesInvoiceViewData ? salesInvoiceViewData.tandc : "--",
      istandc: salesInvoiceViewData ? salesInvoiceViewData.istandc : "--",
      status: salesInvoiceViewData ? salesInvoiceViewData.status : "--",
      userid: salesInvoiceViewData ? salesInvoiceViewData.userid : "--",
      companyid: salesInvoiceViewData ? salesInvoiceViewData.companyid : "--",
      branchid: salesInvoiceViewData ? salesInvoiceViewData.branchid : "--",
      org: salesInvoiceViewData ? salesInvoiceViewData.org : "--",
      financialyear: salesInvoiceViewData ? salesInvoiceViewData.financialyear : "--",
      month: salesInvoiceViewData ? salesInvoiceViewData.month : "--",
      status: salesInvoiceViewData ? salesInvoiceViewData.status : "--",
      company: salesInvoiceViewData ? salesInvoiceViewData.company : "--",
      balancedue: salesInvoiceViewData ? salesInvoiceViewData.balancedue : "",
      btstate: salesInvoiceViewData ? salesInvoiceViewData.btstate : "",
      btcity: salesInvoiceViewData ? salesInvoiceViewData.btcity : "",
      btpin: salesInvoiceViewData ? salesInvoiceViewData.btpin : "",
      btaddress: salesInvoiceViewData ? salesInvoiceViewData.btaddress : "",
      bacountry: salesInvoiceViewData ? salesInvoiceViewData.bacountry : "",
      ststate: salesInvoiceViewData ? salesInvoiceViewData.ststate : "",
      stcity: salesInvoiceViewData ? salesInvoiceViewData.stcity : "",
      stpin: salesInvoiceViewData ? salesInvoiceViewData.stpin : "",
      staddress: salesInvoiceViewData ? salesInvoiceViewData.staddress : "",
      stcountry: salesInvoiceViewData ? salesInvoiceViewData.stcountry : "",
      transpotername: salesInvoiceViewData ? salesInvoiceViewData.transpotername : "",
      samestate: salesInvoiceViewData ? salesInvoiceViewData.samestate == "true" ? true : false : true,
      commonnamealldata: salesInvoiceViewData ? salesInvoiceViewData.commonnamealldata : ""
    })
  }, [salesInvoiceViewData]);
  //useeffect part end
  //Navigate part
  const navigate = useNavigate();

  const handleApprovedAndSend = () => {
    const formData = {
      ...data,
      status: "Unpaid",
    };

    dispatch(salesInvoiceUpdate(formData, navigate));
  };

  return (
    <Container fluid >
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row className="form-container">
        <Col sm={12}>
          <Row>
            <Col>
              <h1>
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={() => navigate("/sales/salesInvoice")}
                />
                Sales <span className="form-title">Invoice</span>
              </h1>
            </Col>
            <Col>
              <h3 className='mr-2'>
                Status:
                <span className={
                  data.status == "Partially Paid" ? "Approved-status" :
                    data.status == "Unpaid" ? "Unpaid-status" :
                      data.status === "Overdue" ? "Rejected-status" :
                        data.status === "Paid" ? "Approved-status" :
                          data.status === "Draft" ? "Draft-status" : ""}
                >
                  {data.status}
                </span>
              </h3>
            </Col>
            <Col className="d-flex gap-3 justify-content-end">
              {data.status != "Paid" && <span className="w-50">
                <Button className="commonBtn" onClick={handleApprovedAndSend}>
                  {loadData ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    data.status === "Draft" ? "Approve and Send" : "Send"
                  )}
                </Button>

              </span>}
              <span>
                <ReactToPrint
                  trigger={() => (
                    <Button className="commonBtn smallBtn cancelBtn ">
                      Print
                    </Button>
                  )}
                  content={() => containerRef.current}
                />
              </span>
            </Col>
          </Row>
        </Col>
        <hr />
        <Col >
          <Row>
            <Col md={6}>
              <p>
                <span className="viewpage-title from-SalesOrder">Bill To:</span>{" "}
              </p>
              <p className='mb-0'>
                <span className="viewpage-title companyname">{data.commonname}</span>
              </p>
              <p className='mb-0'>
                <span className="viewpage-value d-block">{data.btaddress}</span>
                <span className="viewpage-value d-block">{data.btcity}, {data.btstate}, India</span>
                <span className="viewpage-value d-block">{data.btpin}</span>
              </p>

              <p className='mt-5'>
                <span className="viewpage-title heading-title">Invoice Type</span>{" "}
                <span className="viewpage-value">{data.invoicetype}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">{data.invoicetype == "Return To Vendor" ? "Vendor ID" : data.invoicetype == "Other Company" ? "Company ID" : data.invoicetype == "Sub-Dealer" ? "Sub-Dealer ID" : data.invoicetype == "Franchise" ? "Franchise ID" : "Customer ID"}:</span>{" "}
                <span className="viewpage-value">{data.commonid}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Invoice No:</span>{" "}
                <span className="viewpage-value">{data.invoiceno}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Payment Terms:</span>{" "}
                <span className="viewpage-value">{data.paymentterms}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Transport Type:</span>{" "}
                <span className="viewpage-value">{data.transportertype}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Sales Person:</span>{" "}
                <span className="viewpage-value">{data.salesperson}</span>
              </p>
              <p>
                <span className="viewpage-title heading-title">E-way Bill:</span>{" "}
                <span className="viewpage-value">{data.ewaybillno}</span>
              </p>
              <p>
                <span className="viewpage-title heading-title">Remarks:</span>{" "}
                <span className="viewpage-value">{data.remarks}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Architect:</span>{" "}
                <span className="viewpage-value">{data.architect}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Show GST:</span>{" "}
                <span className="viewpage-value">{data.showgst == true ? "Yes" : "No"}</span>
              </p>
            </Col>
            <Col md={6}>
              <p>
                <span className="viewpage-title from-SalesOrder">Ship To:</span>{" "}
              </p>
              <p className='mb-0'>
                <span className="viewpage-title companyname">{data.commonname}</span>
              </p>
              <p className='mb-0'>
                <span className="viewpage-value d-block">{data.staddress}</span>
                <span className="viewpage-value d-block">{data.stcity} {data.ststate}, India</span>
                <span className="viewpage-value d-block">{data.stpin}</span>
              </p>

              <p className='mt-5'>
                <span className="viewpage-title heading-title">{data.invoicetype == "Return To Vendor" ? "Vendor" : data.invoicetype == "Other Company" ? "Company" : data.invoicetype == "Sub-Dealer" ? "Sub-Dealer" : data.invoicetype == "Franchise" ? "Franchise" : "Customer Name"}:</span>{" "}
                <span className="viewpage-value">{data.commonname}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Sale Order No:</span>{" "}
                <span className="viewpage-value">{data.saleorderno}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Invoice Date:</span>{" "}
                <span className="viewpage-value">{!data.invoicedate ? "--" : convertToIndianDateFormat(data.invoicedate)}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Payment Due Date:</span>{" "}
                <span className="viewpage-value">{!data.paymentduedate ? "--" : convertToIndianDateFormat(data.paymentduedate)}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Vehicle No:</span>{" "}
                <span className="viewpage-value">{data.vehicleno}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Created By:</span>{" "}
                <span className="viewpage-value">{data.createdby}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">E-Invoice:</span>{" "}
                <span className="viewpage-value">{data.einvoiceno}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Account:</span>{" "}
                <span className="viewpage-value">{data.account}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Contractor:</span>{" "}
                <span className="viewpage-value">{data.contractor}</span>
              </p>

              <p>
                <span className="viewpage-title heading-title">Is Freight/Labor Charges:</span>{" "}
                <span className="viewpage-value">{data.isfrightcharges == true ? "Yes" : "No"}</span>
              </p>

              {data.invoicetype != "Customer" && <p>
                <span className="viewpage-title heading-title">GI No:</span>{" "}
                <span className="viewpage-value">{data.gino}</span>
              </p>}
            </Col>
          </Row>
          <hr />
          <Row >
            <Col md={12} className="table-main-container print-table">
              <Table responsive>
                <thead className="tableContainer">
                  <tr>
                    <th>#</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Item Name</th>
                    <th>Item ID</th>
                    <th>UOM</th>
                    <th>Order QTY</th>
                    <th>Invoice QTY</th>
                    <th>Remaining QTY</th>
                    {!data.showgst && <th>Rate Incl. GST</th>}
                    <th>Rate</th>
                    {data.showgst && <th>GST%</th>}
                    <th>Discount %</th>
                    <th>Add. Discount %</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loadData || loading ? (
                    <>
                      <DataSpinner count="15" />
                    </>
                  ) : data.itemarray && data.itemarray.length > 0 ? (
                    data.itemarray.map((row, index) => {
                      let {
                        asondate,
                        billingtype,
                        branchid,
                        brand,
                        category,
                        series,
                        cessvaluationtype,
                        chartofaccount,
                        companyid,
                        dispatchqty,
                        qty,
                        orderqty,
                        invoiceqty,
                        remainingqty,
                        rateinclgst,
                        discount,
                        grade,
                        gst,
                        hsncode,
                        hsndescription,
                        id,
                        image,
                        inhandquantity,
                        inventoryaccount,
                        inventorydescription,
                        inventorytype,
                        itemdescription,
                        itemid,
                        itemname,
                        mpn,
                        openingqty,
                        org,
                        purchaseaccount,
                        purchasedescription,
                        rake,
                        rateperunit,
                        reorderpoint,
                        saleaccount,
                        saledescription,
                        size,
                        sku,
                        taxability,
                        thickness,
                        unit,
                        rate,
                        updatedprice,
                        userid,
                        value,
                        warehouse,
                        account,
                        additionaldiscount,
                        amount
                      } = row;

                      return (
                        <tr key={index} >
                          <td>
                            {index + 1}
                          </td>

                          <td>
                            {!category ? "--" : category}
                          </td>

                          <td >
                            {!brand ? "--" : brand}
                          </td>

                          <td >
                            {!itemname ? "--" : itemname}
                          </td>

                          <td>
                            {!itemid ? "--" : itemid}
                          </td>

                          <td>
                            {!unit ? "--" : unit}
                          </td>

                          <td>
                            {!orderqty ? "--" : orderqty}
                          </td>

                          <td>
                            {!invoiceqty ? "--" : invoiceqty}
                          </td>

                          <td>
                            {!remainingqty ? "--" : remainingqty}
                          </td>

                          {!data.showgst && <td>
                            {!rateinclgst ? "--" : rateinclgst}
                          </td>}

                          <td>
                            {!rate ? "--" : rate}
                          </td>

                          {data.showgst && <td>
                            {!gst ? "--" : gst}
                          </td>}

                          <td>
                            {!discount ? "--" : discount}
                          </td>

                          <td>
                            {!additionaldiscount ? "--" : additionaldiscount}
                          </td>

                          <td>
                            {!amount ? "--" : amount}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecordFoud count="15" name="Sales Invoice" />

                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className='justify-content-between'>
            <Col className="table-main-container d-flex ">
              {data.showgst == "true" && <div className='my-auto w-75'>
                <h3>GST Break Down :</h3>
                <Table responsive>
                  <thead className="tableContainer">
                    <tr>
                      <th>CGST(%)</th>
                      <th>SGST(%)</th>
                      <th>IGST(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.itemarray?.map((item, ind) =>
                      data.samestate ? (
                        <tr key={ind}>
                          <td>{Number(item.gst) / 2}</td>
                          <td>{Number(item.gst) / 2}</td>
                          <td>0</td>
                        </tr>
                      ) : (
                        <tr key={ind}>
                          <td>0</td>
                          <td>0</td>
                          <td>{Number(item.gst)}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                  {data.samestate ? (
                    <thead>
                      <tr>
                        <th>Total : {data.cgst}</th>
                        <th>{data.sgst}</th>
                        <th>{0}</th>
                      </tr>
                    </thead>
                  ) : (
                    <thead>
                      <tr>
                        <th>Total : {0}</th>
                        <th>{0}</th>
                        <th>{data.igst}</th>
                      </tr>
                    </thead>
                  )}
                </Table>
              </div>}
            </Col>
            <Col className='text-end'>
              <p className='mt-3 mr-5'>
                <span className="viewpage-title calculation-header" >Sub Total :</span>{" "}
                <span className="viewpage-value calculation-value subtotal">{Number(data.subtotal).toFixed(2)}</span>
              </p>

              {
                data.isfrightcharges &&
                <p className=''>
                  <span className="viewpage-title calculation-header" style={{ marginRight: "14rem" }}>Freight/Labor Charges :</span>{" "}
                  <span className="viewpage-value calculation-value">{data.frightcharges}</span>
                </p>
              }

              {(data.showgst && data.samestate) && <p className='w-100'>
                <span className="viewpage-title calculation-header" style={{ marginRight: "14rem" }}>CGST :</span>{" "}
                <span className="viewpage-value calculation-value">{data.cgst}</span>
              </p>}

              {(data.showgst && data.samestate) && <p className='w-100'>
                <span className="viewpage-title calculation-header" style={{ marginRight: "14rem" }}>SGST :</span>{" "}
                <span className="viewpage-value calculation-value">{data.sgst}</span>
              </p>}

              {(data.showgst && !data.samestate) && <p className='w-100'>
                <span className="viewpage-title calculation-header" style={{ marginRight: "14rem" }}>IGST :</span>{" "}
                <span className="viewpage-value calculation-value">{data.igst}</span>
              </p>}

              {data.tcs && <p className='w-100'>
                <span className="viewpage-title calculation-header">TCS Amount :</span>{" "}
                <span className="viewpage-value calculation-value">{data.tsamount}</span>
              </p>}

              {data.tds && <p className='w-100'>
                <span className="viewpage-title calculation-header">TDS Amount :</span>{" "}
                <span className="viewpage-value calculation-value">{data.tsamount}</span>
              </p>}

              <p className='w-100'>
                <span className="viewpage-title calculation-header" style={{ marginRight: "15.2rem" }}>Adjustment :</span>{" "}
                <span className="viewpage-value calculation-value">{data.adjustmentamount}</span>
              </p>

              <p className=''>
                <span className="viewpage-title calculation-header" style={{ marginRight: "11.6rem" }}>Grand Total :</span>{" "}
                <span className="viewpage-value calculation-value grandtotal">{data.grandtotal}</span>
              </p>
            </Col>
          </Row>
          <Row className='d-none'>
            <Col ref={containerRef}>
              <div className="print-section">
                <table>
                  <tr className="first-section">
                    <td className="saanvi_logo">
                      <img src={billinfo.brandlogo ? billinfo.brandlogo : vs_logo} alt="Brand Logo" className='saanvi-logo' />
                    </td>
                    <td className="saanvi-brand-container">
                      <h1 className="saanvi-brand">{billinfo.brandname ? billinfo.brandname : "--"}</h1>
                      <p className="saanvi-deails">{billinfo.address ? billinfo.address : "--"}</p>
                      <p className='saanvi-deails'>
                        <span className='saanvi-gst'>GST :</span> <span className='saanvi-deails so'>{billinfo.gstno ? billinfo.gstno : "--"}</span>
                        <span className="saanvi-gst">PAN :</span> <span>{billinfo.pan ? billinfo.pan : "--"}</span>
                      </p>
                      <p className='saanvi-deails'>
                        <span className='saanvi-deails'>State Name :</span> <span className='saanvi-deails'>{billinfo.state ? billinfo.state : "--"},</span>
                        <span className='saanvi-deails'> Code :</span> <span className='saanvi-deails'>{billinfo.statecode ? billinfo.statecode : "--"}</span>
                      </p>
                      <p className='saanvi-deails'>
                        <span className='saanvi-deails'>Email : {billinfo.email ? billinfo.email : "--"}</span>
                      </p>
                    </td>
                    <td className="saanvi-contact-details">
                      <p className="printButton">{data.invoicetype != "Return To Vendor" ? "INVOICE" : "DEBIT NOTE"}</p>
                      <p className="saanvi-deails">
                        <span className="saanvi-details-section cd">Phone No: </span>
                        <span className="saanvi-contact">{billinfo.phonenumber ? billinfo.phonenumber : "--"}</span>
                      </p>
                      <p className="saanvi-deails">
                        <span className="saanvi-details-section cd">Website: </span>
                        <span>{billinfo.websitename ? billinfo.websitename : "--"}</span>
                      </p>
                      <p className="saanvi-deails">
                        <span className="saanvi-details-section cd">Invoice No:  </span>
                        <span>{data.invoiceno}</span>
                      </p>
                    </td>
                  </tr>
                  {/* <tr className="second-section">
                    <td className="gst-section" colSpan="2">
                      <span className="gst saanvi-deails">GSTIN: {gstno}</span>
                      <span className='saanvi-deails'>PAN: {pan}</span>
                    </td>
                    <td className="invoiceno-section">
                      <span className='saanvi-deails'>Invoice No: {data.invoiceno}</span>
                    </td>
                  </tr> */}
                  <tr className="third-section">
                    <td className="customer-details-section" colSpan="3">
                      <span className='saanvi-deails'>Customer Details</span>
                    </td>
                  </tr>
                  <tr className="four-section">
                    <td colSpan="3">
                      <div className="address-section">
                        <div className="first-col">
                          <p>
                            <span className="btitle">Bill To :</span>
                            <span className="desc">{data.commonname}</span>
                          </p>
                          <p>
                            <span className="btitle">Address :</span>
                            <span className="desc">{data.btaddress}, {data.btcity}</span>
                          </p>
                          <p>
                            <span className="btitle">State :</span>
                            <span className="desc part">{data.btstate}- {data.btpin}, India</span>
                          </p>
                          <p>
                            <span className="btitle">Phone</span>
                            <span className="desc part">{
                              data.invoicetype == "Return To Vendor" ? data?.commonnamealldata[0]?.workphoneno :
                                data.invoicetype == "Other Company" ? data?.commonnamealldata[0]?.phoneno :
                                  data.invoicetype == "Sub-Dealer" ? data?.commonnamealldata[0]?.phoneno :
                                    data.invoicetype == "Franchise" ? data?.commonnamealldata[0]?.phoneno :
                                      data.invoicetype == "Customer" ? data?.commonnamealldata[0]?.phone :
                                        "--"
                            }</span>
                          </p>
                          <p>
                            <span className="btitle">GSTIN</span>
                            <span className="desc">{
                              data.invoicetype == "Return To Vendor" ? data?.commonnamealldata[0]?.gstno :
                                data.invoicetype == "Other Company" ? data?.commonnamealldata[0]?.gstno :
                                  data.invoicetype == "Sub-Dealer" ? data?.commonnamealldata[0]?.gstno :
                                    data.invoicetype == "Franchise" ? data?.commonnamealldata[0]?.gstno :
                                      data.invoicetype == "Customer" ? data?.commonnamealldata[0]?.bdgstno :
                                        "--"
                            }</span>
                          </p>
                        </div>
                        <div className="second-col">
                          <p>
                            <span className="btitle">Ship To:</span>
                            <span className="desc">{data.commonname}</span>
                          </p>
                          <p>
                            <span className="btitle">Address</span>
                            <span className="desc">{data.staddress}, {data.stcity}</span>
                          </p>
                          <p>
                            <span className="btitle">State</span>
                            <span className="desc">{data.ststate} - {data.stpin}, India</span>
                          </p>
                          <p>
                            <span className="btitle">Phone</span>
                            <span className="desc part">{
                              data.invoicetype == "Return To Vendor" ? data?.commonnamealldata[0]?.workphoneno :
                                data.invoicetype == "Other Company" ? data?.commonnamealldata[0]?.phoneno :
                                  data.invoicetype == "Sub-Dealer" ? data?.commonnamealldata[0]?.phoneno :
                                    data.invoicetype == "Franchise" ? data?.commonnamealldata[0]?.phoneno :
                                      data.invoicetype == "Customer" ? data?.commonnamealldata[0]?.phone :
                                        "--"
                            }</span>
                          </p>
                          <p>
                            <span className="btitle">GSTIN</span>
                            <span className="desc">{
                              data.invoicetype == "Return To Vendor" ? data?.commonnamealldata[0]?.gstno :
                                data.invoicetype == "Other Company" ? data?.commonnamealldata[0]?.gstno :
                                  data.invoicetype == "Sub-Dealer" ? data?.commonnamealldata[0]?.gstno :
                                    data.invoicetype == "Franchise" ? data?.commonnamealldata[0]?.gstno :
                                      data.invoicetype == "Customer" ? data?.commonnamealldata[0]?.bdgstno :
                                        "--"
                            }</span>
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="five-section">
                    <td colSpan="3">
                      <div className="payment-section">
                        <div className="payment-details" style={{ width: "40%" }}>
                          <p className='mb-0'>
                            <span className="btitle cd">{data.invoicetype == "Return To Vendor" ? "Vendor ID" : data.invoicetype == "Other Company" ? "Company ID" : data.invoicetype == "Sub-Dealer" ? "Sub-Dealer ID" : data.invoicetype == "Franchise" ? "Franchise ID" : "Customer ID"}:</span>
                            <span className="desc ">{data.commonid}</span>
                          </p>
                          <p className='mb-0'>
                            <span className="btitle cd">Payment Terms:</span>
                            <span className="desc">{data.paymentterms}</span>
                          </p>
                          <p className='mb-0'>
                            <span className="btitle cd">Payment Due Dt.:</span>
                            <span className="desc">{data.paymentduedate}</span>
                          </p>
                        </div>
                        <div className="payment-details" style={{ width: "40%" }}>
                          <p className='mb-0'>

                            <span className="btitle cd">Transporter Type:</span>
                            <span className="desc">{data.transportertype}</span>
                          </p>


                          <p className='mb-0'>
                            <span className="btitle cd">Vehicle no:</span>
                            <span className="desc">{data.vehicleno}</span>
                          </p>
                          <p className='mb-0'>
                            <span className="btitle cd">INV Dt.:</span>
                            <span className="desc">{data.invoicedate}</span>
                          </p>

                        </div>
                        <div className="payment-details" style={{ width: "20%" }}>
                          <p className='mb-0'>
                            <span className="btitle cd">E-Way Bill:</span>
                            <span className="desc">{data.ewaybillno}</span>
                          </p>
                          <p className='mb-0'>
                            <span className="btitle cd">E-INV no.:</span>
                            <span className="desc">{data.ewaybillno}</span>
                          </p>

                          {data.invoicetype != "Customer" &&
                            <p className='mb-0'>
                              <span className="btitle cd">GI No:</span>
                              <span className="desc">
                                {data.gino}
                              </span>
                            </p>}

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="six-section">
                    <td colSpan="3">
                      <div>
                        <Table responsive className="table-color fs-4">
                          <thead>
                            <tr className="table-head-color">
                              <th >#</th>
                              <th>Item Name</th>
                              <th>HSN/SAC</th>
                              <th>UOM</th>
                              <th>QTY</th>
                              {!data.showgst && <th>
                                <span className='d-block'>Rate</span>
                                <span className=''>(Include of tax)</span>
                              </th>}
                              <th>Rate</th>
                              {data.showgst && <th>GST%</th>}
                              <th>Dis. %</th>
                              <th><span className='d-block'>Add.</span>
                                <span className=''>Dis. %</span></th>

                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody className='tablebody'>
                            {loadData || loading ? (
                              <>
                                <DataSpinner count="12" />

                              </>
                            ) : data.itemarray && data.itemarray.length > 0 ? (
                              data.itemarray.map((row, index) => {
                                let {
                                  asondate,
                                  billingtype,
                                  branchid,
                                  brand,
                                  category,
                                  cessvaluationtype,
                                  chartofaccount,
                                  companyid,
                                  dispatchqty,
                                  qty,
                                  rateinclgst,
                                  discount,
                                  grade,
                                  gst,
                                  hsncode,
                                  hsndescription,
                                  id,
                                  image,
                                  inhandquantity,
                                  inventoryaccount,
                                  inventorydescription,
                                  inventorytype,
                                  itemdescription,
                                  itemid,
                                  itemname,
                                  mpn,
                                  openingqty,
                                  org,
                                  purchaseaccount,
                                  purchasedescription,
                                  rake,
                                  rateperunit,
                                  reorderpoint,
                                  saleaccount,
                                  saledescription,
                                  size,
                                  sku,
                                  taxability,
                                  thickness,
                                  unit,
                                  rate,
                                  updatedprice,
                                  userid,
                                  value,
                                  warehouse,
                                  account,

                                  additionaldiscount,

                                  amount
                                } = row;

                                return (
                                  <tr key={id} >
                                    <td>
                                      {index + 1}
                                    </td>

                                    <td >
                                      {!itemname ? "--" : itemname}
                                    </td>


                                    <td>
                                      {!hsncode ? "--" : hsncode}
                                    </td>

                                    <td>{!unit ? "--" : unit}</td>


                                    <td>
                                      {!qty ? "--" : qty}
                                    </td>


                                    {!data.showgst && <td>

                                      {!rateinclgst ? "--" : rateinclgst}
                                    </td>}

                                    <td>
                                      {!rate ? "--" : rate}
                                    </td>


                                    {data.showgst && <td>

                                      {!gst ? "--" : gst}
                                    </td>}

                                    <td>
                                      {!discount ? "--" : discount}
                                    </td>


                                    <td>{!additionaldiscount ? "--" : additionaldiscount}</td>

                                    <td>
                                      {!amount ? "--" : amount}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <NoRecordFoud count="13" name="Sales Invoice" />
                            )}
                            {new Array(2).fill("")?.map((emtr, i) => (
                              <tr key={i}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            ))}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td><hr /></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="print-total">Sub Total</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{Number(data.subtotal).toFixed(2)}</td>
                            </tr>
                            {(data.showgst && data.samestate) && <tr>
                              <td></td>
                              <td className="print-total">CGST</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{data.cgst}</td>
                            </tr>}
                            {(data.showgst && data.samestate) && <tr>
                              <td></td>
                              <td className="print-total">SGST</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{data.sgst}</td>
                            </tr>}
                            {(data.showgst && !data.samestate) && <tr>
                              <td></td>
                              <td className="print-total">IGST</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{data.igst}</td>
                            </tr>}
                            {data.tcs && <tr>
                              <td></td>
                              <td className="print-total">TCS</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{Number(data.tsamount).toFixed(2)}</td>
                            </tr>}
                            {data.tds && <tr>
                              <td></td>
                              <td className="print-total">TDS</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{Number(data.tsamount).toFixed(2)}</td>
                            </tr>}
                            {data.isfrightcharges && <tr>
                              <td></td>
                              <td className="print-total">Fright Charges</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{data.frightcharges}</td>
                            </tr>}
                            {(data.adjustmentamount != "0" && data.adjustmentamount != "") && <tr>
                              <td></td>
                              <td className="print-total">Adjustment</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}
                              <td></td>
                              <td></td>
                              <td className="font-bold td-top-border">{data.adjustmentamount}</td>
                            </tr>}
                            {new Array(8).fill("")?.map((emtr, i) => (
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td></td>

                              </tr>
                            ))}
                            <tr className="tableContainer">
                              <td></td>
                              <td className="print-total">Total</td>
                              <td></td>
                              <td></td>
                              <td className="font-bold">
                                {data?.itemarray?.reduce((qty, curr) => {
                                  return Number(qty) + Number(curr.qty);
                                }, 0)}
                              </td>
                              <td ></td>
                              {!data.showgst && <td></td>}
                              <td></td>
                              {data.showgst && <td></td>}

                              <td></td>
                              <td className="font-bold">{data.grandtotal}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                    </td>
                  </tr >
                  <tr className="eight-section">
                    <td colSpan="3">
                      <div className='saanvi-info-container'>
                        <div className='saanvi-info'>
                          <div className='bank-details'>Bank Details</div>
                          <div className='bank-info'>
                            <p className='mb-0'>
                              <span className="btitle">Bank:</span>
                              <span className="desc">{billinfo.bank ? billinfo.bank : "--"}</span>
                            </p>
                            <p className='mb-0'>
                              <span className="btitle">Account Number:</span>
                              <span className="desc">{billinfo.accountnumber ? billinfo.accountnumber : "--"}</span>
                            </p>
                            <p className='mb-0'>
                              <span className="btitle">IFSC Code:</span>
                              <span className="desc">{billinfo.ifsccode ? billinfo.ifsccode : "--"}</span>
                            </p>
                            <p className='mb-0'>
                              <span className="btitle">Branch:</span>
                              <span className="desc"> {billinfo.branch ? billinfo.branch : "--"}</span>
                            </p>
                          </div>
                          <div className='tandc'>
                            <Form.Group className="mb-3" controlId="tandc">
                              <Form.Label>Terms and Conditions</Form.Label>
                              <Form.Control
                                type="text"
                                as="textarea"
                                rows={3}
                                name="tandc"
                                value={data.tandc}
                                className='tandcbox'
                              // isInvalid={data.description === "" && showEmptyError}
                              />
                              <Form.Control.Feedback type="invalid">
                                Enter Terms and Conditions
                              </Form.Control.Feedback>

                            </Form.Group>
                          </div>
                        </div>
                        <div className='my-auto'>
                          <Image
                            src={billinfo.qrcode}
                            alt='Qr image not available'
                            width="180"
                            height="180"
                          />
                          <h4>Merchant Name: {billinfo.accountholdername ? billinfo.accountholdername : "--"}</h4>
                          <h4>Mobile Number: {billinfo.phonenumber ? billinfo.phonenumber : "--"}</h4>
                        </div>
                        <div className='saanvi-info'>
                          <p className='saanv-certificate-section'>
                            <span className='certified'>Certified that particulars given above are true</span>
                            <span className='certified-section'>For {billinfo.brandname ? billinfo.brandname : "--"}</span>
                          </p>
                          {/* <div className='signature-section'></div> */}
                          <p className='authorized'>Authorized Signature </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="ten-section">
                    <td colSpan={3}>
                      <div className='text-center'>
                        <h4>POWERED BY VENTURESATHI BUSINESS SERVICES LLP</h4>
                        <h5>This is a Computer Generated Invoice </h5>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row >
    </Container >
  )
}

export default ViewSalesInvoice