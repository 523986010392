import {
  salesOrderAddPost,
  salesOrderCountPost,
  salesOrderDeletePost,
  salesOrderMultipleDocumentUploadPost,
  salesOrderPagiDataPost,
  salesOrderUpdatePost,
  termsAndConditionAllDataPost,
} from "../../constants/api";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_SALES_ORDER_COUNT,
  FETCH_SALES_ORDER_DATA,
  FETCH_SALES_ORDER_DOCUMENT,
  FETCH_TERM_AND_CONDITION_DATA,
} from "../actionTypes/salesOrder.actiontypes";

//Get all salesOrder data using pagination wise
export const salesOrderPagiData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(salesOrderPagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_SALES_ORDER_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching salesOrder data ${error}`);
      notifyError(error.message);
    }
  };

//Get all salesOrder data using pagination wise
export const termsAndConditionData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(termsAndConditionAllDataPost, backendData);
      const initialData = res && res.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_TERM_AND_CONDITION_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching terms and condition data in SO ${error}`);
      notifyError(error.message);
    }
  };

//Get salesOrder auto increment count
export const salesOrderAutoCount =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(salesOrderCountPost, backendData);
      const initialCount = Number(res.data.count) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: FETCH_SALES_ORDER_COUNT, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching salesOrder auto count data ${error}`);
      notifyError(error.message);
    }
  };

//Action dispatcher for document upload
export const salesOrderDocmnentUpload = (backendData) => async (dispatch) => {
  try {
   
    // dispatch({ type: RELOAD, payload: true });
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
    
    const res = await axios.post(
      salesOrderMultipleDocumentUploadPost,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
   
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: FETCH_SALES_ORDER_DOCUMENT, payload: fileurl });
    } else {
      notifyWarning("File not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading salesOrder file:", error.message);
    throw error;
    notifyError(error.message);
  }
};

//Acion dispatcher for adding new salesOrder data
export const salesOrderAdd = (backendData, navigate, message) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(salesOrderAddPost, backendData);
    if (res.data.message === "Sale order added successfully") {
      notifySuccess(message || res.data.message);
      navigate("/sales/salesOrder");
    } else {
      notifyError(res.data.message);
    }
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new salesOrder data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for adding new salesOrder data
export const salesOrderAddedInDraftMode = (backendData, message) => async (dispatch) => {
  try {
    const res = await axios.post(salesOrderAddPost, backendData);
    if (res.data.message === "Sale order added successfully") {
      notifySuccess(message || res.data.message);
    } else {
      notifyError(res.data.message);
    }
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new salesOrder data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating salesOrder data
export const salesOrderUpdate = (backendData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(salesOrderUpdatePost, backendData);
    
    if (res.data.message === "Sale order updated successfully") {
      notifySuccess(res.data.message);
      navigate("/sales/salesOrder");
    } else {
      notifyError(res.data.message);
    }
    navigate("/sales/salesOrder");
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in updating salesOrder data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Delete salesOrder data
export const salesOrderDelete =
  (backendData, handleClose) => async (dispatch) => {
    try {
      const res = await axios.post(salesOrderDeletePost, backendData);

      if (res.data.message == "Sale order deleted successfully") {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleClose();
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.log(`Error in deleting Sales Order data ${error}`);
      notifyError(error.message);
    }
  };
