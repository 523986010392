import { FETCH_PROJECT_DATA } from "../actionTypes/projects.actiontypes";


  const initialState = {
    projectData: {},
  };
  
  //
  export const projectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_PROJECT_DATA: {
        return { ...state, projectData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };
  