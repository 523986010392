import React from 'react'
import ListTable from './ListTable';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import { Col, Container, Row } from 'react-bootstrap';

const ItemAgeReport = () => {
    const URL = extractAndFormatURLPart("reports");
    return (
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListTable />
          </Col>
        </Row>
      </Container>
    );
}

export default ItemAgeReport