import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyWarning } from "../../../../constants/toastAlert";
import { bankPagiData } from "../../../../redux/actions/bank.actions";
import { branchPagiData } from "../../../../redux/actions/branch.action";
import { addContraEntry, contraEntryAutoCount, contraEntryPagiData } from "../../../../redux/actions/contraEntry.actions";
import { getCurrentPreviousYears } from "../../../../constants/currentPreviousYear";


const AddContraForm = () => {
    const finalcialYear = localStorage.getItem("financialyear");
    const finalYear = finalcialYear.trim().split("-");
    const firstFinalcialYear = finalYear[0].replace('"', '')
    const secondFinalcialYear = finalYear[1].replace('"', '')

    //Redux part
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.loadReducer);
    const { companyid, userid, companyname, branchid, org } = useSelector((state) => state.authManager.userData.data[0]) || {};
    // const { contraEntryCount } = useSelector((state) => state.contraEntryData) || 0;
    const { data: bankAllData } = useSelector((state) => state.bankData.bankPagiData) || [];
    const { data: branchAllData } = useSelector((state) => state.branchData.branchPagiData) || [];
    const {data : contraEntryData} = useSelector((state) => state.contraEntryData.contraEntryPagiData) || [];
    const contraEntryAllData = !contraEntryData ? [] : contraEntryData;

    // filter data
    let bankArr = bankAllData && bankAllData.map(ele => `${ele.bank},${ele.branchname},${ele.accountnumber}`);
    let branchArr = branchAllData && branchAllData.map(ele => `${ele.branchname}`);
    let cashArr = ["Company Cash Account"];
    // setBankArr([...temp]);

    //state
    const paymentData = ["Cash", "Bank Transfer", "Cheque", "UPI"];
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [allPaidThrough, setAllPaidThrough] = useState([]);
    const [bankdata, setBankdata] = useState([]);
    const [paidTodata, setPaidToData] = useState([]);

    const [siteArr, setSiteArr] = useState([]);
    // const [bankArr, setBankArr] = useState([]);
    // const [cashArr, setCashArr] = useState([]);

    const [toArray, setToArray] = useState([]);
    const [fromArray, setFromArray] = useState([]);

    const [isrefnoMandatory, setIsrefnoMandatory] = useState(false);
    const [isRemarksMandatory, setIsRemarksMandatory] = useState(false);
    const [loadState, setLoadState] = useState(false);

    const [data, setData] = useState({
        contraid: "",
        contratype: "",
        date: "",
        month: "",
        prefno: "",
        amount: "",
        from: "",
        to: "",
        remark: "",
        companyid: companyid,
        userid: userid,
        branchid: branchid,
        org: org,
        financialyear: finalcialYear
    });

    //default data backend needed
    const backendData = {
        keyword: "₹ΘθϖΠ",
        userid: userid,
        companyid: companyid,
        branchid: branchid
    }

    useEffect(() => {
        setLoading(!loading);
        // dispatch(contraEntryAutoCount(backendData, setLoading))
        dispatch(contraEntryPagiData(backendData, setLoading));
        dispatch(bankPagiData(backendData, setLoading));
        dispatch(branchPagiData(backendData, setLoading));
        let count = autoGenerateID();
        let paddedCount = count.toString().padStart(4, "0");
        let result = `CT/${getCurrentPreviousYears()}/${paddedCount}`;
        setData({
            ...data,
            contraid: result,
            companyid: companyid,
            userid: userid,
            branchid: branchid,
            org: org,
            financialyear: finalcialYear
        })
    }, [dispatch, loadData, show]);

    //Auto generate Id start
    function autoGenerateID() {
        if (contraEntryAllData.length === 0) {
            return "1";
        } else {
            const currentId = contraEntryAllData[0].contraid.split("/")[2];
            return String(parseInt(currentId) + 1);
        }
    };

    //Auto generate Id end

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "contratype") {
            setData((prev) => ({
                ...prev,
                contratype: "",
                prefno: "",
                from: "",
                to: "",
                remark: "",
            }))
            setFromArray([]);
            setToArray([]);
            setIsrefnoMandatory(false);
            setIsRemarksMandatory(false);
            setShowEmptyError(false);
        }

        if (value === "Cash Deposit") {
            setToArray([...bankArr]);
            setFromArray([...cashArr]);
            setIsrefnoMandatory(true);
            setIsRemarksMandatory(false);
        }

        if (value === "Cash Withdrawl") {
            setToArray([...cashArr]);
            setFromArray([...bankArr]);
            setIsrefnoMandatory(true);
            setIsRemarksMandatory(false);
        }

        if (value === "Cash To Cash(Send)") {
            setToArray([...branchArr]);
            setFromArray([...cashArr]);
            setIsrefnoMandatory(false);
            setIsRemarksMandatory(true);
        }

        if (value === "Cash To Cash(Receive)") {
            setToArray([...cashArr]);
            setFromArray([...branchArr]);
            setIsrefnoMandatory(false);
            setIsRemarksMandatory(true);
        }

        if (value == "Bank To Bank") {
            setFromArray([...bankArr]);
            setToArray([...bankArr]);
            setIsrefnoMandatory(true);
            setIsRemarksMandatory(false);
        }

        if (data.contratype == "Bank To Bank") {
            if (name == "from" && value == "") {
                setFromArray([...bankArr]);
            }

            if (name == "from" && value) {
                let temp = [];
                bankArr.forEach((ele) => {
                    if (ele !== value) {
                        temp.push(ele);
                    }
                });
                setToArray([...temp]);
            }

            if (name == "to" && value == "") {
                setToArray([...bankArr]);
            }

            if (name == "to" && value) {
                let temp = [];
                bankArr.forEach((ele) => {
                    if (ele !== value) {
                        temp.push(ele);
                    }
                });
                setFromArray([...temp]);
            }
        }

        if (name == "date") {
            const dateObj = new Date(value);
            const month = dateObj.toLocaleString('default', { month: 'long' });
            setData((prev) => ({
                ...prev,
                month: month,
            }))
        }

        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleClose = () => {
        setData({
            id: "",
            contraid: "",
            date: "",
            month: "",
            contratype: "",
            amount: "",
            prefno: "",
            from: "",
            to: "",
            remark: "",
            branchid: "",
            userid: "",
            companyid: "",
            org: "",
            financialyear: ""
        });
        setShow(false);
        setIsRemarksMandatory(false);
        setIsrefnoMandatory(false);
        setShowEmptyError(false);
        // dispatch(reloadPage(true));
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            data.contratype == "" ||
            data.amount == "" ||
            data.date == "" ||
            data.from == "" ||
            data.to == ""
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        if (data.contratype == "Bank To Bank" && data.from == data.to) {
            return notifyWarning("Sender and Receive account can not be same");
        }

        if (isRemarksMandatory && data.remark == "") {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        if (isrefnoMandatory && data.prefno == "") {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        } else {
            dispatch(addContraEntry(data, handleClose));
        }
    };

    return (
        <>
            <Button className="commonBtn mt-2" onClick={handleShow}>
                Add Contra
            </Button>
            <Modal
                size="xl"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add <span className="form-title">Contra Entry</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="form-lable-name">
                        <Container fluid>
                            <Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="formBasicId">
                                            <Form.Label>Contra No.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Contra No."
                                                name="contraid"
                                                value={data.contraid}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicSite">
                                            <Form.Label>
                                                Contra type<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name="contratype"
                                                onChange={handleChange}
                                                value={data.contratype}
                                                isInvalid={data.contratype === "" && showEmptyError}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Bank To Bank">Bank To Bank</option>
                                                <option value="Cash Deposit">Cash Deposit</option>
                                                <option value="Cash Withdrawl">Cash Withdrawl</option>
                                                <option value="Cash To Cash(Send)">
                                                    Cash To Cash(Send)
                                                </option>
                                                <option value="Cash To Cash(Receive)">
                                                    Cash To Cash(Receive)
                                                </option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select Contra type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formBasicId">
                                                    <Form.Label>
                                                        Date<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Select Date"
                                                        name="date"
                                                        value={data.date}
                                                        min={`${parseInt(finalYear[0].replace('"', ''), 10)}-04-01`}
                                                        max={`${parseInt(finalYear[1].replace('"', ''), 10)}-03-31`}
                                                        onChange={handleChange}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        required
                                                        isInvalid={data.date === "" && showEmptyError}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Select Date
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group controlId="formBasicId">
                                                    {/* <Form.Label>Reference No/Cheque No</Form.Label> */}
                                                    {isrefnoMandatory ? (
                                                        <Form.Label>
                                                            Reference No/Cheque No{" "}
                                                            <span className="star"> *</span>
                                                        </Form.Label>
                                                    ) : (
                                                        <Form.Label>Reference No/Cheque No</Form.Label>
                                                    )}

                                                    <Form.Control
                                                        type="text"
                                                        name="prefno"
                                                        value={data.prefno}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            data.prefno === "" &&
                                                            showEmptyError &&
                                                            isrefnoMandatory
                                                        }
                                                    />
                                                    {isrefnoMandatory ? (
                                                        <Form.Control.Feedback type="invalid">
                                                            Enter Reference No./Cheque No.
                                                        </Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Form.Group controlId="formBasicId">
                                            <Form.Label>
                                                Amount <span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Amount"
                                                name="amount"
                                                value={data.amount}
                                                onChange={handleChange}
                                                required
                                                isInvalid={data.amount === "" && showEmptyError}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Amount
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicSite">
                                            <Form.Label>
                                                From<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name="from"
                                                onChange={handleChange}
                                                value={data.from}
                                                isInvalid={data.from === "" && showEmptyError}
                                            >
                                                <option value="">-- Select --</option>
                                                {fromArray.map((val, i) => {
                                                    return (
                                                        <option value={val} key={i}>
                                                            {val}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select List
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicSite">
                                            <Form.Label>
                                                To<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name="to"
                                                onChange={handleChange}
                                                value={data.to}
                                                isInvalid={data.to === "" && showEmptyError}
                                            >
                                                <option value="">-- Select --</option>

                                                {toArray.map((val, i) => {
                                                    return (
                                                        <option value={val} key={i}>
                                                            {val}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select List
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group controlId="formBasicId">
                                            {isRemarksMandatory ? (
                                                <Form.Label>
                                                    Remarks<span className="star"> *</span>
                                                </Form.Label>
                                            ) : (
                                                <Form.Label>Remarks</Form.Label>
                                            )}

                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Remarks"
                                                name="remark"
                                                value={data.remark}
                                                onChange={handleChange}
                                                isInvalid={
                                                    data.remark === "" &&
                                                    showEmptyError &&
                                                    isRemarksMandatory
                                                }
                                            />

                                            {isRemarksMandatory ? (
                                                <Form.Control.Feedback type="invalid">
                                                    Add Remarks
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start gap-2">
                    <Button
                        type="button"
                        className="commonBtn smallBtn"
                        onClick={handleSubmit}
                    >
                        {loadData ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />)
                            : (
                                "Save"
                            )}
                    </Button>
                    <Button
                        type="button"
                        className="commonBtn smallBtn cancelBtn"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddContraForm;
