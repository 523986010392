import {
  issueGoodgetPagePost,
  saleInvoiceItemData,
  saleInvoiceSingleDataPost,
  salesInvoiceAddPost,
  salesInvoiceCountPost,
  salesInvoiceDeletePost,
  salesInvoiceMultipleDocumentUploadPost,
  salesInvoicePagiDataPost,
  salesInvoiceUpdatePost,
} from "../../constants/api";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_ISSUE_GOODS_DATA,
  FETCH_SALES_INVOICE_COUNT,
  FETCH_SALES_INVOICE_DATA,
  FETCH_SALES_INVOICE_DOCUMENT,
  FETCH_SALES_INVOICE_SINGLE_DATA,
  FETCH_SALES_ORDER_ITEM_DATA,
} from "../actionTypes/salesInvoice.actiontypes";

//Get all salesInvoice data using pagination wise
export const salesInvoicePagiData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(salesInvoicePagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_SALES_INVOICE_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching salesInvoice data ${error}`);
      notifyError(error.message);
    }
  };

//Get all salesInvoice data using pagination wise
export const salesInvoiceSOItemData =(backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(saleInvoiceItemData, backendData);
      return res.data;
      const initialData = res.data && res.data.length > 0 ? res.data : [];
      // console.log("initialData", initialData);
      if (initialData) {
        dispatch({ type: FETCH_SALES_ORDER_ITEM_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching salesInvoice data ${error}`);
      notifyError(error.message);
    }
  };

//Get salesInvoice auto increment count
export const salesInvoiceAutoCount =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(salesInvoiceCountPost, backendData);
      const initialCount = Number(res.data.count) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: FETCH_SALES_INVOICE_COUNT, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching salesInvoice auto count data ${error}`);
      notifyError(error.message);
    }
  };

//Get salesInvoice single data
export const salesInvoiceSingleData =
  (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(saleInvoiceSingleDataPost, backendData);
     
      const singleData = res.data && res.data[0];
      if (singleData) {
        dispatch({
          type: FETCH_SALES_INVOICE_SINGLE_DATA,
          payload: singleData,
        });
      } else {
        notifyError("Sales Invoice data not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching salesInvoice single record data ${error}`);
      notifyError(error.message);
    }
  };

//Action dispatcher for document upload
export const salesInvoiceDocmnentUpload = (backendData) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
    const res = await axios.post(
      salesInvoiceMultipleDocumentUploadPost,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: FETCH_SALES_INVOICE_DOCUMENT, payload: fileurl });
    } else {
      notifyWarning("File not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading salesInvoice file:", error.message);
    throw error;
    notifyError(error.message);
  }
};

//Acion dispatcher for adding new salesInvoice data
export const salesInvoiceAdd = (backendData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(salesInvoiceAddPost, backendData);
    if (res.data.message === "Sale invoice added successfully") {
      notifySuccess(res.data.message);
      navigate("/sales/salesInvoice");
    } else {
      notifyError(res.data.message);
    }
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new salesInvoice data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating salesInvoice data
export const salesInvoiceUpdate =
  (backendData, navigate) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(salesInvoiceUpdatePost, backendData);
      
      if (res.data.message === "Sales invoice updated successfully") {
        notifySuccess(res.data.message);
        navigate("/sales/salesInvoice");
      } else {
        notifyError(res.data.message);
      }
      navigate("/sales/salesInvoice");
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating salesInvoice data ${error}`);
      notifyError(error.response.data.message);
    }
  };

//Delete salesInvoice data
export const salesInvoiceDelete =
  (backendData, handleClose) => async (dispatch) => {
    try {
      const res = await axios.post(salesInvoiceDeletePost, backendData);
     
      if (res.data.message == 'Sale invoice deleted successfully') {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleClose();
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.log(`Error in deleting Sales invoice data ${error}`);
      notifyError(error.message);
    }
  };

//Get all issue goods data using pagination wise
export const issueGoodsPagiData = (backendData, setLoading) => async (dispatch) => {
  try {
    const res = await axios.post(issueGoodgetPagePost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
    if (initialData) {
      dispatch({ type: FETCH_ISSUE_GOODS_DATA, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching issue goods data ${error}`);
    notifyError(error.message);
  }
};
