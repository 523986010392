import { FETCH_ITEM_DATA, FETCH_ITEM_IMAGE } from "../actionTypes/item.actiontypes";


const initialState = {
  itemPagiData: {},
  itemImage:""
};

export const itemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ITEM_DATA: {
      return { ...state, itemPagiData: { ...payload } };
    }
    case FETCH_ITEM_IMAGE: {
      return { ...state, itemImage: payload };
    }


    default: {
      return state;
    }
  }
};
