import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifyWarning } from "../../../../constants/toastAlert";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import axios from "axios";
import { warehouseGetById } from "../../../../constants/api";
import { branchPagiData } from "../../../../redux/actions/branch.action";
import { updateWarehouse } from "../../../../redux/actions/warehouse.actions";
const states = State.getStatesOfCountry("IN");

const EditForm = () => {
  const { id } = useParams();
  const[loading,setLoading]=useState(false);
  const URL = extractAndFormatURLPart("admin");
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const branchMaster = useSelector((state) => state.branchData.branchPagiData.data);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [data, setData] = useState({
    warehouseid: "",
    companyid: companyid,
    warehousetype: "",
    warehousename: "",
    address: "",
    status: "active",
    userid: userid,
    state: "",
    city: "",
    pincode: "",
    branch: "",
    branchid: "",
    id:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "state") {
      if (value) {
        setData((prev) => ({
          ...prev,
          [name]: value,
          city: "",
        }));
      } else {
        setData((prev) => ({
          ...prev,
          state: "",
          city: "",
        }));
      }
    }

    if(name=="branch"){

        if(value){
        const selectedBranch = branchMaster.find((branch) => branch.branchname == value);

        setData((prev) => ({
            ...prev,
            [name]: selectedBranch.branchname,
            branchid: selectedBranch.branchid,
          }));
        }else{
            setData((prev) => ({
                ...prev,
                branch: "",
                branchid: "",
              }));
        }

    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
   
    if(!data.warehousetype || !data.warehouseid || !data.warehousename || !data.branch){
        setShowEmptyError(true);
        return notifyError("You have empty field");
    }

    dispatch(updateWarehouse(data, navigate));
  };

  useEffect(() => {
    if (data.state) {
        const selectedState = states.find((state) => state.name === data.state);
        const citiesOfSelectedState = City.getCitiesOfState("IN", selectedState.isoCode);
        const cityNames = citiesOfSelectedState.map((city) => city.name);
        setCities(cityNames);
    } else {
      setCities([]);
    }
  }, [data.state]);

  //for dynamically getting  branch Master Data
  useEffect(() => {
    setLoading(!loading);
    dispatch(branchPagiData({userid,company:companyname,companyid,keyword:""}, setLoading));
  }, []);

  // dynamically get warehouse by id
  useEffect(() => {
    axios
      .post(warehouseGetById, { id })
      .then((res) => {
        setData((prev) => ({
          ...prev, 
          ...res.data[0],
        }));
      })
      .catch((err) => {
        console.log(err);
        notifyWarning(err);
      });
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <div className="form-container">
          <Form className="form-lable-name">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/admin/godownOrShowroom")}
              />
              Edit <span className="form-title main"> Godown/Showroom</span>
            </h1>
            <hr />
            <div className="d-flex gap-4">
              <div className="w-100">
                <Container fluid>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Type <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="warehousetype"
                          value={data.warehousetype}
                          onChange={handleChange}
                          isInvalid={!data.warehousetype && showEmptyError}
                        >
                          <option value="">Select</option>
                          <option value="Godown">Godown</option>
                          <option value="Showroom">Showroom</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          ID<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          name="warehouseid"
                          value={data.warehouseid}
                          onChange={handleChange}
                          isInvalid={!data.warehouseid && showEmptyError}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Name<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Warehouse Name"
                          name="warehousename"
                          value={data.warehousename}
                          onChange={handleChange}
                          isInvalid={!data.warehousename && showEmptyError}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Branch <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="branch"
                          value={data.branch}
                          onChange={handleChange}
                          isInvalid={!data.branch && showEmptyError}
                        >
                          <option value="">Select</option>
                          {
                            branchMaster?.filter((branch)=>branch.status=="active").map((branch)=>{
                                const{branchname,id}=branch
                                return <option value={branchname} key={id}>{branchname}</option>
                            })
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Adress</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          name="address"
                          value={data.address}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>State</Form.Label>
                            <Form.Select
                              name="state"
                              value={data.state}
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {states.map((state) => {
                                return (
                                  <option
                                    value={state.name}
                                    key={state.isoCode}
                                  >
                                    {state.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Select
                              name="city"
                              value={data.city}
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {cities.map((city, ind) => {
                                return (
                                  <option value={city} key={ind}>
                                    {city}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                              type="number"
                              name="pincode"
                              value={data.pincode}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
          <hr />
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
            <Button className="commonBtn smallBtn" onClick={handleSave}>
              {loadData ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Update"
              )}
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={() => navigate("/admin/godownOrShowroom")}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    </>
  );
};

export default EditForm;
