import {
    GET_ALL_SALE_PAYMENT,
  SALE_PAYMENT_ID_GENERATE,
  UPLOAD_FILE,
} from "../actionTypes/salepayment.actiontypes";

const initialState = {
  salePaymentPagiData: {},
  salePaymentCount: 0,
  salePaymentFile: "",
  salePaymentByID: {},
};

export const salePaymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_SALE_PAYMENT: {
      return { ...state, salePaymentPagiData: { ...payload } };
    }

    case SALE_PAYMENT_ID_GENERATE: {
      return { ...state, salePaymentCount: payload };
    }

    case UPLOAD_FILE: {
      return { ...state, salePaymentFile: payload };
    }

    case UPLOAD_FILE: {
      return { ...state, salePaymentByID: { ...payload } };
    }

    default: {
      return state;
    }
  }
};
