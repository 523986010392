import axios from "axios";
import { addTcsApi, deleteTcsApi, getTcsApi, updateTcsApi } from "../../constants/api";
import { ADD_TCS_DATA, FETCH_TCS_DATA, UPDATE_TCS_DATA } from "../actionTypes/taxes.actiontypes";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";

  
  //Get all tcs data using pagination wise
  export const tcsData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getTcsApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_TCS_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching tcs data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete tcs data dispatcher
  export const deleteTcsData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteTcsApi, backendData);
        if (res.data.message == "TCS deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting tcs data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new tcs data
  export const addTcs = (tcsdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addTcsApi, tcsdata);
  
      if (res.data.message === "TCS added successfully") {
        dispatch({ type: ADD_TCS_DATA });
        notifySuccess(res.data.message);
        navigate("/taxes/tdsTcs");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding tcs data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateTcs = (tcsdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updateTcsApi, tcsdata);
  
      if (res.data.message === "TCS updated successfully") {
        dispatch({ type: UPDATE_TCS_DATA });
        notifySuccess(res.data.message);
        navigate("/taxes/tdsTcs");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating tcs data ${error}`);
      notifyError(error.response.data.message);
    }
  };