import {
  paymentAddPost,
  paymentCountPost,
  paymentDeletePost,
  paymentPagiDataPost,
  paymentUpdatePost,
} from "../../constants/api";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import {
  FETCH_PAYMENT_COUNT,
  FETCH_PAYMENT_DATA,
} from "../actionTypes/payment.actiontypes";

//Get all payment data using pagination wise
export const paymentPagiData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(paymentPagiDataPost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_PAYMENT_DATA, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching Payment data ${error}`);
      notifyError(error.message);
      setLoading(false);
    }
  };

//Get payment auto increment count
export const paymentAutoCount = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(paymentCountPost, backendData);
      const initialCount = Number(res.data.paymentCount) + 1 || 0;
      if (initialCount != 0) {
        dispatch({ type: FETCH_PAYMENT_COUNT, payload: initialCount });
      } else {
        notifyError("Count not comming from backend");
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching payment auto count data ${error}`);
      notifyError(error.message);
    }
  };

//Acion dispatcher for adding new Payment data
export const addPayment = (backendData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: RELOAD, payload: true });
    const res = await axios.post(paymentAddPost, backendData);
    if (res.data.message === "Payment added successfully") {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    handleClose();
  } catch (error) {
    // dispatch({ type: LOGIN_FAILURE, payload: error.message });
    console.log(`Error in adding new Payment data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating Payment data
export const updatePayment =
  (backendData, handleCloseEditModal) => async (dispatch) => {
    try {
      dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(paymentUpdatePost, backendData);
      if (res.data.message === "Payment updated successfully") {
        notifySuccess(res.data.message);
      } else {
        notifyError(res.data.message);
      }
      handleCloseEditModal();
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating Payment data ${error}`);
      notifyError(error.response.data.message);
    }
  };

//Delete Payment data
export const deletePayment = (backendData, handleClose) => async (dispatch) => {
  try {
    const res = await axios.post(paymentDeletePost, backendData);
    
    if (res.data.message == 'Payment deleted successfully') {
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    handleClose();
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in deleting Payment data ${error}`);
    notifyError(error.message);
  }
};
