import React from "react";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListAccess from "./ListAccess";
import { Col, Container, Row } from "react-bootstrap";

const AccessControl = () => {
  const URL = extractAndFormatURLPart("admin");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListAccess />
        </Col>
      </Row>
    </Container>
  );
};

export default AccessControl;
