import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';

function DeleteConfirmation({ show, handleShow, handleClose, handleDelete,name }) {
    return (
        <>
            <MdDelete className='deleteIcon' onClick={handleShow} />
            <Modal show={show} onHide={handleClose} size="md" backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title className='f1'>Delete Record!!!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='f1'>Are you sure you want to delete this {name}?</Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <Button className='commonBtn w-25' onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button className='commonBtn cancelBtn w-25' onClick={handleClose}>
                        Discard
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DeleteConfirmation;