import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  companyData,
  deleteCompanyData,
  statusChange,
} from "../../../redux/actions/company.actions";
import GlobalPagination from "../../../constants/GlobalPagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { MdModeEditOutline } from "react-icons/md";
import ViewModal from "../../../constants/ViewModal";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";

const ListTableFirm = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Tooltip
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  //Redux part
  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector((state) => state.companyData.companyData);
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    userid: userid,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(companyData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  const handleSwitchChange = (e, id, status) => {
    dispatch(statusChange({ id: id, status: status }));
  };

  const handleShow = (item) => {
    const { id, companyid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(companyid);
  };

  const handleEdit = (id) => {
    navigate(`/admin/companyManagement/editCompany/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteCompanyData(
        {
          id: selectedItemId,
          companyid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Companies</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            <Button
              className="commonBtn mt-2"
              onClick={() => navigate("/admin/companyManagement/addCompany")}
            >
              Add Company
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Company ID</th>
              <th>Company Name</th>
              <th>Owner Name</th>
              <th>Head Office Address</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="9" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  companyid,
                  companyname,
                  emailid,
                  hoaddress,
                  id,
                  msmeno,
                  ownername,
                  phoneno,
                  gstno,
                  pan,
                  cinno,
                  type,
                  registrationno,
                  status,
                  userid,
                } = item;
                return (
                  <tr key={index}>
                    <td>{companyid}</td>
                    <td>{companyname}</td>
                    <td>{ownername}</td>
                    <td>{hoaddress}</td>
                    <td>{emailid}</td>
                    <td>{phoneno}</td>
                    <td className="d-flex justify-content-center gap-2">
                      <div>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderTooltip("Active/Inactive")}
                        >
                          <Form.Check
                            type="switch"
                            // id="custom-switch"
                            defaultChecked={status === "active"}
                            // className="custom-switch"
                            onChange={(e) =>
                              handleSwitchChange(
                                e,
                                id,
                                status == "active" ? "inactive" : "active"
                              )
                            }
                          />
                        </OverlayTrigger>
                      </div>

                      <div>
                        <ViewModal
                          title="Company"
                          item={{
                            "Company Name": companyname,
                            Email: emailid,
                            "Owner Name": ownername,
                            "Phone Number": phoneno,
                            Type: type,
                            "Pan No": pan,
                            "GST No.": gstno,
                            "LLPIN/CIN": cinno,
                            "MSME No": msmeno,
                          }}
                        />
                      </div>

                      <div>
                        <MdModeEditOutline
                          className="editIcon mr-2"
                          onClick={() => handleEdit(item.id)}
                        />
                      </div>

                      <div>
                        <DeleteConfirmation
                          show={show}
                          handleShow={() => handleShow(item)}
                          handleClose={handleClose}
                          handleDelete={handleDelete}
                          name={"Company"}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="9" name="Company" />
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTableFirm;
