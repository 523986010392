import React from "react";

const DataSpinner = ({count}) => {
  return (
    <tr>
      <td colSpan={count} className="text-center">
        <div className="spinner-border spinner-border-md" role="status">
          <span className="sr-only"></span>
        </div>
      </td>
    </tr>
  );
};

export default DataSpinner;
