export function validateAadharNumber(aadharNumber) {

    // Check if the Aadhaar number has 12 digits
    const cleanAadharNumber = aadharNumber.replace(/\s/g, ''); // Remove white spaces
    if (cleanAadharNumber.length !== 12) {
      return "Aadhaar number must have exactly 12 digits.";
    }
  
    // Check if the Aadhaar number starts with 0 or 1
    if (cleanAadharNumber[0] === '0' || cleanAadharNumber[0] === '1') {
      return "Aadhaar number should not start with 0 or 1.";
    }
  
    // Check if the Aadhaar number contains only digits
    if (!/^\d+$/.test(cleanAadharNumber)) {
      return "Aadhaar number should not contain alphabets or special characters.";
    }
  
    // All conditions passed, Aadhaar number is valid
    return "Done";
  }


//   validation for pan no . 

export function validatePanNumber(panNumber) {

    // Check if the PAN number is ten characters long
    if (panNumber.length !== 10) {
      
      return "PAN number must be ten characters long.";
    }
  
    // Check if the first five characters are uppercase alphabets
    if (!/^[A-Z]{5}$/.test(panNumber.substring(0, 5))) {
      return "The first five characters of pan should be uppercase alphabets.";
    }
  
    // Check if the next four characters are numbers (0-9)
    if (!/^[0-9]{4}$/.test(panNumber.substring(5, 9))) {
      return "The numeric part of PAN should contain four digits.";
    }
  
    // Check if the last character is an uppercase alphabet
    if (!/^[A-Z]$/.test(panNumber[9])) {
      return "The last character of pan should be an uppercase alphabet.";
    }

  // all condition passed
  return "Done"
  
  }


//   validation for GST no. 


export function validateGSTNumber(gstNumber) {

  // Check if the GST number is 15 characters long
  if (gstNumber.length !== 15) {

    return "GST number must be 15 characters long.";
  }

  // Check if the first two characters are numbers (00 to 99)
  const firstTwoDigits = parseInt(gstNumber.substring(0, 2), 10);
  if (isNaN(firstTwoDigits) || firstTwoDigits < 0 || firstTwoDigits > 99) {

    return "The first two characters of GST should be numbers (00 to 99).";
  }

  // Check if the next five characters are uppercase alphabets
  if (!/^[A-Z]{5}$/.test(gstNumber.substring(2, 7))) {
 
    return " Characters 3 to 7 of GST should be uppercase alphabets.";
  }

  // Check if the next four characters are numbers (0000 to 9999)
  const nextFourDigits = parseInt(gstNumber.substring(7, 11), 10);
  if (isNaN(nextFourDigits) || nextFourDigits < 0 || nextFourDigits > 9999) {

    return "Characters 8 to 11 of GST should be numbers (0000 to 9999).";
  }

  // Check if the next character is an uppercase alphabet
  if (!/^[A-Z]{1}$/.test(gstNumber[11])) {
    return "Character 12 of GST should be an uppercase alphabet.";
  }

  // Check if character 13 is a number from 1 to 9
  const entityCode = parseInt(gstNumber[12], 10);
  if (isNaN(entityCode) || entityCode < 1 || entityCode > 9) {
    return "Character 13 of GST should be a number from 1 to 9.";
  }

  // Check if character 14 is 'Z'
  if (gstNumber[13] !== 'Z') {

    return "Character 14 of GST should be 'Z'.";
  }

  // Check if character 15 is an alphanumeric character (number or alphabet)
  if (!/^[0-9A-Z]{1}$/.test(gstNumber[14])) {
    return "Character 15 of GST should be a number or an alphabet.";
  }

  // All conditions passed, GST number is valid
  return "Done";
}



//   validation for bank account number 


export function validateBankAccountNumber(accountNumber) {
 
    // Check if the account number is within the specified length range
    if (accountNumber.length < 9 || accountNumber.length > 18) {
  
      return "Account number should be between 9 to 18 digits.";
    }
  
    // Check for the presence of whitespaces
    if (/\s/.test(accountNumber)) {
  
      return "Invalid bank account number format. Whitespaces are not allowed.";
    }
  
    // Check for the presence of special characters
    if (/[^0-9]/.test(accountNumber)) {
  
      return "Special characters are not allowed. in bank account";
    }
  
    // All conditions passed, bank account number is valid
    return "Done";
  }


//   validation for IFSC code 

export function validateIFSCCode(ifscCode) {
  
    // Check if the IFSC code is 11 characters long
    if (ifscCode.length !== 11) {
  
      return "IFSC code must be 11 characters long.";
    }
  
    // Check if the first four characters are uppercase alphabets
    if (!/^[A-Z]{4}$/.test(ifscCode.substring(0, 4))) {
  
      return "The first four characters of IFSC should be uppercase alphabets.";
    }
  
    // Check if the fifth character is '0'
    if (ifscCode[4] !== '0') {
  
      return " The fifth character of IFSC should be '0'.";
    }
  
    // Check if the last six characters are alphanumeric
    if (!/^[A-Za-z0-9]{6}$/.test(ifscCode.substring(5))) {
  
      return "The last six characters of IFSC should be alphanumeric.";
    }
  
    // All conditions passed, IFSC code is valid
    return "Done"
  }


 // validation for phone number

  export function validatePhoneNumber(phoneNumber) {

    if (phoneNumber.length !== 10) {
      return "Invalid phone number format. Please enter a 10-digit phone number.";
    }
  
    // All conditions passed, phone number is valid
    return "Done";
  }
  
  // validation for age

  export function validateAge(age) {
    // Convert age to an integer
    const parsedAge = parseInt(age, 10);
  
    // Check if the age is 18 or above
    if (parsedAge < 18) {
     
      return "Age must be 18 years or older.";
    }
  
    // All conditions passed, age is 18 or above
   
    return "Done";
  }
  
  // validation for email

 export function validateEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  
    // Check if the email matches the regular expression
    if (!emailRegex.test(email)) {
      return "Invalid email format. Please enter a valid email address.";
    }

    return "Done";
  }

  // validation for upi id

 export function validateUPI(upiId) {
    // Regular expression for basic UPI ID validation
    const upiRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    // Check if the UPI ID matches the regular expression
    if (!upiRegex.test(upiId)) {

      return "Invalid UPI ID format. Please enter a valid UPI ID.";
    }
  

    return "Done";
  }

  
  // validation for vehicle number 

  export function validateIndianVehicleNumber(vehicleNumber) {
    // Regular expression for Indian vehicle number validation
    const vehicleNumberRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{2}\d{4}$/;
  
  
    // Check if the first two characters are uppercase letters
    if (!/^[A-Z]{2}$/.test(vehicleNumber.substring(0, 2))) {
      return "First two characters of vehicle number should be capital letters.";
    }
  
    // Check if the next two characters are numeric
    const nextTwoDigits = parseInt(vehicleNumber.substring(2, 4), 10);
    if (isNaN(nextTwoDigits) || nextTwoDigits < 0 || nextTwoDigits > 99) {
      return "3rd & 4th characters of vehicle number should be numeric.";
    }
  
    // Check if the next two characters are alphanumeric
    if (!/^[A-Z0-9]{2}$/.test(vehicleNumber.substring(4, 6))) {
      return "5-6 characters of vehicle number should be alphanumeric.";
    }
  
    // Check if the last four characters are numeric
    const lastFourDigits = parseInt(vehicleNumber.substring(6), 10);
    if (isNaN(lastFourDigits) || lastFourDigits < 0 || lastFourDigits > 9999) {
      return " Last four characters of vehicle number should be numeric.";
    }
  
    // All conditions passed, vehicle number is valid
    return "Done";
  }

  // validation for MPN number

  export function validateMPN(mpn) {
    // Regular expression for basic Manufacturer Part Number validation
    const mpnRegex = /^[a-zA-Z0-9]+$/;
  
    // Check if the MPN matches the regular expression
    if (!mpnRegex.test(mpn)) {
      return "Invalid Manufacturer Part Number format. Please use alphanumeric characters only.";
    }
  
    return "Done";
  }
  
  // validation for HSN Code

 export function validateHSNCode(hsnCode) {
    // Regular expression for basic HSN code validation
    const hsnCodeRegex = /^[0-9]{4}\s*[0-9]{2}\s*[0-9]{2}$/;
  
  
    // Remove whitespaces for validation
    const trimmedHSNCode = hsnCode.replace(/\s/g, '');
  
    // Check if the HSN code matches the regular expression
    if (!hsnCodeRegex.test(trimmedHSNCode)) {
      return "Invalid HSN code format. Please enter a valid HSN code.";
    }
  
    return "Done";
  }
  

  export function validateCIN(cin) {
    // Regular expression for CIN validation
    const cinRegex = /^[UL][0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6}$/;

  
    // Check if the CIN starts with U or L
    if (!/^[UL]/.test(cin)) {
      return "Invalid CIN format. It should start with U or L.";
    }
  
    // Check if the next five characters are digits
    if (!/^[UL][0-9]{5}/.test(cin)) {
      return "Invalid CIN format. Characters 2 to 6 should be digits (0-9).";
    }
  
    // Check if the next two places are occupied by alphabet letters
    if (!/^[UL][0-9]{5}[A-Za-z]{2}/.test(cin)) {
      return "Invalid CIN format. Characters 7 and 8 should be alphabet letters (A-Z or a-z).";
    }
  
    // Check if the next four places are taken by digits
    if (!/^[UL][0-9]{5}[A-Za-z]{2}[0-9]{4}/.test(cin)) {
      return "Invalid CIN format. Characters 9 to 12 should be digits (0-9).";
    }
  
    // Check if the next three characters are reserved for alphabet letters
    if (!/^[UL][0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}/.test(cin)) {
      return "Invalid CIN format. Characters 13 to 15 should be alphabet letters (A-Z or a-z).";
    }
  
    // Check if the next six characters are digits
    if (!cinRegex.test(cin)) {
      return "Invalid CIN format. Characters 16 to 21 should be digits (0-9).";
    }
  
    // All conditions passed, CIN is valid
    return "Done";
  }
  
 
 export function validatePINCode(pinCode) {
    // Regular expression for PIN code validation
    const pinCodeRegex = /^[1-9][0-9]{2}(?:\s?[0-9]{3})?$/;
  
    // Check if the PIN code matches the regular expression
    if (!pinCodeRegex.test(pinCode)) {
      return "Invalid PIN code format. Please enter a valid PIN code.";
    }
  
    // All conditions passed, PIN code is valid
    return "Done";
  }
  

  