// Function to extract the desired part from the URL and capitalize the words
export const extractAndFormatURLPart = (keyword) => {
  const url = window.location.href;
  const urlParts = url.split("/"); // Split the URL by "/"
  const keywordIndex = urlParts.indexOf(keyword); // Find the index of the keyword
  if (keywordIndex !== -1 && keywordIndex <= urlParts.length - 1) {
    // Check if the keyword is in the URL and if there's a part after it
    const desiredPart = urlParts.slice(keywordIndex).join("/"); // Get the part after the keyword
    const formattedPart = desiredPart
      .split("/")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" / ");
    const spacedPart = formattedPart.replace(/([a-z])([A-Z])/g, "$1 $2");
    return spacedPart; // Return the extracted and formatted part
  } else {
    return `The URL does not contain the ${keyword}`;
  }
};
