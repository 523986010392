import React from "react";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { Col, Container, Row } from "react-bootstrap";
import ListCustomer from "./ListCustomer";

const Customer = () => {
  const URL = extractAndFormatURLPart("sales");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListCustomer />
        </Col>
      </Row>
    </Container>
  );
};

export default Customer;
