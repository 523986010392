import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  addSalePaymentData,
  generateSalePaymentID,
  salePaymentData,
  salePaymentFileUpload,
} from "../../../redux/actions/salepayment.actions";
import TableHeading from "../../../constants/TableHeading";
import { salesInvoicePagiData } from "../../../redux/actions/salesInvoice.actions";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import { customerPagiData } from "../../../redux/actions/customer.actions";
import { companyData } from "../../../redux/actions/company.actions";
import { vendorData } from "../../../redux/actions/vendor.actions";
import { bankPagiData } from "../../../redux/actions/bank.actions";
import { notifyError } from "../../../constants/toastAlert";
import { getCurrentPreviousYears } from "../../../constants/currentPreviousYear";

const AddSalePayment = () => {
  const {
    companyid,
    userid,
    companyname,
    name: createdby,
    branchid,
    org,
  } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const financialyear = localStorage.getItem("financialyear") || ""
  const IDdata = useSelector((state) => state.salePaymentData.salePaymentCount);
  const saleBillData =
    useSelector((state) => state.salesInvoiceData.salesInvoicePagiData.data) ||
    [];
  const customersData =
    useSelector((state) => state.customerData.customerPagiData.data) || [];
  const companysData =
    useSelector((state) => state.companyData.companyData.data) || [];
  const vendorsData =
    useSelector((state) => state.vendorData.vendorData.data) || [];
  const banksData =
    useSelector((state) => state.bankData.bankPagiData.data) || [];
  const salepaymentsData =
    useSelector((state) => state.salePaymentData.salePaymentPagiData.data) ||
    [];
  const fileUpload = useSelector(
    (state) => state.salePaymentData.salePaymentFile
  );

  const loadData = useSelector((state) => state.loadReducer);
  const URL = extractAndFormatURLPart("sales");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApplyCredit, setIsApplyCredit] = useState(false);
  const [credits, setCrdits] = useState(0);
  const [data, setData] = useState({
    invoicetype: "",
    commonname: "",
    commonid: "",
    paymentno: "",
    paymentdate: `${new Date().toISOString().split("T")[0]}`,
    paymentmode: "",
    depositeto: "",
    createdby,
    amountreceived: "",
    referenceno: "",
    uploads: [],
    billarray: [],
    amountinpayment: "",
    companyid,
    branchid,
    userid,
    month: "",
    financialyear,
    org,
    total: "",
    excessamount: "",
  });

  const backendData = {
    companyid,
    userid,
    branchid,
    keyword: "₹ΘθϖΠ",
  };

  const generateCategoryID = () => {
    if (salepaymentsData.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = salepaymentsData[0].paymentno.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    setLoading(!loading);
    //dispatch(generateSalePaymentID(backendData, setLoading));
    dispatch(salesInvoicePagiData(backendData, setLoading));
    dispatch(customerPagiData(backendData, setLoading));
    dispatch(companyData(backendData, setLoading));
    dispatch(vendorData(backendData, setLoading));
    dispatch(bankPagiData(backendData, setLoading));
    dispatch(salePaymentData(backendData, setLoading));

    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    let result = `PM/${getCurrentPreviousYears()}/${paddedCount}`;
    setData((prevData) => ({
      ...prevData,
      paymentno: result,
    }));
  }, [dispatch, loadData]);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      uploads: fileUpload || [],
    }));
  }, [fileUpload]);

  useEffect(() => {
    if (data.commonname) {
      const findBills = saleBillData.filter(
        (bill) => bill.commonname == data.commonname
      );
      const updatedFindbills = findBills
        .filter((bill) => bill.balancedue != 0)
        .map((item) => ({
          ...item,
          paymentamount: 0.0,
        }));
      setData((prev) => ({ ...prev, billarray: updatedFindbills }));
    } else {
      setData((prev) => ({ ...prev, billarray: [] }));
    }
  }, [data.commonname]);

  useEffect(() => {
    const creditBills = salepaymentsData.filter(
      (bill) => bill.commonname == data.commonname
    );
    const creditAmount =
      creditBills.length > 0 ? creditBills[0].excessamount : 0;

    setCrdits(creditAmount <= 0 ? 0 : creditAmount);
  }, [data.commonname]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name == "invoicetype") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        commonname: "",
        commonid: "",
      }));
    }
    if (name == "commonname") {
      const commonvalue = value.split("|");
      setData((prevData) => ({
        ...prevData,
        commonname: commonvalue[0],
        commonid: commonvalue[1],
      }));
    }
    if (name == "paymentdate") {
      const monthName = new Date(value).toLocaleDateString("en-US", {
        month: "long",
      });
      setData((prevData) => ({
        ...prevData,
        month: monthName,
      }));
    }
    if (name == "paymentmode") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
        depositeto: "",
      }));
    }
    if (name === "documents") {
      dispatch(salePaymentFileUpload(files));
    }

    setShowEmptyError(false);
  };

  const totalbillamount = data.billarray.reduce((acc, item) => {
    return acc + Number(item.balancedue);
  }, 0);

  const handleApplyCredit = () => {
    // const updatedBillArr = data.billarray.map((item) => ({
    //   ...item,
    //   paymentamount:
    //     credits >= parseInt(totalbillamount) ? item.balancedue : 0.0,
    // }));
    const totalCredits = Number(credits) + Number(data.amountreceived || 0);
    const updatedBillArr = data.billarray.map((item) => {
      return {
        ...item,
        paymentamount:
          totalCredits >= Number(totalbillamount) ? item.balancedue : 0.0,
      };
    });

    setData((prevData) => ({
      ...prevData,
      billarray: updatedBillArr,
    }));

    const Total = updatedBillArr.reduce((acc, curr) => {
      return acc + Number(curr.paymentamount);
    }, 0);
    setData((prevData) => ({
      ...prevData,
      total: Total,
      amountinpayment: Total,
    }));
    const totalAmountDue = updatedBillArr.reduce((acc, curr) => {
      return acc + Number(curr.balancedue);
    }, 0);
    setData((prevData) => ({
      ...prevData,
      excessamount: totalCredits - totalAmountDue,
    }));
  };

  const handleTableRowChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedBillArr = data.billarray
      .filter((bill) => bill.balancedue != 0)
      .map((item, i) => (i == ind ? { ...item, [name]: value } : item));

    setData((prev) => ({
      ...prev,
      billarray: updatedBillArr,
    }));
    const Total = updatedBillArr.reduce((acc, curr) => {
      return acc + Number(curr.paymentamount);
    }, 0);

    setData((prevData) => ({
      ...prevData,
      total: Total,
      amountinpayment: Total,
      amountreceived: Total,
    }));

    const totalAmountDue = updatedBillArr.reduce((acc, curr) => {
      return acc + Number(curr.balancedue);
    }, 0);
    setData((prevData) => ({
      ...prevData,
      excessamount: Total - totalAmountDue,
    }));
  };

  const handlePaymentAmountChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      amountreceived: value,
    }));
    if (Number(credits) == 0) {
      const updatedBillArr = data.billarray.map((item) => ({
        ...item,
        paymentamount:
          value >= parseInt(totalbillamount) ? item.balancedue : 0.0,
      }));
      setData((prevData) => ({
        ...prevData,
        billarray: updatedBillArr,
      }));
      const Total = updatedBillArr.reduce((acc, curr) => {
        return acc + Number(curr.paymentamount);
      }, 0);

      setData((prevData) => ({
        ...prevData,
        total: Total,
        amountinpayment: Total,
      }));
      setData((prevData) => ({
        ...prevData,
        excessamount: (prevData.amountreceived - prevData.total).toFixed(2),
      }));
    }
  };

  const handleSave = () => {
    if (
      data.invoicetype == "" ||
      data.commonname == "" ||
      data.commonid == "" ||
      data.paymentmode == "" ||
      data.depositeto == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    dispatch(addSalePaymentData(data, navigate));
  };

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/sales/salePayment")}
            />
            Add <span className="form-title main"> Payments</span>
          </h1>
          <hr />
          <Container fluid>
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Invoice Type <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="invoicetype"
                    value={data.invoicetype}
                    onChange={handleChange}
                    isInvalid={data.invoicetype === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    <option value="Customer">Customer</option>
                    <option value="Other Company">Other Company</option>
                    <option value="Sub-Dealer">Sub-Dealer</option>
                    <option value="Franchise">Franchise</option>
                    <option value="Return To Vendor">Return To Vendor</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Choose Invoice Type
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    {data.invoicetype == "Customer" && "Customer Name"}{" "}
                    {data.invoicetype == "Other Company" && "Company Name"}
                    {data.invoicetype == "Sub-Dealer" && "Sub-Dealer Name"}
                    {data.invoicetype == "Franchise" && "Franchise Name"}
                    {data.invoicetype == "Return To Vendor" && "Vendor Name"}
                    {data.invoicetype == "" && "Name"}
                    <span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    name="commonname"
                    onChange={handleChange}
                    isInvalid={data.commonname === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {data.invoicetype == "Customer" &&
                      customersData.length > 0 &&
                      customersData.map((item, index) =>
                        item.customertype == "Retail" ? (
                          <option
                            key={index}
                            value={`${item.fullname}|${item.customerid}`}
                          >
                            {`${item.fullname}|${item.customerid}`}
                          </option>
                        ) : (
                          <option
                            key={index}
                            value={`${item.bdcompany}|${item.customerid}`}
                          >
                            {`${item.bdcompany}|${item.customerid}`}
                          </option>
                        )
                      )}
                    {data.invoicetype == "Other Company" &&
                      companysData.length > 0 &&
                      companysData.map(
                        (item, index) =>
                          item.type == "Other Company" && (
                            <option
                              key={index}
                              value={`${item.companyname}|OC-${item.id}`}
                            >
                              {`${item.companyname}|OC-${item.id}`}
                            </option>
                          )
                      )}
                    {data.invoicetype == "Sub-Dealer" &&
                      companysData.length > 0 &&
                      companysData.map(
                        (item, index) =>
                          item.type == "Sub-Dealer" && (
                            <option
                              key={index}
                              value={`${item.companyname}|SD-${item.id}`}
                            >
                              {`${item.companyname}|SD-${item.id}`}
                            </option>
                          )
                      )}
                    {data.invoicetype == "Franchise" &&
                      companysData.length > 0 &&
                      companysData.map(
                        (item, index) =>
                          item.type == "Franchise" && (
                            <option
                              key={index}
                              value={`${item.companyname}|FC-${item.id}`}
                            >
                              {`${item.companyname}|FC-${item.id}`}
                            </option>
                          )
                      )}
                    {data.invoicetype == "Return To Vendor" &&
                      vendorsData.length > 0 &&
                      vendorsData.map((item, index) => (
                        <option
                          key={index}
                          value={`${item.fullname}|VD-${item.id}`}
                        >
                          {`${item.fullname}|VD-${item.id}`}
                        </option>
                      ))}
                    {data.invoicetype == "" && (
                      <option value="">First Select Invoice Type</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Invoice Type
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                {" "}
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Payment No</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="paymentno"
                    value={data.paymentno}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    disabled
                    type="date"
                    name="paymentdate"
                    value={data.paymentdate}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row lg={4} md={2} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Payment Mode <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="paymentmode"
                    value={data.paymentmode}
                    onChange={handleChange}
                    isInvalid={data.paymentmode === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    <option value="Cash">Cash</option>
                    <option value="Net Banking">Net Banking</option>
                    <option value="UPI">UPI</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="CC Account">CC Account</option>
                    <option value="Cheque">Cheque</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Payment Mode
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Deposit To <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="depositeto"
                    value={data.depositeto}
                    onChange={handleChange}
                    isInvalid={data.depositeto === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {data.paymentmode == "Cash" && (
                      <option value="Company Cash Account">
                        Company Cash Account
                      </option>
                    )}{" "}
                    {data.paymentmode != "Cash" &&
                      data.paymentmode != "" &&
                      banksData.length > 0 &&
                      banksData.map((item, index) => (
                        <option key={index} value={item.bank}>
                          {item.bank}
                        </option>
                      ))}
                    {data.paymentmode == "" && (
                      <option value="">First Select Payment Mode</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Deposit To
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Created By</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="createdby"
                    value={data.createdby}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Reference No</Form.Label>
                  <Form.Control
                    type="text"
                    name="referenceno"
                    value={data.referenceno}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row lg={2} md={1} sm={1} xs={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Amount Received</Form.Label>
                  <Form.Control
                    type="number"
                    name="amountreceived"
                    value={data.amountreceived}
                    onChange={handlePaymentAmountChange}
                  />
                  <Form.Text>
                    <Form.Label>
                      Credits Available:{credits} &nbsp; &nbsp; &nbsp;
                      <span
                        className={
                          credits > 0
                            ? "text-primary text-decoration-underline"
                            : "d-none"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={handleApplyCredit}
                      >
                        Apply Credits
                      </span>
                    </Form.Label>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Row md={2} sm={1} xs={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicID">
                      <Form.Label>Upload File</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Select File"
                        name="documents"
                        value={data.documents}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Table className="table-main-container" responsive>
              <TableHeading
                data={[
                  "SL No",
                  "Invoice Date",
                  "Invoice No",
                  "Sale Order No",
                  "Invoice Amount",
                  "Amount Due",
                  "Payment Amount",
                ]}
              />
              <tbody>
                {data.billarray.length > 0 &&
                  data.billarray.map((ele, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="mb-2">{ele.invoicedate}</div>
                        <div className="text-muted">
                          Due Date:-&nbsp;
                          {ele.paymentduedate}
                        </div>
                      </td>
                      <td>{ele.invoiceno}</td>
                      <td>{ele.saleorderno}</td>
                      <td>{ele.grandtotal}</td>
                      <td>{ele.balancedue}</td>
                      <td>
                        <Form.Control
                          disabled={
                            data.amountreceived >= parseInt(totalbillamount)
                          }
                          type="number"
                          name="paymentamount"
                          className="text-center"
                          value={ele.paymentamount}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Row className="d-flex justify-content-end align-items-center mb-3">
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Total : </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      value={data.total}
                      name="total"
                      min={"0"}
                      className="text-center"
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end align-items-center mb-3">
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Amount Received : </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      value={data.amountreceived}
                      name="amountreceived"
                      min={"0"}
                      className="text-center"
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end align-items-center mb-3">
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Amount used for payment: </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      value={data.amountinpayment}
                      name="amountinpayment"
                      min={"0"}
                      disabled
                      className="text-center"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-end align-items-center mb-3">
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Amount in excess: </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      value={data.excessamount}
                      name="excessamount"
                      min={"0"}
                      className="text-center"
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Form>
        <Row>
          <Col md={6}>
            <Button
              disabled={data.billarray.length <= 0}
              className="commonBtn smallBtn"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn mx-3"
              onClick={() => navigate("/sales/salePayment")}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AddSalePayment;
