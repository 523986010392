
import { FETCH_STOCKLOCATION_DATA } from "../actionTypes/stocklocation.actionTypes";

  
  const initialState = {

    stockData: {},

  };
  
  //
  export const stockReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_STOCKLOCATION_DATA: {
        return { ...state, stockData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };