import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { notifyError } from "../../../constants/toastAlert";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { getPurchaseBillById } from "../../../constants/api";
import { getStatusColorClass } from "../../../constants/colorClass";
import { updatePurchaseBills } from "../../../redux/actions/purchaseBills.actions";
import { aggregateAmounts } from "../../../constants/resData";
import PurchaseBillPrint from "./PurchaseBillPrint";
import { billInfoData } from "../../../redux/actions/auth.actions";

const ViewPurchaseBills = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const URL = extractAndFormatURLPart("purchase");
  let componentRef = null;
  const { org: organization} = useSelector((state) => state.authManager.userData.data[0]) || {};
  const { hoaddress, gstno, pan } =
    useSelector((state) => state.authManager.userData.companyaddress) || {};
  const { billinfo } = useSelector((state) => state.authManager);

  const [data, setData] = useState({
    invoiceno: "",
    grn: "",
    purchasebillno: "",
    purchasedate: "",
    billdate: "",
    paymentduedate: "",
    paymentterms: "",
    vehicletype: "",
    vehicleno: "",
    purchaseorder: "",
    itemarray: [],
    documents: "",
    subtotal: 0,
    others: "",
    taxableamount: 0,
    ewaybill: "",
    cgst: 0,
    igst: 0,
    sgst: 0,
    discount: 0,
    discountamount: 0,
    branch: "",
    branchid: "",
    company: "",
    companyid: "",
    userid: "",
    month: "",
    financialyear: "",
    tcs: "",
    tds: "",
    tsamount: 0,
    fadvance: 0,
    adjustment: 0,
    adjustmentamont: 0,
    grandtotal: 0,
    vendoradvance: 0,

    finalamount: 0,
    uploads: [],
    org: "₹ΘθϖΠ",
    deliveryaddress: "",
    vendor: "",
    status: "",
    notes: "",
    taxtype: "",
    vendorgst: "",
    locationgst: "",
    address: "",
    id: id,
  });

  const fetchData = async () => {
    try {
      const res = await axios.post(getPurchaseBillById, { id });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };
  const handleMarkApprove = () => {
    dispatch(updatePurchaseBills({ ...data, status: "Unpaid" }, navigate));
  };

  useEffect(() => {
    fetchData();
    dispatch(billInfoData({ org:organization }));
  }, []);

  const {
    address,
    adjustment,
    adjustmentamont,
    billdate,
    branch,
    branchid,
    cgst,
    company,
    companyid,
    deliveryaddress,
    discount,
    discountamount,
    ewaybill,
    fadvance,
    finalamount,
    financialyear,
    grandtotal,
    grn,
    igst,
    invoiceno,
    itemarray,
    locationgst,
    month,
    notes,
    org,
    others,
    paymentduedate,
    paymentterms,
    purchasebillno,
    purchasedate,
    purchaseorder,
    sgst,
    status,
    subtotal,
    taxableamount,
    taxtype,
    account,
    tcs,
    tds,
    tsamount,
    uploads,
    userid,
    vehicleno,
    vehicletype,
    vendor,
    vendoradvance,
    vendorgst,
    dueamount,
  } = data || {};

  const printStyles = `
  @page {
    size: auto;
    margin: 0;
  }
  body {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .print-content {
    width: 100% !important;
  }
  @media print {
    .page-break {
      page-break-before: always;
    }
  }
`;

  const colorClass = getStatusColorClass(status);
  const iscgst =
    vendorgst[0] == locationgst[0] && vendorgst[1] == locationgst[1];
  // const journalArr = aggregateAmounts(itemarray);
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12}>
            <Row>
              <Col>
                <h1>
                  <FaArrowLeftLong
                    className="left-arrow"
                    onClick={() => navigate("/purchase/purchaseBills")}
                  />
                  Purchase <span className="form-title">Bill</span>
                </h1>
              </Col>
              <Col>
                <div className="d-flex align-items-center justify-content-around">
                  <div className="d-flex justify-content-center align-items-center">
                    <h2>Status: </h2>
                    &nbsp;
                    <h2 className={`${colorClass}`}>{status}</h2>
                  </div>
                  <div className="d-flex gap-2">
                    {status == "Draft" ? (
                      <Button
                        className="commonBtn p-3"
                        onClick={handleMarkApprove}
                      >
                        Mark Approve
                      </Button>
                    ) : null}
                    {status == "Draft" ? null : (
                      <ReactToPrint
                        trigger={() => (
                          <Button className="commonBtn smallBtn cancelBtn px-5">
                            Print
                          </Button>
                        )}
                        content={() => componentRef}
                        pageStyle={printStyles}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Col>
              <Container className="print-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="company-information">
                    <h2>Bill To</h2>
                    <h4>{company ? company : "--"}</h4>
                    <h4> {deliveryaddress ? deliveryaddress : "--"}</h4>
                  </div>
                  <div>
                    <h2>Bill From</h2>
                    <h4>{vendor ? vendor : "--"}</h4>
                    <h4>{address ? address : "--"}</h4>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4>
                      Purchase Bill No:-{" "}
                      {purchasebillno ? purchasebillno : "--"}
                    </h4>
                    <h4>
                      {" "}
                      Purchase Order:- {purchaseorder ? purchaseorder : "--"}
                    </h4>
                    <h4>GRN No:- {grn ? grn : "--"}</h4>
                    <h4> Invoice No :- {invoiceno ? invoiceno : "--"}</h4>
                  </div>
                  <div>
                    <h4>Account:- {account}</h4>
                    <h4>Bill Date:- {billdate ? billdate : "--"}</h4>
                    <h4>
                      Purchase Date:- {purchasedate ? purchasedate : "--"}
                    </h4>
                    <h4>Due Date:- {paymentduedate ? paymentduedate : "--"}</h4>
                  </div>
                  <div>
                    <h4>
                      Payment Terms:- {paymentterms ? paymentterms : "--"}
                    </h4>
                    <h4>Vehicle Type:- {vehicletype ? vehicletype : "--"}</h4>
                    <h4>Vehicle No:- {vehicleno ? vehicleno : "--"}</h4>
                    <h4>E-Way Bill:- {ewaybill ? ewaybill : "--"}</h4>
                  </div>
                </div>
                <hr />
                <Table
                  responsive
                  className=" fs-4 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <thead>
                    <tr>
                      <th className="th-color">Category</th>
                      <th className="th-color">Brand</th>
                      <th className="th-color">Item</th>
                      <th className="th-color">Unit</th>
                      <th className="th-color">QTY</th>
                      <th className="th-color">Rate</th>
                      <th className="th-color">GST%</th>
                      <th className="th-color">Discount%</th>
                      <th className="th-color">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemarray.length > 0 ? (
                      itemarray.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>{item.category}</td>
                            <td>{item.brand}</td>
                            <td>
                              {item.itemid}-{item.itemname}
                            </td>
                            <td>{item.unit}</td>
                            <td>{item.qty}</td>
                            <td>{item.rate}</td>
                            <td>{item.gst}</td>
                            <td>{item.discount}</td>
                            <td>{item.discountamount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9">
                          <div className="datanotfound-center-text">
                            No Item Found 🤔
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <br />
                <div className="d-flex flex-column gap-2 justify-content-end">
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>Sub Total:</h4>
                    <h4 className="font-bold">{subtotal}</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>Total Discount:</h4>
                    <h4 className="font-bold">{discountamount}</h4>
                  </div>
                  {iscgst ? (
                    <div className="d-flex align-items-center justify-content-end gap-2">
                      <h4>CGST:</h4>
                      <h4 className="font-bold">{cgst}</h4>
                    </div>
                  ) : null}
                  {iscgst ? (
                    <div className="d-flex align-items-center justify-content-end gap-2">
                      <h4>SGST:</h4>
                      <h4 className="font-bold">{sgst}</h4>
                    </div>
                  ) : null}
                  {!iscgst ? (
                    <div className="d-flex align-items-center justify-content-end gap-2">
                      <h4>IGST:</h4>
                      <h4 className="font-bold">{igst}</h4>
                    </div>
                  ) : null}
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>{taxtype} : </h4>
                    <h4 className="font-bold">{tsamount}</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>Freight Advance : </h4>
                    <h4 className="font-bold">{fadvance}</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>Adjustment : </h4>
                    <h4 className="font-bold">{adjustmentamont}</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>Grand Total : </h4>
                    <h4 className="font-bold">{grandtotal}</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>Vendor Advance : </h4>
                    <h4 className="font-bold">{vendoradvance}</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <h4>Final amount : </h4>
                    <h4 className="font-bold">{finalamount}</h4>
                  </div>
                </div>
              </Container>
            </Col>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h3>Journal</h3>
          </Col>
          <hr />
          <Col>
            <h1>Bills</h1>
          </Col>

          <Col md={12} className="table-main-container">
            <Table responsive>
              <thead
                className="tableContainer"
                style={{ border: "0.5px solid gray" }}
              >
                <tr>
                  <th>Account</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody className="tableContainer">
                <tr>
                  <td>Accounts Payable</td>
                  <td>0.00</td>
                  <td>{finalamount}</td>
                </tr>
                <tr>
                  <td>{account}</td>
                  <td>{subtotal}</td>
                  <td>0.00</td>
                </tr>

                <tr>
                  <td>GST Receivable</td>
                  <td>{igst}</td>
                  <td>0.00</td>
                </tr>
                <tr>
                  <td>{taxtype == "TDS" ? "TDS Payable" : "TCS Receivable"}</td>
                  <td>{taxtype == "TDS" ? 0.0 : Math.abs(tsamount)}</td>
                  <td>{taxtype == "TDS" ? Math.abs(tsamount) : 0.0}</td>
                </tr>
                <tr>
                  <td>Vendor Advance</td>
                  <td>0.00</td>
                  <td>{vendoradvance}</td>
                </tr>
                <tr>
                  <td> Freight Advance</td>
                  <td>{fadvance}</td>
                  <td>0.00</td>
                </tr>
                <tr style={{ borderBottom: "0.5px solid gray" }}>
                  <td>Adjustment</td>
                  <td>{adjustmentamont}</td>
                  <td>0.00</td>
                </tr>
                <tr></tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className="d-none">
          <PurchaseBillPrint ref={(el) => (componentRef = el)} data={data} billinfo={billinfo} />
        </div>
      </Container>
    </>
  );
};

export default ViewPurchaseBills;
