import axios from "axios";
import { notifyError, notifySuccess } from "../../constants/toastAlert";
import { addTdsApi, deleteTdsApi, getTdsApi, updateTdsApi } from "../../constants/api";
import { ADD_TDS_DATA, FETCH_TDS_DATA, UPDATE_TDS_DATA } from "../actionTypes/taxes.actiontypes";
import { RELOAD } from "../actionTypes/load.actiontypes";

  
  //Get all tds data using pagination wise
  export const tdsData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(getTdsApi, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_TDS_DATA, payload: initialData });
        
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching tds data ${error}`);
      notifyError(error.message);
    }
  };
  
  //Delete tds data dispatcher
  export const deleteTdsData =
    (backendData, handleClose) => async (dispatch) => {
      try {
        const res = await axios.post(deleteTdsApi, backendData);
        if (res.data.message == "TDS deleted successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyError(res.data.message);
        }
        handleClose();
        dispatch({ type: RELOAD, payload: true });
      } catch (error) {
        console.log(`Error in deleting tds data ${error}`);
        notifyError(error.message);
      }
    };
  
  
  //Acion dispatcher for adding new tds data
  export const addTds = (tdsdata, navigate) => async (dispatch) => {
    try {
      // dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(addTdsApi, tdsdata);
  
      if (res.data.message === "TDS added successfully") {
        dispatch({ type: ADD_TDS_DATA });
        notifySuccess(res.data.message);
        navigate("/taxes/tdsTcs");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in adding tds data ${error}`);
      notifyError(error.response.data.message);
    }
  };
  
  //Acion dispatcher for updating new company data
  export const updateTds = (tdsdata, navigate) => async (dispatch) => {
    try {
      //     dispatch({ type: RELOAD, payload: true });
      const res = await axios.post(updateTdsApi, tdsdata);
  
      if (res.data.message === "TDS updated successfully") {
        dispatch({ type: UPDATE_TDS_DATA });
        notifySuccess(res.data.message);
        navigate("/taxes/tdsTcs");
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE, payload: error.message });
      console.log(`Error in updating tds data ${error}`);
      notifyError(error.response.data.message);
    }
  };