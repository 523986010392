import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ViewAccountLedger = ({ item }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const {
    ledgerid,
    ledgername,
    ledgertype,
    istds,
    isinventory,
    accountid,
    accountname,
    accounttype,
    openingbalance,
    debitcredit,
    asondate,
  } = item || [];

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title">Ledger Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <p>
                  <span className="viewpage-title">Ledger ID:</span>
                  <span className="viewpage-value">{ledgerid}</span>
                </p>
                <p>
                  <span className="viewpage-title">Ledger Type:</span>
                  <span className="viewpage-value">{ledgertype}</span>
                </p>
                <p>
                  <span className="viewpage-title">TDS Applicable:</span>
                  <span className="viewpage-value">{istds}</span>
                </p>
              </Col>
              <Col>
                <p>
                  <span className="viewpage-title">Ledger Name:</span>
                  <span className="viewpage-value">{ledgername}</span>
                </p>
                <p>
                  <span className="viewpage-title">Account:</span>
                  <span className="viewpage-value">{accountname}</span>
                </p>
                <p>
                  <span className="viewpage-title">
                    Inventory values are affected:
                  </span>
                  <span className="viewpage-value">{isinventory}</span>
                </p>
              </Col>
            </Row>
            <Row className="ledger-line">
              <Col>
                <p>
                  <span className="viewpage-title">Opening Balance:</span>
                  <span className="viewpage-value">
                    {debitcredit == "Debit"
                      ? `${openingbalance} Dr`
                      : `${openingbalance} Cr`}
                  </span>
                </p>
              </Col>
              <Col>
                <p>
                  <span className="viewpage-title">From Date:</span>
                  <span className="viewpage-value">
                    {asondate ? formatDateToDDMMYYYY(asondate) : []}
                  </span>
                </p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewAccountLedger;
