import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import ListTable from './ListTable';

function InventoryMovement() {
    const [extractedPart, setExtractedPart] = useState("");
    useEffect(() => {
      document.title = "Venturesathi Enterprises App Purchase Report";
      const currentURL = window.location.href; // Get the current URL
      const urlParts = currentURL.split("/"); // Split the URL by "/"
      const paymentIndex = urlParts.indexOf("purchase-report"); // Find the index of "payment"
      if (paymentIndex !== -1 && paymentIndex < urlParts.length - 1) {
        // Check if "adminPanel" is in the URL and if there's a part after it
        const desiredPart = urlParts.slice(paymentIndex).join("/"); // Get the part after "payment"
        const formattedPart = desiredPart
          .split("/")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" / ");
        const spacedpart = formattedPart.replace(/([a-z])([A-Z])/g, "$1 $2");
        setExtractedPart(spacedpart); // Set the extracted part in the component's state
      } else {
        console.log("The URL does not contain 'adminPanel'");
      }
    }, []);
  
    return (
      <Container fluid>
      <Row>
        <Col className="colStyle">
          {" "}
          <span>{extractedPart}</span>{" "}
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTable />
        </Col>
      </Row>
    </Container>
   
    )
  }
  

export default InventoryMovement