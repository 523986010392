import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Country, State, City } from "country-state-city";
import { fileUpload } from '../../../constants/fileUpload';
import axios from 'axios';
import { billInfoGetPost, billInfoLogoPost, billInfoQrcodePost, billInfoUpdatePost } from '../../../constants/api';
import { notifySuccess } from '../../../constants/toastAlert';
import vs_logo from "../../../assets/venturesathi-logo.png";
import { billInfoData } from '../../../redux/actions/auth.actions';
import { validateBankAccount } from '../../../constants/validationOnBlur';

const states = State.getStatesOfCountry("IN");

function ViewProfile({ show, handleViewProfileClose }) {
    //redux
    const dispatch = useDispatch();
    const {
        id,
        userid,
        password,
        name,
        email,
        contactno,
        designationname,
        designationid,
        departmentname,
        departmentid,
        branch,
        branchid,
        warehousename,
        warehouseid,
        showroomname,
        showroomid,
        isshowroom,
        companyname,
        companyid,
        status,
        module,
        org,
        submodule,
        type,
        lastlogindate,
        lastlogintime,
        currentlogintime,
        currentlogindate,
        crud,
        ip
    } = useSelector((state) => state.authManager.userData.data[0]) || {};

    const { billinfo } = useSelector((state) => state.authManager);

    //State
    const [editBrandDetails, setEditBrandDetails] = useState(false);
    const [data, setData] = useState({})
    const [showEmptyError, setShowEmptyError] = useState(false);

    useEffect(() => {
        dispatch(billInfoData({ org }));
    }, []);

    useEffect(() => {
        setData(billinfo);
    }, [billinfo]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileupload = (e) => {
        const file = e.target.files[0];
        fileUpload(file, billInfoLogoPost)
            .then((res) => {
                setData((prevdata) => ({
                    ...prevdata,
                    brandlogo: res
                }));
            })
            .catch((error) => {
                console.log("Error while upload file", error)
            })
    }

    const handleQrcodeupload = (e) => {
        const file = e.target.files[0];
        fileUpload(file, billInfoQrcodePost)
            .then((res) => {
                setData((prevdata) => ({
                    ...prevdata,
                    qrcode: res
                }));
            })
            .catch((error) => {
                console.log("Error while upload qrcode file", error)
            })
    }

    const handleSubmit = async () => {
        console.log("hello submit button clicked")
        try {
            const res = await axios.post(billInfoUpdatePost, data);
            console.log("res", res);
            if (res.data.message == "Bill Details updated successfully") {
                notifySuccess(res.data.message)
                setEditBrandDetails(false);
            } else {
                notifySuccess(res.data.message)
            }
        }
        catch (error) {
            console.log(`Error while submitting brand details, ${error}`);
        }
    }

    const handleBrandClick = () => {
        
        if (editBrandDetails) {
            if (!data.brandname || !data.address || !data.gstno || !data.pan || !data.state || !data.statecode || !data.email || !data.phonenumber || !data.websitename || !data.bank || !data.accountholdername || !data.accountnumber || !data.ifsccode || !data.branch) {
                // If required fields are empty, show an error
                console.log("submit btn click")
                setShowEmptyError(true);
                return;
            } else{
                handleSubmit();
            }



        }
        else {
            setEditBrandDetails(true)
        }
    }

    console.log("data", data)
    return (
        <>
            <Modal show={show} size="xl" onHide={handleViewProfileClose} backdrop="static" keyboard={false} centered={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        View <span className="form-title">Profile</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <p>
                                    <span className="viewpage-title">User ID:</span>{" "}
                                    <span className="viewpage-value">{!userid ? "--" : userid}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">User Name:</span>{" "}
                                    <span className="viewpage-value">
                                        {!name ? "--" : name}
                                    </span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Contact No:</span>{" "}
                                    <span className="viewpage-value">
                                        {!contactno ? "--" : contactno}
                                    </span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Company:</span>{" "}
                                    <span className="viewpage-value">{companyname}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Designation:</span>{" "}
                                    <span className="viewpage-value">
                                        {!designationname ? "--" : designationname}
                                    </span>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <span className="viewpage-title">Department:</span>{" "}
                                    <span className="viewpage-value">{!departmentname ? "--" : departmentname}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Company:</span>{" "}
                                    <span className="viewpage-value">
                                        {!companyname ? "--" : companyname}
                                    </span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Branch:</span>{" "}
                                    <span className="viewpage-value">
                                        {!branch ? "--" : branch}
                                    </span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Warehouse:</span>{" "}
                                    <span className="viewpage-value">
                                        {!warehousename ? "--" : warehousename}
                                    </span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Showroom:</span>{" "}
                                    <span className="viewpage-value">
                                        {!showroomname ? "--" : showroomname}
                                    </span>
                                </p>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={12}>
                                <h3>Brand  <span className="form-title">Details</span></h3>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            {!editBrandDetails && <Col >
                                <p>
                                    <span className="viewpage-title">Brand Name:</span>{" "}
                                    <span className="viewpage-value">{data.brandname ? data.brandname : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Email:</span>{" "}
                                    <span className="viewpage-value">{data.email ? data.email : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">GST No.:</span>{" "}
                                    <span className="viewpage-value">{data.gstno ? data.gstno : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Pan:</span>
                                    <span className="viewpage-value">{data.pan ? data.pan : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">State:</span>{" "}
                                    <span className="viewpage-value">{data.state ? data.state : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">State Code:</span>{" "}
                                    <span className="viewpage-value">{data.statecode ? data.statecode : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Bank name:</span>
                                    <span className="viewpage-value">{data.bank ? data.bank : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Account no.:</span>
                                    <span className="viewpage-value">{data.accountnumber ? data.accountnumber : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Branch:</span>
                                    <span className="viewpage-value">{data.branch ? data.branch : "--"}</span>
                                </p>

                            </Col>}
                            {!editBrandDetails && <Col>
                                <p>
                                    <span className="viewpage-title">Phone no.:</span>{" "}
                                    <span className="viewpage-value">{data.phonenumber ? data.phonenumber : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Website name:</span>{" "}
                                    <span className="viewpage-value">{data.websitename ? data.websitename : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">Mobile App name:</span>{" "}
                                    <span className="viewpage-value">{data.mobileappname ? data.mobileappname : "--"}</span>
                                </p>
                                <p className='d-flex'>
                                    <span className="viewpage-title">Address:</span>{" "}
                                    <span className="viewpage-value w-50">{data.address ? data.address : "--"}</span>
                                </p>
                                <p className='d-flex'>
                                    <span className="viewpage-title">Brand Logo:</span>{" "}
                                    <img
                                        src={data.brandlogo || vs_logo}
                                        alt="selected image"
                                        style={{
                                            marginTop: "1rem",
                                            maxWidth: '30%',
                                            maxHeight: '100px',
                                            borderRadius: '5px',
                                            objectFit: "contain",
                                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                                        }}
                                    />
                                </p>
                                <p>
                                    <span className="viewpage-title">Account holder name:</span>
                                    <span className="viewpage-value">{data.accountholdername ? data.accountholdername : "--"}</span>
                                </p>
                                <p>
                                    <span className="viewpage-title">IFSC Code:</span>
                                    <span className="viewpage-value">{data.ifsccode ? data.ifsccode : "--"}</span>
                                </p>

                                <p className='d-flex'>
                                    <span className="viewpage-title">Qrcode:</span>{" "}
                                    <img
                                        src={data.qrcode}
                                        alt="selected image"
                                        style={{
                                            marginTop: "1rem",
                                            maxWidth: '30%',
                                            maxHeight: '100px',
                                            borderRadius: '5px',
                                            objectFit: "contain",
                                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                                        }}
                                    />
                                </p>
                            </Col>}
                            {editBrandDetails &&
                                <Col>
                                    <Form className="form-lable-name">
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="brandname">
                                                    <Form.Label>
                                                        Branch Name<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Brand Name"
                                                        name="brandname"
                                                        value={data.brandname}
                                                        onChange={handleChange}
                                                        isInvalid={data.brandname === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Brand Name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="address">
                                                    <Form.Label>
                                                        Address<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Address"
                                                        name="address"
                                                        value={data.address}
                                                        onChange={handleChange}
                                                        isInvalid={data.address === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Address
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="gstno">
                                                    <Form.Label>
                                                        GST No.<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter GST No."
                                                        name="gstno"
                                                        value={data.gstno}
                                                        onChange={handleChange}
                                                        isInvalid={data.gstno === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter GST No.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="pan">
                                                    <Form.Label>
                                                        Pan<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Pan Number"
                                                        name="pan"
                                                        value={data.pan}
                                                        onChange={handleChange}
                                                        isInvalid={data.pan === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Pan name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="state">
                                                    <Form.Label>
                                                        State <span className="star">*</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        name="state"
                                                        value={data.state}
                                                        onChange={handleChange}
                                                        isInvalid={data.state == "" && showEmptyError}
                                                    >
                                                        <option value="">Select</option>
                                                        {states.map((state) => {
                                                            return (
                                                                <option value={state.name} key={state.isoCode}>
                                                                    {state.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a valid state.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="statecode">
                                                    <Form.Label>
                                                        State Code<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter State Code "
                                                        name="statecode"
                                                        value={data.statecode}
                                                        onChange={handleChange}
                                                        isInvalid={data.statecode === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter State Code
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>
                                                        Email<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Enter email "
                                                        name="email"
                                                        value={data.email}
                                                        onChange={handleChange}
                                                        isInvalid={data.email === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Email address
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="phonenumber">
                                                    <Form.Label>
                                                        Phone number<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter Phone number "
                                                        name="phonenumber"
                                                        value={data.phonenumber}
                                                        onChange={handleChange}
                                                        isInvalid={data.phonenumber === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Phone number
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="websitename">
                                                    <Form.Label>
                                                        Website name<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Website name "
                                                        name="websitename"
                                                        value={data.websitename}
                                                        onChange={handleChange}
                                                        isInvalid={data.websitename === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Website name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="mobileappname">
                                                    <Form.Label>
                                                        Mobile App name
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter mobile app name "
                                                        name="mobileappname"
                                                        value={data.mobileappname}
                                                        onChange={handleChange}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Mobile app name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="bankname">
                                                    <Form.Label>
                                                        Bank Name<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Bank Name"
                                                        name="bank"
                                                        value={data.bank}
                                                        onChange={handleChange}
                                                        isInvalid={data.bank === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Bank Name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="accountholdername">
                                                    <Form.Label>
                                                        Account holder name <span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Account holder Name"
                                                        name="accountholdername"
                                                        value={data.accountholdername}
                                                        onChange={handleChange}
                                                        isInvalid={data.accountholdername === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Account holder name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="accountnumber">
                                                    <Form.Label>
                                                        Account number <span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter Account number"
                                                        name="accountnumber"
                                                        value={data.accountnumber}
                                                        onChange={handleChange}
                                                        
                                                        isInvalid={data.accountnumber === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Account number
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="ifsccode">
                                                    <Form.Label>
                                                        IFSC Code <span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter IFSC code"
                                                        name="ifsccode"
                                                        value={data.ifsccode}
                                                        onChange={handleChange}
                                                        isInvalid={data.ifsccode === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter IFSC code
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <Form.Group className="mb-3" controlId="branch">
                                                    <Form.Label>
                                                        Branch <span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Branch Name"
                                                        name="branch"
                                                        value={data.branch}
                                                        onChange={handleChange}
                                                        isInvalid={data.branch === "" && showEmptyError}
                                                        // disabled
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Enter Branch name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12} className='d-flex justify-content-between'>
                                                <Form.Group className="mb-3" controlId="brandlogo">
                                                    <Form.Label>Upload Brand Logo</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        accept="image/*"
                                                        placeholder="Select File"
                                                        onChange={handleFileupload}
                                                        isInvalid={data.brandlogo === "" && showEmptyError}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Upload Your logo
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                {data.brandlogo && (
                                                    <img
                                                        src={data.brandlogo || vs_logo}
                                                        alt="selected image"
                                                        style={{
                                                            maxWidth: '30%',
                                                            maxHeight: '100px',
                                                            borderRadius: '5px',
                                                            objectFit: "contain",
                                                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                                                        }} />
                                                )}
                                            </Col>
                                            <Col lg={6} sm={12} className='d-flex justify-content-between'>
                                                <Form.Group className="mb-3" controlId="qrcode">
                                                    <Form.Label>Upload Qrcode</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        accept="image/*"
                                                        placeholder="Select File"
                                                        onChange={handleQrcodeupload}
                                                        isInvalid={data.qrcode === "" && showEmptyError}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Upload Your Qrcode
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                {data.qrcode && (
                                                    <img
                                                        src={data.qrcode}
                                                        alt="selected image"
                                                        style={{
                                                            maxWidth: '30%',
                                                            maxHeight: '100px',
                                                            borderRadius: '5px',
                                                            objectFit: "contain",
                                                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                                                        }} />
                                                )}
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-end gap-3'>
                                <>
                                    <Button type={editBrandDetails ? "submit" : "button"} className="commonBtn smallBtn" onClick={handleBrandClick}>{editBrandDetails ? "Update" : "Edit"}</Button>
                                    {editBrandDetails && <Button className="commonBtn smallBtn cancelBtn" onClick={() => { setEditBrandDetails(false) }}>Cancel</Button>}
                                </>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewProfile