import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError } from "../../../constants/toastAlert";
import { updateTds } from "../../../redux/actions/tds.actions";
import { getTdsByIdApi, tdsIdAutocountApi } from "../../../constants/api";
import axios from "axios";

const EditTds = () => {
    const { id } = useParams();
  const URL = extractAndFormatURLPart("taxes");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const [showEmptyError, setShowEmptyError] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState({
    tdsno: "",
    section: "",
    ratepercent: "",
    taxname: "",
    applicabledate: "",
    org: "₹ΘθϖΠ",
    companyid: companyid,
    userid: userid,
    id:id
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {

    if(!data.applicabledate || !data.ratepercent || !data.section || !data.taxname || !data.tdsno){
        setShowEmptyError(true);
        notifyError("You have empty fields");
        return;
    }

    dispatch(updateTds(data,navigate));

  };

  const fetchData = async () => {
    try {
      const res = await axios.post(getTdsByIdApi,{id});
      if(res.data.length>0){
        setData((prev)=>({
          ...prev,
          ...res.data[0]
        }))
      }else{
        setData((prev)=>({...prev}))
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };


  useEffect(()=>{
    fetchData()
  },[])

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/taxes/tdsTcs")}
            />
            Edit <span className="form-title main"> TDS </span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            TDS No.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            name="tdsno"
                            value={data.tdsno}
                            //   onChange={handleChange}
                            isInvalid={!data.tdsno && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Rate Percent<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="ratepercent"
                            value={data.ratepercent}
                            onChange={handleChange}
                            isInvalid={!data.ratepercent && showEmptyError}
                            min={"0"}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Section<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="section"
                        value={data.section}
                        onChange={handleChange}
                        isInvalid={!data.section && showEmptyError}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Tax Name<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="taxname"
                            value={data.taxname}
                            onChange={handleChange}
                            isInvalid={!data.taxname && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                      <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Applicable Date<span className="star"> *</span>
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="applicabledate"
                              value={data.applicabledate}
                              onChange={handleChange}
                              isInvalid={!data.applicabledate && showEmptyError}
                            />
                           
                          </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col></Col>
                </Row>
              </Container>
            </div>
          </div>
        </Form>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/taxes/tdsTcs/")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default EditTds;
