import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";

const ViewPurchasePayment = ({ item }) => {
  const contentRef = useRef(null);
  const { companyname, branch, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { hoaddress, gstno, emailid } =
    useSelector((state) => state.authManager.userData.companyaddress) || {};
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const {
    vendor,
    vendorid,
    vendordetails,
    paymentno,
    paymentdate,
    paymentmode,
    paidthrough,
    createdby,
    referenceno,
    paymentmade,
    uploads,
    billarray,
    total,
    paymentammount,
    paymenttype,
    adjustamount,
    excessamount,
    billno,
    month,
    financialyear,
    org,
  } = item || {};

  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" d-flex w-100 justify-content-between gap-10">
            <div className="form-title ">Purchase Payment</div>
            <div className="d-flex gap-3">
              <ReactToPrint
                trigger={() => (
                  <Button className="commonBtn cancelBtn px-5">Print</Button>
                )}
                content={() => contentRef.current}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={contentRef}>
          <br />
          <Container>
            <Row lg={1} md={1} sm={1}>
              <Col lg={4} md={6} sm={12}>
                <h2 className=" mb-2">From</h2>
                <p className="viewpage-title mb-2">
                  {companyname ? companyname : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  {hoaddress ? hoaddress : "--"}
                </p>
              </Col>
            </Row>
            <hr />
            <Row lg={1} className="d-flex justify-content-between gap-4">
              <Col lg={4} md={6} sm={6}>
                <p className="viewpage-title mb-2">
                  Paid To:- {vendor ? vendor : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Company:-{" "}
                  {vendordetails && vendordetails.companyname
                    ? vendordetails.companyname
                    : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Address:-{" "}
                  {vendordetails && vendordetails.billingaddress
                    ? vendordetails.billingaddress
                    : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  State:-{" "}
                  {vendordetails && vendordetails.billingstate
                    ? vendordetails.billingstate
                    : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  City:-{" "}
                  {vendordetails && vendordetails.billingcity
                    ? vendordetails.billingcity
                    : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Pincode:-{" "}
                  {vendordetails && vendordetails.billingpincode
                    ? vendordetails.billingpincode
                    : "--"}
                </p>
              </Col>
              <Col lg={4} md={6} sm={6}>
                <p className="viewpage-title mb-2">
                  Payment No:- {paymentno ? paymentno : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Payment Date:- {paymentdate ? paymentdate : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Payment Mode:- {paymentmode ? paymentmode : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Paid Through:- {paidthrough ? paidthrough : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Payment Made:- {paymentmade ? paymentmade : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Payment Amount:- {total ? total : "--"}
                </p>
              </Col>
            </Row>
            <hr />
            <Row className="d-flex justify-content-end">
              <Col lg={4} md={6} sm={12} className="viewpage-title mb-2">
                Over Payment :- {excessamount ? excessamount : 0.0}
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="viewpage-title mb-2">
                <p>Payment For</p>
              </Col>
              <Col md={12} lg={12} sm={12} className="table-main-container">
                <Table responsive>
                  <thead className="tableContainer">
                    <tr className="bg-secondary">
                      <th>SI No.</th>
                      <th>Bill Date</th>
                      <th>Purchase Bill No.</th>
                      <th>Bill Amount</th>
                      <th>Amount Due</th>
                      <th>Payment Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billarray.length > 0 ? (
                      billarray.map((bill, ind) => <tr key={ind}>
                         <td>{ind+1}</td>
                         <td>{bill.billdate}</td>
                         <td>{bill.purchasebillno}</td>
                         <td>{bill.finalamount}</td>
                         <td>{bill.dueamount}</td>
                         <td>{bill.paymentamount}</td>
                      </tr>)
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div className="datanotfound-center-text">
                            No Item Found 🤔
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewPurchasePayment;
