import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const DocumentUpload = ({data, handleChange, showEmptyError}) => {
  return (
    <Container fluid>
      <Row className="form-container">
        <Col>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>ID Proof</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select File"
                  name="idpicture"
                  value={data.idpicture}
                  onChange={handleChange}
                  required
                  accept="image/*"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Aadhar</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select File"
                  name="aadharpicture"
                  value={data.aadharpicture}
                  onChange={handleChange}
                  required
                  accept="image/*"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DocumentUpload;
