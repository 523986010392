import React, { useEffect, useState } from "react";
import SearchField from "../../constants/SearchField";
import { Col, Container, Row, Table } from "react-bootstrap";
import axios from "axios";
import DataSpinner from "../../constants/DataSpinner";
import { useSelector } from "react-redux";
import TableHeading from "../../constants/TableHeading";
import NoRecordFoud from "../../constants/NoRecordFoud";

const ListAuditLog = () => {
  const loadData = useSelector((state) => state.loadReducer);
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyid = localData.companyid;
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .post(`https://erp.venturesathi.co.in/api/v1/logaudit/get`, {
        //userid: userID,
        companyid: companyid,
      })
      .then((res) => {
        setData(res.data.length > 0 && res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const filteredData = !data
  ? []
  : data
      .filter((item) =>
        searchKeyword
          ? Object.values(item).some((value) =>
              value
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchKeyword.toLowerCase().trim())
            )
          : true
      )

  return (
    <Container fluid>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={9}>
              <p className="section-title form-section ptag">
                List of All <span className="form-title">Operational Logs</span>
              </p>
            </Col>
            <Col lg={3}>
              <SearchField
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="table-main-container">
          <Table responsive>
            <TableHeading
              data={["User ID", "Method", "Vch.", "Vch. Type", "debit", "credit", "amount", "Date", "Time", "Operation"]}
            />
            <tbody>
              {loadData ? (
                <DataSpinner count="10" />
              ) : filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.userid}</td>
                    <td>{item.method}</td>
                    <td>{item.voucher}</td>
                    <td>{item.vouchertype}</td>
                    <td>{item.debit}</td>
                    <td>{item.credit}</td>
                    <td>{item.amount}</td>
                    <td>{item.date}</td>
                    <td>{item.time}</td>
                    <td>{item.message.split(" ")}</td>
                  </tr>
                ))
              ) : (
                <NoRecordFoud count="10" name="Records" />
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ListAuditLog;
