import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { extractAndFormatURLPart } from '../../constants/breadCrumb';
import PurchaseTarget from './PurchaseTarget/PurchaseTarget';
import SalesTarget from './SalesTarget/SalesTarget';


const TargetTab = () => {
  const [currentTab, setCurrentTab] = useState("Purchase Target");
  const URL = extractAndFormatURLPart("targets");

  const handleTabSelect = (key) => {
    // Define the extracted part based on the selected tab event key
    switch (key) {
        case "salestarget":
        setCurrentTab("Sales Target");
        break;

        default:
        setCurrentTab("Purchase Target");
    }
  };
  return (
    <Container fluid>
      <Row >
        <Col className="colStyle">
          <span>{URL  + "/ " +currentTab}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="purchasetarget"
            id="justify-tab-example"
            className="mb-3"
            onSelect={handleTabSelect}
          >
            <Tab eventKey="purchasetarget" title="Purchase Target" className="tabview">
              <PurchaseTarget/>
            </Tab>
            <Tab eventKey="salestarget" title="Sales Target" className="tabview">
              <SalesTarget/>
            </Tab>

          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default TargetTab;