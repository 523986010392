import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import React, { useRef, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import sanviLogo from "../../../assets/sanvi_logo.png";

const ViewDeliveryReceipt = ({ item }) => {
  const [data, setData] = useState({
    ...item,
  });
  const { companyid, userid, companyname, branchid, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { hoaddress, gstno, pan } =
    useSelector((state) => state.authManager.userData.companyaddress) || {};

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    // setShowCustomer(false);
    setShow(false);
  };
  const contentRef = useRef(null);



  return (
    <>
      <MdOutlineRemoveRedEye
        className={item.signature ? "text-success" : "text-danger"}
        onClick={handleShow}
      />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex w-100 justify-content-between gap-4">
            <div className="form-title ">Delivery Receipt</div>
            {data.signature ? (
              <ReactToPrint
                trigger={() => (
                  <Button className=" smallBtn commonBtn cancelBtn ">
                    Print
                  </Button>
                )}
                content={() => contentRef.current}
              />
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={contentRef}>
          <br />

          <Container>
            <div className="dr-logo-heading">
              <div className="img-logo">
                <img src={sanviLogo} alt="logo" />
              </div>
              <div className="company-information">
                <h2>{companyname}</h2>
                <h4>GSTIN:- {gstno ? gstno : "--"}</h4>
                <h4>PAN:- {pan ? pan : "--"}</h4>
                <h4>Address:- {hoaddress ? hoaddress : "--"}</h4>
              </div>
              <div className="inv-info">
                <h1 style={{ fontSize: "60px" }}>E</h1>
                <h4>Invoice No:- {data.invoiceno}</h4>
                <h4>Invoice Date:- {data.invoicedate}</h4>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center bill-ship-info">
              <div>
                <h2>Bill To</h2>
                <h2>{item.commonname ? item.commonname : "--"}</h2>
                <h4>{item.btstate}</h4>
                <h4>{item.btcity}</h4>
                <h4>{item.btpin}</h4>
                <h4>{item.btaddress}</h4>
              </div>
              <div>
                <h2>Ship To</h2>
                <h4>{item.ststate}</h4>
                <h4>{item.stcity}</h4>
                <h4>{item.stpin}</h4>
                <h4>{item.staddress}</h4>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center bill-ship-info">
              <div style={{ borderRight: "none" }}>
                <h4>Company Id:- {data.commonid}</h4>
                <h4>GI No:- {data.gino}</h4>
                <h4>Payment Terms:- {data.paymentterms}</h4>
                <h4>Payment Due Date:- {data.paymentduedate}</h4>
                <h4>Issued By:- {name}</h4>
              </div>
              <div>
                <h4>Transporter:- {data.transpotername}</h4>
                <h4>Vehicle No:- {data.vehicleno}</h4>
                <h4>E-Way bill:- {data.ewaybillno}</h4>
                <h4>Remarks:- {data.remarks}</h4>
              </div>
            </div>
            <Table responsive className="table-color">
              <thead>
                <tr>
                  <th className="th-color">Item Name</th>
                  <th className="th-color">GST%</th>
                  <th className="th-color">Rate</th>
                  <th className="th-color">QTY</th>
                  <th className="th-color">Unit</th>
                  <th className="th-color">Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.itemarray.length > 0 ? (
                  data.itemarray.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td>
                          {item.itemid}-{item.itemname}
                        </td>
                        <td>{item.gst}</td>
                        <td>{item.rate}</td>
                        <td>{item.qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.amount}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div className="datanotfound-center-text">
                        No Item Found 🤔
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tddata-right">Sub Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bolder" }}>{data.subtotal}</td>
                </tr>
                <tr>
                  <td className="tddata-right">Discount</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bolder" }}>{data.discount} %</td>
                  <td style={{ fontWeight: "bolder" }}>
                    {data.discountamount}
                  </td>
                </tr>
                <tr>
                  <td className="tddata-right">Taxable Amount</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bolder" }}>{data.taxableamount}</td>
                </tr>
                <tr>
                  <td className="tddata-right">CGST</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bolder" }}>{data.cgst}</td>
                </tr>
                <tr>
                  <td className="tddata-right">SGST</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bolder" }}>{data.sgst}</td>
                </tr>
                <tr>
                  <td className="tddata-right">IGST</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bolder" }}>{data.igst}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  style={{ border: "1px solid black", backgroundColor: "gray" }}
                >
                  <td className="tddata-right">Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ fontWeight: "bolder" }}>{data.grandtotal}</td>
                </tr>
              </tbody>
            </Table>
        <br />
            <Row
              md={1}
              lg={2}
              className="d-flex justify-content-between align-items-center "
            >
              <Col
                d-flex
                flex-column
                align-items-start
                justify-content-between
                gap-2
              >
                {data.signature ? (
                  <img
                    src={data.signature}
                    alt="signature"
                    width={200}
                    height={100}
                  />
                ) : null}
                <h3>Receiver's Signature</h3>
              </Col>
            </Row>
            <br />
            <hr />
            <Row></Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDeliveryReceipt;
