import React, { useEffect, useState } from "react";
import SearchField from "../../../constants/SearchField";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reloadPage } from "../../../redux/actions/load.action";
import axios from "axios";
import { userUserpaginationPost } from "../../../constants/api";
import EditCrud from "./EditCrud";

const ListCrud = () => {
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [itemId, setItemid] = useState("");
  const fetchData = async () => {
    try {
      const res = await axios.post(userUserpaginationPost, {
        userid: userID,
      });
      setData(
        res.data.data.length > 0
          ? res.data.data.filter((user) => user.status == "Active")
          : []
      );
      dispatch(reloadPage(false));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loadData, searchKeyword]);

  //search logic
  // const filteredData = !data
  //   ? []
  //   : data.filter((item) =>
  //       searchKeyword
  //         ? Object.values(item).some((value) =>
  //             value
  //               ?.toString()
  //               ?.toLowerCase()
  //               ?.includes(searchKeyword.toLowerCase().trim())
  //           )
  //         : true
  //     );

  const filteredData = data.filter((item) => {
    if (!searchKeyword) return true;
    const lowercasedKeyword = searchKeyword.toLowerCase().trim();
    return (
      item.branch.toString().toLowerCase().includes(lowercasedKeyword) ||
      item.companyname.toLowerCase().includes(lowercasedKeyword) ||
      item.designationname.toLowerCase().includes(lowercasedKeyword) ||
      item.name.toLowerCase().includes(lowercasedKeyword) ||
      item.userid.toLowerCase().includes(lowercasedKeyword)
    );
  });

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={9}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title"> Users CRUD Control</span>
            </p>
          </Col>
          <Col lg={3}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>User ID</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Company</th>
              <th>Branch</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr
                  key={index}
                  className={item.status == "In-Active" ? "deactivated" : ""}
                >
                  <td>{item.userid}</td>
                  <td>{item.name}</td>
                  <td>{item.designationname}</td>
                  <td>{item.companyname}</td>
                  <td>{item.branch}</td>
                  <td>
                    <EditCrud userid={item.userid} companyid={item.companyid} id={item.id} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListCrud;
