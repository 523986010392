import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { validatePin } from "../../../../constants/validationOnBlur";

const ContactAddress = ({
  data,
  handleChange,
  showEmptyError,
  states,
  currentCities,
  permanentCities,
  selectedCurrentState,
  setSelectedPermanentState,
  setData,
}) => {
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedPermanentState(selectedCurrentState);
      setData((prev) => ({
        ...prev,
        permanentcountry: data.currentcountry,
        permanentstate: data.currentstate,
        permanentcity: data.currentcity,
        permanentaddress: data.currentaddress,
        permanentpincode: data.currentpincode,
      }));
    } else {
      setSelectedPermanentState("");
      setData((prev) => ({
        ...prev,
        permanentcountry: "",
        permanentstate: "",
        permanentcity: "",
        permanentaddress: "",
        permanentpincode: "",
      }));
    }
  };

  return (
    <Container fluid>
      <Row className="form-container">
        <Col>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <Form.Label>Current Address</Form.Label>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="currentcountry"
                      value={data.currentcountry}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      State <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="currentstate"
                      value={data.currentstate}
                      onChange={handleChange}
                      isInvalid={!data.currentstate && showEmptyError}
                    >
                      <option value="">Select</option>
                      {states.map((state) => {
                        return (
                          <option value={state.name} key={state.isoCode}>
                            {state.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      City <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="currentcity"
                      value={data.currentcity}
                      onChange={handleChange}
                      isInvalid={!data.currentcity && showEmptyError}
                    >
                      <option value="">Select</option>
                      {currentCities.map((city, ind) => {
                        return (
                          <option value={city} key={ind}>
                            {city}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Address<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Current address"
                      name="currentaddress"
                      value={data.currentaddress}
                      onChange={handleChange}
                      isInvalid={data.currentaddress === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Current Address
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Pincode<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      max="6"
                      placeholder="Current pincode"
                      name="currentpincode"
                      value={data.currentpincode}
                      onChange={handleChange}
                      onBlur={() => validatePin(data.currentpincode)}
                      isInvalid={data.currentpincode === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Current Pincode
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col>
              {" "}
              <span className="d-flex justify-content-between">
                <Form.Label>Permanent Address</Form.Label>
                <Form.Check
                  inline
                  className="form-label"
                  label="Same as current address"
                  name="group1"
                  type="checkbox"
                  id="check2"
                  onChange={handleCheckboxChange}
                />
              </span>{" "}
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="permanentcountry"
                      value={data.permanentcountry}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      State <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="permanentstate"
                      value={data.permanentstate}
                      onChange={handleChange}
                      isInvalid={!data.permanentstate && showEmptyError}
                    >
                      <option value="">Select</option>
                      {states.map((state) => {
                        return (
                          <option value={state.name} key={state.isoCode}>
                            {state.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicID">
                    <Form.Label>
                      City <span className="star">*</span>
                    </Form.Label>
                    <Form.Select
                      name="permanentcity"
                      value={data.permanentcity}
                      onChange={handleChange}
                      isInvalid={!data.permanentcity && showEmptyError}
                    >
                      <option value="">Select</option>
                      {permanentCities.map((city, ind) => {
                        return (
                          <option value={city} key={ind}>
                            {city}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Address<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Permanent address"
                      name="permanentaddress"
                      value={data.permanentaddress}
                      onChange={handleChange}
                      isInvalid={data.permanentaddress === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Permanent Address
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Pincode<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      max="6"
                      placeholder="Permanent pincode"
                      name="permanentpincode"
                      value={data.permanentpincode}
                      onChange={handleChange}
                      onBlur={() => validatePin(data.permanentpincode)}
                      isInvalid={data.permanentpincode === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Permanent Pincode
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactAddress;
