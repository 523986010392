import React from "react";
import ListIssue from "./ListIssue";
import { Col, Container, Row } from "react-bootstrap";

const IssueGoods = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <ListIssue />
        </Col>
      </Row>
    </Container>
  );
};

export default IssueGoods;
