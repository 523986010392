import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ListTableTds from './ListTableTds'

const Tds = () => {
  return (
    <Container fluid>
    <Row>
      <Col>
        <ListTableTds />
      </Col>
    </Row>
  </Container>
  )
}

export default Tds