import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

function GlobalPagination({ currentPage, setCurrentPage, totalPagesFromAPI, setPageSize }) {
    const { totalRecords } = totalPagesFromAPI
    const { totalPages } = totalPagesFromAPI; // Set the total number of pages based on your API response

    const numberOfRecords = [10, 20, 30, 40, 50];

    if(!numberOfRecords.includes(totalRecords)){
        numberOfRecords.push(totalRecords);
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const generatePaginationItems = () => {
        const items = [];
        // const totalPages = totalPagesFromAPI.totalPages; // Set the total number of pages based on your API response
        const maxPagesToShow = 5; // Set the maximum number of pages to show initially

        // Calculate the range of pages to display
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        // Ensure that the range always shows maxPagesToShow pages, adjusting startPage if necessary
        startPage = Math.max(1, endPage - maxPagesToShow + 1);

        // Add "Prev" button
        items.push(
            <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        );

        // Add individual page numbers or ellipsis as needed
        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        // Add "Next" button
        items.push(
            <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        );

        return items;
    };
    return (
        <Col className='d-flex justify-content-between p-0'>
            <div>
                <Form.Group className="d-flex gap-2" controlId="sort">
                    <Form.Label className="paginationLabel mt-2" >View</Form.Label>
                    <Form.Select aria-label="Default select example" className='w-50' onChange={(e) => setPageSize(e.target.value)} defaultValue={numberOfRecords[0]} >
                        {numberOfRecords.map((item, index) => (
                            <option key={index} value={item}>{item===totalRecords?"All":item}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <p className="pagination-text">Page {currentPage} of {totalPages} | {totalRecords} records</p>
            </div>
            <div>
                <Pagination>{generatePaginationItems()}</Pagination>
            </div>
        </Col>
    )
}

export default GlobalPagination;