import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListTableAccountLedger from "./ListTableAccountLedger";

function Ledger() {
  const URL = extractAndFormatURLPart("accounting");
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListTableAccountLedger />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Ledger;
