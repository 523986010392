import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import {
  assests_sub,
  equity_liability_sub,
  expenses_sub,
  income_sub,
} from "./Accounts";
// import {
//   notifyError,
//   notifySuccess,
//   notifyWarning,
// } from "../../../constants/toastAlerts";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateCoa } from "../../../redux/actions/chartofaccount.actions";
import { notifyError } from "../../../constants/toastAlert";
// import { coaupdatecoapost } from "../../../constants/api";

const EditAccount = ({
  showEditModal,
  handleEditModal,
  editItem,
  handleCloseEditModal
}) => {
  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid} = useSelector((state) => state.authManager.userData.data[0]) || {};
  
  //state
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [loadState, setLoadState] = useState(false);
  const [coaid, setcoaid] = useState("");
  const [specifyData, setSpecifyData] = useState("");
  const [specifyValue, setSpecifyValue] = useState("");
  const [data, setData] = useState({
    id: editItem ? editItem.id : "",
    coaid: editItem ? editItem.coaid : "",
    category: editItem ? editItem.category : "",
    subcategory: editItem ? editItem.subcategory : "",
    gledger: editItem ? editItem.gledger : "",
    isledger: editItem ? editItem.isledger : ""
  });

  useEffect(() => {
    setData({
      id: editItem ? editItem.id : "",
      coaid: editItem ? editItem.accountid : "",
      category: editItem ? editItem.parentaccount : "",
      subcategory: editItem ? editItem.accounttype : "",
      gledger: editItem ? editItem.accountname : "",
      isledger: editItem ? editItem.isledger : ""
    });
  }, [editItem]);

  //Default data backend needed
  const backendData = {
    id:data.id,
    accountid: data.coaid,
    parentaccount: data.category,
    accounttype: data.subcategory,
    accountname: data.gledger,
    isledger: data.isledger,
    companyid: companyid,
    branchid: branchid,
    userid: userid
  }

  const handleSubmit = async () => {
    if (data.category == "" || data.subcategory == "" || data.gledger == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    dispatch(updateCoa(backendData, handleCloseEditModal, setData));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setShowEmptyError(false);
  };

  return (
    <>
      <MdEdit className="editIcon" onClick={handleEditModal} />
      <Modal
        size="md"
        show={showEditModal}
        onHide={handleCloseEditModal}
        backdrop={true}
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit <span className="form-title">Account</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Account Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Account Code"
                  name="coaid"
                  value={data.coaid}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Parent Account (Category) <span className="star">*</span>
                </Form.Label>
                <Form.Select
                  disabled
                  value={data.category}
                  onChange={(e) => setSpecifyData(e.target.value)}
                  // required
                  isInvalid={data.category === "" && showEmptyError}
                >
                  <option className="invisible" value="">
                    --Select--
                  </option>
                  <option value="Assets">Assets</option>
                  <option value="Equity and Liability">
                    Equity and Liability
                  </option>
                  <option value="Income">Income</option>
                  <option value="Expenses">Expenses</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Choose Category
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Account Type (Sub-category) <span className="star">*</span>
                </Form.Label>
                <Form.Select
                  disabled
                  value={data.subcategory}
                  onChange={(e) => setSpecifyValue(e.target.value)}
                  // required
                  isInvalid={data.subcategory === "" && showEmptyError}
                >
                  <option className="invisible" value="">
                    {data.category === "" ? "Select Category" : "--Select--"}
                  </option>
                  {data.category == "Assets"
                    ? assests_sub.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))
                    : data.category == "Equity and Liability"
                      ? equity_liability_sub.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))
                      : data.category == "Income"
                        ? income_sub.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))
                        : data.category == "Expenses"
                          ? expenses_sub.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))
                          : []}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Choose Sub Category
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Account Name (General Ledger) <span className="star">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Account Name"
                  name="gledger"
                  value={data.gledger}
                  //disabled
                  onChange={handleChange}
                  //required
                  isInvalid={data.gledger === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter General Ledger
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Ledger Applicable? <span className="star">*</span>
                </Form.Label>
                <Form className="d-flex gap-5">
                  <Form.Check
                    type="checkbox"
                    name="isledger"
                    label="Yes"
                    value="Yes"
                    checked={data.isledger === "Yes"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="checkbox"
                    name="isledger"
                    label="No"
                    value="No"
                    checked={data.isledger === "No"}
                    onChange={handleChange}
                  />
                </Form>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "row-reverse" }}>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleCloseEditModal}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSubmit}
          //disabled={!isLastTab}
          >
            {loadData ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />)
              : (
                "Update"
              )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAccount;
