import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = () => {
    const signatureCanvasRef = useRef(null);

    const clearSignature = () => {
      signatureCanvasRef.current.clear();
    };

    const saveSignature = () => {
      const signatureImage = signatureCanvasRef.current.toDataURL();
      
    };

    return (
      <div>
        <SignatureCanvas
          ref={signatureCanvasRef}
          penColor='purple'
          canvasProps={{
            width: 500,
            height: 200,
            style: { border: "1px solid black" },
            className: 'signature-canvas'
          }}
          
        />
        <button onClick={clearSignature}>Clear Signature</button>
        <button onClick={saveSignature}>Save Signature</button>
      </div>
    );
  };

  export default SignaturePad;