import React from "react";
import { extractAndFormatURLPart } from "../../constants/breadCrumb";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ReceiveGoods from "./ReceiveGoods/ReceiveGoods";
import IssueGoods from "./IssueGoods/IssueGoods";
import { useParams } from "react-router-dom";

const ManageStock = () => {
  let { tab } = useParams();
  const URL = extractAndFormatURLPart("inventory");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <span>{URL}</span>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey={tab}
            id="justify-tab-example"
            className="mb-3"
            //onSelect={handleTabSelect}
          >
            <Tab
              eventKey="receivegoods"
              title="Receive Goods"
              className="tabview"
            >
              <ReceiveGoods />
            </Tab>
            <Tab eventKey="issuegoods" title="Issue Goods" className="tabview">
              <IssueGoods />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageStock;
