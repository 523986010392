import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { BiSearch, BiSolidFileExport } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { HiViewColumns } from "react-icons/hi2";
// import { coacoabysitepost } from "../../../../constants/api";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import Select from "react-select";
import html2pdf from "html2pdf.js";
import { DownloadTableExcel } from "react-export-table-to-excel";

const ListTable = () => {
  const pdfRef = useRef(null);

  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;


  let financialyear = localStorage.financialyear;
  // const financialyear = JSON.parse(financialyearString);

  // for by default date as a month this is connected to bellow date wise filtering functions
  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const formattedStartOfMonth = formatDate(monthStart);
  const formattedEndOfMonth = formatDate(monthEnd);

  const loadData = useSelector((state) => state.loadReducer);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // useSatate

  const [data, setData] = useState([]);
  const [rangeType, setRangeType] = useState("");
  const [saleAllData, setSaleAllData] = useState([]);
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);
  const [selectedRange, setSelectedRange] = useState("This Month");

  //For multi select start
  const uniqueCustomer = new Set();
  saleAllData.forEach((data) => {
    uniqueCustomer.add(data.commonname);
  });
  const Customeroptions = Array.from(uniqueCustomer).map((commonname) => ({
    value: commonname,
    label: commonname,
  }));

  const uniqueInvoice = new Set();
  data.forEach((data) => {
    uniqueInvoice.add(data.invoiceno);
  });
  const invoiceOption = Array.from(uniqueInvoice).map((invoiceno) => ({
    value: invoiceno,
    label: invoiceno,
  }));

  const uniqueStatus = new Set();
  data.forEach((data) => {
    uniqueStatus.add(data.status);
  });
  const statusOption = Array.from(uniqueStatus).map((status) => ({
    value: status,
    label: status,
  }));

  const [multiCustomerName, setMultiCustomerName] = useState([]);
  const [multiInvoice, setMultiinvoice] = useState([]);
  const [multiStatus, setMultiStatus] = useState([]);
  const [multiBrandName, setMultiBrandName] = useState([]);

  const handleCustomerChange = (multiCustomerName) => {
    setMultiCustomerName(multiCustomerName || []);
  };
  const handleInvoiceChange = (multiInvoice) => {
    setMultiinvoice(multiInvoice);
  };
  const handleStatusChange = (multiStatus) => {
    setMultiStatus(multiStatus);
  };
  // For multi select end

  const columnData = [
    { name: "invoicedate", label: "Invoice Date" },
    { name: "invoiceno", label: "Invoice No" },
    { name: "saleorderno", label: "Sale Order No" },
    { name: "commonname", label: "Customer Name" },
    { name: "grandtotal", label: "Invoice Amount" },
    { name: "paymentduedate", label: "Payment Due Date" },
    { name: "status", label: "Status", default: true },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnData.map((name) => ({ name, selected: true }))
  );

  useEffect(() => {
    setLoading(true);
    axios

      .post(`https://erp.venturesathi.co.in/api/v1/salesreport/getbyfilter1`, {
        multiCustomerName,
        multiInvoice,
        multiStatus,
        fromDate,
        toDate,
        companyid: companyID,

      })
      .then((res) => {
        setData(res.data.message ? [] : res.data);
        setLoading(false);
        //filterData1();
      })
      .catch((err) => console.log(err));

    //  get all data

    axios

      .post(`https://erp.venturesathi.co.in/api/v1/salesreport/getsalebillreport`)
      .then((res) => {
        setSaleAllData(res.data);
      })
      .catch((err) => console.log(err));
  }, [
    rangeType,
    loadData,
    multiCustomerName,
    multiInvoice,
    multiStatus,
  ]);

  const handleClose = () => {
    setShow(false);
  };

  // check box for visible start

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    columnData.forEach((column) => {
      initialVisibility[column.name] = true;
    });
    return initialVisibility;
  });

  // Remove the useEffect block related to localStorage

  const handleCheckboxChange = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };

  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {}, [columnVisibility]);

  // debu dropdown date range filter start

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const handleDaterange = () => {
    setFromDate("");
    setToDate("");
  };

  // debu dropdown date range filter end

  // financila year should be in that same year to choose from date range start
  const [startYear, endYear] = financialyear.split("-").map(Number);

  // Create the financialYearStart and financialYearEnd dates
  const financialYearStart = new Date(startYear, 3, 1); // April 1, of the start year
  const financialYearEnd = new Date(endYear, 2, 32);

  const currentYear = new Date().getFullYear();

  const isCurrentYear = currentYear >= startYear && currentYear <= endYear;

  const handleFromDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setToDate(e.target.value);
  };
  // financila year should be in that same year to choose from date range end

  //search & filter logic
  const filteredData = !data
    ? []
    : data
        .filter((item) =>
          searchValue
            ? Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchValue.toLowerCase().trim())
              )
            : true
        )
        .filter((item) => {
          if (!fromDate || !toDate) {
            return true; // No date range specified, don't filter based on dates
          } else {
            if (rangeType == "Invoice") {
              const invoiceDate = new Date(item.invoicedate);
              const fromDateObj = new Date(fromDate);
              const toDateObj = new Date(toDate);
              return invoiceDate >= fromDateObj && invoiceDate <= toDateObj;
            } else if (rangeType == "Payment Due") {
              const paymentDueDate = new Date(item.paymentduedate);
              const fromDateObj = new Date(fromDate);
              const toDateObj = new Date(toDate);
              return (
                paymentDueDate >= fromDateObj && paymentDueDate <= toDateObj
              );
            } else {
              return true;
            }
          }
        });

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // handle view another component

  const handleViewAction = (gledger) => {
    navigate(`/reports/trialBalance/accountTransactions`, {
      state: { gledger },
    });
  };

  const handleDownload = () => {
    const element = pdfRef.current;
    const options = {
      margin: 10,
      filename: "sell_report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const content = `
    <div style="font-family: Arial, sans-serif;">
    <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">
      List of All <span style="color: #ffa000;">Sales Report-Invoice wise</span>
    </p>
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr style="background-color: #f0f0f0;">
        <th style="border: 1px solid black; padding: 6px;">Invoice Date</th>
        <th style="border: 1px solid black; padding: 6px;">Invoice No</th>
        <th style="border: 1px solid black; padding: 6px;">Sale Order No</th>
        <th style="border: 1px solid black; padding: 6px;">Customer Name</th>
        <th style="border: 1px solid black; padding: 6px;">Invoice Amount</th>
        <th style="border: 1px solid black; padding: 6px;">Status</th>
       
        </tr>
      </thead>
      <tbody>
      ${data
        .map(
          (item, index) => `
        <tr key=${index}>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.invoicedate
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.invoiceno ? item.invoiceno : ""
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.saleorderno
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.commonname
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.grandtotal
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${item.status}</td>
        </tr>
      `
        )
        .join("")}
    </tbody>
    </table>
  </div>
  `;
    //html2pdf(element, options);
    html2pdf().from(content).set(options).save();
  };

  return (
    <Container fluid>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={8}>
              {/* <p className="section-title form-section ptag">
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={handleLeftArrow}
                />
              </p> */}
            </Col>
            <Col lg={2}>
              <div className="inputFieldIcon">
                <FormControl
                  type="text"
                  placeholder="Search..."
                  aria-label="Search..."
                  aria-describedby="basic-addon2"
                  className="mt-2"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="iconPosition">
                  <BiSearch />
                </span>
              </div>
            </Col>
            <Col md={1} className="d-flex gap-3">
              <span>
                {/* <ReactHTMLTableToExcel
                  id="exportButton"
                  className="commonBtn smallBtn cancelBtn mt-2"
                  table="table-to-export"
                  filename={`sell report-${formatDateToDDMMYYYY(
                    fromDate
                  )} - ${formatDateToDDMMYYYY(toDate)}`}
                  sheet="trial_report"
                  buttonText={
                    <>
                      <BiSolidFileExport /> Export
                    </>
                  }
                /> */}

                <DownloadTableExcel
                  filename="Sell table"
                  sheet="Sales"
                  currentTableRef={pdfRef.current}
                >
                  <Button className="commonBtn smallBtn cancelBtn mt-2">
                    {" "}
                    Excel{" "}
                  </Button>
                </DownloadTableExcel>
              </span>
              <span>
                <Button
                  className="commonBtn smallBtn cancelBtn mt-2"
                  onClick={handleDownload}
                >
                  Save pdf
                </Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={4}>
          <Form.Label className="section-title form-section ptag">
            Sales Reports- Invoice wise
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col style={{ flex: " 0 0 auto", width: "27.33333333%" }}>
          <Form.Label className="form-section ptag text-align-center">
            {`From ${fromDate ? formatDateToDDMMYYYY(fromDate) : ""} to ${
              toDate ? formatDateToDDMMYYYY(toDate) : ""
            }`}
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={3}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
            <Form.Label className="mt-3 text-color">For</Form.Label>
            <Form.Select
              className="mt-2 p-2"
              value={rangeType}
              onChange={(e) => setRangeType(e.target.value)}
            >
              <option value="">--Select--</option>
              <option value="Invoice">Invoice</option>
              <option value="Payment Due">Payment Due</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
            <Form.Label className="mt-3 text-color">Range</Form.Label>
            <Form.Select
              className="mt-2 p-2"
              value={selectedRange}
              onChange={handleSelectedRange}
              disabled={!rangeType}
            >
              <option disabled={!isCurrentYear} value="Today">
                Today
              </option>
              <option disabled={!isCurrentYear} value="Yesterday">
                Yesterday
              </option>
              <option disabled={!isCurrentYear} value="This Week">
                This Week
              </option>
              <option disabled={!isCurrentYear} value="This Month">
                This Month
              </option>
              <option disabled={!isCurrentYear} value="This Quarter">
                This Quarter
              </option>
              <option disabled={!isCurrentYear} value="This Year">
                This Year
              </option>
              <option value="Date Range">Date Range</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">From</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="from"
              // value={data.requestdate}
              value={fromDate}
              onChange={handleFromDateChange}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">To</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="to"
              // value={data.requestdate}
              value={toDate}
              onChange={handleToDateChange}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">Select Column</Form.Label>
            <span>
              <HiViewColumns
                onClick={handleShow}
                className="column-select-icon"
              />
            </span>
          </Form.Group>
        </Col>
      </Row>

      <Row className="justify-content-center table-wrapper-container" lg={12}>
        <Col lg={3}>
          <Form.Group controlId="formMonth">
            <Form.Label className="mt-4  text-color">Customer Name</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={Customeroptions}
              onChange={handleCustomerChange}
              value={multiCustomerName}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),

                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="formMonth">
            <Form.Label className="mt-4 text-color">Invoice No</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={invoiceOption}
              onChange={handleInvoiceChange}
              value={multiInvoice}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),

                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="formMonth">
            <Form.Label className="mt-4 text-color">Status</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={statusOption}
              onChange={handleStatusChange}
              value={multiStatus}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),

                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="table-main-container" ref={pdfRef}>
          <Table id="table-to-export" responsive>
            <thead className="tableContainer">
              <tr>
                {columnData.map(
                  (column) =>
                    columnVisibility[column.name] && (
                      <th key={column.index}>{column.label}</th>
                    )
                )}
              </tr>
            </thead>

            <tbody>
              {loadData || loading ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {columnData.map(
                      (column) =>
                        columnVisibility[column.name] && (
                          <React.Fragment key={column.index}>
                            {column.name === "Action" ? (
                              <td key={column.index}>
                                {/* Eye icon for viewing */}
                                <span
                                  onClick={() => handleViewAction(item.gledger)}
                                >
                                  <AiFillEye className="editIcon" />
                                </span>
                              </td>
                            ) : (
                              <td key={column.index}>
                                {item[column.name.toLowerCase()]}
                              </td>
                            )}
                          </React.Fragment>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="datanotfound-center-text">
                      No Record Found!! 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/*  //---------------Modal-----------------------------// */}
      <Modal
        show={show}
        size="sm"
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <h4>Select Column</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {columnData.map((column) => (
                <div key={column.index} className="mb-3">
                  {column.default ? (
                    <span style={{ fontSize: "14px" }}>{column.label}</span>
                  ) : (
                    <Form.Check
                      type="checkbox"
                      id={column.name}
                      label={column.label}
                      checked={columnVisibility[column.name]}
                      onChange={() => handleCheckboxChange(column.name)}
                    />
                  )}
                </div>
              ))}
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center">
          <Button
            type="button"
            className="commonBtn smallBtn"
            //onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Container>
  );
};

export default ListTable;
