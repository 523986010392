import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ViewModal = ({ title, item }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const keys = Object.keys(item);
  const middleIndex = Math.ceil(keys.length / 2);
  const leftKeys = keys.slice(0, middleIndex);
  const rightKeys = keys.slice(middleIndex);

  return (
    <>
      <MdOutlineRemoveRedEye className="viewIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">{title} Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                {leftKeys.map((key) => (
                  <Row className="mt-1 mb-1" key={key}>
                    <Col className="viewpage-title">{key}:</Col>
                    <Col className="viewpage-value">
                      {!item[key] ? "--" : item[key]}
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col>
                {rightKeys.map((key) => (
                  <Row className="mt-1 mb-1" key={key}>
                    <Col className="viewpage-title">{key}:</Col>
                    <Col className="viewpage-value">
                      {!item[key] ? "--" : item[key]}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewModal;
