import { FETCH_VENDOR_DATA } from "../actionTypes/vendor.actiontypes";

  const initialState = {

    vendorData: {},

  };
  
  //
  export const  vendorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_VENDOR_DATA: {
        return { ...state, vendorData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };