import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListTable from "./ListTable";
import "../../../styles/Sidebar/Sidebar.css";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";

function TermCondition() {
  const URL = extractAndFormatURLPart("admin");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTable />
        </Col>
      </Row>
    </Container>
  );
};

export default TermCondition