import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import ListTable from "./ListTable";

const SellReportsCommission = () => {
  const URL = extractAndFormatURLPart("reports");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTable />
        </Col>
      </Row>
    </Container>
  );
};

export default SellReportsCommission;
