import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ListTableContraEntry from './ListTableContraEntry';

function ContraEntry() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <ListTableContraEntry/>
        </Col>
      </Row>
    </Container>
  )
}

export default ContraEntry