import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import GodownOrShowRoom from './godownOrShowrooms/GodownOrShowRoom';


const GodownOrShowroomTab = () => {
  const [currentTab, setCurrentTab] = useState("Godown/Showroom");
  const URL = extractAndFormatURLPart("admin");

  const handleTabSelect = (key) => {
    // Define the extracted part based on the selected tab event key
    switch (key) {
    //   case "designation":
    //     setCurrentTab("Designation");
    //     break;
    //   case "roletransition":
    //     setCurrentTab("Role Transition");
    //     break;
      default:
        setCurrentTab("Godown/Showroom");
    }
  };
  return (
    <Container fluid>
      <Row >
        <Col className="colStyle">
          <span>{URL + ' / ' + currentTab}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="godownorshowroom"
            id="justify-tab-example"
            className="mb-3"
            //variant='pills'
            //fill
            onSelect={handleTabSelect}
          >
            <Tab eventKey="godownorshowroom" title="Godown/Showroom" className="tabview">
              <GodownOrShowRoom/>
            </Tab>
            {/* <Tab eventKey="roletransition" title="Role Transition" className="tabview">
              Role
            </Tab>
            <Tab eventKey="designation" title="Designation" className="tabview">
              designaton
            </Tab> */}

          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default GodownOrShowroomTab;