import { FETCH_PURCHASE_PAYMENT_DATA } from "../actionTypes/purchasePayment.actionTypes";

  const initialState = {

    purchasePaymentData: {},

  };
  
  //
  export const  purchasePaymentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_PURCHASE_PAYMENT_DATA: {
        return { ...state, purchasePaymentData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };