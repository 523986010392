import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import ListTableCreditNote from './ListTableCreditNote';

function CreditNote() {
  const URL = extractAndFormatURLPart("sales");
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListTableCreditNote/>
        </Col>
      </Row>
    </Container>
  );
}

export default CreditNote