import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ListTableBranches from './ListTableBranches';

function Branches() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <ListTableBranches/>
        </Col>
      </Row>
    </Container>
  )
}

export default Branches