import { FETCH_SALESTARGET_DATA } from "../actionTypes/salesTarget.actiontypes";

const initialState = {

    salesTargetData: {},

  };
  
  //
  export const  salesTargetReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_SALESTARGET_DATA: {
        return { ...state, salesTargetData: { ...payload } };
      }
      
      default: {
        return state;
      }
    }
  };